import { Box, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { cleanDevices, listDevices, setDevice } from '../actions/deviceActions';

class DeviceAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.selectedDevice = undefined;
    this.ref = undefined;
  }

  render() {
    return (
      <Grid container spacing={1} className="grid-input-element">
        <Grid item xs={12}>
          <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element ">
            <Autocomplete
              ref={(ref) => (this.ref = ref)}
              id="selectedDevice"
              options={this.props.devices}
              getOptionLabel={(option) => option.name}
              onChange={(e, option) => {
                this.handleSelectDevice(option);
              }}
              noOptionsText="No hay dispositivos asociados para este IOT"
              fullWidth
              renderInput={(params) => <TextField {...params} label="Selecciona un dispositivo" variant="outlined" />}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }

  handleSelectDevice(option) {
    this.selectedDevice = option;
    this.props.setDevice({ item: option });
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevProps.selectedIot && this.props.selectedIot) ||
      (prevProps.selectedIot?.id && this.props.selectedIot?.id && prevProps.selectedIot.id != this.props.selectedIot.id)
    ) {
      this.list();
    }
    if (prevProps.selectedIot && !this.props.selectedIot) {
      this.props.cleanDevices();
    }
    if (prevProps.selectedDevice && !this.props.selectedDevice) {
      const e = this.ref.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
      if (e) e.click();
      this.selectedDevice = undefined;
    }
  }

  list() {
    let typeDevice = this.props.deviceType ? this.props.deviceType : null;
    this.props.listDevices({
      page: 0,
      size: 300,
      iot: this.props.selectedIot,
      filters: {
        headquarterId: this.props.selectedHeadquarter?.id,
        iot: this.props.selectedIot,
        classification: typeDevice,
      },
    });
  }

  componentWillUnmount() {
    this.props.cleanDevices();
  }
}

const mapStateToProps = (state) => {
  return {
    devices: state.deviceReducer.avaliabledDevices ? state.deviceReducer.avaliabledDevices : [],
    selectedHeadquarter: state.headquarterReducer.selectedHeadquarter,
    selectedIot: state.iotReducer.selectedIot,
    selectedDevice: state.deviceReducer.selectedDevice,
    isRequiredDevices:
      (state.iotReducer.selectedIot && !state.deviceReducer.isRequiredDevices) ||
      state.deviceReducer.isRequiredDevices == appConstants.IS_REQUIRED_DEVICE_LIST
        ? appConstants.IS_REQUIRED_DEVICE_LIST
        : appConstants.IS_NOT_REQUIRED_DEVICE_LIST,
  };
};

const mapDispatchToProps = {
  listDevices,
  cleanDevices,
  setDevice,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceAutocomplete);
