import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { EditIcon, HardDiskIcon } from '../../assets/icons';
import { componentActions, fetchAction, setGlobalTitle } from '../../components/actions/componentActions';
import MenuButttons from '../../components/menu/MenuButtons';

const menu = [
  {
    name: 'Crear una Instalación',
    icon: <EditIcon height="125px" width="125px" />,
    route: '/installation',
  },
  {
    name: 'Instalación paso a paso ',
    icon: <HardDiskIcon height="125px" width="135px" />,
    route: '/installation/create/distribution',
  },
];

const DistributionMenu = (props) => {
  const toolbarButtons = [{ id: appConstants.BACK_BUTTON_ID, history: props.history }];

  useEffect(() => {
    props.setGlobalTitle(appConstants.IOT_VIEW_TITLE);

    props.fetchAction(componentActions.ADD_TOOLBAR_BUTTON, toolbarButtons);

    return function () {};
  }, []);
  return (
    <Box width={1} height={1} display="flex" justifyContent="center" className="background-image-bubble " padding={2}>
      <MenuButttons items={menu} perRow={2} />
    </Box>
  );
};
const mapDispatchToProps = {
  setGlobalTitle,
  fetchAction,
};

export default connect(null, mapDispatchToProps)(DistributionMenu);
