import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgPmIcon(props) {
  const { height, width, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 507.2 507.2"
      {...props}
      height={height ? height : appConstants.ICON_DEFAULT_HEIGHT}
      width={width ? width : appConstants.ICON_DEFAULT_WIDTH}
    >
      <path
        d="M504.528 439.195v54.212c0 5.526-4.47 10.006-9.996 10.006H17.468c-5.526 0-9.996-4.48-9.996-10.006v-54.212L256 396.896z"
        fill="#fdc202"
      />
      <path
        d="M52.315 503.413H17.468c-5.526 0-9.996-4.48-9.996-10.006v-54.212h34.847v54.212c0 5.525 4.47 10.006 9.996 10.006z"
        fill="#f0a70a"
      />
      <path
        d="M504.528 18.593v420.602H7.472V18.593c0-5.526 4.47-10.006 9.996-10.006h477.064c5.525 0 9.996 4.481 9.996 10.006z"
        fill="#766d78"
      />
      <path
        d="M460.103 61.853v224.016c0 5.496-4.46 9.956-9.956 9.956H61.853c-5.496 0-9.956-4.46-9.956-9.956V61.853c0-5.496 4.46-9.956 9.956-9.956h388.294c5.496 0 9.956 4.46 9.956 9.956z"
        fill="#fff5f4"
      />
      <g fill="#fdc202">
        <circle cx={256} cy={367.029} r={29.867} />
        <circle cx={81.268} cy={367.029} r={29.867} />
        <circle cx={430.732} cy={367.029} r={29.867} />
        <path d="M313.32 295.825H198.68v-4.409c0-31.657 25.663-57.319 57.319-57.319 31.657 0 57.319 25.663 57.319 57.319v4.409z" />
      </g>
      <path
        d="M96.7 295.825H61.853c-5.496 0-9.956-4.46-9.956-9.956V61.853c0-5.496 4.46-9.956 9.956-9.956H96.7c-5.496 0-9.956 4.46-9.956 9.956v224.016c0 5.496 4.46 9.956 9.956 9.956z"
        fill="#ebe0da"
      />
      <path
        d="M52.315 8.587c-5.526 0-9.996 4.48-9.996 10.006v420.602H7.472V18.593c0-5.526 4.47-10.006 9.996-10.006z"
        fill="#655e67"
      />
      <path
        d="M271.104 392.779A29.575 29.575 0 01256 396.891c-16.498 0-29.869-13.371-29.869-29.859 0-16.498 13.371-29.869 29.869-29.869a29.565 29.565 0 0115.104 4.112c-8.841 5.167-14.775 14.765-14.775 25.757 0 10.982 5.933 20.58 14.775 25.747zM96.371 392.779a29.575 29.575 0 01-15.104 4.112c-16.498 0-29.869-13.371-29.869-29.859 0-16.498 13.371-29.869 29.869-29.869a29.565 29.565 0 0115.104 4.112c-8.841 5.167-14.775 14.765-14.775 25.757 0 10.982 5.934 20.58 14.775 25.747zM445.836 392.779a29.573 29.573 0 01-15.104 4.112c-16.498 0-29.869-13.371-29.869-29.859 0-16.498 13.371-29.869 29.869-29.869a29.565 29.565 0 0115.104 4.112c-8.841 5.167-14.775 14.765-14.775 25.757 0 10.982 5.934 20.58 14.775 25.747zM273.423 236.794c-23.138 7.378-39.895 29.042-39.895 54.62v4.411h-34.847v-4.411c0-31.651 25.657-57.318 57.318-57.318a57.27 57.27 0 0117.424 2.698z"
        fill="#f0a70a"
      />
      <path d="M494.532 1.12H17.469C7.836 1.12 0 8.956 0 18.588v474.824c0 9.632 7.836 17.468 17.469 17.468h477.063c9.632 0 17.468-7.836 17.468-17.468V18.588c0-9.632-7.836-17.468-17.468-17.468zm0 494.826H17.469a2.537 2.537 0 01-2.534-2.534v-46.75h384.933a7.467 7.467 0 100-14.934H14.934V18.588a2.537 2.537 0 012.534-2.534h477.063a2.537 2.537 0 012.534 2.534v413.14h-67.329a7.467 7.467 0 100 14.934h67.329v46.75a2.536 2.536 0 01-2.533 2.534z" />
      <path d="M61.853 303.292h40.535a7.467 7.467 0 100-14.934H61.853a2.492 2.492 0 01-2.489-2.489V61.853a2.492 2.492 0 012.489-2.489h261.711a7.467 7.467 0 100-14.934H61.853c-9.607 0-17.423 7.816-17.423 17.423v224.016c0 9.607 7.816 17.423 17.423 17.423z" />
      <path d="M467.57 285.869V61.853c0-9.607-7.816-17.423-17.423-17.423h-96.714a7.467 7.467 0 100 14.934h96.714a2.491 2.491 0 012.489 2.489v224.016a2.491 2.491 0 01-2.489 2.489H320.709c-.986-21.114-12.123-39.617-28.633-50.728l49.918-82.805c21.083 9.736 40.575 23.108 57.659 39.799a7.466 7.466 0 0010.559-.123 7.466 7.466 0 00-.122-10.559c-17.917-17.504-38.303-31.603-60.34-41.983l23.16-38.418a7.468 7.468 0 00-12.79-7.71l-24.224 40.184c-25.159-9.789-52.141-14.923-79.85-14.923-57.902 0-112.643 22.353-154.138 62.942a7.468 7.468 0 0010.444 10.676c38.687-37.842 89.719-58.684 143.695-58.684 24.942 0 49.255 4.461 72.015 12.985l-49.288 81.76a64.452 64.452 0 00-22.773-4.143c-34.698 0-63.106 27.42-64.709 61.729h-59.034a7.467 7.467 0 100 14.934h317.89c9.606.001 17.422-7.815 17.422-17.422zm-261.329 2.489c1.585-26.07 23.298-46.794 49.759-46.794s48.174 20.724 49.76 46.794zM256 329.694c-20.586 0-37.334 16.749-37.334 37.335s16.748 37.334 37.334 37.334 37.334-16.748 37.334-37.334c0-20.587-16.748-37.335-37.334-37.335zm0 59.734c-12.351 0-22.4-10.049-22.4-22.4 0-12.352 10.049-22.401 22.4-22.401s22.4 10.049 22.4 22.401-10.049 22.4-22.4 22.4zM81.268 329.694c-20.586 0-37.334 16.749-37.334 37.335s16.748 37.334 37.334 37.334 37.334-16.748 37.334-37.334c0-20.587-16.748-37.335-37.334-37.335zm0 59.734c-12.351 0-22.4-10.049-22.4-22.4 0-12.352 10.049-22.401 22.4-22.401s22.4 10.049 22.4 22.401-10.049 22.4-22.4 22.4zM468.066 367.029c0-20.586-16.748-37.335-37.334-37.335s-37.334 16.749-37.334 37.335 16.748 37.334 37.334 37.334 37.334-16.748 37.334-37.334zm-59.733 0c0-12.352 10.049-22.401 22.4-22.401s22.4 10.049 22.4 22.401c0 12.351-10.049 22.4-22.4 22.4-12.352-.001-22.4-10.049-22.4-22.4z" />
    </svg>
  );
}

export default SvgPmIcon;
