import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgAoaIcon(props) {
  const { height, width, color } = props;
  return (
    <svg viewBox="-4 1 596 596.718" xmlns="http://www.w3.org/2000/svg" {...props}
       height={height ? height : appConstants.ICON_DEFAULT_HEIGHT}
      width={width ? width : appConstants.ICON_DEFAULT_WIDTH}
    >
      <rect fill="#dcdbd6" height={304} rx={16} width={464} x={24} y={152} />
      <path d="M56 152h400v304H56z" fill="#6dc197" />
      <path
        d="M421.93 269.08l-15.177 4.066-5.779-3.336v-9.788l5.779-3.337 15.177 4.066a8 8 0 104.14-15.455l-10.649-2.853 2.853-10.65a8 8 0 00-15.455-4.14l-4.066 15.175-5.78 3.338-8.476-4.894V234.6l11.11-11.11a8 8 0 10-11.314-11.313l-7.8 7.8-7.8-7.8a8 8 0 10-11.314 11.313L368.5 234.6v6.674l-8.476 4.894-5.78-3.338-4.066-15.175a8 8 0 00-15.455 4.14l2.853 10.65-10.651 2.855a8 8 0 104.14 15.455l15.176-4.066 5.78 3.337v9.787l-5.78 3.337-15.176-4.066a8 8 0 00-4.14 15.455l10.648 2.853-2.853 10.65a8 8 0 1015.455 4.141L354.241 287l5.78-3.337 8.476 4.893v6.674l-11.109 11.11a8 8 0 1011.312 11.317l7.8-7.8 7.8 7.8a8 8 0 1011.314-11.314l-11.11-11.11v-6.674l8.476-4.893 5.78 3.337 4.066 15.176a8 8 0 0015.455-4.141l-2.853-10.65 10.649-2.853a8 8 0 10-4.14-15.455zm-36.956.729L376.5 274.7l-8.476-4.893v-9.788l8.476-4.894 8.477 4.894zM168 312H88v-64l40-32 40 32z"
        fill="#62ae88"
      />
      <path d="M240 280h32v16a16 16 0 01-16 16 16 16 0 01-16-16v-16z" fill="#cdccc8" />
      <path
        d="M278.368 275.755L272 280h-32l-6.368-4.245A39.6 39.6 0 01216 242.808a39.6 39.6 0 0124.891-36.764l.4-.162a39.6 39.6 0 0129.412 0l.4.162A39.6 39.6 0 01296 242.808a39.6 39.6 0 01-17.632 32.947z"
        fill="#eeb436"
      />
      <path
        d="M296 242.81a39.489 39.489 0 01-2.27 13.19h-18.42l-5.65 5.66A8.043 8.043 0 01264 264a7.085 7.085 0 01-.79-.04 8 8 0 01-5.87-3.52l-8.35-12.53-1.83 3.67A8.011 8.011 0 01240 256h-21.73a39.489 39.489 0 01-2.27-13.19c0-.94.03-1.88.1-2.81h18.96l5.78-11.58a8.014 8.014 0 0113.82-.86l10.58 15.88 1.1-1.1A8.008 8.008 0 01272 240h23.9c.07.93.1 1.87.1 2.81z"
        fill="#eeb436"
      />
      <path d="M288 360h56v32h-56z" fill="#027e2a" />
      <path d="M88 360h88v32H88z" fill="#02a437" />
      <path d="M176 360h112v32H176z" fill="#029532" />
      <path d="M344 360h80v32h-80z" fill="#027226" />
      <path
        d="M296 242.81a39.489 39.489 0 01-2.27 13.19h-18.42l-5.65 5.66A8.043 8.043 0 01264 264a7.085 7.085 0 01-.79-.04 8 8 0 01-5.87-3.52l-8.35-12.53-1.83 3.67A8.011 8.011 0 01240 256h-21.73a39.489 39.489 0 01-2.27-13.19c0-.94.03-1.88.1-2.81h18.96l5.78-11.58a8.014 8.014 0 0113.82-.86l10.58 15.88 1.1-1.1A8.008 8.008 0 01272 240h23.9c.07.93.1 1.87.1 2.81z"
        fill="#ee9d0d"
      />
      <path d="M88 264h80v16H88z" fill="#5aa07d" />
      <g fill="#62ae88">
        <circle cx={256} cy={424} r={8} />
        <circle cx={224} cy={424} r={8} />
        <circle cx={288} cy={424} r={8} />
      </g>
      <g fill="#6dc197">
        <path d="M180.985 87.642a8 8 0 01-6.2-13.049A104.144 104.144 0 01281.49 39.455a8 8 0 01-3.99 15.494 88.147 88.147 0 00-90.3 29.741 7.983 7.983 0 01-6.215 2.952zM330.005 87.642a7.985 7.985 0 01-6.211-2.952 88.126 88.126 0 00-24.306-20.742A8 8 0 11307.5 50.1a104.039 104.039 0 0128.7 24.493 8 8 0 01-6.2 13.049zM205.824 107.824a8 8 0 01-6.2-13.05A72.515 72.515 0 01237.5 70.447a8 8 0 013.991 15.494 56.4 56.4 0 00-29.456 18.931 7.988 7.988 0 01-6.211 2.952zM305.166 107.824a7.989 7.989 0 01-6.211-2.952 55.7 55.7 0 00-36.45-20.261 8 8 0 011.98-15.877 71.637 71.637 0 0146.882 26.04 8 8 0 01-6.2 13.05zM230.657 128a8 8 0 01-6.2-13.048 39.857 39.857 0 0131.043-14.775 8 8 0 010 16 23.92 23.92 0 00-18.627 8.871 7.985 7.985 0 01-6.216 2.952zM280.333 128a7.983 7.983 0 01-6.211-2.952 24.353 24.353 0 00-2.762-2.875 8 8 0 0110.59-11.994 40.29 40.29 0 014.584 4.773 8 8 0 01-6.2 13.048z" />
      </g>
      <circle cx={40.971} cy={304.971} fill="#cdccc8" r={15.029} />
    </svg>
  );
}

export default SvgAoaIcon;
