import { Box, Grid, Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import 'c3/c3.css';
import { format } from 'd3-format';
import React from 'react';
import C3Chart from 'react-c3js';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { commonMessage, icon } from '../../service/messageHandler';
import {
  convertDate,
  convertDateMonth,
  getDateDayAndHour,
  getDatePeriod,
  getDateYearAndDay,
  getDifferentBetweenDates,
  getDifferentBetweenDatesMount,
  getDifferentBetweenDay,
  getGroupBy,
} from '../../service/util';
import { downloadTrend, fetchRequest, listSeriesComparate } from '../action/dashboardActions';
import { getFilterDateObservable } from '../services/filterDateObservable';
import '../styles/dashboard.scss';
import ComparativeDate from './ComparativeDate';

class BarGraphicComparative extends React.Component {
  constructor(props) {
    super(props);

    this.info = {
      updateIn: 5,
      frecuency: '',
      average: 0,
      current: undefined,
      period: 'minutes',
    };
    this.state = {
      graphic: {
        data: {
          x: 'x',
          columns: [['x'], ['Rango1'], ['Rango2']],
          colors: {
            Rango1: '#1d2877',
            Rango2: '#3dcd58',
          },
          type: 'bar',
        },
        bar: {
          width: {
            ratio: 0.5,
          },
        },

        axis: {
          x: {
            label: {
              text: 'FECHA',
              position: 'outer-right',
            },

            type: 'timeseries',
            tick: {
              format: '%m-%d %H:%M',
            },
          },
          y: {
            label: {
              text: this.getMedida(),
              position: 'outer-top',
            },
            type: 'cantidad',
            position: 'outer-middle',
            tick: {
              format: format('.3f'),
            },
          },
        },
        tooltip: {
          format: {
            title: function (x) {
              return `${convertDate(x)}`;
            },
          },
        },
      },

      valuesHistory: this.props.valuesPerFieldReport && this.props.valuesPerFieldReport[this.props.field?.name],
    };

    this.ref = {};

    this.timeOutRefres = undefined;
    this.reload = true;
    this.subscription = [];
  }
  getMedida = () => {
    if (this.props.field?.masterField?.unitMeasurement === '') {
      return '';
    }
    if (this.props.field?.masterField?.unitMeasurement === 'NA') {
      return '';
    }
    if (this.props.field?.masterField?.unitMeasurement === 'PORCENTAJE') {
      return '%';
    }
    return this.props.field?.masterField?.unitMeasurement;
  };

  getFilter = () => {
    let from = new Date(Date.now() - this.info.frecuency * 60000);
    let fromFormatted = convertDate(from);
    let currentDate = convertDate(new Date(Date.now()));
    return {
      page: 0,
      size: 300,
      from: fromFormatted,
      to: currentDate,
      filters: { fieldId: this.props.field.id, deviceId: this.props.field.device.id },
    };
  };

  getFilterByField = () => {
    let from = new Date(Date.now() - this.info.frecuency * 60000);
    let fromFormatted = convertDate(from);
    let currentDate = convertDate(new Date(Date.now()));
    return {
      page: 0,
      size: 3000,
      from: fromFormatted,
      to: currentDate,
      filters: [this.props.field],
    };
  };

  handleDownload = (event) => {
    this.props.downloadTrend(this.getFilter());
  };

  getValuesSerie(serie) {
    return serie.content.content.map((element) => {
      return Number(element.value);
    });
  }

  getDateSerie(serie) {
    return serie.content.content.map((element) => {
      return Number(element.updatedAt);
    });
  }

  getDateValue(serieOne, serieTwo, columOne, columTwo) {
    let date = [];
    let aux = 1;
    let dateMax;
    if (serieOne.length >= serieTwo.length) {
      for (let i = 0; i < columOne.length; i++) {
        if (i != 0) {
          if (columOne[i] === 'Sin dato') {
            date.push(new Date(dateMax));
            aux++;
          } else {
            date.push(new Date(serieOne[i - aux]?.createdAt));
            dateMax = serieOne[i - aux]?.createdAt;
          }
        }
      }
    } else {
      for (let i = 0; i < columTwo.length; i++) {
        if (i != 0) {
          if (columTwo[i] === 'Sin dato') {
            date.push(new Date(dateMax));
            aux++;
          } else {
            date.push(new Date(serieTwo[i - aux]?.createdAt));
            dateMax = serieOne[i - aux]?.createdAt;
          }
        }
      }
    }

    return date;
  }

  putInfoInGraphic() {
    let values = this.props.valuesPerFieldReport[this.props.field?.name]?.series;
    this.reload = false;
    if (values?.length >= 2) {
      let periodo = getDatePeriod(this.info.period);
      let dataNew = [[...this.getElementSerie(values[0])], [...this.getElementSerie(values[1])]];

      let date = 0;
      let date2 = 0;
      let diferent = 0;
      let diferent2 = 0;
      if (this.props.globalSerie.length > 0) {
        date = convertDate(new Date(this.props.globalSerie[0].from));
        date2 = convertDate(new Date(this.props.globalSerie[1].from));
        if (periodo === 'Cada mes') {
          diferent = getDifferentBetweenDatesMount(this.props.globalSerie[0].from, this.props.globalSerie[0].to);
          diferent2 = getDifferentBetweenDatesMount(this.props.globalSerie[1].from, this.props.globalSerie[1].to);
        } else {
          diferent = getDifferentBetweenDay(this.props.globalSerie[0].from, this.props.globalSerie[0].to);
          diferent2 = getDifferentBetweenDay(this.props.globalSerie[1].from, this.props.globalSerie[1].to);
        }
      } else {
        date = convertDate(new Date(this.props.localSerie[0].from));
        date2 = convertDate(new Date(this.props.localSerie[1].from));
        if (periodo === 'Cada mes') {
          diferent = getDifferentBetweenDatesMount(this.props.localSerie[0].from, this.props.localSerie[0].to);
          diferent2 = getDifferentBetweenDatesMount(this.props.localSerie[1].from, this.props.localSerie[1].to);
        } else {
          diferent = getDifferentBetweenDay(this.props.localSerie[0].from, this.props.localSerie[0].to);
          diferent2 = getDifferentBetweenDay(this.props.localSerie[1].from, this.props.localSerie[1].to);
        }
      }

      let arrayDate = this.getArrayDate(diferent, periodo, date);
      let arrayDate2 = this.getArrayDate(diferent2, periodo, date2);
      let dataColumns1 = [];
      let dataColumns2 = [];

      const { data: dataColumns } = this.state.graphic;

      if (arrayDate != undefined && arrayDate2 != undefined && dataNew != undefined) {
        dataColumns1 = [...this.getDataColumn(arrayDate, dataNew[0], periodo, date)];
        dataColumns2 = [...this.getDataColumn2(arrayDate2, dataNew[1], periodo, date2)];
        let aux = this.getDateValue(
          [...this.getElementSerie(values[0])],
          [...this.getElementSerie(values[1])],
          dataColumns1,
          dataColumns2,
        );

        if (dataColumns.columns[0].length <= 1) {
          aux.forEach((element) => {
            dataColumns.columns[0].push(element);
          });
          dataColumns1.forEach((element) => {
            dataColumns.columns[1].push(element);
          });
          dataColumns2.forEach((element) => {
            dataColumns.columns[2].push(element);
          });
        }
      } else {
        commonMessage('No hay datos disponibles para la grafica', 'Dashboard', icon.info);
      }

      this.reload = true;
      let translate = {
        data: dataColumns,
        axis: { ...this.state.graphic.axis },
        regions: this.state.graphic.regions,
      };
      this.subscription.push(
        getFilterDateObservable().subscribe((data) => {
          if (data.name == this.props.field.name) {
            this.getSeries(data.series);
          }
        }),
      );
      this.setState({ graphic: translate, valuesHistory: { series: values } });
    }
  }

  getDataColumn(arrayDate, arrayData, periodo, date) {
    let arrayAux = [];
    let stop = 0;
    for (let step = 0; step < arrayDate.length; step++) {
      if (arrayData.length > 0) {
        for (stop = 0; stop < arrayData.length; stop++) {
          if (periodo === 'Cada día') {
            if (arrayDate[step] != getDateYearAndDay(new Date(arrayData[stop]?.createdAt))) {
              arrayAux[step] = 'Sin dato';
            } else {
              arrayAux[step] = arrayData[stop]?.totalValue;
              stop = arrayData.length;
            }
          }
          if (periodo === 'Cada hora') {
            if (arrayDate[step] != getDateDayAndHour(new Date(arrayData[stop]?.createdAt))) {
              arrayAux[step] = 'Sin dato';
            } else {
              arrayAux[step] = arrayData[stop]?.totalValue;
              stop = arrayData.length;
            }
          }
          if (periodo === 'Cada mes') {
            if (arrayDate[step] != convertDateMonth(new Date(arrayData[stop]?.createdAt))) {
              arrayAux[step] = 'Sin dato';
            } else {
              arrayAux[step] = arrayData[stop]?.totalValue;
              stop = arrayData.length;
            }
          }
        }
      } else {
        commonMessage(
          'La fecha selecionada para el rango 1 no contiene información almacenaada para presentar',
          'Dashboard',
          icon.info,
        );
      }
    }
    return arrayAux;
  }

  getDataColumn2(arrayDate, arrayData, periodo, date2) {
    let arrayAux = [];
    let stop = 0;

    for (let step = 0; step < arrayDate.length; step++) {
      if (arrayData.length > 0) {
        for (stop = 0; stop < arrayData.length; stop++) {
          if (periodo === 'Cada día') {
            if (arrayDate[step] != getDateYearAndDay(new Date(arrayData[stop]?.createdAt))) {
              arrayAux[step] = 'Sin dato';
            } else {
              arrayAux[step] = arrayData[stop]?.totalValue;
              stop = arrayData.length;
            }
          }
          if (periodo === 'Cada hora') {
            if (arrayDate[step] != getDateDayAndHour(new Date(arrayData[stop]?.createdAt))) {
              arrayAux[step] = 'Sin dato';
            } else {
              arrayAux[step] = arrayData[stop]?.totalValue;
              stop = arrayData.length;
            }
          }
          if (periodo === 'Cada mes') {
            if (arrayDate[step] != convertDateMonth(new Date(arrayData[stop]?.createdAt))) {
              arrayAux[step] = 'Sin dato';
            } else {
              arrayAux[step] = arrayData[stop]?.totalValue;
              stop = arrayData.length;
            }
          }
        }
      } else {
        commonMessage(
          'La fecha selecionada para el rango 2 no contiene información almacenaada para presentar',
          'Dashboard',
          icon.info,
        );
      }
    }
    return arrayAux;
  }

  sumDay(fecha, dias) {
    fecha.setDate(fecha.getDate() + dias);
    return getDateYearAndDay(fecha);
  }

  sumHours(fecha, hours) {
    fecha.setHours(fecha.getHours() + hours);
    return getDateDayAndHour(fecha);
  }

  sumMounth(fecha, dias) {
    fecha.setMonth(fecha.getMonth() + dias);
    return convertDateMonth(fecha);
  }

  getArrayDate(diferent, periodo, day) {
    let arrayDate = [];
    switch (periodo) {
      case 'Cada día':
        for (let step = 0; step < diferent + 1; step++) {
          arrayDate.push(this.sumDay(new Date(day), step));
        }
        return arrayDate;
        break;

      case 'Cada hora':
        for (let step = 0; step < diferent * 24 + 1; step++) {
          arrayDate.push(this.sumHours(new Date(day), step));
        }
        return arrayDate;
        break;

      case 'Cada mes':
        for (let step = 0; step < diferent + 1; step++) {
          arrayDate.push(this.sumMounth(new Date(day), step));
        }
        return arrayDate;
        break;
      default:
        return (arrayDate = []);
    }
  }

  getElementSerie(serie) {
    return serie.content.content.map((element) => {
      return element;
    });
  }

  componentDidMount() {
    this.subscription.push(
      getFilterDateObservable().subscribe((data) => {
        if (data.name == this.props.field.name) {
          this.getSeries(data.series);
        }
      }),
    );
  }

  getSeries(data) {
    data.forEach((element) => {
      const frecuency = getDifferentBetweenDates(Date.parse(element.from), Date.parse(element.to)) / 60000;

      let gb = getGroupBy(frecuency);
      this.info.period = gb;
    });

    if (data != '') {
      this.props.listSeriesComparate(data, this.props.field);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const currentSerie = this.props.valuesPerFieldReport[this.props.field.name]?.series;
    const prevSeries = prevState.valuesHistory?.series;

    if (!prevSeries && currentSerie) {
      this.putInfoInGraphic();
    } else if (prevSeries && currentSerie) {
      if (prevSeries.length != currentSerie.length) {
        this.putInfoInGraphic();
      } else {
        if (prevSeries.length == 2) {
          if (
            prevSeries[0].content.length != currentSerie[0].content.length ||
            prevSeries[1].content.length != currentSerie[1].content.length
          ) {
            this.putInfoInGraphic();
          } else {
            if (
              prevSeries[0].content.content[0]?.id != currentSerie[0].content.content[0]?.id ||
              prevSeries[0].content.content[prevSeries[0].content.content.length - 1]?.id !=
                currentSerie[0].content.content[prevSeries[0].content.content.length - 1]?.id ||
              prevSeries[1].content.content[1]?.id != currentSerie[1].content.content[1]?.id ||
              prevSeries[1].content.content[prevSeries[1].content.content.length - 1]?.id !=
                currentSerie[1].content.content[prevSeries[1].content.content.length - 1]?.id
            ) {
              this.putInfoInGraphic();
            }
          }
        }
      }
    }
  }

  componentWillUnmount() {
    this.subscription.forEach((element) => {
      element.unsubscribe();
    });
  }

  render() {
    return (
      <Box height={1} width={1}>
        <Grid container>
          <Box height={1 / 10} width={1} margin={2}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Box alignItems="center" display="flex" height={1} width={1}></Box>
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>Compara por fechas</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ComparativeDate
                      series={[
                        {
                          name: appConstants.BAR_INITIAL_RANGE_COMPARATIVE,
                          title: 'Comparativo 1',
                          firstNameTitle: 'Fecha comparativa inicial rango 1',
                          secondNameTitle: 'Fecha comparativa final rango 1',
                        },
                        {
                          name: appConstants.BAR_SECOND_RANGE_COMPARATIVE,
                          title: 'Comparativo 2',
                          firstNameTitle: 'Fecha comparativa inicial rango 2',
                          secondNameTitle: 'Fecha comparativa final rango 2',
                        },
                      ]}
                      md={6}
                      lg={6}
                      xl={6}
                      nameItem={this.props.field.name}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                <Box className="font-bold fs-title-graphic t-center" justifyContent="center" display="flex">
                  {this.props.field.device.name}
                </Box>
              </Grid>
              <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                <Box fontWeight="fontWeightBold" justifyContent="center" display="flex">
                  <Typography variant="h6">{this.props.field.name}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box height={9 / 10} width={1} padding={2}>
            {
              <C3Chart
                data={this.state.graphic.data}
                axis={this.state.graphic.axis}
                regions={this.state.graphic.regions}
                tooltip={this.state.graphic.tooltip}
                color={this.state.graphic.color}
                legend={this.state.graphic.legend}
                padding={this.state.graphic.padding}
              />
            }
            <Typography align="right" className="ff-poppins-small">
              <strong>{getDatePeriod(this.info.period)}</strong>
            </Typography>
          </Box>
        </Grid>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    intervals: state.dashboardReducer.intervals ? state.dashboardReducer.intervals : [],
    values: state.dashboardReducer.values ? state.dashboardReducer.values : [],
    valuesPerFieldReport: state.dashboardReducer.valuesPerFieldReport
      ? state.dashboardReducer.valuesPerFieldReport
      : {},
    isRequiredAddButtons: state.componentsReducer.buttons
      ? appConstants.IS_NOT_REQUIRED_ADD_BUTTON
      : appConstants.IS_REQUIRED_ADD_BUTTON,
    globalSerie: state.dashboardReducer.globalSeries ? state.dashboardReducer.globalSeries : [],
    localSerie: state.dashboardReducer.localSeries ? state.dashboardReducer.localSeries : [],
  };
};

const mapDispatchToProps = {
  downloadTrend,
  fetchRequest,
  listSeriesComparate,
};

export default connect(mapStateToProps, mapDispatchToProps)(BarGraphicComparative);
