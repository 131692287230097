import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgUser(props) {
  const { height, width, color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}
      height={height ? height : appConstants.ICON_DEFAULT_HEIGHT}
      width={width ? width : appConstants.ICON_DEFAULT_WIDTH}
    >
      <path
        d="M414.849 382.729c.173-61.316-35.562-133.714-84.467-177.83l22.771-13.234c13.734-7.982 22.25-24.954 22.319-49.076.138-48.557-34.005-107.695-76.262-132.092C277.944-1.78 258.675-2.92 244.761 5.165l-84.455 49.081c.045-.026.091-.049.136-.075-13.812 7.948-22.386 24.949-22.455 49.149-.089 31.259 14.039 66.894 35.382 94.83l-42.008 24.413c.185-.108.374-.208.56-.313-21.237 12.015-34.437 37.98-34.542 75.031l-.228 80.188 233.014 134.53 84.455-49.081.229-80.189z"
        fill="#acdd58"
      />
      <g fill="#8dc43d">
        <path d="M131.36 222.56l84.455-49.08c16.845-9.789 38.844-10.698 63.429-1.262l-84.457 49.08c-24.583-9.435-46.582-8.527-63.427 1.262zM318.186 194.701l-84.455 49.08c55.045 42.447 96.85 121.718 96.662 188.027l-.227 80.189 84.455-49.081.228-80.188c.187-66.309-41.618-145.58-96.663-188.027z" />
      </g>
      <path
        d="M299.209 10.496C277.943-1.782 258.674-2.922 244.76 5.164l-84.455 49.081c13.914-8.085 33.183-6.945 54.448 5.332 42.257 24.397 76.4 83.536 76.262 132.092-.068 24.122-8.585 41.095-22.319 49.076l84.455-49.081c13.734-7.982 22.25-24.954 22.319-49.076.139-48.556-34.004-107.695-76.261-132.092z"
        fill="#ccff69"
      />
      <path
        d="M214.753 59.577c42.258 24.397 76.4 83.536 76.262 132.093-.116 40.827-24.43 61.172-57.285 52.112 55.045 42.447 96.85 121.718 96.662 188.027l-.227 80.188L97.151 377.465l.228-80.188c.188-66.31 42.306-97.128 97.411-75.979-32.799-28.845-56.918-77.154-56.802-117.981.136-48.556 34.508-68.138 76.765-43.74z"
        fill="#acdd58"
      />
      <g fill="#58948e">
        <path d="M333.409 370.945l.185-65.242-43.15-24.912-45.127 26.225v13.069l-11.835-6.833-45.128 26.225v49.588l56.637 32.781-.104 65.242L288.036 512l45.233-26.225v-13.026l11.796 6.793 45.126-26.225v-49.589z" />
        <path d="M288.141 446.758l45.128-26.225v65.242L288.036 512z" />
      </g>
      <path fill="#82cf95" d="M188.354 339.477l45.128-26.225 56.777 32.781-44.942 26.225z" />
      <path fill="#58948e" d="M345.065 429.954l45.127-26.226v49.589l-45.127 26.225z" />
      <path fill="#82cf95" d="M288.281 397.171l45.128-26.226 56.783 32.783-45.127 26.226z" />
      <path fill="#58948e" d="M288.466 331.929l45.128-26.226-.185 65.242-45.128 26.226z" />
      <path fill="#82cf95" d="M245.317 307.016l45.127-26.225 43.15 24.912-45.128 26.226z" />
      <path
        fill="#6fb59d"
        d="M288.466 331.929l-.185 65.242 56.784 32.783v49.588l-56.924-32.784-.105 65.242-43.149-24.912.104-65.242-56.637-32.781v-49.588l56.963 32.781v-65.242z"
      />
    </svg>
  );
}

export default SvgUser;
