import { appConstants } from '../../appConstants';
import ApiAxios from '../../service/Api';

export const licenseHistoryActions = {
  SET_LICENSE_HISTORY: 'SET_LICENSE_HISTORY',
  ADD_LICENSE_HISTORY: 'ADD_LICENSE_HISTORY',
  FETCHING_LICENSE_HISTORY_REQUEST: 'FETCHING_LICENSE_HISTORY_REQUEST',

  FETCHING_LICENSE_HISTORY_SAVE_SUCCESS: 'FETCHING_LICENSE_HISTORY_SAVE_SUCCESS',
  FETCHING_LICENSE_HISTORY_UPDATE_SUCCESS: 'FETCHING_LICENSE_HISTORY_UPDATE_SUCCESS',
  FETCHING_LICENSE_HISTORY_LIST_SUCCESS: 'FETCHING_LICENSE_HISTORY_LIST_SUCCESS',
  FETCHING_LICENSE_HISTORY_FAILURE: 'FETCHING_LICENSE_HISTORY_FAILURE',
  FETCHING_LICENSE_HISTORY_REQUEST_LIST: 'FETCHING_LICENSE_HISTORY_REQUEST_LIST',
  CLEAN_LICENSE_HISTORY_STORE: 'CLEAN_LICENSE_HISTORY_STORE',
  FETCHING_LICENSE_HISTORY_DELETE_SUCCESS: 'FETCHING_LICENSE_HISTORY_DELETE_SUCCESS',
};

export const setlicense = (payload) => {
  return {
    type: licenseHistoryActions.SET_LICENSE_HISTORY,
    payload,
  };
};

export const cleanlicenses = () => {
  return {
    type: licenseHistoryActions.CLEAN_LICENSE_HISTORY_STORE,
  };
};

export const fetchRequest = (type, payload) => {
  return {
    type,
    payload,
  };
};

export const savelicense = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_LICENSE_HISTORY, item, dispatch, fetchRequest, {
      request: licenseHistoryActions.FETCHING_LICENSE_HISTORY_REQUEST,
      success: licenseHistoryActions.FETCHING_LICENSE_HISTORY_SAVE_SUCCESS,
      error: licenseHistoryActions.FETCHING_LICENSE_HISTORY_FAILURE,
    });
  };
};

export const updatelicense = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.put(appConstants.CONTEXT_LICENSE_HISTORY, item, dispatch, fetchRequest, {
      request: licenseHistoryActions.FETCHING_LICENSE_HISTORY_REQUEST,
      success: licenseHistoryActions.FETCHING_LICENSE_HISTORY_UPDATE_SUCCESS,
      error: licenseHistoryActions.FETCHING_LICENSE_HISTORY_FAILURE,
    });
  };
};

export const listlicenses = (dto) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_LICENSES_HISTORIES, dto, dispatch, fetchRequest, {
      request: licenseHistoryActions.FETCHING_LICENSE_HISTORY_REQUEST_LIST,
      success: licenseHistoryActions.FETCHING_LICENSE_HISTORY_LIST_SUCCESS,
      error: licenseHistoryActions.FETCHING_LICENSE_HISTORY_FAILURE,
    });
  };
};

export const deletelicense = (id) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.delete(`${appConstants.CONTEXT_LICENSE_HISTORY}${id}`, dispatch, fetchRequest, {
      request: licenseHistoryActions.FETCHING_LICENSE_HISTORY_REQUEST_LIST,
      success: licenseHistoryActions.FETCHING_LICENSE_HISTORY_DELETE_SUCCESS,
      error: licenseHistoryActions.FETCHING_LICENSE_HISTORY_FAILURE,
    });
  };
};
