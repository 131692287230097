import { Box, Button, Card } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { cleanCompany, deleteCompany, fetchRequest, listCompanies } from '../../company/actions/companyActions';
import { convertDate } from '../../service/util';
import { fetchRequest as fetchRequestLicense, licenseActions, reactiveLicense } from '../actions/licenseActions';
import { fetchRequest as fetchRequestCycleLicense, licenseCycleActions } from '../actions/licenseCycleActions';
import LicenseReactiveModal from './LicenseReactiveModal';

const columns = [
  { id: 'company', label: 'Compañia' },
  { id: 'startDate', label: 'Fecha inicio', format: 'date' },
  {
    id: 'endDate',
    label: 'Fecha fin ',
    format: 'date',
  },
  {
    id: 'active',
    label: 'Estado ',
  },
  {
    id: 'cantDevice',
    label: 'Dispositivos ',
  },
];

const ListLicenses = (props) => {
  const { list, licenseReactived } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    props.listCompanies({
      page: 0,
      size: 300,
      filters: { license: { cantDevice: '3' } },
    });

    return () => {
      props.cleanCompany();
    };
  }, [licenseReactived]);

  const handleEdit = (itemSelected) => {
    props.fetchRequestLicense(licenseActions.SET_LICENSE, { item: itemSelected });
    props.fetchRequestCycleLicense(licenseCycleActions.ADD_LICENSE_CYCLE,{id: itemSelected.licenseCycleId})
  };

  const isActive = (active) => {
    return active ? 'Activa' : 'Inactiva';
  };
  const isBoolean = (value) => {
    return typeof value === 'boolean';
  };
  const format = (value) => {
    return convertDate(new Date(value));
  };
  return (
    <Box width={1} height={1}>
      <Card className="border-10">
        <TableContainer className="table-master">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    className="bg-primary fc-white ff-poppins fw-bold fs-Large t-center-important"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}

                <TableCell align="center" className="bg-primary fc-white ff-poppins fw-bold fs-Large  " key={'action'}>
                  Ajustar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((company) => {
                let row = { ...company.license, company: company.name };
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} className="t-center-important">
                          {isBoolean(value) && isActive(value)}
                          {!isBoolean(value) && column.format ? format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell key={'action'} className="center-info" align="center">
                      {
                        <Button
                          color="primary"
                          className="btn-transparent ff-poppins fc-green min-width-1px"
                          onClick={(e) => handleEdit(company.license)}
                        >
                          <LicenseReactiveModal company={company} />
                        </Button>
                      }
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Card>
    </Box>
  );
};

const mapDispatchToProps = {
  listCompanies,
  cleanCompany,
  fetchRequestLicense,
  fetchRequest,
  deleteCompany,
  convertDate,
  reactiveLicense,
  fetchRequestCycleLicense,
};

const mapStateToProps = (state) => {
  return {
    list: state.companyReducer.avaliabledCompanies ? state.companyReducer.avaliabledCompanies : [],
    isRequiredCompanies: state.companyReducer.isRequiredCompanies
      ? state.companyReducer.isRequiredCompanies
      : appConstants.IS_REQUIRED_COMPANY_LIST,
    company: state.companyReducer.selectedCompany ? state.companyReducer.selectedCompany : {},
    licenseReactived: state.licenseReducer.licenseReactived ? state.licenseReducer.licenseReactived : undefined,
    license: state.licenseReducer.selectedLicense ? state.licenseReducer.selectedLicense : {},
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListLicenses);
