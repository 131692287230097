import { Box, Card, Grid } from '@material-ui/core';
import React from 'react';
import AutoCompleteFilters from '../../components/filters/AutoCompleteFilters';
import AlarmForm from '../components/AlarmForm';

const AlarmWithSelectsForms = (props) => {
  return (
    <Box boxShadow={3} width={1} height={1}>
      <Card>
        <Grid container spacing={1}>
          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
            <Box paddingTop={2} paddingLeft={2} paddingRight={2}>
              <AutoCompleteFilters headquarter={true} iot={true} device={true} field={true} xl={6} lg={6} md={6} />
            </Box>
          </Grid>
          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
            <AlarmForm />
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default AlarmWithSelectsForms;
