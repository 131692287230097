import { appConstants } from '../../appConstants';
import { commonMessage, errorHandler, icon } from '../../service/messageHandler';
import { supportActions } from '../actions/supportActions';

const supportReducer = (state = {}, action) => {
  switch (action.type) {
    case supportActions.FETCHING_SUPPORT_SEND_REQUEST:
      return {
        ...state,
        loading: { open: true },
      };
    case supportActions.FETCHING_SUPPORT_SEND_SUCCESS:
      commonMessage(appConstants.SUCCES_SUPPORT_SEND, '', icon.success);
      return {
        ...state,
        loading: { open: false },
      };
    case supportActions.FETCHING_SUPPORT_FAILURE:
      errorHandler(action.payload);
      return {
        ...state,
        loading: { open: false },
      };

    default:
      return state;
  }
};

export default supportReducer;
