import { appConstants } from '../../appConstants';
import ApiAxios from '../../service/Api';

export const CregActions = {
    FETCHING_ALARM_CREG_LIST_REQUEST: 'FETCHING_ALARM_CREG_LIST_REQUEST',
    FETCHING_ALARM_CREG_LIST_SUCCESS: 'FETCHING_ALARM_CREG_LIST_SUCCESS',
    FETCHING_ALARM_CREG_LIST_FAILURE: 'FETCHING_ALARM_CREG_FAILURE',
    FETCHING_FACTOR_M_FOR_DEVICE_REQUEST: 'FETCHING_FACTOR_M_FOR_DEVICE_REQUEST',
    FETCHING_FACTOR_M_FOR_DEVICE_SUCCESS: 'FETCHING_FACTOR_M_FOR_DEVICE_SUCCESS',
    FETCHING_FACTOR_M_FOR_DEVICE_FAILURE: 'FETCHING_FACTOR_M_FOR_DEVICE_FAILURE',
    FETCHING_FACTOR_M_FOR_ALL_DEVICE_REQUEST: 'FETCHING_FACTOR_M_FOR_ALL_DEVICE_REQUEST',
    FETCHING_FACTOR_M_FOR_ALL_DEVICE_SUCCESS: 'FETCHING_FACTOR_M_FOR_ALL_DEVICE_SUCCESS',
    FETCHING_FACTOR_M_FOR_ALL_DEVICE_FAILURE: 'FETCHING_FACTOR_M_FOR_ALL_DEVICE_FAILURE',
    FETCHING_UPDATE_FACTOR_M_REQUEST: 'FETCHING_UPDATE_FACTOR_M_REQUEST',
    FETCHING_UPDATE_FACTOR_M_SUCCESS: 'FETCHING_UPDATE_FACTOR_M_SUCCESS',
    FETCHING_UPDATE_FACTOR_M_FAILURE: 'FETCHING_UPDATE_FACTOR_M_FAILURE',
    FETCHING_CONSOLIDATED_FACTOR_M_REQUEST: 'FETCHING_CONSOLIDATED_FACTOR_M_REQUEST',
    FETCHING_CONSOLIDATED_FACTOR_M_SUCCESS: 'FETCHING_CONSOLIDATED_FACTOR_M_SUCCESS',
    FETCHING_CONSOLIDATED_FACTOR_M_FAILURE: 'FETCHING_CONSOLIDATED_FACTOR_M_FAILURE',

    SET_SERIE_FILTER_CONSOLIDATED:'SET_SERIE_FILTER_CONSOLIDATED',
    CLEAN_LIST_CREG_ALARMS:'CLEAN_LIST_CREG_ALARMS',
    CLEAN_LIST_FACTOR_M:'CLEAN_LIST_FACTOR_M',
    CLEAN_SERIE_FILTER_CONSOLIDATED:'CLEAN_SERIE_FILTER_CONSOLIDATED',
    CLEAN_ON_EXIT_REACTIVE: "CLEAN_ON_EXIT_REACTIVE"

};

export const fetchRequest = (type, payload = undefined) => {
  return {
    type: type,
    payload: payload,
  };
};

export const listCregAlarms = (filter) => {
    return function (dispatch) {
      ApiAxios.crudGeneric.post(appConstants.CONTEXT_ALARMS_CREG, filter, dispatch, fetchRequest, {
        request: CregActions.FETCHING_ALARM_CREG_LIST_REQUEST,
        success: CregActions.FETCHING_ALARM_CREG_LIST_SUCCESS,
        error: CregActions.FETCHING_ALARM_CREG_LIST_FAILURE,
      });
    };
  };


  export const getFactorMForByDeviceId = (idDevice) => {
    return function (dispatch) {
      ApiAxios.crudGeneric.get(`${appConstants.CONTEXT_CREG_BEACH}factorm/${idDevice}`, dispatch, fetchRequest, {
        request: CregActions.FETCHING_FACTOR_M_FOR_DEVICE_REQUEST,
        success: CregActions.FETCHING_FACTOR_M_FOR_DEVICE_SUCCESS,
        error: CregActions.FETCHING_FACTOR_M_FOR_DEVICE_FAILURE,
      });
    };
  };

  export const getFactorMForAllDevices = (devices) => {
    return function (dispatch) {
      ApiAxios.crudGeneric.get(
        `${appConstants.CONTEXT_CREG_BEACH}factorm-for-all-devices/${devices}`,
        dispatch,
        fetchRequest,
        {
          request: CregActions.FETCHING_FACTOR_M_FOR_ALL_DEVICE_REQUEST,
          success: CregActions.FETCHING_FACTOR_M_FOR_ALL_DEVICE_SUCCESS,
          error: CregActions.FETCHING_FACTOR_M_FOR_ALL_DEVICE_FAILURE,
        },
      );
    };
  };

  export const updateFactorM = (item) => {
    return function (dispatch) {
      ApiAxios.crudGeneric.put(appConstants.CONTEXT_CREG_BEACH, item, dispatch, fetchRequest, {
        request: CregActions.FETCHING_UPDATE_FACTOR_M_REQUEST,
        success: CregActions.FETCHING_UPDATE_FACTOR_M_SUCCESS,
        error: CregActions.FETCHING_UPDATE_FACTOR_M_FAILURE,
      });
    };
  };

  export const getConsolidatedFactorM = (filter) => {
    return function (dispatch) {
      ApiAxios.crudGeneric.post(`${appConstants.CONTEXT_CREG_BEACH}consolidated-creg/`, filter, dispatch, fetchRequest, {
        request: CregActions.FETCHING_CONSOLIDATED_FACTOR_M_REQUEST,
        success: CregActions.FETCHING_CONSOLIDATED_FACTOR_M_SUCCESS,
        error: CregActions.FETCHING_CONSOLIDATED_FACTOR_M_FAILURE,
      });
    };
  };

  export const cleanCregAlarm = () => {
    return {
      type: CregActions.CLEAN_LIST_CREG_ALARMS,
    };
  };

  export const cleanFactorM = () => {
    return {
      type: CregActions.CLEAN_LIST_FACTOR_M,
    };
  };

  export const cleanSerieFilter= () => {
    return {
      type: CregActions.CLEAN_SERIE_FILTER_CONSOLIDATED,
    };
  };