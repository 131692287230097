import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { FilterIcon } from '../../assets/icons';
import '../../assets/styles/App.scss';
import { addButton, componentActions, fetchAction, setGlobalTitle } from '../../components/actions/componentActions';
import AutocompleteFilter from '../../components/filters/AutoCompleteFilters';
import Loading from '../../components/Loading';
import { listFields } from '../actions/fieldActions';
import FieldList from '../components/FieldList';
import MasterFieldSelectList from '../components/MasterFieldSelectList';
const FieldView = (props) => {
  const buttonsToolbar = [{ id: appConstants.BACK_BUTTON_ID, history: props.history }];

  useEffect(() => {
    props.setGlobalTitle(appConstants.FIELD_VIEW_TITLE);

    props.fetchAction(componentActions.ADD_TOOLBAR_BUTTON, buttonsToolbar);
  }, []);

  const handleFilter = (event) => {
    event.preventDefault();
    // handleFieldChange(true);

    let filter = {
      sort: 'name',
      page: 0,
      size: 3000,
      filters: {
        headquarter: props.headquarter,
        iot: props.iot,
        device: props.device,
      },
    };

    props.listFields(filter);
  };

  return (
    <Box height={1} width={1} padding={1} className="background-image-bubble mt-10px">
      <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={4} xs={12} sm={12}>
          <Paper className="paper ">
            <Box boxShadow={3} height={1} borderRadius={10} padding={2}>
              <Grid container spacing={2} className="center-info  ">
                <Box marginTop={2}>
                  <Grid container className="center-info bg-secundary border-10 h-50px ">
                    <Typography variant="h5" className="ff-poppins fc-third fw-bold h-line">
                      Filtro y creación
                    </Typography>
                  </Grid>

                  <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                    <Box flex="center" display="flex" marginTop={2}>
                      <AutocompleteFilter headquarter={true} iot={true} device={true} xl={12} lg={12} md={12} />
                    </Box>
                  </Grid>
                  <Grid item xl={12} md={12} lg={12} xs={12} sm={12} className="center-info ">
                    <Box width={4 / 10} height={1} flex="center" display="flex" marginTop={2}>
                      <Button className="bg-green-gradient " onClick={(e) => handleFilter(e)}>
                        <FilterIcon className="mr-2" />
                        Filtrar
                      </Button>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item xl={8} lg={8} md={8} xs={12} sm={12}>
          <Box boxShadow={4} borderRadius={10}>
            <MasterFieldSelectList />
          </Box>
        </Grid>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
          <Box boxShadow={4} borderRadius={10}>
            <FieldList />
          </Box>
        </Grid>
      </Grid>
      <Loading />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    iot: state.iotReducer.selectedIot,
    device: state.deviceReducer.selectedDevice,
    headquarter: state.headquarterReducer.selectedHeadquarter,
  };
};

const mapDispatchToProps = {
  setGlobalTitle,
  addButton,
  fetchAction,
  listFields,
};

export default connect(mapStateToProps, mapDispatchToProps)(FieldView);
