import { Box, Button, Card, Grid, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { CancelIcon } from '../../assets/icons';
import FieldInfo from '../../field/components/FieldInfo';
import AlarmEditForm from '../components/AlarmEditForm';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '100vh',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const AlarmEditFormModal = (props) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(appConstants.CLOSE_EVENT == props.alarmCreationModal);
  }, [props.alarmCreationModal]);

  return (
    <Box height={1}>
      <Button color="primary" className="btn-transparent ff-poppins fc-green" onClick={handleOpen}>
        <EditIcon />
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="alarm-modal-title"
        aria-describedby="alarm-modal-description"
      >
        <Box style={modalStyle} className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xl={12} lg={12} md={12} xs={12} sm={12} container className="center-info bg-secundary ">
              <Grid item xl={11} lg={11} md={11} xs={11} sm={11} className="center-info bg-secundary ">
                <Typography variant="h4" component="h3" className="ff-poppins fc-third">
                  EDICIÓN DE ALARMAS
                </Typography>
              </Grid>
              <Grid item xl={1} lg={1} md={1} sm={1} xs={1} className="bg-secundary ">
                <Button onClick={(e) => handleClose()}>
                  <CancelIcon className="mr-1" height="25px" width="25px" />
                </Button>
              </Grid>
            </Grid>
            <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info">
              <Box boxShadow={3} width={1} height={1}>
                <Card>
                  <Grid container spacing={1}>
                    <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                      <Box paddingTop={2} paddingLeft={2} paddingRight={2}>
                        <FieldInfo iot={true} device={true} field={true} xl={4} lg={4} md={4} alarm={props.alarm} />
                      </Box>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                      <AlarmEditForm alarm={props.alarm} />
                    </Grid>
                  </Grid>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    alarmCreationModal: state.alarmReducer.alarmCreationModal ? true : false,
  };
};

export default connect(mapStateToProps, null)(AlarmEditFormModal);
