import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import Brush from '@material-ui/icons/Brush';
import Save from '@material-ui/icons/Save';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { commonMessage, icon } from '../../service/messageHandler';
import { fetchRequest, headquarterActions, saveHeadquarter, updateHeadquarter } from '../actions/headquarterActions';

const HeadquarterForm = (props) => {
  const { headquarter } = props;

  const [headquarterForm, setHeadquarterForm] = useState({
    name: '',
    address: '',
    quantityInstallation: '',
  });

  useEffect(() => {
    deserialize();
  }, [props.headquarter]);

  const handleSave = () => {
    let msn = validate();

    if (msn == '') {
      let itemSaved = {
        ...headquarterForm,
      };

      if (!itemSaved.id) props.saveHeadquarter(itemSaved);
      else props.updateHeadquarter(itemSaved);
    } else {
      commonMessage(msn, appConstants.VALIDATION_ERROR_TITLE, icon.error);
    }
  };

  const validate = () => {
    let msn = '';

    msn += !headquarterForm.name ? 'Debe ingresar el nombre \n' : '';
    msn += !headquarterForm.address ? 'Debe ingresar la dirección \n' : '';
    msn += !headquarterForm.quantityInstallation ? 'Debe ingresar el numeró de instalaciones \n' : '';
    if (headquarterForm.quantityInstallation < 0) {
      msn += 'La cantidad de instalciones debe ser positiva';
    }

    return msn;
  };

  const deserialize = () => {
    let valueForm = {};
    valueForm.id = headquarter.id ? headquarter.id : '';
    valueForm.name = headquarter.name ? headquarter.name : '';
    valueForm.address = headquarter.address ? headquarter.address : '';
    valueForm.quantityInstallation = headquarter.quantityInstallation ? headquarter.quantityInstallation : '';
    setHeadquarterForm({
      ...valueForm,
    });
  };

  const handleClean = () => {
    props.fetchRequest(headquarterActions.SET_HEADQUARTER, { item: {} });
  };

  return (
    <Box>
      <Card className="h-403px center w-100">
        <CardContent>
          <form noValidate={false} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info bg-secundary">
                <Typography variant="h5" className="ff-poppins fw-bold  fc-third">
                  Creación
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    id="name"
                    label="Nombre"
                    fullWidth
                    variant="outlined"
                    value={headquarterForm.name}
                    onChange={(valueEvent) => setHeadquarterForm({ ...headquarterForm, name: valueEvent.target.value })}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    id="address"
                    label="Dirección"
                    fullWidth
                    variant="outlined"
                    value={headquarterForm.address}
                    onChange={(valueEvent) =>
                      setHeadquarterForm({ ...headquarterForm, address: valueEvent.target.value })
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    id="quantityInstallation"
                    label="Número de instalación"
                    fullWidth
                    variant="outlined"
                    type="number"
                    value={headquarterForm.quantityInstallation}
                    onChange={(valueEvent) =>
                      setHeadquarterForm({ ...headquarterForm, quantityInstallation: valueEvent.target.value })
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <CardActions className="center d-flex h-190px pl-3">
          <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
            <Button className="bg-green-gradient mr-2 pl-5 pr-5" onClick={handleSave}>
              <Save className="mr-2" />
              {headquarter.id ? 'Actualizar' : 'Guardar'}
            </Button>

            <Button className="bg-green-gradient mr-2 pl-5 pr-5" disabled={headquarter == {}} onClick={handleClean}>
              <Brush className="mr-2" />
              Limpiar
            </Button>
          </ButtonGroup>
        </CardActions>
      </Card>
    </Box>
  );
};

const mapStateToprops = (state) => {
  return {
    headquarter: state.headquarterReducer.selectedHeadquarter ? state.headquarterReducer.selectedHeadquarter : {},
    isRequiredHeadquarters: state.headquarterReducer.isRequiredHeadquarters,
  };
};

const mapDispatchToProps = {
  fetchRequest,
  saveHeadquarter,
  updateHeadquarter,
};

export default connect(mapStateToprops, mapDispatchToProps)(HeadquarterForm);
