import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { componentActions, fetchAction, setGlobalTitle } from '../../components/actions/componentActions';
import ListHistoryLicenses from '../components/ListHistoryLicenses';

const HistoryView = (props) => {
  const buttonsToolbar = [{ id: appConstants.BACK_BUTTON_ID, history: props.history }];

  useEffect(() => {
    props.setGlobalTitle(appConstants.LICENSE_VIEW_TITLE);

    props.fetchAction(componentActions.ADD_TOOLBAR_BUTTON, buttonsToolbar);
  }, []);

  return (
    <Box width={1} height={1} display="flex" justifyContent="center" className="background-image-bubble " padding={3}>
      <ListHistoryLicenses />
    </Box>
  );
};

const mapDispatchToProps = {
  setGlobalTitle,
  fetchAction,
};

export default connect(null, mapDispatchToProps)(HistoryView);
