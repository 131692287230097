import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgEnergyIcon(props) {
    const { height, width, color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height ? height : appConstants.ICON_DEFAULT_HEIGHT}
      width={width ? width : appConstants.ICON_DEFAULT_WIDTH} viewBox="0 0 375 375" {...props}>
      <defs>
        <clipPath id="EnergyIcon_svg__a">
          <path d="M0 43h375v324.36H0zm0 0" />
        </clipPath>
        <clipPath id="EnergyIcon_svg__b">
          <path d="M193 4.36h43V56h-43zm0 0" />
        </clipPath>
      </defs>
      <g clipPath="url(#EnergyIcon_svg__a)">
        <path
          d="M319 137.277c.992 2.567 1.906 5.16 2.738 7.782a132.952 132.952 0 012.258 7.933 135.241 135.241 0 013.035 16.211 134.12 134.12 0 011.028 16.457c0 2.75-.082 5.496-.247 8.242a137.662 137.662 0 01-.75 8.215c-.335 2.73-.753 5.45-1.253 8.153a133.77 133.77 0 01-3.992 16 133 133 0 01-2.723 7.785 135.31 135.31 0 01-3.196 7.605 135.299 135.299 0 01-3.656 7.395 134.981 134.981 0 01-13.59 20.652 131.906 131.906 0 01-5.343 6.285 132.521 132.521 0 01-5.72 5.95 136.93 136.93 0 01-6.073 5.585 135.108 135.108 0 01-20.11 14.387 135.365 135.365 0 01-7.25 3.941 135.367 135.367 0 01-7.476 3.497 136.164 136.164 0 01-15.528 5.582 134.746 134.746 0 01-16.097 3.64c-2.715.442-5.446.801-8.184 1.078a134.908 134.908 0 01-24.73.2c-2.746-.23-5.477-.547-8.203-.942a132.91 132.91 0 01-8.125-1.445 133.962 133.962 0 01-8.024-1.938 132.297 132.297 0 01-7.89-2.425c-2.606-.891-5.18-1.856-7.727-2.903a135.354 135.354 0 01-14.848-7.2 135.049 135.049 0 01-20.34-14.058 130.374 130.374 0 01-6.164-5.488 134.29 134.29 0 01-16.316-18.57 134.399 134.399 0 01-21.18-44.438 132.825 132.825 0 01-3.266-16.164 134.105 134.105 0 01-1.265-16.441 134.974 134.974 0 01.762-16.469c.293-2.738.672-5.46 1.136-8.172.461-2.71 1.008-5.406 1.633-8.086a134.549 134.549 0 017.828-23.441l32.41 22.082-.117-109.91L.004 83.875l30.848 21.02a180.5 180.5 0 00-17.84 60 180.926 180.926 0 00-1.188 20.921c0 100.211 81.297 181.446 181.586 181.446 100.285 0 181.586-81.235 181.586-181.446a180.379 180.379 0 00-3.363-34.886 180.086 180.086 0 00-10.028-33.582zm0 0"
          fill="#008037"
        />
      </g>
      <path
        d="M258.348 67.957a134.75 134.75 0 0120.183 13.645 135.17 135.17 0 0117.41 17.043l33.637-32.848a182.183 182.183 0 00-51.09-40.3zm0 0"
        fill="#008037"
      />
      <g clipPath="url(#EnergyIcon_svg__b)">
        <path
          d="M235.543 9.293a181.545 181.545 0 00-42.133-4.918v46.918a134.565 134.565 0 0132.942 4.066zm0 0"
          fill="#008037"
        />
      </g>
      <path d="M172.21 116.465h50.634l-14.309 44.39h41.942l-82.528 109.711 20.598-77.05h-41.445zm0 0" fill="#ffcf64" />
      <path d="M151.719 179.352l-4.617 14.164h41.445l3.773-14.164zm0 0" fill="#f2a330" />
      <path
        d="M60.68 163.266a133.281 133.281 0 019.472-31.594l-39.3-26.777a180.088 180.088 0 00-12.153 31.394zm0 0"
        fill="#7ed957"
      />
    </svg>
  );
}

export default SvgEnergyIcon;
