import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

const TrendTitle = (props) => {
  useEffect(() => {}, [props.selectedFilter]);

  return (
    <Grid
      container
      spacing={1}
      justify="center"
      alignContent="center"
      item
      xl={8}
      lg={8}
      md={8}
      sm={12}
      xs={12}
      className="mt-20px "
    >
      {props.selectedFilter?.headquarter?.name
        ? 'Sede: ' + props.selectedFilter.headquarter.name + '.  '
        : 'No hay filtro Seleccionado'}
      {props.selectedFilter?.iot?.name ? 'Iot: ' + props.selectedFilter.iot.name + '.  ' : ''}
      {props.selectedFilter?.device?.name ? 'Dispositivo: ' + props.selectedFilter.device.name + '.' : ''}
    </Grid>
  );
};

const mapDispatchToProps = {
  // getCurrentByUser,
  // setGlobalTitle,
  // resetHome,
};

const mapStateToProps = (state) => {
  return {
    selectedFilter: state.dashboardReducer.selectedFilter,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrendTitle);
