import { appConstants } from '../../appConstants';
import { commonMessage, errorHandler, icon } from '../../service/messageHandler';
import { headquarterActions } from '../actions/headquarterActions';

const headquarterReducer = (state = {}, action) => {
  switch (action.type) {
    case headquarterActions.FETCHING_HEADQUARTER_REQUEST_SAVE:
    case headquarterActions.FETCHING_HEADQUARTER_REQUEST_UPDATE:
      return {
        ...state,
        loading: { open: true },
      };
    case headquarterActions.FETCH_LIST_HA_REQUEST:
      return {
        ...state,
        loading: { open: true, message: '' },
        isRequiredHeadquarters: appConstants.IS_NOT_REQUIRED_HEADQUARTERS,
      };
    case headquarterActions.FETCH_LIST_HA_REQUEST_SUCCES:
      return {
        ...state,
        avaliabledHeadquarters: action.payload.content,
        loading: { open: false },
      };
    case headquarterActions.SET_HEADQUARTER:
      return {
        ...state,
        selectedHeadquarter: action.payload.item,
        disabledAutocomplete: false,
      };
    case headquarterActions.CLEAN_HEADQUARTER:
      return {
        ...state,
        selectedHeadquarter: undefined,
        isRequiredHeadquarters: appConstants.IS_REQUIRED_HEADQUARTERS,
        disabledAutocomplete: false,
      };

    case headquarterActions.FETCH_REQUEST_ERROR:
      errorHandler(action.payload);
      return {
        ...state,
        error: action.payload,
        loading: { open: false },
      };

    case headquarterActions.SEARCH_SET_BY_ID_HQ:
      let selectedHeadquarterById = undefined;
      let filteredheadquarters = [];
      if (state.avaliabledHeadquarters && state.avaliabledHeadquarters.length > 0)
        filteredheadquarters = state.avaliabledHeadquarters.filter((item) => item.id === action.payload.id);

      if (filteredheadquarters && filteredheadquarters.length > 0) selectedHeadquarterById = filteredheadquarters[0];

      let requiredFindById = !selectedHeadquarterById ? appConstants.IS_REQUIRED_HQ_FIND_BY_ID : undefined;

      return {
        ...state,
        selectedHeadquarter: selectedHeadquarterById,
        isRequiredHeadquarters: appConstants.IS_NOT_REQUIRED_HEADQUARTERS,
        disabledAutocomplete: action.payload.disabledAutocomplete,
        requiredFindById: { value: requiredFindById, id: action.payload.id },
      };
    case headquarterActions.FETCHING_HEADQUARTER_SAVE_SUCCESS:
      commonMessage(appConstants.SAVE_HEADQUARTER_SUCCESS_MESSAGE, appConstants.SUCCESFUL_SAVED_TITLE, icon.success);
      return {
        ...state,
        loading: { open: false },
        selectedHeadquarter: {},
        isRequiredHeadquarters: appConstants.IS_REQUIRED_HEADQUARTERS,
      };
    case headquarterActions.FETCHING_HEADQUARTER_UPDATE_SUCCESS:
      commonMessage(appConstants.UPDATE_HEADQUARTER_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);
      return {
        ...state,
        selectedHeadquarter: {},
        loading: { open: false },
        isRequiredHeadquarters: appConstants.IS_REQUIRED_HEADQUARTERS,
      };

    case headquarterActions.FETCHING_HEADQUARTER_DELETE_SUCCESS:
      commonMessage(appConstants.DELETE_HEADQUARTER_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);
      return {
        ...state,
        selectedHeadquarter: undefined,
        loading: { open: false },
        isRequiredHeadquarters: appConstants.IS_REQUIRED_HEADQUARTERS,
      };
    case headquarterActions.FETCHING_HQ_FIND_BY_ID_SUCCESS:
      return {
        ...state,
        requiredFindById: appConstants.IS_NOT_REQUIRED_HQ_FIND_BY_ID,
        selectedHeadquarter: action.payload,
        loading: { open: false },
      };
    default:
      return state;
  }
};

export default headquarterReducer;
