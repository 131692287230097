import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import 'c3/c3.css';
import React from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { dashboardAction, downloadTrend, fetchRequest } from '../action/dashboardActions';
import '../styles/dashboard.scss';

class SelectedGlobalFrecuency extends React.Component {
  constructor(props) {
    super(props);

    this.info = {
      frecuency: '',
    };
  }
  handleFrecuency = (event) => {
    this.info.frecuency = event.target.value;
    this.props.fetchRequest(dashboardAction.SET_GLOBAL_FRECUENCY, event.target.value);

    this.props.fetchRequest(dashboardAction.FETCHING_SYNC_TRENDS, false);
  };

  order() {
    let keys = Object.keys(this.props.intervals);
    let intervals = [];

    for (let i = 0; i < keys.length; i++) {
      intervals.push({ name: keys[i], value: this.props.intervals[keys[i]] });
    }

    intervals.sort(function (a, b) {
      if (a.value > b.value) {
        return 1;
      } else if (a.value < b.value) {
        return -1;
      }

      return 0;
    });

    let valores = intervals.map((element) => {
      return element.name;
    });

    return valores;
  }
  render() {
    return (
      <Grid item xl={2} lg={2} md={2} sm={9} xs={9} className="mt-10px mb-10px">
        <FormControl variant="filled" fullWidth style={{ border: '20px' }}>
          <InputLabel id="time">Rango de tiempo</InputLabel>
          <Select
            labelId="frecuency2"
            id="frecuency2"
            variant="filled"
            fullWidth={true}
            value={this.props.globalFrecuency}
            onChange={(e) => this.handleFrecuency(e)}
          >
            {this.props.intervals &&
              this.order().map((element) => {
                return (
                  <MenuItem value={this.props.intervals[element]} key={this.props.intervals[element]}>
                    {element}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    selectedDevice: state.deviceReducer.selectedDevice,
    selectedHeadquarter: state.headquarterReducer.selectedHeadquarter,
    selectedIot: state.iotReducer.selectedIot,
    intervals: state.dashboardReducer.intervals ? state.dashboardReducer.intervals : [],
    values: state.dashboardReducer.values ? state.dashboardReducer.values : [],
    isRequiredAddButtons: state.componentsReducer.buttons
      ? appConstants.IS_NOT_REQUIRED_ADD_BUTTON
      : appConstants.IS_REQUIRED_ADD_BUTTON,
    isRequieredSyncTrend: state.dashboardReducer.isRequieredSyncTrend,
    isFrecuencyReload: state.dashboardReducer.isFrecuencyReload,
  };
};

const mapDispatchToProps = {
  downloadTrend,
  fetchRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedGlobalFrecuency);
