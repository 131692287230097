import { Box, Button, Grid, Modal, Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ArbolIcon, BombilloIcon, CancelIcon, FabricaIcon } from '../../assets/icons';
import '../../assets/styles/App.scss';

const ModalInfoCo2 = (props) => {
  const [open, setOpen] = useState(false);

  const changeOpen = (event) => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);
  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <Box className="mt-20px" justifyContent="center" alignItems="center">
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} xs={12} sm={12} className="center-info bg-co2-primary">
          <Typography className="ff-poppins fs-t-title-co2  fc-text-co2-secundary fw-bold center-midd">
            TONELADAS DE DIÓXIODO DE CARBONO
          </Typography>
        </Grid>
        <Grid item xl={6} lg={6} md={6} xs={12} sm={12} className="center-info bg-co2-secundary">
          <Grid>
            <Typography className="ff-poppins fs-t-title-co2 fc-text-co2-primary t-center">¿PARA QUÉ SIRVE?</Typography>
            <Typography className="ff-poppins fc-white fs-t-text t-justify">
              El índice de emisiones CO2 es importante para validar si el sistema de gestión integral de su proceso está
              permitiendo el cumplimento de los objeticos de desarrollo sostenible.
            </Typography>

            <Typography className="ff-poppins fc-text-co2-primary fs-t-text t-justify">
              <Link href="https://www1.upme.gov.co/DemandaEnergetica/EEIColombia/Guia_estructura_ISO50001.pdf">
                Más información:
              </Link>
            </Typography>
          </Grid>
          <Grid className="icon-co2">
            <ArbolIcon height={300} width={200} />
          </Grid>
        </Grid>
        <Grid item xl={6} lg={6} md={6} xs={12} sm={12} className="center-info bg-co2-secundary">
          <Grid>
            <Typography className="ff-poppins fs-info fc-text-co2-primary t-center ">
              ¿POR QUÉ ES IMPORTANTE CONOCER ESTA INFORMACIÓN?
            </Typography>
            <Typography className="ff-poppins fc-white fs-t-text t-justify">
              Las emisiones de gases de efecto invernadero son responsables del calentamiento global, siendo el CO2 el
              principal causante de este fenómeno. El ahorro energético de cualquier fuente, trae asociado una mejora
              ambiental producto de las llamadas emisiones indirectas, las cuales dependen de la fuente de generación o
              del combustible utilizado. Conocer su consumo energético le permite dimensionar el impacto ambiental de
              sus procesos, y las ventajas que trae el uso eficiente de la energía para combatir el cambio climático
              mediante la reducción en las emisiones de CO2.
            </Typography>
          </Grid>
          <Grid className="icon-co2">
            <FabricaIcon height={450} width={200} />
          </Grid>
        </Grid>
        <Grid item xl={6} lg={6} md={6} xs={12} sm={12} className="center-info bg-co2-grey">
          <Grid>
            <Typography className="ff-poppins fs-info fc-text-co2-primary t-center">¿CÓMO SE CALCULA?</Typography>
            <Typography className="ff-poppins fc-white fs-t-text t-justify">
              Las emisiones de CO2se calculan a partir de la siguiente ecuación:
            </Typography>
            <Typography className="ff-poppins fc-text-co2-primary fs-t-text t-justify">
              EMISIONES = CONSUMO DE ELECTRICIDAD * FACTOR DE EMISIÓN
            </Typography>
            <Typography className="ff-poppins fc-text-co2-primary fc-white t-justify">Donde:</Typography>
            <Typography className="ff-poppins fc-text-co2-primary fc-white t-justify">
              Donde: El consumo de electricidad está asociado a la cantidad de energía requerida para el funcionamiento
              de nuestro proceso productivo y se relaciona con la cantidad de potencia empleada en un intervalo de
              tiempo (Kilovatio/Hora kWh). FE o Factor de Emisión, representa la cantidad de CO2 que se libera de la
              producción de cada unidad de electricidad y está definido por el operador de red nacional XM como164.38
              gramos de CO2 por kilovatio hora. Este valor se ha estandarizado a partir de las mediciones de consumo
              energético nacional y la cantidad de emisiones para el año 2019.
            </Typography>
            <Typography className="ff-poppins fc-text-co2-primary fs-t-text">
              <Link href="https://www.xm.com.co/Paginas/detalle-noticias.aspx?identificador=2383">
                Más información:
              </Link>
            </Typography>
          </Grid>
          <Grid className="icon-co2">
            <BombilloIcon height={400} width={200} />
          </Grid>
        </Grid>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info bg-co2-primary  ">
          <Typography className="ff-poppins   fc-text-co2-secundary ">
            "GLEM, LA HERRAMIENTA PARA UNA RED ELÉCTRICA MÁS INTELIGENTE”
          </Typography>

          <Typography className="ff-poppins   fc-text-co2-secundary ">GLEM ES: GL ENERGY MANAGEMENT</Typography>

          <Typography className="ff-poppins fc-white fs-title-graphic">
            <Link className="fc-white" href="https://www.glingenieros.com.co/">
              Si requiere más información presiona Clic
            </Link>
          </Typography>
        </Grid>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-right bg-co2-primary">
          <Button className="bg-green-gradient mr-2 pl-5 pr-5 " onClick={handleClose}>
            <CancelIcon className="mr-2" height="25px" width="25px" />
            Cerrar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <>
      <Tooltip title="Click para mas información" aria-label="add">
        <Button onClick={changeOpen}>
          <InfoIcon color="primary" />
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={changeOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="o-scroll"
      >
        {body}
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isOpen: state.userReducer.currentUser && state.userReducer.currentUser.changePassword === true ? true : false,
  };
};

export default connect(mapStateToProps, null)(ModalInfoCo2);
