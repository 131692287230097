import { appConstants } from '../../appConstants';
import ApiAxios from '../../service/Api';

export const deviceActions = {
  SET_DEVICE: 'SET_DEVICE',
  ADD_DEVICE: 'ADD_DEVICE',
  FETCHING_DEVICE_REQUEST: 'FETCHING_DEVICE_REQUEST',

  FETCHING_DEVICE_SAVE_SUCCESS: 'FETCHING_DEVICE_SAVE_SUCCESS',
  FETCHING_DEVICE_UPDATE_SUCCESS: 'FETCHING_DEVICE_UPDATE_SUCCESS',
  FETCHING_DEVICE_LIST_SUCCESS: 'FETCHING_DEVICE_LIST_SUCCESS',
  FETCHING_DEVICE_FAILURE: 'FETCHING_DEVICE_FAILURE',
  FETCHING_DEVICE_REQUEST_LIST: 'FETCHING_DEVICE_REQUEST_LIST',
  CLEAN_DEVICE_STORE: 'CLEAN_DEVICE_STORE',
  FETCHING_DEVICE_DELETE_SUCCESS: 'FETCHING_DEVICE_DELETE_SUCCESS',
  FETCH_REQUEST_PROTOCOLTYPES: 'FETCH_REQUEST_PROTOCOLTYPES',
  FETCH_REQUEST_PROTOCOLTYPES_SUCCESS: 'FETCH_REQUEST_PROTOCOLTYPES_SUCCESS',
  FETCH_REQUEST_CLASIFICATION: 'FETCH_REQUEST_CLASIFICATION',
  FETCH_REQUEST_CLASIFICATION_SUCCESS: 'FETCH_REQUEST_CLASIFICATION_SUCCESS',
};

export const addDevice = (payload) => ({
  type: deviceActions.ADD_DEVICE,
  payload,
});

export const setDevice = (payload) => {
  return {
    type: deviceActions.SET_DEVICE,
    payload,
  };
};

export const fetchDeviceRequest = () => {
  return {
    type: deviceActions.FETCHING_DEVICE_REQUEST,
    payload: { open: true },
  };
};

export const fetchlistDeviceRequest = () => {
  return {
    type: deviceActions.FETCHING_DEVICE_REQUEST_LIST,
    payload: { open: true },
  };
};

export const fetchDeviceList = (items) => {
  return {
    type: deviceActions.FETCHING_DEVICE_LIST_SUCCESS,
    payload: items,
  };
};

export const fetchDeviceSave = (item) => {
  return {
    type: deviceActions.FETCHING_DEVICE_SAVE_SUCCESS,
    payload: item,
  };
};

export const fetchDeviceFailure = (error) => {
  return {
    type: deviceActions.FETCHING_DEVICE_FAILURE,
    payload: error,
  };
};

export const cleanDevices = () => {
  return {
    type: deviceActions.CLEAN_DEVICE_STORE,
  };
};

export const fetchRequest = (type, payload) => {
  return {
    type,
    payload,
  };
};

export const saveDevice = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_DEVICE, item, dispatch, fetchRequest, {
      request: deviceActions.FETCHING_DEVICE_REQUEST,
      success: deviceActions.FETCHING_DEVICE_SAVE_SUCCESS,
      error: deviceActions.FETCHING_DEVICE_FAILURE,
    });
  };
};

export const updateDevice = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.put(appConstants.CONTEXT_DEVICE, item, dispatch, fetchRequest, {
      request: deviceActions.FETCHING_DEVICE_REQUEST,
      success: deviceActions.FETCHING_DEVICE_UPDATE_SUCCESS,
      error: deviceActions.FETCHING_DEVICE_FAILURE,
    });
  };
};

export const listDevices = (dto) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_DEVICES, dto, dispatch, fetchRequest, {
      requesting: fetchlistDeviceRequest,
      request: deviceActions.FETCHING_DEVICE_REQUEST_LIST,
      success: deviceActions.FETCHING_DEVICE_LIST_SUCCESS,
      error: deviceActions.FETCHING_DEVICE_FAILURE,
    });
  };
};

export const deleteDevice = (id) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.delete(`${appConstants.CONTEXT_DEVICE}${id}`, dispatch, fetchRequest, {
      request: deviceActions.FETCHING_DEVICE_REQUEST_LIST,
      success: deviceActions.FETCHING_DEVICE_DELETE_SUCCESS,
      error: deviceActions.FETCHING_DEVICE_FAILURE,
    });
  };
};

export const getTypeProtocol = () => {
  return function (dispatch) {
    ApiAxios.crudGeneric.get(`${appConstants.CONTEXT_DEVICE}protocol-types`, dispatch, fetchRequest, {
      request: deviceActions.FETCH_REQUEST_PROTOCOLTYPES,
      success: deviceActions.FETCH_REQUEST_PROTOCOLTYPES_SUCCESS,
      error: deviceActions.FETCHING_DEVICE_FAILURE,
    });
  };
};

export const getClasification = () => {
  return function (dispatch) {
    ApiAxios.crudGeneric.get(`${appConstants.CONTEXT_DEVICE}clasification`, dispatch, fetchRequest, {
      request: deviceActions.FETCH_REQUEST_CLASIFICATION,
      success: deviceActions.FETCH_REQUEST_CLASIFICATION_SUCCESS,
      error: deviceActions.FETCHING_DEVICE_FAILURE,
    });
  };
};
