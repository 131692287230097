import { appConstants } from '../../appConstants';
import { commonMessage, errorHandler, icon } from '../../service/messageHandler';
import { licenseCycleActions } from '../actions/licenseCycleActions';

const licenseCycleReducer = (state = {}, action) => {
  switch (action.type) {
    case licenseCycleActions.SET_LICENSE_CYCLE:
      return { ...state, selectedLicenseCycle: action.payload.item };
      
    case licenseCycleActions.ADD_LICENSE_CYCLE:
      let selecteLicenseCycledById = undefined;
      let filterLicenseCycle = []
      if (state.avaliabledLicenseCycles && state.avaliabledLicenseCycles.length > 0)
        filterLicenseCycle = state.avaliabledLicenseCycles.filter((item) => (item.id === action.payload.id));

      if (filterLicenseCycle && filterLicenseCycle.length > 0) 
        selecteLicenseCycledById = filterLicenseCycle[0];    

      let requiredFindById =!selecteLicenseCycledById? appConstants.IS_REQUIRED_LICENSE_CYCLE_FIND_BY_ID : undefined;
        
      return {
        ...state,
        selectedLicenseCycle: selecteLicenseCycledById,
        isRequiredLicenseCycles: appConstants.IS_NOT_REQUIRED_LICENSE_CYCLE_LIST,
        disabledAutocomplete: action.payload.disabledAutocomplete,
        requiredFindById: { value: requiredFindById, id: action.payload.id },

      };

    case licenseCycleActions.FETCHING_LICENSE_CYCLE_SAVE_SUCCESS:
      commonMessage(appConstants.SAVE_LICENSE_CYCLE_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);
      return {
        ...state,
        loading: { open: false },
        selectedLicenseCycle: {},
        isRequiredLicenseCycles: appConstants.IS_REQUIRED_LICENSE_CYCLE_LIST,
      };
    case licenseCycleActions.FETCHING_LICENSE_CYCLE_REQUEST_LIST:
      return {
        ...state,
        loading: { open: true, message: ''},
        isRequiredLicenseCycles: appConstants.IS_NOT_REQUIRED_LICENSE_CYCLE_LIST,
      };
    case licenseCycleActions.FETCHING_LICENSE_CYCLE_REQUEST:
      return state;
    case licenseCycleActions.FETCHING_LICENSE_CYCLE_FAILURE:
      errorHandler(action);
      return {
        ...state,
        isRequiredLicenseCycles: appConstants.IS_NOT_REQUIRED_LICENSE_CYCLE_LIST,
      };
    case licenseCycleActions.FETCHING_LICENSE_CYCLE_LIST_SUCCESS:
      return {
        ...state,
        avaliabledLicenseCycles: action.payload.content ? action.payload.content : [],
        loading: { open: false },
      };
    case licenseCycleActions.CLEAN_LICENSE_CYCLE_STORE:
      return {
        ...state,
        avaliabledLicenseCycles: [],
        isRequiredLicenseCycles: appConstants.IS_REQUIRED_LICENSE_CYCLE_LIST,
        selectedLicenseCycle: undefined,
      };
    case licenseCycleActions.FETCHING_LICENSE_CYCLE_UPDATE_SUCCESS:
      commonMessage(
        appConstants.UPDATE_LICENSE_CYCLE_SUCCESS_MESSAGE,
        appConstants.SUCCESFUL_UPDATE_TITLE,
        icon.success,
      );
      return {
        ...state,
        selectedLicenseCycle: {},
        loading: { open: false },
        isRequiredLicenseCycles: appConstants.IS_REQUIRED_LICENSE_CYCLE_LIST,
      };
    case licenseCycleActions.FETCHING_LICENSE_CYCLE_DELETE_SUCCESS:
      commonMessage(
        appConstants.DELETE_LICENSE_CYCLE_SUCCESS_MESSAGE,
        appConstants.SUCCESFUL_UPDATE_TITLE,
        icon.success,
      );
      return {
        ...state,
        isRequiredLicenseCycles: appConstants.IS_REQUIRED_LICENSE_CYCLE_LIST,
        selectedLicenseCycle: undefined,
        loading: { open: false },
      };

    case licenseCycleActions.FETCH_REQUEST_TYPE_LICENSE:
      return {
        ...state,
        loading: { open: true },
        isRequiredTypeLicense: appConstants.IS_NOT_REQUIRED_TYPE_LICENSE,
      };
    case licenseCycleActions.FETCH_REQUEST_TYPE_LICENSE_SUCCESS:
      return {
        ...state,
        loading: { open: false },
        typeLicense: action.payload,
      };
      case licenseCycleActions.FETCHING_TYPE_LICENSE_FIND_BY_ID_SUCCESS:
        return {
          ...state,
          requiredFindById: appConstants.IS_NOT_REQUIRED_IOT_FIND_BY_ID,
          selectedLicenseCycle: action.payload,
          loading: { open: false },
        };

    default:
      return state;
  }
};

export default licenseCycleReducer;
