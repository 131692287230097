import { appConstants } from '../../appConstants';
import axiosInstance from '../../service/axiosInstance';
import { fetchRequest } from '../actions/userActions';

const apiUser = {
  actions: {
    login(dispatch, loginRequest, actions) {
      dispatch(fetchRequest(actions.request));
      axiosInstance()
        .post(`${appConstants.CONTEXT_AUTH}`, loginRequest)
        .then((data) => {
          dispatch(fetchRequest(actions.success, data));
        })
        .catch((error) => {
          dispatch(fetchRequest(actions.error, error));
        });
    },
    list(dispatch, filter, actions) {
      dispatch(fetchRequest(actions.request));
      axiosInstance()
        .post(`${appConstants.CONTEXT_USERS}`, filter)
        .then((data) => {
          dispatch(fetchRequest(actions.success, data));
        })
        .catch((error) => {
          dispatch(fetchRequest(actions.error, error));
        });
    },
  },
};

export default apiUser;
