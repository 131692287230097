import { Box, Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { componentActions, fetchAction, setGlobalTitle } from '../../components/actions/componentActions';
import AutocompleteFilter from '../../components/filters/AutoCompleteFilters';
import FormDevice from '../../device/components/FormDevice';
import ListDevice from '../components/ListDevice';

const DeviceView = (props) => {
  const toolbarButtons = [{ id: appConstants.BACK_BUTTON_ID, history: props.history }];

  useEffect(() => {
    props.setGlobalTitle(appConstants.DEVICE_VIEW_TITLE);

    props.fetchAction(componentActions.ADD_TOOLBAR_BUTTON, toolbarButtons);
  }, []);

  return (
    <Box width={1} padding={1} className="background-image-bubble mt-10px">
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info bg-secundary mt-20px">
          <Typography variant="h5" className="ff-poppins fw-bold  fc-third">
            Creación
          </Typography>
        </Grid>
        <Grid item xl={4} lg={4} md={4} xs={12} sm={12}>
          <Paper className="paper">
            <Box boxShadow={3} height={1} borderRadius={10} padding={2}>
              <Grid container spacing={1}>
                <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                  <AutocompleteFilter headquarter={true} iot={true} xl={12} lg={12} md={12} />
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item xl={8} lg={8} md={8} xs={12} sm={12}>
          <Box boxShadow={4} borderRadius={10}>
            <FormDevice></FormDevice>
          </Box>
        </Grid>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
          <Box boxShadow={4} borderRadius={10}>
            <ListDevice />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapDispatchToProps = {
  setGlobalTitle,
  fetchAction,
};

export default connect(null, mapDispatchToProps)(DeviceView);
