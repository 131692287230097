import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import Save from '@material-ui/icons/Save';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { CancelIcon } from '../../assets/icons';
import '../../assets/styles/App.scss';
import { listDevices } from '../../device/actions/deviceActions';
import { commonMessage, icon } from '../../service/messageHandler';
import { updateFactorM } from '../action/CregActions';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '55vh',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const EditFactorM = (props) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [factorMForm, setfactorMForm] = useState({
    factorM: '',
    consecutiveMonths: '',
  });
  useEffect(() => {
    deserialize();
    if (props.isRequiredFactorMForAllDevice === appConstants.IS_REQUIRED_FACTORM_FOR_ALL_DEVICE) {
      listItems();
    }
  }, [open]);

  const handleOpen = () => {
    setOpen(true);
  };
  const deserialize = () => {
    let valueForm = {};

    valueForm.factorM = props.factorM.cregBreach.factorM ? props.factorM.cregBreach.factorM : '';
    valueForm.consecutiveMonths = props.factorM.cregBreach.consecutiveMonths
      ? props.factorM.cregBreach.consecutiveMonths
      : '';

    setfactorMForm({
      ...valueForm,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    let sendItem = { ...props.factorM.cregBreach, ...factorMForm };
    let msn = validate(sendItem);
    if (msn == '') {
      props.updateFactorM(sendItem);
      handleClose();
    } else {
      commonMessage(msn, appConstants.VALIDATION_ERROR_TITLE, icon.info);
    }
  };

  const validate = (userParam) => {
    let msn = '';

    msn += !userParam.factorM ? 'Debe especificar el factor M  \n' : '';

    msn += !userParam.consecutiveMonths ? 'Debe especificar los mese consecutivos \n' : '';

    return msn;
  };

  const listItems = () => {
    let filterDto = {
      page: 0,
      size: 300,
      filters: { classification: appConstants.PRINCIPAL_DEVICE },
    };
    props.listDevices(filterDto);
  };

  return (
    <div>
      <Button color="primary" className="btn-transparent ff-poppins fc-green min-width-1px" onClick={handleOpen}>
        <EditIcon />
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="factorm-modal-title"
        aria-describedby="factorm-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Box style={modalStyle} className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xl={12} lg={12} md={12} xs={12} sm={12} container className="center-info bg-secundary ">
                <Grid item xl={10} lg={10} md={10} xs={10} sm={10} className="center-info bg-secundary ">
                  <Typography variant="h5" component="h4" className="ff-lpoppins fc-third">
                    EDITAR FACTOR M
                  </Typography>
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1} className="bg-secundary ">
                  <Button onClick={(e) => handleClose()}>
                    <CancelIcon className="mr-1" height="25px" width="25px" />
                  </Button>
                </Grid>
              </Grid>
              <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info">
                <Card className="w-100">
                  <CardContent>
                    <form noValidate={false} autoComplete="off">
                      <Grid container spacing={2}>
                        <Grid item md={12} xl={12} lg={12} sm={12} xs={12}>
                          <Box boxShadow={5} borderRadius={10} className="input-element">
                            <TextField
                              required
                              type="number"
                              id="factorM"
                              label="Factor M"
                              fullWidth
                              variant="outlined"
                              value={factorMForm.factorM}
                              onChange={(valueEvent) =>
                                setfactorMForm({ ...factorMForm, factorM: valueEvent.target.value })
                              }
                            />
                          </Box>
                        </Grid>
                        <Grid item md={12} xl={12} lg={12} sm={12} xs={12}>
                          <Box boxShadow={5} borderRadius={10} className="input-element">
                            <TextField
                              required
                              type="number"
                              id="consecutiveMonths"
                              label="Meses Consecutivos"
                              fullWidth
                              variant="outlined"
                              value={factorMForm.consecutiveMonths}
                              onChange={(valueEvent) =>
                                setfactorMForm({ ...factorMForm, consecutiveMonths: valueEvent.target.value })
                              }
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                  <CardActions className="center d-flex">
                    <Grid item xl={10} lg={10} md={10} xs={12} sm={12} className="center-info">
                      <ButtonGroup
                        variant="text"
                        color="primary"
                        aria-label="text primary button group"
                        className="center"
                      >
                        <Button className="bg-green-gradient mr-2 pl-5 pr-5" onClick={handleSave}>
                          <Save />
                          Actualizar
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = { updateFactorM, listDevices };

const mapStateToProps = (state) => {
  return {
    isRequiredFactorMForAllDevice: state.CregReducer.isRequiredFactorMForAllDevice
      ? state.CregReducer.isRequiredFactorMForAllDevice
      : appConstants.IS_REQUIRED_FACTORM_FOR_ALL_DEVICE,
    isRequiredDevices: state.deviceReducer.isRequiredDevices
      ? state.deviceReducer.isRequiredDevices
      : appConstants.IS_REQUIRED_DEVICE_LIST,
    listFactorM: state.CregReducer.avaliabledFactorMForAllDevice ? state.CregReducer.avaliabledFactorMForAllDevice : [],
    isRequiredlistOfDevices: state.CregReducer.isRequiredlistOfDevices
      ? state.CregReducer.isRequiredlistOfDevices
      : appConstants.IS_REQUIRED_DEVICE_LIST,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditFactorM);
