import { Box, Button, FormControl, Grid, Input, InputAdornment, InputLabel } from '@material-ui/core';
import { AccountCircle, LockOpen } from '@material-ui/icons';
import PasswordField from 'material-ui-password-field';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import lettergl from '../../assets/image/lettergl.png';
import '../../assets/styles/App.scss';
import Loading from '../../components/Loading';
import { userLogin } from '../actions/userActions';
import '../assets/styles/user.scss';
import ModalRecoverPassword from './ModalRecoverPassword';

const LoginForm = (props) => {
  const [user, setUser] = useState({});

  const valid = () => {
    let msn = '';
    if (!user.username) msn += 'Debe ingresar el nombre de usuario<br>';

    if (!user.password) msn += 'Debe ingresar la contraseña';

    return msn;
  };

  const handleLogIn = (event) => {
    event.preventDefault();

    let msn = valid();
    if (msn == '') props.userLogin(user);
    else
      swal({
        title: 'Error',
        text: msn,
        icon: 'error',
      });
  };

  return (
    <Box height={1} width={1} justifyContent="center" display="flex" alignItems="center">
      <form onSubmit={handleLogIn} className="h-680px">
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="m-left-129px">
          <Box
            height={1}
            width={1}
            alignItems="center"
            justifyContent="center"
            display="flex"
            paddingBottom={1}
            className="mg-logo"
          >
            <img className="img-letter" src={lettergl} />
          </Box>
        </Grid>
        <Grid container spacing={2} className="position_input">
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <FormControl className="input-login">
              <InputLabel htmlFor="userNameInput" className="label-input ff-poppins ">
                Ingresa tu usuario
              </InputLabel>
              <Input
                autoComplete="off"
                fullWidth
                id="userNameInput"
                endAdornment={
                  <InputAdornment position="start">
                    <AccountCircle className="icon-login" />
                  </InputAdornment>
                }
                inputProps={{ maxLength: 100 }}
                value={user.username}
                onChange={(valueEvent) => {
                  user.username = valueEvent.target.value;
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <FormControl className="input-login">
              <InputLabel htmlFor="passw  ord" className="label-input ff-poppins ">
                Ingresa la contraseña
              </InputLabel>
              <PasswordField
                className="icon-pass"
                id="password"
                value={user.password}
                onChange={(valueEvent) => (user.password = valueEvent.target.value)}
              />
            </FormControl>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box type="submit" className="button-login" width={1} height={1}>
              <Button
                type="submit"
                variant="contained"
                onClick={handleLogIn}
                className="ff-poppins fw-bold classes.submit"
              >
                <LockOpen className="mr-2" /> Ingresar
              </Button>
            </Box>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="m-left-48px">
            <Box type="submit" className="" width={1} height={1}>
              <ModalRecoverPassword />
            </Box>
          </Grid>
        </Grid>
      </form>
      <Loading />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    //userPassed: state.userReducer.user ? state.userReducer.user : {},
  };
};

const mapDispatchToProps = {
  userLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
