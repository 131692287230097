import { appConstants } from '../../appConstants';
import { commonMessage, errorHandler, icon } from '../../service/messageHandler';
import { deviceActions } from '../actions/deviceActions';

const deviceReducer = (state = {}, action) => {
  switch (action.type) {
    case deviceActions.SET_DEVICE:
      return { ...state, selectedDevice: action.payload.item };
    case deviceActions.ADD_DEVICE:
      let removeItem = state.avaliabledDevices.filter((item) => item.id == action.payload.item.id)[0];
      state.avaliabledDevices.splice(state.avaliabledDevices.indexOf(removeItem), 1);

      state.avaliabledDevices.push(action.payload.item);

      return {
        ...state,
        avaliabledDevices: state.avaliabledDevices,
        selectedDevice: undefined,
      };
    case deviceActions.FETCHING_DEVICE_SAVE_SUCCESS:
      commonMessage(appConstants.SAVE_DEVICE_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);

      return {
        ...state,
        loading: { open: false },
        selectedDevice: {},
        isRequiredDevices: appConstants.IS_REQUIRED_DEVICE_LIST,
      };
    case deviceActions.FETCHING_DEVICE_REQUEST_LIST:
      return {
        ...state,
        isRequiredDevices: appConstants.IS_NOT_REQUIRED_DEVICE_LIST,
        loading: { open: true },
      };
    case deviceActions.FETCHING_DEVICE_REQUEST:
      return {
        ...state,
        loading: { open: true },
      };
    case deviceActions.FETCHING_DEVICE_FAILURE:
      errorHandler(action);
      return {
        ...state,
        isRequiredDevices: appConstants.IS_NOT_REQUIRED_DEVICE_LIST,
        loading: { open: false },
      };
    case deviceActions.FETCHING_DEVICE_LIST_SUCCESS:
      return {
        ...state,
        avaliabledDevices: action.payload.content ? action.payload.content : [],
        isRequiredDevices: appConstants.IS_NOT_REQUIRED_DEVICE_LIST,
        loading: { open: false },
      };
    case deviceActions.CLEAN_DEVICE_STORE:
      return {
        ...state,
        avaliabledDevices: [],
        isRequiredDevices: appConstants.IS_REQUIRED_DEVICE_LIST,
        selectedDevice: undefined,
      };
    case deviceActions.FETCHING_DEVICE_UPDATE_SUCCESS:
      commonMessage(appConstants.UPDATE_DEVICE_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);
      return {
        ...state,
        selectedDevice: {},
        loading: { open: false },
        isRequiredDevices: appConstants.IS_REQUIRED_DEVICE_LIST,
      };
    case deviceActions.FETCHING_DEVICE_DELETE_SUCCESS:
      commonMessage(appConstants.DELETE_DEVICE_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);
      return {
        ...state,
        isRequiredDevices: appConstants.IS_REQUIRED_DEVICE_LIST,
        selectedDevice: undefined,
        loading: { open: false },
      };
    case deviceActions.FETCH_REQUEST_PROTOCOLTYPES:
      return {
        ...state,
        loading: { open: true },
        isRequiredTypeProtocol: appConstants.IS_NOT_REQUIRED_MASTER_FIELDS_TYPEPROTOCOL,
      };

    case deviceActions.FETCH_REQUEST_PROTOCOLTYPES_SUCCESS:
      return {
        ...state,
        loading: { open: false },
        typeProtocol: action.payload,
      };
    case deviceActions.FETCH_REQUEST_CLASIFICATION:
      return {
        ...state,
        loading: { open: true },
        isRequiredClassification: appConstants.IS_NOT_REQUIRED_CLASIFICATION,
      };
    case deviceActions.FETCH_REQUEST_CLASIFICATION_SUCCESS:
      return {
        ...state,
        loading: { open: false },
        classification: action.payload,
      };

    default:
      return state;
  }
};

export default deviceReducer;
