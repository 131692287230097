import { BehaviorSubject } from 'rxjs';

let alarmSubject = new BehaviorSubject({});

let buttonModal = new BehaviorSubject('');

export const handleAlarmChange = (newValue) => {
  alarmSubject.next(newValue);
};

export function getAlarmObservable() {
  return alarmSubject;
}

export const handleFilterModalEvent = (newValue) => {
  buttonModal.next(newValue);
};

export function getButtonFilterModal() {
  return buttonModal;
}
