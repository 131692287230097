import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgIot(props) {
  const { height, width, color } = props;
  return (
    <svg
      viewBox="-76 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      height={height ? height : appConstants.ICON_DEFAULT_HEIGHT}
      width={width ? width : appConstants.ICON_DEFAULT_WIDTH}
    >
      <path d="M352.5 144c-.04-35.328-28.672-63.96-64-64h-24.36C260 34.687 222.005 0 176.5 0S93 34.688 88.86 80H64.5c-35.348 0-64 28.652-64 64s28.652 64 64 64h88v-16h-88c-26.508 0-48-21.492-48-48s21.492-48 48-48h40v-8c0-39.766 32.234-72 72-72s72 32.234 72 72v8h40c26.508 0 48 21.492 48 48s-21.492 48-48 48h-88v16h88c35.328-.04 63.96-28.672 64-64zm0 0" />
      <path d="M320.5 456V320c-.016-13.25-10.75-23.984-24-24h-16v-17.473c10.527-3.703 17.102-14.187 15.848-25.277s-10.008-19.844-21.098-21.098-21.574 5.32-25.277 15.848H184.5V147.312l18.344 18.344 11.312-11.312-37.656-37.657-37.656 37.657 11.312 11.312 18.344-18.344V248h-65.473c-3.703-10.527-14.187-17.105-25.277-15.848-11.09 1.254-19.844 10.004-21.098 21.094s5.32 21.578 15.848 25.277V304h-48c-13.25.016-23.984 10.75-24 24v96c.016 13.25 10.75 23.984 24 24h48v16h-24v16h64v-16h-24v-16h48c13.25-.016 23.984-10.75 24-24v-96c-.016-13.25-10.75-23.984-24-24h-48v-25.473A23.977 23.977 0 00103.027 264h146.946a23.962 23.962 0 0014.527 14.523V296h-48c-13.25.016-23.984 10.75-24 24v136c.016 13.25 10.75 23.984 24 24h80c13.25-.016 23.984-10.75 24-24zm-176-32a8.005 8.005 0 01-8 8h-112a8.005 8.005 0 01-8-8v-16h128zm-8-104a8.005 8.005 0 018 8v64h-128v-64a8.005 8.005 0 018-8zm-56-56a8 8 0 118-8 8.005 8.005 0 01-8 8zm192-16a8 8 0 11-8 8 8.005 8.005 0 018-8zm-64 72a8.005 8.005 0 018-8h80a8.005 8.005 0 018 8v104h-96zm0 136v-16h96v16a8.005 8.005 0 01-8 8h-80a8.005 8.005 0 01-8-8zm0 0" />
    </svg>
  );
}

export default SvgIot;
