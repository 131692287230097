import { DocumentationActions } from '../actions/DocumentationActions';
import { commonMessage, icon } from '../../service/messageHandler';
import { appConstants } from '../../appConstants';

const DocumentationReducer = (state = {}, action) => {
  switch (action.type) {
    case DocumentationActions.FETCHING_DOCUMENTATION_SAVE_REQUEST:
      return {
        ...state,
        loading: { open: true },
      };

    case DocumentationActions.FETCHING_DOCUMENTATION_SAVE_SUCCESS:
      commonMessage(appConstants.SAVE_MANUAL_MESSAGE, appConstants.SUCCESFUL_SAVED_MANUAL_TITLE, icon.success);
      return {
        ...state,
        loading: { open: false },
      };

    case DocumentationActions.FETCHING_DOCUMENTATION_FAILURE:
      commonMessage(appConstants.ERROR_MANUAL_MESSAGE, appConstants.SUCCESFUL_SAVED_MANUAL_TITLE, icon.error);
      return {
        ...state,
        loading: { open: false },
      };

    default:
      return state;
  }
};

export default DocumentationReducer;
