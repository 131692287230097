import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../../appConstants';
import { AlarmHistoryIcon, ConfigCregIcon } from '../../../assets/icons';
import '../../../assets/styles/App.scss';
import { componentActions, fetchAction, setGlobalTitle } from '../../../components/actions/componentActions';
import MenuButttons from '../../../components/menu/MenuButtons';

const menu = [
  {
    name: 'Configuracíon',
    icon: <ConfigCregIcon height="130px" width="130px" />,
    route: '/energy-reactive/config-creg/',
  },
  {
    name: 'Análisis Reactivos',
    icon: <AlarmHistoryIcon height="130px" width="130px" />,
    route: '/energy-reactive/analysis/',
  },
];

const PrincipalMenu = (props) => {
  const buttonsToolbar = [{ id: appConstants.BACK_BUTTON_ID, history: props.history }];

  useEffect(() => {
    props.setGlobalTitle(appConstants.MENU_ENERGY_REACTIVE);

    props.fetchAction(componentActions.ADD_TOOLBAR_BUTTON, buttonsToolbar);
  }, []);

  return (
    <Box width={1} height={1} display="flex" justifyContent="center" padding={3} className="background-image-bubble ">
      <MenuButttons items={menu} perRow={2} />
    </Box>
  );
};

const mapDispatchToProps = {
  setGlobalTitle,
  fetchAction,
};

export default connect(null, mapDispatchToProps)(PrincipalMenu);
