import { Box, Grid, Link, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import VideoPlayer from 'react-simple-video-player';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import {
  componentActions,
  fetchAction,
  getIntegrationPage,
  setGlobalTitle,
} from '../../components/actions/componentActions';

const Aoa = (props) => {
  const toolbarButtons = [{ id: appConstants.BACK_BUTTON_ID, history: props.history }];

  useEffect(() => {
    props.setGlobalTitle(appConstants.AOA_VIEW_TITLE);

    props.fetchAction(componentActions.ADD_TOOLBAR_BUTTON, toolbarButtons);

    if (props.isRequiredIntegrationPage == appConstants.IS_REQUIRED_INTEGRATION_PAGE) {
      props.getIntegrationPage(componentActions.FETCH_REQUEST_INTEGRATION_PAGES, toolbarButtons);
    }
  }, []);

  return (
    <Box
      height={1}
      width={1}
      padding={1}
      className="background-image-bubble "
      justifyContent="center"
      alignItems="center"
    >
      <Grid container spacing={2} className="mt-10px h-80">
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info bg-white">
          <Typography className="ff-poppins  fc-text-co2-primary  fs-t-cell">
            Descubre EcoStruxure Augmented Operator Advisor
          </Typography>
        </Grid>
        <Grid item xl={6} lg={6} md={6} xs={12} sm={6} className="center-info bg-co2-secundary h-100">
          <VideoPlayer
            url="https://www.youtube.com/watch?v=LBolUiyUKyE"
            autosize={true}
            controls={true}
            playsinline={true}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} xs={12} sm={6} className="center-info  bg-co2-primary">
          <Grid>
            <Typography className="ff-poppins fs-t-title-co2 fc-text-co2-secundary t-center mt-20px"></Typography>
            <Typography className="ff-poppins fc-white fs-t-text t-justify mt-20px">
              EcoStruxure Augmented Operator Advisor pone la información en tiempo real al alcance de su mano, cuando y
              donde se necesite.  La aplicación personalizada de Schneider electric mejora la eficiencia operativa
              gracias a la realidad aumentada, lo que permite a los operadores superponer datos y objetos virtuales en
              un gabinete, una máquina o una planta.
            </Typography>
            <Typography className="ff-poppins fc-white fs-t-text t-justify mt-20px">
              ¿Quieres conocer mas?
              <Link
                className="ff-poppins fc-text-co2-secundary fs-t-text t-justify mt-20px"
                href="https://www.se.com/ww/en/product-range-presentation/64507-ecostruxure%E2%84%A2-augmented-operator-advisor/"
              >
                Leer más…
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info bg-white">
          <Typography variant="body1" className="ff-poppins  fs-t-text t-justify ">
            ¿Te interesa adquirirlo?
            <Link
              className="ff-poppins fc-text-co2-primary fs-t-text t-justify mt-20px"
              href="https://play.google.com/store/apps/details?id=com.schneiderelectric.AugmentedOperator&hl=es_CO&gl=US"
            >
              Descarga la APP
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    url: state.componentsReducer.integrationPage?.urlAoa,
    isRequiredIntegrationPage: state.componentsReducer.isRequiredIntegrationPages
      ? state.componentsReducer.isRequiredIntegrationPages
      : appConstants.IS_REQUIRED_INTEGRATION_PAGE,
  };
};

const mapDispatchToProps = {
  setGlobalTitle,
  fetchAction,
  getIntegrationPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Aoa);
