import { Box } from '@material-ui/core';
import React from 'react';
import RenderDevices from '../components/renderImage/RenderDevices';
import RenderImage from '../components/renderImage/RenderImage';
const RenderDistribution = (props) => {
  return (
    <Box height={1} width={1} padding={3} position="relative" className="background-image-bubble ">
      <RenderImage />
      <RenderDevices />
    </Box>
  );
};

export default RenderDistribution;
