import { Box, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../../assets/styles/App.scss';
import { convertDate } from '../../service/util';
import BarReport from '../components/BarReport';
import TableBar from '../components/TableBar';
import TableTrend from '../components/TableTrend';
import TrendReport from '../components/TrendReport';
const ReportView = (props) => {
  const [info, setInfo] = useState([]);

  useEffect(() => {
    let values = props.valuesPerField[props.field?.name]?.content;
    setInfo(values ? values : []);
  }, [props.valuesPerField]);

  return (
    <Grid container>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Box height={1} width={'216mm'} padding={2} id="report" className="background-image-template">
          {props.field && (
            <Box width={1}>
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="center-info">
                  <Typography variant="h4">
                    <span className="fw-bold ff-poppins">INFORME DE LA VARIABLE: </span>
                    <span className="td-underline ff-poppins">{props.field?.name.toUpperCase()}</span>
                  </Typography>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="center-info bg-third"></Grid>

                <Box
                  width={1}
                  padding={4}
                  paddingLeft={12}
                  paddingRight={12}
                  boxShadow={2}
                  marginTop={2}
                  paddingTop={2}
                >
                  <Grid container spacing={2}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="center-info">
                      <Grid container>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <span className="fw-bold ff-poppins ff-poppins">SEDE : </span>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <span className=" ff-poppins">{props.headquarter?.name.toUpperCase()}</span>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="center-info">
                      <Grid container>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <span className="fw-bold ff-poppins ff-poppins">INSTALACiÓN : </span>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <span className=" ff-poppins">{props.installation?.name.toUpperCase()}</span>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="center-info">
                      <Grid container>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <span className="fw-bold ff-poppins ff-poppins">IOT : </span>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <span className=" ff-poppins">{props.iot?.name.toUpperCase()}</span>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="center-info">
                      <Grid container>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <span className="fw-bold ff-poppins ff-poppins">DISPOSITIVO : </span>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <span className=" ff-poppins">{props.device?.name.toUpperCase()}</span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          )}
          <Box width={1} paddingTop={4}>
            {props.field?.masterField?.typeGraphic === 'TRENDS' && <TrendReport field={props.field} />}
            {props.field?.masterField?.typeGraphic == 'BAR' && <BarReport field={props.field} />}
          </Box>

          {props.field && (
            <Box width={1}>
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="center-info">
                  <Typography variant="h6">
                    {info[0]
                      ? 'DATOS OBTENIDOS DESDE EL ' +
                        convertDate(new Date(info[info.length - 1]?.createdAt)) +
                        ' HASTA ' +
                        convertDate(new Date(info[0]?.createdAt))
                      : ''}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box width={1} padding={4}>
            {props.field?.masterField?.typeGraphic === 'TRENDS' && <TableTrend info={info} />}
            {props.field?.masterField?.typeGraphic === 'BAR' && <TableBar info={info} />}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    field: state.fieldReducer.selectedField,
    headquarter: state.headquarterReducer.selectedHeadquarter,
    installation: state.installationReducer.selectedInstallation,
    device: state.deviceReducer.selectedDevice,
    iot: state.iotReducer.selectedIot,
    valuesPerField: state.dashboardReducer.valuesPerField ? state.dashboardReducer.valuesPerField : {},
  };
};

export default connect(mapStateToProps, null)(ReportView);
