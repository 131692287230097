import React from 'react';
import { Route } from 'react-router-dom';
import AlarmHistoryList from '../../alarms/components/AlarmHistoryList';
import AlarmView from '../../alarms/layouts/AlarmView';
import { default as PrincipalMenuAlarms } from '../../alarms/layouts/Menus/PrincipalMenu';
import { appConstants } from '../../appConstants';
import TrendsChargeability from '../../chargeability/layouts/TrendsChargeability';
import CompanyView from '../../company/layouts/CompanyView';
import Home from '../../components/Home';
import Aoa from '../../components/integration/Aoa';
import BarDashboard from '../../dashboard/layouts/BarDashboard';
import TrendsDashboard from '../../dashboard/layouts/TrendsDashboard';
import DeviceView from '../../device/layouts/DeviceView';
import DocumentationView from '../../documentation/layouts/DocumentationView';
import FieldView from '../../field/layouts/FieldView';
import MasterFieldView from '../../field/layouts/MasterFieldView';
import HeadquarterView from '../../headquarter/layouts/HeadquarterView';
import CreateInstallationStepper from '../../installation/layouts/CreateInstallationStepper';
import Distribution from '../../installation/layouts/Distribution';
import DistributionMenu from '../../installation/layouts/DistributionMenu';
import InstallationView from '../../installation/layouts/InstallationView';
import { default as PrincipalMenuInstallation } from '../../installation/layouts/PrincipalMenu';
import RealDistribution from '../../installation/layouts/RealDistribution';
import IotView from '../../iot/layouts/IotView';
import { default as HistoryLicense } from '../../license/layouts/HistoryView';
import { default as LicenseView } from '../../license/layouts/LicenseView.jsx';
import { default as CycleLicense } from '../../license/layouts/PeriodoDeLicenciasView';
import { default as PrincipalMenuLicense } from '../../license/layouts/PrincipalMenu';
import FactorMList from '../../reactiveAnalysis/components/FactorMList';
import { default as PrincipalMenuReactive } from '../../reactiveAnalysis/layouts/Menus/PrincipalMenu';
import ReactivesView from '../../reactiveAnalysis/layouts/ReactivesView';
import GeneratorReportView from '../../reports/views/GeneratorReportView';
import ReportView from '../../reports/views/ReportView';
import { default as SupportView } from '../../support/layouts/SupportView';
import UserView from '../../user/layouts/UserView';
import LoginView from '../layouts/LoginView';
import { getGreatestRol } from './roleRate';

export const getRoutes = (user, isUserLogged) => {
  if (user?.roles) {
    let greatestRole = getGreatestRol(user.roles);
    switch (greatestRole.name) {
      case appConstants.SUPERADMIN:
        return (
          <>
            <Route exact path="/company" component={CompanyView} />
            <Route exact path="/" component={Home} />
            <Route exact path="/users" component={UserView} />
            <Route exact path="/master-field/create" component={MasterFieldView} />
            <Route exact path="/license/menu" component={PrincipalMenuLicense} />
            <Route exact path="/license/history" component={HistoryLicense} />
            <Route exact path="/license/cycle" component={CycleLicense} />
            <Route exact path="/license/license" component={LicenseView} />
            <Route exact path="/support" component={SupportView} />
            <Route exact path="/documentation" component={DocumentationView} />
          </>
        );

      case appConstants.ADMIN:
        return (
          <>
            <Route exact path="/" component={Home} />
            <Route exact path="/installation/create/distribution" component={CreateInstallationStepper} />
            <Route exact path="/distribution/menu" component={DistributionMenu} />
            <Route exact path="/installation/edit/distribution" component={Distribution} />
            <Route exact path="/installation/view/distribution" component={RealDistribution} />
            <Route exact path="/headquarter" component={HeadquarterView} />
            <Route exact path="/installation/menu" component={PrincipalMenuInstallation} />
            <Route exact path="/installation" component={InstallationView} />
            <Route exact path="/dashboard" component={BarDashboard} />
            <Route exact path="/field/create" component={FieldView} />
            <Route exact path="/device/create" component={DeviceView} />
            <Route exact path="/iot/create" component={IotView} />
            <Route exact path="/trends" component={TrendsDashboard} />
            <Route exact path="/users" component={UserView} />
            <Route exact path="/alarms" component={AlarmView} />
            <Route exact path="/alarms/history/" component={AlarmHistoryList} />
            <Route exact path="/energy-reactive/menu/" component={PrincipalMenuReactive} />
            <Route exact path="/energy-reactive/analysis/" component={ReactivesView} />
            <Route exact path="/energy-reactive/config-creg/" component={FactorMList} />
            <Route exact path="/alarms/menu" component={PrincipalMenuAlarms} />
            <Route exact path="/support" component={SupportView} />
            <Route exact path="/aoa" component={Aoa} />
            <Route exact path="/chargeability" component={TrendsChargeability} />
            <Route exact path="/reports" component={GeneratorReportView} />
            <Route exact path="/reports/download" component={ReportView} />
          </>
        );

      case appConstants.SUPERVISOR:
        return (
          <>
            <Route exact path="/" component={Home} />
            <Route exact path="/trends" component={TrendsDashboard} />
            <Route exact path="/dashboard" component={BarDashboard} />
            <Route exact path="/alarms/menu" component={PrincipalMenuAlarms} />
            <Route exact path="/alarms/history/" component={AlarmView} />
            <Route exact path="/chargeability" component={TrendsChargeability} />
            <Route exact path="/support" component={SupportView} />
          </>
        );

      case appConstants.OPERATOR:
        break;
      case appConstants.CONTROLLER:
        break;
      case appConstants.USER:
        break;
      default:
        return (
          <>
            <Route exact path="/" component={Home} />
            <Route exact path="/support" component={SupportView} />
          </>
        );
    }
  } else {
    return <Route exact path="/" component={isUserLogged ? Home : LoginView} />;
  }
};
