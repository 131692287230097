import { appConstants } from '../../appConstants';
import { errorHandler } from '../../service/messageHandler';
import { CregActions } from '../action/CregActions';

const CregReducer = (state = {}, action) => {
  switch (action.type) {

    case CregActions.FETCHING_ALARM_CREG_LIST_REQUEST:
        return {
          ...state,
          loading: { open: true },
          isRequiredListCregAlarms: appConstants.IS_NOT_REQUIRED_LIST_CREG_ALARMS,
          isCleanConsolidate: false,
        };

    case CregActions.FETCHING_ALARM_CREG_LIST_SUCCESS:
        return {
          ...state,
          loading: { open: false },
          avaliabledListCregAlarms: action.payload.content ? action.payload.content : [],
        };

    case CregActions.FETCHING_ALARM_CREG_LIST_FAILURE:
        errorHandler(action.payload);
         return {
          ...state,
           loading: { open: false },
           avaliabledListCregAlarms: [],
         };

    case CregActions.FETCHING_FACTOR_M_FOR_DEVICE_REQUEST:
      return {
        ...state,
        loading: { open: true },
        avaliabledFactorM:  undefined,
      };
    case CregActions.FETCHING_FACTOR_M_FOR_DEVICE_SUCCESS:
      return {
        ...state,
        loading: { open: false },
        avaliabledFactorM:  action.payload ? action.payload : undefined,
      };

    case CregActions.FETCHING_FACTOR_M_FOR_DEVICE_FAILURE:
        errorHandler(action.payload);
        return {
          ...state,
          loading: { open: false },
          avaliabledFactorM: undefined,
        };

    case CregActions.FETCHING_FACTOR_M_FOR_ALL_DEVICE_REQUEST:
      return {
        ...state,
        loading: { open: true },
        avaliabledFactorMForAllDevice:[],
        isRequiredFactorMForAllDevice: appConstants.IS_NOT_REQUIRED_FACTORM_FOR_ALL_DEVICE,
      };

    case CregActions.FETCHING_FACTOR_M_FOR_ALL_DEVICE_SUCCESS:
      return {
        ...state,
        loading: { open: false },
        avaliabledFactorMForAllDevice: action.payload.content ? action.payload.content :[],
        isRequiredlistOfDevices: appConstants.IS_REQUIRED_DEVICE_LIST,
      };

    case CregActions.FETCHING_FACTOR_M_FOR_ALL_DEVICE_FAILURE:
      return {
        ...state,
        loading: { open: false },
        avaliabledFactorMForAllDevice:[],
      };

    case CregActions.FETCHING_UPDATE_FACTOR_M_REQUEST:
      return {
        ...state,
        laoding: { open: true },
      };

    case CregActions.FETCHING_UPDATE_FACTOR_M_SUCCESS:
      return {
        ...state,
        avaliableUpdateFactorM: action.payload ? action.payload : undefined,
        laoding: { open: false },
        isRequiredlistOfDevices: appConstants.IS_REQUIRED_DEVICE_LIST,
        isRequiredFactorMForAllDevice: appConstants.IS_REQUIRED_FACTORM_FOR_ALL_DEVICE,
      };

    case CregActions.FETCHING_UPDATE_FACTOR_M_FAILURE:
      errorHandler(action.payload);
      return {
        ...state,
        laoding: { open: true },
      };

    case CregActions.FETCHING_CONSOLIDATED_FACTOR_M_REQUEST:
      return {
        ...state,
        loading: { open: true },
        avaliabledConsolidatedFactorM: action.payload.content ? action.payload.content : undefined,
      };

    case CregActions.FETCHING_CONSOLIDATED_FACTOR_M_SUCCESS:
      return {
        ...state,
        loading: { open: true },
        avaliabledConsolidatedFactorM: action.payload.content ? action.payload.content : undefined,
      };

    case CregActions.FETCHING_CONSOLIDATED_FACTOR_M_FAILURE:
      errorHandler(action.payload);
      return {
        ...state,
        laoding: { open: true },
      };

    case CregActions.SET_SERIE_FILTER_CONSOLIDATED:
      return {
        ...state,
        serieFilterConsolidated: action.payload.item ? action.payload.item : undefined,
        isRequiredChangeDateFilterConsolidated: true,
      };

    case CregActions.CLEAN_LIST_CREG_ALARMS:
        return {
          ...state,
          avaliabledListCregAlarms: [],
        };
    case CregActions.CLEAN_LIST_FACTOR_M:
        return {
          ...state,
          avaliabledFactorMForAllDevice:[],
        };
    case CregActions.CLEAN_SERIE_FILTER_CONSOLIDATED:
        return {
          ...state,
          serieFilterConsolidated:undefined,
          isRequiredChangeDateFilterConsolidated:false,
        };
        
    default:
      return {};
  }
};

export default CregReducer;
