import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import Brush from '@material-ui/icons/Brush';
import Save from '@material-ui/icons/Save';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { fetchRequest, headquarterActions } from '../../headquarter/actions/headquarterActions';
import HeadquarterAutocomplete from '../../headquarter/components/HeadquarterAutocomplete';
import { commonMessage, icon } from '../../service/messageHandler';
import { saveIot, setIot, updateIot } from '../actions/iotActions';

const IotForm = (props) => {
  const { iot } = props;

  const [iotForm, setIotForm] = useState({
    description: '',
    nameControlPanel: '',
    quantityDevice: '',
    name: '',
    serial: '',
    soVersion: '',
    mac: '',
  });

  useEffect(() => {
    deserialize();
  }, [props.iot]);

  const deserialize = () => {
    let valueForm = {};
    valueForm.id = iot.id ? iot.id : '';
    valueForm.installationId = iot.installationId ? iot.installationId : '';
    valueForm.companyId = iot.companyId ? iot.companyId : '';
    valueForm.description = iot.description ? iot.description : '';
    valueForm.nameControlPanel = iot.nameControlPanel ? iot.nameControlPanel : '';
    valueForm.quantityDevice = iot.quantityDevice ? iot.quantityDevice : '';
    valueForm.name = iot.name ? iot.name : '';
    valueForm.serial = iot.serial ? iot.serial : '';
    valueForm.soVersion = iot.soVersion ? iot.soVersion : '';
    valueForm.mac = iot.mac ? iot.mac : '';
    setIotForm({
      ...valueForm,
    });
  };

  const handleClean = () => {
    props.setIot({ item: undefined });
    props.fetchRequest(headquarterActions.SET_HEADQUARTER, { item: undefined });
  };

  const handleSave = () => {
    let iotSended = {
      ...iotForm,
      headquarterId: props.selectedHeadquarter?.id,
    };

    let msn = validate(iotSended);

    if (msn == '') {
      !iot.id ? props.saveIot(iotSended) : props.updateIot(iotSended);
    } else {
      commonMessage(msn, appConstants.VALIDATION_ERROR_TITLE, icon.info);
    }
    handleClean();
  };

  const validate = (iotParam) => {
    let msn = '';
    msn += !props.selectedHeadquarter?.id ? 'Debe seleccionar la sede \n' : '';

    msn +=
      !iotParam.nameControlPanel || iotParam.nameControlPanel?.trim() == ''
        ? 'Debe especificar el panel de control  \n'
        : '';

    msn += !iotParam.name || iotParam.name?.trim() == '' ? 'Debe especificar nombre del dispositivo  \n' : '';

    msn += !iotParam.serial || iotParam.serial?.trim() == '' ? 'Debe especificar el serial  \n' : '';

    msn += !iotParam.mac || iotParam.mac?.trim() == '' ? 'Debe especificar la mac \n' : '';

    msn += !iotParam.soVersion || iotParam.soVersion.trim() == '' ? 'Debe especificar el sistema operativo \n' : '';

    msn += !iotParam.description || iotParam.description.trim() == '' ? 'Debe especificar la descripción \n' : '';

    msn += !iotParam.headquarterId ? 'Debe seleccionar una sede \n' : '';

    msn += !iotParam.quantityDevice ? 'Debe especificar la cantidad de  dispositivos\n' : '';

    if (iotParam.quantityDevice < 0) {
      msn += 'La cantidad de dispositivos debe ser positiva';
    }

    return msn;
  };

  return (
    <Box height={1}>
      <Card className="border-10">
        <CardContent>
          <form noValidate={false} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info bg-secundary">
                <Typography variant="h5" className="ff-poppins fw-bold  fc-third">
                  Creación
                </Typography>
              </Grid>
              <Grid item md={12} xl={12} lg={12} sm={12} xs={12}>
                <Box display="flex" alignItems="center" borderRadius={10}>
                  <HeadquarterAutocomplete required={true} />
                </Box>
              </Grid>
              <Grid item md={8} xl={8} lg={8} sm={12} xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    id="nameControlPanel"
                    label="Nombre del panel de control"
                    fullWidth
                    className="border-none"
                    variant="outlined"
                    inputProps={{
                      maxLength: 100,
                    }}
                    value={iotForm.nameControlPanel}
                    onChange={(valueEvent) => setIotForm({ ...iotForm, nameControlPanel: valueEvent.target.value })}
                  />
                </Box>
              </Grid>

              <Grid item md={4} xl={4} lg={4} sm={12} xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    fullWidth
                    id="quantity"
                    label="Cantidad de dispositivos"
                    type="number"
                    inputProps={{
                      maxLength: 3,
                      max: 15,
                    }}
                    variant="outlined"
                    value={iotForm.quantityDevice}
                    onChange={(valueEvent) => setIotForm({ ...iotForm, quantityDevice: valueEvent.target.value })}
                  />
                </Box>
              </Grid>

              <Grid item md={8} xl={8} lg={8} sm={12} xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    id="nameIot"
                    label="Nombre"
                    fullWidth
                    inputProps={{
                      maxLength: 100,
                    }}
                    variant="outlined"
                    value={iotForm.name}
                    onChange={(valueEvent) => setIotForm({ ...iotForm, name: valueEvent.target.value })}
                  />
                </Box>
              </Grid>

              <Grid item md={4} xl={4} lg={4} sm={12} xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    fullWidth
                    id="serial"
                    label="Serial"
                    inputProps={{
                      maxLength: 20,
                    }}
                    variant="outlined"
                    value={iotForm.serial}
                    onChange={(valueEvent) => setIotForm({ ...iotForm, serial: valueEvent.target.value })}
                  />
                </Box>
              </Grid>

              <Grid item md={6} xl={6} lg={6} sm={12} xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    fullWidth
                    id="mac"
                    label="Mac"
                    inputProps={{
                      maxLength: 50,
                    }}
                    variant="outlined"
                    value={iotForm.mac}
                    onChange={(valueEvent) => setIotForm({ ...iotForm, mac: valueEvent.target.value })}
                  />
                </Box>
              </Grid>

              <Grid item md={6} xl={6} lg={6} sm={12} xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    fullWidth
                    id="soVersion"
                    label="Sistema operativo"
                    variant="outlined"
                    inputProps={{
                      maxLength: 100,
                    }}
                    value={iotForm.soVersion}
                    onChange={(valueEvent) => setIotForm({ ...iotForm, soVersion: valueEvent.target.value })}
                  />
                </Box>
              </Grid>

              <Grid item md={6} xl={6} lg={6} sm={12} xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    fullWidth
                    id="description"
                    label="Descripción"
                    variant="outlined"
                    multiline
                    inputProps={{
                      maxLength: 255,
                    }}
                    rows={2}
                    value={iotForm.description}
                    onChange={(valueEvent) => setIotForm({ ...iotForm, description: valueEvent.target.value })}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <CardActions className="center d-flex">
          <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
            <Button color="primary" className="bg-green-gradient mr-2 pl-5 pr-5" onClick={handleSave}>
              <Save />
              {iot.id ? 'Actualizar' : 'Guardar'}
            </Button>

            <Button disabled={iotForm == {}} className="bg-green-gradient mr-2 pl-5 pr-5" onClick={handleClean}>
              <Brush />
              Limpiar
            </Button>
          </ButtonGroup>
        </CardActions>
      </Card>
    </Box>
  );
};

const mapDispatchToProps = {
  setIot,
  saveIot,
  fetchRequest,
  updateIot,
};

const mapStateToProps = (state) => {
  return {
    iot: state.iotReducer.selectedIot ? state.iotReducer.selectedIot : {},
    selectedHeadquarter: state.headquarterReducer.selectedHeadquarter
      ? state.headquarterReducer.selectedHeadquarter
      : undefined,
    isRequiredIots: state.iotReducer.isRequiredIots,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IotForm);
