import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent, Grid, TextField
} from '@material-ui/core';
import Brush from '@material-ui/icons/Brush';
import Save from '@material-ui/icons/Save';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { setDevice } from '../../device/actions/deviceActions';
import {
  fetchRequest as FetchHeadquarterRequest,
  headquarterActions
} from '../../headquarter/actions/headquarterActions';
import { setIot } from '../../iot/actions/iotActions';
import { commonMessage, icon } from '../../service/messageHandler';
import { saveField, setField, updateField } from '../actions/fieldActions';

const FieldForm = (props) => {
  const { field, selectedDevice } = props;

  const [fieldForm, setFieldForm] = useState({
    name: '',
    signalType: '',
    unitMeasurement: '',
  });

  const [signal] = React.useState({type:''});
  const [unitMeasurement] = React.useState({measurement:''});


  useEffect(() => {
    deserialize();
  }, [props.fieldForm,field]);

  const handleSave = () => {
    let msn = validate();

    if (msn == '') {
      let itemSaved = {
        ...fieldForm,
      };
      props.updateField(itemSaved);
    } else {
      commonMessage(msn, appConstants.VALIDATION_ERROR_TITLE, icon.error);
    }
  };

  const validate = () => {
    let msn = '';

    msn += !fieldForm.name ? 'Debe ingresar el nombre \n' : '';


    return msn;
  };

  const deserialize = () => {
    let valueForm = {};
    valueForm.device = field.device ?field.device : {};
    valueForm.headquarter = field.headquarter ? field.headquarter : {};
    valueForm.masterField = field.masterField ? field.masterField : {};
    valueForm.id = field.id ? field.id : '';
    valueForm.name = field.name ? field.name : '';
    valueForm.companyId= field.companyId ? field.companyId :'';
    valueForm.iot = field.iot ? field.iot:{};
    
    signal.type= field.masterField?.signalType? field.masterField.signalType:'';
    unitMeasurement.measurement = field.masterField?.unitMeasurement? field.masterField.unitMeasurement:'';

    setFieldForm({
      ...valueForm,
    });
  };

  const handleClean = () => {
    fieldForm.name='';
    fieldForm.signalType='';
    fieldForm.unitMeasurement='';
    props.setField({ item: undefined });
    props.setDevice({ item: undefined });
    props.setIot({ item: undefined });
    props.FetchHeadquarterRequest(headquarterActions.SET_HEADQUARTER, { item: undefined });
  };

  return (
    <Box>
      <Card className="border-10 ">
        <CardContent>
          <form noValidate={false} autoComplete="off">
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    id="name"
                    label="Nombre"
                    fullWidth
                    variant="outlined"
                    value={fieldForm.name}
                    onChange={(valueEvent) => setFieldForm({ ...fieldForm, name: valueEvent.target.value })}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    enabled={false}
                    id="typeSignal"
                    label="Tipo de señal"
                    fullWidth
                    variant="filled"
                    value={signal.type}
                     inputProps={{
                      readOnly: true,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                    <TextField
                    enabled={false}
                    id="unitMeasurement"
                    label="Unidad de medida"
                    fullWidth
                    variant="filled"
                    value={unitMeasurement.measurement}
                     inputProps={{
                      readOnly: true,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <CardActions className="center d-flex ">
          <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
            <Button color="primary" className="bg-green-gradient mr-2 pl-5 pr-5" onClick={handleSave}>
              <Save className="mr-2" />
              Actualizar
            </Button>

            <Button className="bg-green-gradient mr-2 pl-5 pr-5"  onClick={handleClean}>
              <Brush className="mr-2" />
              Limpiar
            </Button>
          </ButtonGroup>
        </CardActions>
      </Card>
    </Box>
  );
};

const mapStateToprops = (state) => {
  return {
    field: state.fieldReducer.selectedField ? state.fieldReducer.selectedField : {},
    selectedHeadquarter: state.headquarterReducer.selectedHeadquarter,
    selectedIot: state.iotReducer.selectedIot,
    selectedDevice: state.deviceReducer.selectedDevice ? state.deviceReducer.selectedDevice : undefined,
    isRequiredFields: state.fieldReducer.isRequiredFields
      ? state.fieldReducer.isRequiredFields
      : appConstants.IS_REQUIRED_FIELDS_LIST,
  };
};

const mapDispatchToProps = {
  saveField,
  setField,
  setIot,
  setDevice,
  FetchHeadquarterRequest,
  updateField,
};

export default connect(mapStateToprops, mapDispatchToProps)(FieldForm);
