import { Box, Button, Card } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { appConstants } from '../../../appConstants';
import '../../../assets/styles/App.scss';
import { listDevices } from '../../../device/actions/deviceActions';
import { fetchRequest as fetchHeadquarter, headquarterActions } from '../../../headquarter/actions/headquarterActions';
import {
  deleteInstallation,
  fetchRequest,
  installationActions,
  listInstallation,
} from '../../actions/installationActions';

const columns = [
  { id: 'id', label: 'ID' },
  { id: 'name', label: 'Nombre' },
  { id: 'headquarterName', label: 'Sede' },
];

const InstallationList = (props) => {
  const { list } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (props.isRequiredInstallations == appConstants.IS_REQUIRED_INSTALLATION_LIST) {
      props.listInstallation({
        page: 0,
        size: 300,
      });
    }
    return function () {
      props.fetchRequest(installationActions.CLEAN_INSTALLATION, { edit: true });
    };
  }, []);

  const handleEdit = (itemSelected) => {
    props.fetchRequest(installationActions.SET_INSTALLATION, { item: itemSelected });
    props.listDevices({
      page: 0,
      size: 1000,
      filters: { iot: { installationId: itemSelected.id } },
    });

    props.fetchHeadquarter(headquarterActions.SEARCH_SET_BY_ID_HQ, {
      id: itemSelected.headquarter.id,
      disabledAutocomplete: true,
    });
    history.push('/installation/edit/distribution');
  };

  const handleView = (itemSelected) => {
    props.fetchRequest(installationActions.VIEW_INSTALLATION, { item: itemSelected });
    props.listDevices({ page: 0, size: 1000, filters: { iot: { installationId: itemSelected.id } } });
    history.push('/installation/view/distribution');
  };
  const handleDelete = (itemSelected) => {
    swal({
      title: appConstants.INSTALATION_TITLE,
      text: appConstants.WARNING_DELETE,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteInstallation(itemSelected?.id);
      }
    });
  };
  return (
    <Box width={1} height={1}>
      <Card className="border-10">
        <TableContainer className="table-master">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    className="bg-secundary fc-third ff-poppins fw-bold fs-Large t-center-important"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}

                <TableCell
                  colSpan={2}
                  align="center"
                  className="bg-secundary fc-third ff-poppins fw-bold fs-Large "
                  key={'action'}
                >
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((item) => {
                let row = {
                  id: item.id,
                  headquarterName: item?.headquarter?.name,
                  name: item.name,
                };
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} className="t-center-important">
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell key={'action'} className="center-info" align="center">
                      <Button
                        color="primary"
                        className="btn-transparent ff-poppins fc-green min-width-1px"
                        onClick={(e) => handleEdit(item)}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        color="primary"
                        className="btn-transparent ff-poppins fc-green min-width-1px"
                        onClick={(e) => handleView(item)}
                      >
                        <VisibilityIcon />
                      </Button>
                      <Button
                        color="primary"
                        className="btn-transparent ff-poppins fc-green min-width-1px"
                        onClick={(e) => handleDelete(item)}
                      >
                        <DeleteIcon color="secondary" />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Card>
    </Box>
  );
};

const mapDispatchToProps = {
  listInstallation,
  fetchRequest,
  fetchHeadquarter,
  deleteInstallation,
  listDevices,
};

const mapStateToProps = (state) => {
  return {
    list: state.installationReducer.avaliabledInstallations ? state.installationReducer.avaliabledInstallations : [],
    isRequiredInstallations: state.installationReducer.isRequiredInstallations
      ? state.installationReducer.isRequiredInstallations
      : appConstants.IS_REQUIRED_INSTALLATION_LIST,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InstallationList);
