import { Box, Button, Card } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { setField } from '../../field/actions/fieldActions';
import { deleteAlarm, listAlarms } from '../action/alarmActions';
import AlarmEditFormModal from '../components/AlarmEditFormModal';
import { getButtonFilterModal } from '../services/alarmObservables';

const columns = [
  { id: 'name', label: 'Nombre Alarma' },
  { id: 'max', label: 'Valor máximo' },
  {
    id: 'min',
    label: 'Valor mínimo',
  },
  { id: 'messageMax', label: 'Mensaje valor máximo' },
  {
    id: 'messageMin',
    label: 'Mensaje valor mínimo',
  },
];

const AlarmList = (props) => {
  const { list } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [subs, setSubs] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (props.isRequiredAlarms == appConstants.IS_REQUIRED_ALARMS) listItems();

    subscribe();
    return function () {
      subs.forEach((element) => {
        element.unsubscribe();
      });
    };
  }, [props.selectedAlarms]);

  const subscribe = () => {
    setSubs([
      ...subs,
      getButtonFilterModal().subscribe((data) => {
        if (data == appConstants.FILTER_EVENT) {
          listItems();
        }
      }),
    ]);
  };

  const listItems = () => {
    let filter = {
      page: 0,
      size: 3000,
      filters: {},
    };

    props.listAlarms(filter);
  };

  const handleDelete = (itemSelected) => {
    swal({
      title: appConstants.ALARM_VIEW_TITLE,
      text: appConstants.WARNING_DELETE,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (itemSelected?.field?.alarms?.length === 1) {
          itemSelected.field.alarms = null;
        } else {
          let position = itemSelected.field.alarms.indexOf(itemSelected.alarm);

          itemSelected?.field?.alarms.splice(position, 1);
        }

        let itemField = itemSelected.field;

        props.deleteAlarm({
          ...itemField,
        });
      }
    });
  };
  return (
    <Box width={1} height={1}>
      <Card className="border-10">
        <TableContainer className="table-master">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    className="bg-secundary fc-third ff-poppins fw-bold fs-Large t-center-important"
                    key={column.id}
                    align="center"
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}

                <TableCell className="bg-secundary fc-third ff-poppins fw-bold fs-Large " key={'action'}>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((item, index) => {
                let row = item.alarm;
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} className="t-center-important">
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell key={'action'} className="center-info" align="center">
                      <Button color="primary" className="btn-transparent ff-poppins fc-green min-width-1px">
                        <AlarmEditFormModal alarm={item} />
                      </Button>
                      <Button
                        color="primary"
                        className="btn-transparent ff-poppins fc-green min-width-1px"
                        onClick={(e) => handleDelete(item)}
                      >
                        <DeleteIcon color="secondary" />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Card>
    </Box>
  );
};

const mapDispatchToProps = {
  listAlarms,
  deleteAlarm,
  setField,
};

const mapStateToProps = (state) => {
  return {
    list: state.alarmReducer.alarms ? state.alarmReducer.alarms : [],
    isRequiredIots: state.iotReducer.isRequiredIots
      ? state.iotReducer.isRequiredIots
      : appConstants.IS_REQUIRED_IOT_LIST,
    selectedAlarms: state.alarmReducer.selectedAlarms,
    selectedHeadquarter: state.headquarterReducer.selectedHeadquarter,
    field: state.fieldReducer.selectedField,
    isRequiredAlarms: state.alarmReducer.isRequiredAlarms
      ? state.alarmReducer.isRequiredAlarms
      : appConstants.IS_REQUIRED_ALARMS,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlarmList);
