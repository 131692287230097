import { appConstants } from '../../appConstants';
import ApiAxios from '../../service/Api';
import installationApi from '../services/installationApi';

export const installationActions = {
  FETCHING_INSTALLATION_SAVE: 'FETCHING_INSTALLATION_REQUEST',
  FETCHING_INSTALLATION_SAVE_SUCCESS: 'FETCHING_INSTALLATION_SAVE_SUCCESS',
  FETCHING_INSTALLATION_LIST: 'FETCHING_INSTALLATION_LIST',
  FETCHING_INSTALLATION_LIST_SUCCESS: 'FETCHING_INSTALLATION_LIST_SUCCESS',
  FETCHING_INSTALLATION_FAILURE: 'FETCHING_INSTALLATION_FAILURE',
  SET_INSTALLATION: 'SET_INSTALLATION',
  CLEAN_INSTALLATION: 'CLEAN_INSTALLATION',
  VIEW_INSTALLATION: 'VIEW_INSTALLATION',
  FETCHING_INSTALLATION_IMAGE: 'FETCHING_INSTALLATION_IMAGE',
  FETCHING_INSTALLATION_IMAGE_SUCCESS: 'FETCHING_INSTALLATION_IMAGE_SUCCESS',
  FETCHING_INSTALLATION_UPDATE: 'FETCHING_INSTALLATION_UPDATE',
  FETCHING_INSTALLATION_UPDATE_SUCCESS: 'FETCHING_INSTALLATION_UPDATE_SUCCESS',
  FETCHING_INSTALLATION_DELETE_SUCCESS: 'FETCHING_INSTALLATION_DELETE_SUCCESS',
  FETCHING_INSTALLATION_REQUEST: 'FETCHING_INSTALLATION_REQUEST',
};

export const fetchRequest = (type, payload = {}) => {
  return {
    type,
    payload,
  };
};

export const cleanInstallation = () => {
  return {
    type: installationActions.CLEAN_INSTALLATION,
  };
};

export const saveInstallation = (file, installation, devices) => {
  return function (dispatch) {
    installationApi.saveDistribution(file, installation, devices, dispatch, fetchRequest, {
      request: installationActions.FETCHING_INSTALLATION_SAVE,
      success: installationActions.FETCHING_INSTALLATION_SAVE_SUCCESS,
      error: installationActions.FETCHING_INSTALLATION_FAILURE,
    });
  };
};

export const editInstallationWithoutFile = (installation, devices) => {
  return function (dispatch) {
    installationApi.saveInstallationWithoutFile(installation, devices, dispatch, fetchRequest, {
      request: installationActions.FETCHING_INSTALLATION_SAVE,
      success: installationActions.FETCHING_INSTALLATION_SAVE_SUCCESS,
      error: installationActions.FETCHING_INSTALLATION_FAILURE,
    });
  };
};

export const listInstallation = (dto) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_INSTALLATIONS, dto, dispatch, fetchRequest, {
      request: installationActions.FETCHING_INSTALLATION_LIST,
      success: installationActions.FETCHING_INSTALLATION_LIST_SUCCESS,
      error: installationActions.FETCHING_INSTALLATION_FAILURE,
    });
  };
};

export const loadImage = (id) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.get(`${appConstants.CONTEXT_FILE}${id}`, dispatch, fetchRequest, {
      request: installationActions.FETCHING_INSTALLATION_IMAGE,
      success: installationActions.FETCHING_INSTALLATION_IMAGE_SUCCESS,
      error: installationActions.FETCHING_INSTALLATION_FAILURE,
    });
  };
};

export const deleteInstallation = (id) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.delete(`${appConstants.CONTEXT_INSTALLATION}${id}`, dispatch, fetchRequest, {
      request: installationActions.FETCHING_INSTALLATION_REQUEST,
      success: installationActions.FETCHING_INSTALLATION_DELETE_SUCCESS,
      error: installationActions.FETCHING_INSTALLATION_FAILURE,
    });
  };
};
