import { Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { componentActions, fetchAction, setGlobalTitle } from '../../components/actions/componentActions';
import Loading from '../../components/Loading';
import LicenseCycleForm from '../components/LicenseCycleForm';
import ListLicenseCycle from '../components/ListLicenseCycle';

const PeriodoDeLicenciasView = (props) => {
  const buttonsToolbar = [{ id: appConstants.BACK_BUTTON_ID, history: props.history }];

  useEffect(() => {
    props.setGlobalTitle(appConstants.LICENSE_VIEW_TITLE);

    props.fetchAction(componentActions.ADD_TOOLBAR_BUTTON, buttonsToolbar);
  }, []);

  return (
    <Box width={1} height={1} display="flex" justifyContent="center" className="background-image-bubble " padding={3}>
      <Box height={1} width={1} padding={3} className="background-image-bubble ">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box boxShadow={4} borderRadius={10}>
              <LicenseCycleForm />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box boxShadow={4} borderRadius={10}>
              <ListLicenseCycle />
            </Box>
          </Grid>
        </Grid>
        <Loading />
      </Box>
    </Box>
  );
};

const mapDispatchToProps = {
  setGlobalTitle,
  fetchAction,
};

export default connect(null, mapDispatchToProps)(PeriodoDeLicenciasView);
