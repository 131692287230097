import { Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { componentActions, fetchAction, removeButton, setGlobalTitle } from '../../components/actions/componentActions';
import Loading from '../../components/Loading';
import UserCreateForm from '../components/UserCreateForm';
import UserList from '../components/UserList';

const UserView = (props) => {
  const toolbarButtons = [{ id: appConstants.BACK_BUTTON_ID, history: props.history }];

  useEffect(() => {
    props.setGlobalTitle(appConstants.USER_VIEW_TITLE);

    props.fetchAction(componentActions.ADD_TOOLBAR_BUTTON, toolbarButtons);
  }, []);

  return (
    <Box height={1} width={1} padding={1} className="background-image-bubble mt-10px">
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} xs={12} sm={12}>
          <Box boxShadow={4} borderRadius={10}>
            <UserCreateForm />
          </Box>
        </Grid>
        <Grid item xl={6} lg={6} md={6} xs={12} sm={12}>
          <Box boxShadow={4} borderRadius={10}>
            <UserList />
          </Box>
        </Grid>
      </Grid>
      <Loading />
    </Box>
  );
};

const mapDispatchToProps = {
  setGlobalTitle,
  fetchAction,
  removeButton,
};

export default connect(null, mapDispatchToProps)(UserView);
