import { Box, Button, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel } from '@material-ui/core';
import { LockOpen, Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { commonMessage, icon } from '../../service/messageHandler';
import { changePassowrd } from '../actions/userActions';

const ChangePassword = (props) => {
  const [formPassoword, setFormPassoword] = useState(initializeForm());
  const [showPasswords, setShowPasswords] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const changeValueForm = (event, type) => {
    event.preventDefault();
    setFormPassoword({ ...formPassoword, [type]: event.target.value });
  };

  const changeVisibility = (event, type) => {
    event.preventDefault();
    setShowPasswords({ ...showPasswords, [type]: !showPasswords[type] });
  };

  const changePassword = (event) => {
    event.preventDefault();
    let msnValidation = validate();

    if ('' != msnValidation) {
      commonMessage(msnValidation, appConstants.ERROR_TITLE, icon.error);
      return;
    }

    props.changePassowrd(formPassoword);
  };

  const validate = () => {
    let msn = '';

    msn +=
      formPassoword.oldPassword == null || formPassoword.oldPassword == '' || formPassoword.oldPassword.length < 6
        ? ' La contraseña actual es inválida \n'
        : '';
    msn +=
      formPassoword.newPassword == null || formPassoword.newPassword == '' || formPassoword.newPassword.length < 6
        ? ' La contraseña nueva es inválida \n'
        : '';
    msn +=
      formPassoword.confirmPassword == null ||
      formPassoword.confirmPassword == '' ||
      formPassoword.confirmPassword.length < 6
        ? ' La contraseña nueva es inválida \n'
        : '';
    msn += formPassoword.confirmPassword != formPassoword.newPassword ? 'Las contraseñas no coinciden' : '';

    return msn;
  };

  return (
    <Box height={1} width={1}>
      <Grid container spacing={2}>
        <Grid item xlxs={12} lgxs={12} mdxs={12} smxs={12} xs={12}>
          <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
            <FormControl className=" w-100">
              <InputLabel htmlFor="oldPassword" className="label-input ff-poppins w-100 t-center">
                Ingrese la contraseña actual.
              </InputLabel>
              <Input
                required
                id="oldPassword"
                label="Antigua contraseña"
                fullWidth
                className="border-none pl-2"
                variant="outlined"
                inputProps={{
                  maxLength: 15,
                  minLength: 6,
                }}
                type={showPasswords.oldPassword ? 'text' : 'password'}
                value={formPassoword.oldPassword}
                onChange={(e) => changeValueForm(e, 'oldPassword')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      className="bg-white"
                      aria-label="toggle password visibility"
                      onClick={(e) => changeVisibility(e, 'oldPassword')}
                    >
                      {showPasswords.oldPassword ? (
                        <Visibility className="bg-white" />
                      ) : (
                        <VisibilityOff className="bg-white" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xlxs={12} lgxs={12} mdxs={12} smxs={12} xs={12}>
          <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
            <FormControl className=" w-100 ">
              <InputLabel htmlFor="newPassword" className="label-input ff-poppins w-100 t-center">
                Ingrese la nueva contraseña.
              </InputLabel>
              <Input
                required
                id="newPassword"
                label="Nueva contraseña"
                fullWidth
                className="border-none pl-2"
                variant="outlined"
                inputProps={{
                  maxLength: 15,
                  minLength: 6,
                }}
                type={showPasswords.newPassword ? 'text' : 'password'}
                value={formPassoword.newPassword}
                onChange={(e) => changeValueForm(e, 'newPassword')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      className="bg-white"
                      aria-label="toggle password visibility"
                      onClick={(e) => changeVisibility(e, 'newPassword')}
                    >
                      {showPasswords.newPassword ? (
                        <Visibility className="bg-white" />
                      ) : (
                        <VisibilityOff className="bg-white" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xlxs={12} lgxs={12} mdxs={12} smxs={12} xs={12}>
          <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
            <FormControl className=" w-100">
              <InputLabel htmlFor="confirmPassword" className="label-input ff-poppins w-100 t-center">
                Confirme la contraseña.
              </InputLabel>
              <Input
                required
                id="confirmPassword"
                label="Confirmación de contraseña"
                fullWidth
                className="border-none pl-2"
                variant="outlined"
                inputProps={{
                  maxLength: 15,
                  minLength: 6,
                }}
                type={showPasswords.confirmPassword ? 'text' : 'password'}
                value={formPassoword.confirmPassword}
                onChange={(e) => changeValueForm(e, 'confirmPassword')}
                endAdornment={
                  <InputAdornment position="end" className>
                    <IconButton
                      className="bg-white"
                      aria-label="toggle password visibility"
                      onClick={(e) => changeVisibility(e, 'confirmPassword')}
                    >
                      {showPasswords.confirmPassword ? (
                        <Visibility className="bg-white" />
                      ) : (
                        <VisibilityOff className="bg-white" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item className="center-info" xlxs={12} lgxs={12} mdxs={12} smxs={12} xs={12}>
          <Box type="submit" flex="center" display="flex" width={4 / 10} height={1} className="button-login">
            <Button type="submit"  variant="contained" className="ff-poppins fw-bold" onClick={changePassword}>
              <LockOpen className="mr-2" /> Ingresar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const initializeForm = () => {
  return { oldPassword: '', newPassword: '', confirmPassword: '' };
};

const mapDispatchToProps = {
  changePassowrd,
};

export default connect(null, mapDispatchToProps)(ChangePassword);
