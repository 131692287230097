import * as React from 'react';
import { appConstants } from '../../appConstants';
function SvgCompany(props) {
  const { height, width } = props;
  return (
    <svg
      height={height ? height : appConstants.ICON_DEFAULT_HEIGHT}
      width={width ? width : appConstants.ICON_DEFAULT_WIDTH}
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={64} cy={64} fill="#f6c863" r={64} data-name="Circle Grid" />
      <path d="M37.683 27.953h52.444v75.781H37.683z" fill="#eeefee" />
      <path d="M42.375 23l-2.485 4.953h48.029L85.434 23z" fill="#dbd8dd" />
      <path d="M74.389 33.04h11.31v10.964h-11.31z" fill="#69b0ee" />
      <g fill="#eeefee" opacity={0.5}>
        <path d="M83.057 33.04l-8.668 8.685v-1.174L81.9 33.04zM85.698 33.04v.863L75.597 44.004h-1.208v-1.019l9.945-9.945z" />
      </g>
      <path d="M58.251 33.04h11.31v10.964h-11.31z" fill="#69b0ee" />
      <g fill="#eeefee" opacity={0.5}>
        <path d="M66.919 33.04l-8.668 8.685v-1.174l7.511-7.511zM69.561 33.04v.863L59.46 44.004h-1.209v-1.019l9.946-9.945z" />
      </g>
      <path d="M42.114 33.04h11.31v10.964h-11.31z" fill="#69b0ee" />
      <g fill="#eeefee" opacity={0.5}>
        <path d="M50.782 33.04l-8.668 8.685v-1.174l7.511-7.511zM53.423 33.04v.863l-10.1 10.101h-1.209v-1.019l9.945-9.945z" />
      </g>
      <path d="M74.389 48.94h11.31v10.964h-11.31z" fill="#69b0ee" />
      <g fill="#eeefee" opacity={0.5}>
        <path d="M83.057 48.94l-8.668 8.685v-1.174L81.9 48.94zM85.698 48.94v.863L75.597 59.904h-1.208v-1.018l9.945-9.946z" />
      </g>
      <path d="M58.251 48.94h11.31v10.964h-11.31z" fill="#69b0ee" />
      <g fill="#eeefee" opacity={0.5}>
        <path d="M66.919 48.94l-8.668 8.685v-1.174l7.511-7.511zM69.561 48.94v.863L59.46 59.904h-1.209v-1.018l9.946-9.946z" />
      </g>
      <path d="M42.114 48.94h11.31v10.964h-11.31z" fill="#69b0ee" />
      <g fill="#eeefee" opacity={0.5}>
        <path d="M50.782 48.94l-8.668 8.685v-1.174l7.511-7.511zM53.423 48.94v.863l-10.1 10.101h-1.209v-1.018l9.945-9.946z" />
      </g>
      <path d="M74.389 65.844h11.31v10.964h-11.31z" fill="#69b0ee" />
      <g fill="#eeefee" opacity={0.5}>
        <path d="M83.057 65.844l-8.668 8.685v-1.174l7.511-7.511zM85.698 65.844v.863L75.597 76.808h-1.208V75.79l9.945-9.946z" />
      </g>
      <path d="M58.251 65.844h11.31v10.964h-11.31z" fill="#69b0ee" />
      <g fill="#eeefee" opacity={0.5}>
        <path d="M66.919 65.844l-8.668 8.685v-1.174l7.511-7.511zM69.561 65.844v.863L59.46 76.808h-1.209V75.79l9.946-9.946z" />
      </g>
      <path d="M42.114 65.844h11.31v10.964h-11.31z" fill="#69b0ee" />
      <g fill="#eeefee" opacity={0.5}>
        <path d="M50.782 65.844l-8.668 8.685v-1.174l7.511-7.511zM53.423 65.844v.863l-10.1 10.101h-1.209V75.79l9.945-9.946z" />
      </g>
      <path d="M49.834 84.404h28.141v18.498H49.834z" fill="#575b6d" />
      <path d="M33.729 100.804h60.543V105H33.729z" fill="#62667c" />
      <path d="M62.097 83.914h3.614v16.89h-3.614z" fill="#eeefee" />
    </svg>
  );
}

export default SvgCompany;
