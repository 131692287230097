import { appConstants } from '../../appConstants';
import ApiAxios from '../../service/Api';

export const iotActions = {
  SET_IOT: 'SET_IOT',
  listIots: 'list',
  setIots: 'setIots',
  FETCHING_IOT_REQUEST: 'FETCHING_IOT_REQUEST',
  FETCHING_IOT_REQUEST_LIST: 'FETCHING_IOT_REQUEST_LIST',
  FETCHING_IOT_SAVE_SUCCESS: 'FETCHING_IOT_SAVE_SUCCESS',
  FETCHING_IOT_UPDATE_SUCCESS: 'FETCHING_IOT_UPDATE_SUCCESS',
  FETCHING_IOT_LIST_SUCCESS: 'FETCHING_IOT_LIST_SUCCESS',
  FETCHING_IOT_FIND_BY_ID_SUCCESS: 'FETCHING_IOT_FIND_BY_ID_SUCCESS',
  FETCHING_IOT_FAILURE: 'FETCHING_IOT_FAILURE',
  CLEAN_IOT_STORE: 'CLEAN_IOT',
  FETCHING_IOT_REQUEST_DELETE: 'FETCHING_IOT_REQUEST_DELETE',
  FETCHING_IOT_DELETE_SUCCESS: 'FETCHING_IOT_DELETE_SUCCESS',
  SEARCH_SET_BY_ID_IOT: 'SEARCH_SET_BY_ID_IOT',
};

export const fetchRequest = (type, payload = {}) => {
  return {
    type,
    payload,
  };
};

export const setIot = (payload) => ({
  type: iotActions.SET_IOT,
  payload,
});

export const setIots = (payload) => ({
  type: iotActions.setIots,
  payload,
});

export const fetchIotFailure = (error) => {
  return {
    type: iotActions.FETCHING_IOT_FAILURE,
    payload: error,
  };
};

export const cleanIot = () => {
  return {
    type: iotActions.CLEAN_IOT_STORE,
  };
};

export const saveIot = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_IOT, item, dispatch, fetchRequest, {
      request: iotActions.FETCHING_IOT_REQUEST,
      success: iotActions.FETCHING_IOT_SAVE_SUCCESS,
      error: iotActions.FETCHING_IOT_FAILURE,
    });
  };
};

export const listIots = (dto) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_IOTS, dto, dispatch, fetchRequest, {
      request: iotActions.FETCHING_IOT_REQUEST_LIST,
      success: iotActions.FETCHING_IOT_LIST_SUCCESS,
      error: iotActions.FETCHING_IOT_FAILURE,
    });
  };
};

export const updateIot = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.put(appConstants.CONTEXT_IOT, item, dispatch, fetchRequest, {
      request: iotActions.FETCHING_IOT_REQUEST,
      success: iotActions.FETCHING_IOT_UPDATE_SUCCESS,
      error: iotActions.FETCHING_IOT_FAILURE,
    });
  };
};
export const deleteIot = (id) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.delete(`${appConstants.CONTEXT_IOT}${id}`, dispatch, fetchRequest, {
      request: iotActions.FETCHING_IOT_REQUEST,
      success: iotActions.FETCHING_IOT_DELETE_SUCCESS,
      error: iotActions.FETCHING_IOT_FAILURE,
    });
  };
};

export const findById = (id) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.get(`${appConstants.CONTEXT_IOT}${id}`, dispatch, fetchRequest, {
      request: iotActions.FETCHING_IOT_REQUEST,
      success: iotActions.FETCHING_IOT_FIND_BY_ID_SUCCESS,
      error: iotActions.FETCHING_IOT_FAILURE,
    });
  };
};
