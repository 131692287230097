import { Box, Button, Grid, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import PostAddIcon from '@material-ui/icons/PostAdd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { CancelIcon } from '../../assets/icons';
import LicenseForm from './LicenseForm';
import {fetchRequest,licenseActions} from '../actions/licenseActions';


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '90vh',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const LicenseModal = (props) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
      setOpen(props.isOpenModal)
  },[props.isOpenModal])

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.fetchRequest(licenseActions.SET_OPEN_MODAL,{items:false})
  };

  return (
    <div>
      <Button color="primary" className="btn-transparent ff-poppins  min-width-1px" onClick={handleOpen}>
        <PostAddIcon />
      </Button>

      <Modal
        open={open}
        onClose={handleOpen}
        aria-labelledby="license-modal-title"
        aria-describedby="license-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Box style={modalStyle} className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xl={12} lg={12} md={12} xs={12} sm={12} container className="center-info bg-secundary ">
                <Grid item xl={11} lg={11} md={11} xs={11} sm={11} className="center-info bg-secundary ">
                  <Typography variant="h4" component="h3" className="ff-lpoppins fc-third">
                    ASIGNAR LICENCIA
                  </Typography>
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1} className="bg-secundary ">
                  <Button onClick={(e) => handleClose()}>
                    <CancelIcon className="mr-1" height="25px" width="25px" />
                  </Button>
                </Grid>
              </Grid>
              <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info">
                <LicenseForm company={props.company} />
              </Grid>
            </Grid>
          </Box>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isOpenModal: state.licenseReducer.isOpenModal,
  }
}
const mapDispatchToProps = { 
  fetchRequest,
 };

export default connect(mapStateToProps,mapDispatchToProps)(LicenseModal);
