import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import Brush from '@material-ui/icons/Brush';
import Save from '@material-ui/icons/Save';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { commonMessage, icon } from '../../service/messageHandler';
import { validateEmail } from '../../util/validations';
import { saveAlarm } from '../action/alarmActions';

const AlarmForm = (props) => {
  const { field } = props;
  const [alarm, setAlarm] = useState({
    name: '',
    mailsToNotify: '',
    cellphoneToNotify: '',
    min: 0,
    max: 0,
    messageMax: '',
    messageMin: '',
    waitTime: 0,
  });
  const [type, setType] = React.useState('');

  useEffect(() => {
    deserialize();
  }, [field]);
  const deserialize = () => {
    if (field?.alarms) {
      let valueForm = {};
      let tmpAlarm = field?.alarms;
      valueForm.name = tmpAlarm.name;
      valueForm.mailsToNotify = tmpAlarm.mailsToNotify;
      valueForm.cellphoneToNotify = tmpAlarm.cellphoneToNotify;
      valueForm.min = tmpAlarm.min;
      valueForm.max = tmpAlarm.max;
      valueForm.messageMax = tmpAlarm.messageMax;
      valueForm.messageMin = tmpAlarm.messageMin;
      valueForm.waitTime = tmpAlarm.waitTime;

      valueForm.id = field.id ? field.id : '';

      setAlarm({
        ...valueForm,
      });
    }
  };
  const handleSave = () => {
    let result = serializeObject();
    let alarms = field?.alarms ? field.alarms : [];
    let msn = validate();
    if (msn == '') {
      props.saveAlarm({
        ...field,
        alarms: [...alarms, result],
      });
      handleClean();
    } else {
      commonMessage(msn, appConstants.VALIDATION_ERROR_TITLE, icon.error);
    }
  };

  const validate = () => {
    let msn = '';

    msn += !props.headquarter?.id ? 'Debe seleccionar la sede \n' : '';
    msn += !props.iot?.id ? 'Debe seleccionar el IOT \n' : '';
    msn += !props.device?.id ? 'Debe seleccionar el dispositivo \n' : '';
    msn += !props.field?.id ? 'Debe seleccionar la variable \n' : '';
    msn += !alarm.name ? 'Debe ingresar el nombre \n' : '';
    msn += !alarm.messageMax ? 'Debe ingresar el mensaje del maximo \n' : '';
    msn += !alarm.messageMin ? 'Debe ingresar el mensaje del minimo \n' : '';
    msn += !alarm.min ? 'Debe ingresar el valor minimo \n' : '';
    msn += !alarm.max ? 'Debe ingresar el valor maximo \n' : '';

    msn += validateEmail(alarm.mailsToNotify);

    if (alarm.waiteTime < 0) {
      msn += 'La cantidad del tiempo de espera debe ser positiva';
    }

    return msn;
  };

  const serializeObject = () => {
    let result = {
      ...alarm,
      mailsToNotify: [alarm.mailsToNotify],
      cellphoneToNotify: [alarm.cellphoneToNotify],
    };

    return result;
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
    alarm.typeAlarm = event.target.value;
  };

  const handleClean = () => {
    setAlarm({
      ...alarm,

      name: '',
      mailsToNotify: '',
      cellphoneToNotify: '',
      min: 0,
      max: 0,
      messageMax: '',
      messageMin: '',
      waitTime: 0,
    });
    setType('');
  };

  return (
    <Box width={1} height={1}>
      <Card className="border-10">
        <CardContent>
          <form noValidate={false} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    fullWidth
                    id="nameAlarm"
                    label="Nombre"
                    variant="standard"
                    value={alarm.name}
                    onChange={(valueEvent) => setAlarm({ ...alarm, name: valueEvent.target.value })}
                  />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                  <TextField
                    required
                    id="min"
                    label="Mínimo"
                    variant="outlined"
                    type="number"
                    value={alarm.min}
                    onChange={(valueEvent) => setAlarm({ ...alarm, min: valueEvent.target.value })}
                  />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                  <TextField
                    required
                    variant="filled"
                    id="unitmin"
                    value={props.field?.masterField?.unitMeasurement}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    fullWidth
                    required
                    id="messageMin"
                    label="Mensaje valor Mínimo"
                    variant="outlined"
                    value={alarm.messageMin}
                    inputProps={{
                      maxLength: 200,
                    }}
                    onChange={(valueEvent) => setAlarm({ ...alarm, messageMin: valueEvent.target.value })}
                  />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    id="max"
                    label="Máximo"
                    variant="outlined"
                    type="number"
                    value={alarm.max}
                    onChange={(valueEvent) => setAlarm({ ...alarm, max: valueEvent.target.value })}
                  />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    fullWidth
                    variant="filled"
                    id="unitmax"
                    value={props.field?.masterField?.unitMeasurement}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    fullWidth
                    id="messageMax"
                    label="Mensaje Máximo"
                    variant="outlined"
                    required
                    value={alarm.messageMax}
                    inputProps={{
                      maxLength: 200,
                    }}
                    onChange={(valueEvent) => setAlarm({ ...alarm, messageMax: valueEvent.target.value })}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="type">Tipo Alarma</InputLabel>
                    <Select
                      required
                      labelId="type"
                      id="typeAlarm"
                      variant="outlined"
                      fullWidth={true}
                      value={type}
                      onChange={handleTypeChange}
                    >
                      <MenuItem value="" key="none">
                        <em>Ninguna</em>
                      </MenuItem>
                      <MenuItem value="inmediata" key="inmediata">
                        Inmediata
                      </MenuItem>
                      <MenuItem value="tiempo" key="tiempo">
                        Tiempo
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    fullWidth
                    required
                    id="waiteTime"
                    label="Tiempo de Espera"
                    variant="outlined"
                    type="number"
                    value={alarm.waitTime}
                    onChange={(valueEvent) => setAlarm({ ...alarm, waitTime: valueEvent.target.value })}
                  />
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    fullWidth
                    id="cellphone"
                    label="Celular a notificar"
                    variant="outlined"
                    inputProps={{
                      maxLength: 50,
                    }}
                    value={alarm.cellphoneToNotify}
                    onChange={(valueEvent) => setAlarm({ ...alarm, cellphoneToNotify: valueEvent.target.value })}
                  />
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    fullWidth
                    id="emailNotify"
                    label="Correo a notificar"
                    variant="outlined"
                    inputProps={{
                      maxLength: 50,
                    }}
                    value={alarm.mailsToNotify}
                    onChange={(valueEvent) => setAlarm({ ...alarm, mailsToNotify: valueEvent.target.value })}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <CardActions className="center d-flex">
          <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
            <Button
              className="bg-green-gradient mr-2 pl-5 pr-5"
              color="primary"
              style={{ background: '#e3dafb', borderRadius: '30px', width: '100%', marginRight: '5px' }}
              onClick={handleSave}
            >
              <Save />
              Guardar
            </Button>

            <Button
              className="bg-green-gradient mr-2 pl-5 pr-5"
              style={{ background: '#5404b4', color: 'white', borderRadius: '30px', width: '100%' }}
              disabled={alarm == {}}
              onClick={handleClean}
            >
              <Brush />
              Limpiar
            </Button>
          </ButtonGroup>
        </CardActions>
      </Card>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    iot: state.iotReducer.selectedIot ? state.iotReducer.selectedIot : undefined,
    headquarter: state.headquarterReducer.selectedHeadquarter
      ? state.headquarterReducer.selectedHeadquarter
      : undefined,
    device: state.deviceReducer.selectedDevice ? state.deviceReducer.selectedDevice : {},
    field: state.fieldReducer.selectedField,
    isRequiredFields: state.fieldReducer.isRequiredFields,
  };
};

const mapDispatchToProps = {
  saveAlarm,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlarmForm);
