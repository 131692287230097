import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgDashboard(props) {
  const { height, width, color } = props;
  return (
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" {...props}
              height={height ? height : appConstants.ICON_DEFAULT_HEIGHT}
      width={width ? width : appConstants.ICON_DEFAULT_WIDTH}
    >
      <path d="M2 9v38h40c0-2.76 2.24-5 5-5s5 2.24 5 5h4V9z" fill="#e6e9ed" />
      <path
        d="M6 35h4v8H6zM14 37h4v6h-4zM22 29h4v14h-4zM34 33v6.52c-.62 1.08-1.12 2.25-1.46 3.48H30V33z"
        fill="#fcd770"
      />
      <path
        d="M47 38a9 9 0 00-9 9c0 1.64.44 3.18 1.21 4.5C40.76 54.19 43.67 56 47 56s6.24-1.81 7.79-4.5A8.908 8.908 0 0056 47a9 9 0 00-9-9zm0 14c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"
        fill="#ccd1d9"
      />
      <path
        d="M54.79 51.5l5.2 3C57.39 58.98 52.55 62 47 62s-10.39-3.02-12.99-7.5l5.2-3C40.76 54.19 43.67 56 47 56s6.24-1.81 7.79-4.5z"
        fill="#fcd770"
      />
      <path
        d="M62 47c0 2.73-.73 5.3-2.01 7.5l-5.2-3A8.908 8.908 0 0056 47a9 9 0 00-9-9v-6c3.38 0 6.5 1.12 9 3 3.64 2.74 6 7.1 6 12z"
        fill="#ff826e"
      />
      <path
        d="M47 32v6a9 9 0 00-9 9c0 1.64.44 3.18 1.21 4.5l-5.2 3C32.73 52.3 32 49.73 32 47c0-1.38.19-2.73.54-4 .34-1.23.84-2.4 1.46-3.48 2.59-4.5 7.45-7.52 13-7.52z"
        fill="#b4dd7f"
      />
      <path
        d="M29.41 22.59c.37.36.59.86.59 1.41 0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2c.55 0 1.05.22 1.41.59zM38.41 16.41c-.36.37-.86.59-1.41.59s-1.05-.22-1.41-.59c-.37-.36-.59-.86-.59-1.41 0-1.1.9-2 2-2s2 .9 2 2c0 .55-.22 1.05-.59 1.41zM44.41 19.59c.37.36.59.86.59 1.41 0 1.1-.9 2-2 2s-2-.9-2-2c0-.55.22-1.05.59-1.41.36-.37.86-.59 1.41-.59s1.05.22 1.41.59zM56 2c3.31 0 6 2.69 6 6s-2.69 6-6 6c-1.66 0-3.16-.67-4.24-1.76A5.961 5.961 0 0150.09 9 5.47 5.47 0 0150 8c0-3.31 2.69-6 6-6z"
        fill="#ff826e"
      />
      <g>
        <path d="M57 34.53V14.92c3.387-.488 6-3.401 6-6.92 0-3.859-3.14-7-7-7s-7 3.141-7 7H1v40h30.051c.52 8.356 7.465 15 15.949 15 8.822 0 16-7.178 16-16 0-5.039-2.347-9.535-6-12.47zM56 3c2.757 0 5 2.243 5 5s-2.243 5-5 5-5-2.243-5-5 2.243-5 5-5zM3 10h46.295a6.995 6.995 0 001.107 2.185l-6.116 6.116C43.894 18.113 43.462 18 43 18s-.894.113-1.285.301L39.7 16.286c.187-.392.3-.824.3-1.286 0-1.654-1.346-3-3-3s-3 1.346-3 3c0 .462.113.894.301 1.285L29.286 21.3A2.972 2.972 0 0028 21c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3a2.95 2.95 0 00-.301-1.285l5.015-5.015c.392.187.824.3 1.286.3s.894-.113 1.285-.301l2.015 2.015c-.187.392-.3.824-.3 1.286 0 1.654 1.346 3 3 3s3-1.346 3-3a2.95 2.95 0 00-.301-1.285l6.117-6.116A6.957 6.957 0 0055 14.921v18.245A15.874 15.874 0 0047 31c-4.78 0-9.066 2.118-12 5.453V32h-6v12h2.292a16.042 16.042 0 00-.241 2H3zm40 10a1.001 1.001 0 11-1 1c0-.552.449-1 1-1zm-7-5a1.001 1.001 0 111 1c-.551 0-1-.448-1-1zm-7 9a1.001 1.001 0 01-2 0 1.001 1.001 0 012 0zm18 31c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zM33 39.271A15.88 15.88 0 0031.811 42H31v-8h2zm13-6.22v4c-5.046.504-9 4.773-9 9.949 0 1.463.324 2.85.891 4.104l-3.469 2.003A13.881 13.881 0 0133 47c0-7.382 5.747-13.433 13-13.949zM47 61c-4.814 0-9.068-2.443-11.588-6.155l3.483-2.011C40.713 55.352 43.664 57 47 57s6.287-1.648 8.105-4.166l3.483 2.011C56.068 58.557 51.814 61 47 61zm12.578-7.893l-3.469-2.003A9.934 9.934 0 0057 47c0-5.176-3.954-9.446-9-9.949v-4c7.253.516 13 6.567 13 13.949 0 2.191-.52 4.259-1.422 6.107z" />
        <path d="M47 41c-3.309 0-6 2.691-6 6s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6zm0 10c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zM5 12h2v2H5zM9 12h14v2H9zM5 16h2v2H5zM9 16h14v2H9zM5 20h2v2H5zM9 20h14v2H9zM5 24h2v2H5zM9 24h14v2H9zM5 44h6V34H5zm2-8h2v6H7zM13 44h6v-8h-6zm2-6h2v4h-2zM21 44h6V28h-6zm2-14h2v12h-2z" />
      </g>
    </svg>
  );
}

export default SvgDashboard;
