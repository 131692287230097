import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgManualIcon(props) {
  const { height, width } = props;
  return (
    <svg
      height={height ? height : appConstants.ICON_DEFAULT_HEIGHT}
      width={width ? width : appConstants.ICON_DEFAULT_WIDTH}
      viewBox="0 0 464 464"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M432 408.004H32v-288H0v320h194.719a16.375 16.375 0 0115.203 9.36 23.999 23.999 0 0044.156 0 16.375 16.375 0 0115.203-9.36H464v-320h-32zm0 0"
        fill="#254b6f"
      />
      <path d="M32 88.004h400v320H32zm0 0" fill="#efeadd" />
      <path d="M64 168.004h128v96H64zm0 0" fill="#326fc0" />
      <path
        d="M344 112.004c-.02 48.469-31.203 91.433-77.281 106.48L232 280.562l-34.719-62.078c-54.488-17.77-86.773-73.797-74.816-129.847C134.418 32.59 186.754-5.395 243.746.617 300.742 6.63 344 54.691 344 112.004zm0 0"
        fill="#f2b134"
      />
      <path d="M264 304.004h136v64H264zm0 0" fill="#e74c3c" />
      <path
        d="M216 104.004h32v80h-32zm0 0M248 64.004c0 8.836-7.164 16-16 16s-16-7.164-16-16c0-8.84 7.164-16 16-16s16 7.16 16 16zm0 0"
        fill="#ee2820"
      />
      <path d="M32 208.004v200h184c-101.703 0-184-89.457-184-200zm0 0" fill="#d7d3c7" />
      <g fill="#f26158">
        <path d="M56 288.004h16v16H56zm0 0M88 288.004h112v16H88zm0 0M56 320.004h16v16H56zm0 0M88 320.004h112v16H88zm0 0M56 352.004h16v16H56zm0 0M88 352.004h112v16H88zm0 0M296 264.004h104v16H296zm0 0M296 232.004h104v16H296zm0 0M336 200.004h64v16h-64zm0 0M352 168.004h48v16h-48zm0 0" />
      </g>
    </svg>
  );
}

export default SvgManualIcon;
