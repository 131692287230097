import { appConstants } from '../../appConstants';
import ApiAxios from '../../service/Api';

export const alarmActions = {
  FETCHING_ALARM_REQUEST: 'FETCHING_ALARM_REQUEST',
  FETCHING_ALARM_LIST: 'FETCHING_ALARM_LIST',
  FETCHING_ALARM_SAVE: 'FETCHING_ALARM_SAVE',
  FETCHING_ALARM_SAVE_SUCCESS: 'FETCHINGFETCHING_ALARM_SAVE_ALARM_SAVE_SUCCESS',
  FETCHING_ALARM_UPDATE_SUCCESS: 'FETCHING_ALARM_UPDATE_SUCCESS',
  FETCHING_ALARM_LIST_SUCCESS: 'FETCHING_ALARM_LIST_SUCCESS',
  FETCHING_ALARM_FAILURE: 'FETCHING_ALARM_FAILURE',
  CLEAN_ALARM_STORE: 'CLEAN_ALARM',
  ALARM_MODAL_CREATE: 'ALARM_MODAL_CREATE',
  FETCHING_ALARM_DELETE_SUCCESS: 'FETCHING_ALARM_DELETE_SUCCESS',
  FETCHING_ALARM_UPDATE_SUCCESS: 'FETCHING_ALARM_UPDATE_SUCCESS',
  SET_ALARM: 'SET_ALARM',
};

export const fetchRequest = (type, payload = undefined) => {
  return {
    type,
    payload,
  };
};

export const listAlarms = (filter) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_FIELDS, filter, dispatch, fetchRequest, {
      request: alarmActions.FETCHING_ALARM_LIST,
      success: alarmActions.FETCHING_ALARM_LIST_SUCCESS,
      error: alarmActions.FETCHING_ALARM_FAILURE,
    });
  };
};

export const saveAlarm = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.put(appConstants.CONTEXT_FIELD, item, dispatch, fetchRequest, {
      request: alarmActions.FETCHING_ALARM_SAVE,
      success: alarmActions.FETCHING_ALARM_SAVE_SUCCESS,
      error: alarmActions.FETCHING_ALARM_FAILURE,
    });
  };
};

export const deleteAlarm = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.put(appConstants.CONTEXT_FIELD, item, dispatch, fetchRequest, {
      request: alarmActions.FETCHING_ALARM_REQUEST,
      success: alarmActions.FETCHING_ALARM_DELETE_SUCCESS,
      error: alarmActions.FETCHING_ALARM_FAILURE,
    });
  };
};

export const updateAlarm = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.put(appConstants.CONTEXT_FIELD, item, dispatch, fetchRequest, {
      request: alarmActions.FETCHING_ALARM_REQUEST,
      success: alarmActions.FETCHING_ALARM_UPDATE_SUCCESS,
      error: alarmActions.FETCHING_ALARM_FAILURE,
    });
  };
};

export const cleanAlarm = (id) => {
  return {
    type: alarmActions.CLEAN_ALARM_STORE,
  };
};

export const setAlarm = (id) => {
  return {
    type: alarmActions.SET_ALARM,
  };
};
