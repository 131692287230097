import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import AutoCompleteFilters from '../../components/filters/AutoCompleteFilters';

const FilterTrend = (props) => {
  return (
    <>
      <Box height={1} width={1} padding={2}>
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
            <AutoCompleteFilters headquarter={true} device={true} iot={true} xl={12} lg={12} md={12} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default connect(null, null)(FilterTrend);
