import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import 'c3/c3.css';
import { format } from 'd3-format';
import React from 'react';
import C3Chart from 'react-c3js';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { dashboardAction, downloadTrend, fetchRequest, listHistory } from '../../dashboard/action/dashboardActions';
import axiosInstance from '../../service/axiosInstance';
import { showToast } from '../../service/messageToast';
import { convertDate, getGroupByTrends } from '../../service/util';
import '../styles/chargeability.scss';
import ModalInfoChar from './ModalInfoChar';

class TrendCard extends React.Component {
  constructor(props) {
    super(props);

    this.info = {
      updateIn: 5,
      frecuency: '15',
      average: 0,
      current: undefined,
    };
    this.state = {
      graphic: {
        data: {
          x: 'x',
          columns: [['x'], ['Valor'], ['Porcent']],
          axes: {
            x: 'x',
            Valor: 'y',
            Porcent: 'y2',
          },
          colors: {
            Valor: '#1d2877',
            Porcent: '#388546',
          },
        },
        legend: {
          show: false,
        },
        padding: {
          bottom: 75,
        },
        axis: {
          x: {
            label: {
              text: 'FECHA',
              position: 'inner-right',
            },

            type: 'timeseries',
            tick: {
              rotate: -50,
              format: '%m-%d %H:%M',
            },
          },
          y: {
            label: {
              text: 'KVA',
              position: 'outer-right',
            },
            type: 'cantidad',
            position: 'outer-middle',
            tick: {
              format: format('.2f'),
            },
          },
          y2: {
            show: true,
            label: {
              text: '%',
            },
            type: 'cantidad',
            position: 'outer-middle',
            tick: {
              format: format('.2f'),
            },
          },
        },
        grid: {
          y: {
            color: '#ff0000',
            lines: [
              { value: this.props.field?.device?.ratedPower, text: 'Máximo', position: 'start', class: 'grid800' },
            ],
          },
        },
        tooltip: {
          format: {
            title: function (x) {
              return `${convertDate(x)}`;
            },
          },
        },
      },
      valuesHistory: this.props.valuesPerField && this.props.valuesPerField[this.props.field?.name],
    };

    this.ref = {};

    this.timeOutRefres = undefined;
    this.reload = true;
    this.props.fetchRequest(dashboardAction.CLEAN_FRECUENCY_RELOAD);
    this.props.fetchRequest(dashboardAction.CLEAN_GLOBAL_FRECUENCY);
    this.listValueHistoryInitial(this.getFilterByField());
  }
  maxValue = {
    kva: 0,
    por: 0,
  };

  mediaValue = {
    kva: 0,
    por: 0,
  };

  reseraValue = {
    kva: 0,
    por: 0,
  };

  facCar = {
    kva: 0,
    por: 0,
  };
  componentDidUpdate(prevProps) {
    let frecuency = this.props.globalFrecuency ? this.props.globalFrecuency : '15';

    if (this.props.isRequieredSyncTrend == true && prevProps.isRequieredSyncTrend == false) {
      this.setFrecuency(frecuency);
      this.info.frecuency = frecuency;
    }
    if (this.props.isFrecuencyReload == true && prevProps.isFrecuencyReload == false) {
      this.setFrecuency(frecuency);
      this.info.frecuency = frecuency;
    }
    if (this.props.isRequieredChangeGlobalFrecuency == true && prevProps.isRequieredChangeGlobalFrecuency == false) {
      this.setFrecuency(frecuency);
      this.info.frecuency = frecuency;
    }
  }

  listValueHistory = (item) => {
    axiosInstance()
      .post(`${appConstants.CONTEXT_TREND}list`, item)
      .then((data) => {
        if (this.props.listFields[this.props.listFields.length - 1].id == this.props.field.id) {
          this.props.fetchRequest(dashboardAction.CLEAN_FRECUENCY_RELOAD);
          this.props.fetchRequest(dashboardAction.CLEAN_GLOBAL_FRECUENCY);
        }
        if (data.data[this.props.field?.name].content.length > 0) {
          this.putInfoInGraphic(data.data);
        } else {
          showToast(appConstants.DONT_INFO_TITLE);
        }
      })
      .catch((error) => {
        console.warn(error.message);
      });
  };

  listValueHistoryInitial = (item) => {
    axiosInstance()
      .post(`${appConstants.CONTEXT_TREND}list`, item)
      .then((data) => {
        if (data.data[this.props.field?.name].content.length > 0) {
          this.putInfoInGraphic(data.data);
        } else {
          showToast(appConstants.DONT_INFO_TITLE);
        }
      })
      .catch((error) => {
        console.warn(error.message);
      });
  };

  putInfoInGraphic(value) {
    this.reload = false;
    let values = value[this.props.field?.name]?.content;

    const { data: dataColumns } = this.state.graphic;
    dataColumns.columns[0].splice(1);
    dataColumns.columns[1].splice(1);
    dataColumns.columns[2].splice(1);

    let ratedPower = parseInt(this.props.field?.device?.ratedPower);

    if (values && values.length > 0) {
      this.info.current = values[values.length - 1];

      values.forEach((element) => {
        dataColumns.columns[0].push(new Date(element.createdAt));
        dataColumns.columns[1].push(Number(element.value).toFixed(5));
        dataColumns.columns[2].push(this.mediaChargeabilityPor(element.value, ratedPower));
      });
    }

    this.maxChargeability(values, ratedPower);
    this.mediaChargeability(values, ratedPower);
    this.reservaChargeability(values, ratedPower);
    this.FacCarChargeability(values);

    this.reload = false;
    let translate = {
      axis: { ...this.state.graphic.axis },
      data: dataColumns,

      regions: this.state.graphic.regions,
    };

    this.setState({ graphic: translate, valuesHistory: values });
  }

  handleFrecuency = (event) => {
    this.info.frecuency = event.target.value;
    this.listValueHistory(this.getFilterByField());
  };

  setFrecuency = (event) => {
    this.info.frecuency = event;
    this.listValueHistory(this.getFilterByField());
  };

  getFilter = () => {
    let from = new Date(Date.now() - this.info.frecuency * 60000);
    let fromFormatted = convertDate(from);
    let currentDate = convertDate(new Date(Date.now()));
    return {
      page: 0,
      size: 300,
      from: fromFormatted,
      to: currentDate,
      filters: { fieldId: this.props.field.id, deviceId: this.props.field.device.id },
    };
  };

  getFilterByField = () => {
    let from = new Date(Date.now() - this.info.frecuency * 60000);
    let fromFormatted = convertDate(from);
    let currentDate = convertDate(new Date(Date.now()));
    let gb = getGroupByTrends(this.info.frecuency);
    return {
      page: 0,
      size: 3000,
      from: fromFormatted,
      to: currentDate,
      groupBy: gb,
      filters: [this.props.field],
    };
  };

  handleDownload = (event) => {
    this.props.downloadTrend(this.getFilter());
  };

  converFormat(x) {
    return Number.parseFloat(x).toFixed(4);
  }
  converFormatPor(x) {
    return Number.parseFloat(x).toFixed(2);
  }

  mediaChargeabilityPor = (data, ratedPower) => {
    let aux = Number((data / ratedPower) * 100).toFixed(2);
    return aux.toString();
  };

  maxChargeability(data, ratedPower) {
    let aux = 0;
    data.forEach((element) => {
      if (element.value > aux) {
        aux = element.value;
      }
    });

    this.maxValue.kva = this.converFormat(aux);
    this.maxValue.por = this.converFormatPor((aux / ratedPower) * 100);
  }

  mediaChargeability(data, ratedPower) {
    let aux = 0;
    data.forEach((element) => {
      aux = aux + parseInt(element.value);
    });
    this.mediaValue.kva = this.converFormat(aux / data.length);
    let div = aux / data.length;
    this.mediaValue.por = this.converFormatPor((div / ratedPower) * 100);
  }

  reservaChargeability(data, ratedPower) {
    let aux = 0;

    data.forEach((element) => {
      aux = aux + parseInt(element.value);
    });
    let div = aux / data.length;

    let porMe = div / ratedPower;

    this.reseraValue.kva = this.converFormat(ratedPower - div);
    this.reseraValue.por = this.converFormatPor((1 - porMe) * 100);
  }

  FacCarChargeability(data) {
    let aux = 0;
    let aux2 = 0;
    data.forEach((element) => {
      if (element.value > aux) {
        aux2 = element.value;
      }
      aux = aux + parseInt(element.value);
    });

    let div = aux / data.length;
    this.facCar.por = this.converFormatPor(div / aux2);
  }

  getMedida = () => {
    if (this.props.field.unitMeasurement === '') {
      return '';
    }
    if (this.props.field?.masterField?.unitMeasurement === 'NA') {
      return '';
    }
    if (this.props.field?.masterField?.unitMeasurement === 'PORCENTAJE') {
      return '%';
    }
    if (this.props.field?.masterField?.unitMeasurement === 'C') {
      return '°C';
    }

    return this.props.field?.masterField?.unitMeasurement;
  };

  order() {
    let keys = Object.keys(this.props.intervals);
    let intervals = [];

    for (let i = 0; i < keys.length; i++) {
      intervals.push({ name: keys[i], value: this.props.intervals[keys[i]] });
    }

    intervals.sort(function (a, b) {
      if (a.value > b.value) {
        return 1;
      } else if (a.value < b.value) {
        return -1;
      }

      return 0;
    });

    let valores = intervals.map((element) => {
      return element.name;
    });

    return valores;
  }

  render() {
    return (
      <Box height={1} width={1}>
        <Grid container>
          <Box width={1} margin={2}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Box
                  alignItems="center"
                  className="font-bold fs-title-graphic t-center"
                  display="flex"
                  height={1}
                  width={1}
                >
                  <span className="w-100">
                    Último valor{' '}
                    {this.info.current ? (
                      <Typography className="font-bold t-center">
                        {' '}
                        {(this.info.current.value * 1).toFixed(3) + ' ' + this.getMedida()}
                      </Typography>
                    ) : (
                      'cargando...'
                    )}
                  </span>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                <FormControl variant="filled" fullWidth style={{ border: '20px' }}>
                  <InputLabel id="typeSignal">Rango de tiempo</InputLabel>
                  <Select
                    labelId="frecuency"
                    id="frecuency"
                    variant="filled"
                    fullWidth={true}
                    value={this.info.frecuency}
                    onChange={(e) => this.handleFrecuency(e)}
                  >
                    <MenuItem value="" key="none">
                      <em>Ninguna</em>
                    </MenuItem>

                    {this.props.intervals &&
                      this.order().map((element) => {
                        return (
                          <MenuItem value={this.props.intervals[element]} key={this.props.intervals[element]}>
                            {element}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="center-info mt-10px grid-border-blue">
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="center-info ma-5px">
                    <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                      <Typography className="ff-poppins t-center fw-bold"> Tensión de transformador</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="center-info ma-5px">
                    <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                      <Typography className="ff-poppins t-center fw-bold">
                        {`${this.props.field?.device?.transformerVoltage} V.`}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                <Box className="font-bold fs-title-graphic t-center" justifyContent="center" display="flex">
                  {this.props.field.device.name}
                </Box>
              </Grid>
              <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                <Tooltip title="El muestreo es realizado cada dos minutos" aria-label="add">
                  <Box className="  fs-title-graphic t-center" justifyContent="center" display="flex">
                    {this.props.field.name}
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>

          <Box width={1} padding={1}>
            {
              <C3Chart
                data={this.state.graphic.data}
                axis={this.state.graphic.axis}
                regions={this.state.graphic.regions}
                tooltip={this.state.graphic.tooltip}
                color={this.state.graphic.color}
                legend={this.state.graphic.legend}
                padding={this.state.graphic.padding}
                grid={this.state.graphic.grid}
              />
            }
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Paper className="paper ">
            <Box boxShadow={3} height={1} borderRadius={10} padding={2}>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                xs={12}
                report
                sm={12}
                className="center-info bg-secundary border-10  "
              >
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1} className="bg-secundary ">
                  <ModalInfoChar />
                </Grid>
                <Grid item xl={11} lg={11} md={11} xs={11} sm={11} className="center-info bg-secundary border-10 ">
                  <Typography variant="h4" component="h3" className="ff-poppins fc-third ">
                    Cargabilidad
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} className="mt-16px center-info">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="center-info mt-10px grid-border">
                  <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="center-info ma-5px">
                    <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                      <Typography className="ff-poppins t-center"> Potencial (KVA)</Typography>
                    </Box>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="center-info ma-5px">
                    <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                      <Typography className="ff-poppins t-center">{this.props.field?.device?.ratedPower}</Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info mt-10px">
                  <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                    <Typography className="ff-poppins t-center"> Valores %</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3} className="mt-10px grid-border ">
                  <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info mt-10px">
                    <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                      <Typography className="ff-poppins t-center">Demanda Máxima </Typography>
                    </Box>
                  </Grid>
                  <Grid container>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
                      <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info mt-10px">
                        <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                          <Typography className="ff-poppins t-center">{this.maxValue.kva}</Typography>
                        </Box>
                      </Grid>

                      <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info mt-10px">
                        <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                          <Typography className="ff-poppins t-center">{this.maxValue.por}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
                      <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info mt-10px">
                        <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                          <Typography className="ff-poppins t-center">KVA</Typography>
                        </Box>
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info mt-10px">
                        <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                          <Typography className="ff-poppins t-center">%</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3} className="mt-10px grid-border ml-5px">
                  <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info mt-10px">
                    <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                      <Typography className="ff-poppins t-center">Demanda media sostenida</Typography>
                    </Box>
                  </Grid>
                  <Grid container>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
                      <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info mt-10px">
                        <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                          <Typography className="ff-poppins t-center">{this.mediaValue.kva}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info mt-10px">
                        <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                          <Typography className="ff-poppins t-center">{this.mediaValue.por}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
                      <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info mt-10px ">
                        <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                          <Typography className="ff-poppins t-center">KVA</Typography>
                        </Box>
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info mt-10px">
                        <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                          <Typography className="ff-poppins t-center">%</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2} xl={2} className="mt-10px grid-border ml-5px">
                  <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info mt-10px">
                    <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                      <Typography className="ff-poppins t-center">Factor de carga</Typography>
                    </Box>
                  </Grid>
                  <Grid container>
                    <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                      <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info mt-10px">
                        <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                          <Typography className="ff-poppins t-center">Valor</Typography>
                        </Box>
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info mt-10px">
                        <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                          <Typography className="ff-poppins t-center">{this.facCar.por}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3} className="mt-10px grid-border ml-5px">
                  <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info mt-10px">
                    <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                      <Typography className="ff-poppins t-center">Capacidad de reserva</Typography>
                    </Box>
                  </Grid>
                  <Grid container>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
                      <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info mt-10px">
                        <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                          <Typography className="ff-poppins t-center">{this.reseraValue.kva}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info mt-10px">
                        <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                          <Typography className="ff-poppins t-center">{this.reseraValue.por}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
                      <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info mt-10px">
                        <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                          <Typography className="ff-poppins t-center">KVA</Typography>
                        </Box>
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info mt-10px">
                        <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                          <Typography className="ff-poppins t-center">%</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listFields: state.fieldReducer.avaliabledFields ? state.fieldReducer.avaliabledFields : [],
    intervals: state.dashboardReducer.intervals ? state.dashboardReducer.intervals : [],
    values: state.dashboardReducer.values ? state.dashboardReducer.values : [],
    valuesPerField: state.dashboardReducer.valuesPerField ? state.dashboardReducer.valuesPerField : {},
    isRequiredAddButtons: state.componentsReducer.buttons
      ? appConstants.IS_NOT_REQUIRED_ADD_BUTTON
      : appConstants.IS_REQUIRED_ADD_BUTTON,
    isRequieredSyncTrend: state.dashboardReducer.isRequieredSyncTrend,
    isFrecuencyReload: state.dashboardReducer.isFrecuencyReload,
    isRequieredChangeGlobalFrecuency: state.dashboardReducer.isRequieredChangeGlobalFrecuency,
    globalFrecuency: state.dashboardReducer.globalFrecuency ? state.dashboardReducer.globalFrecuency : '',
  };
};

const mapDispatchToProps = {
  listHistory,
  downloadTrend,
  fetchRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrendCard);
