import { Box, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../../appConstants';
import {
  cleanInstallation,
  fetchRequest,
  installationActions,
  listInstallation,
} from '../../actions/installationActions';

class InstallationAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.ref = undefined;
    this.state = { selectedInstallation: props.selectedInstallation, installationName: props.selectedInstallation?.name};
  }

  render() {
    return (
      <Grid container spacing={1} className="grid-input-element">
        <Grid item xs={12}>
          <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element ">
            <Autocomplete
              ref={(ref) => (this.ref = ref)}
              id="installationAutocomplete"
              options={this.props.installations}
              getOptionLabel={(option) => option.name}
              onChange={(e, option) => {
                this.handleSelectInstallation(option);
              }}
              noOptionsText="Debe seleccionar una sede"
              inputValue={this.state.installationName ? this.state.installationName : ''}
              renderInput={(params) => (
                <TextField
                  required={this.props.required}
                  {...params}
                  label={this.props.label ? this.props.label : 'Selecciona una instalación'}
                  variant="outlined"
                  value={this.state.installationName}
                  onChange={(event) => {
                    this.setState({ ...this.state, installationName: event.target.value });
                  }}
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }

  handleSelectInstallation(option) {
    if (option) {
      this.props.fetchRequest(installationActions.SET_INSTALLATION, { item: option });
    } else {
      this.props.fetchRequest(installationActions.SET_INSTALLATION, { item: undefined });
    }
  }

  componentDidMount() { }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevProps.selectedInstallation?.id && this.props.selectedInstallation?.id) ||
      this.state.selectedInstallation?.id != this.props.selectedInstallation?.id
    ) {
      this.setState({ selectedInstallation: this.props.selectedInstallation, installationName:this.props.selectedInstallation?.name });
    }

    if (
      (!prevProps.selectedHeadquarter && this.props.selectedHeadquarter) ||
      (prevProps.selectedHeadquarter?.id &&
        this.props.selectedHeadquarter?.id &&
        prevProps.selectedHeadquarter.id != this.props.selectedHeadquarter.id)
    ) {
      this.props.listInstallation({
        page: 0,
        size: 300,
        filters: {
          headquarterId: this.props.selectedHeadquarter?.id,
        },
      });
    }
  }

  componentWillUnmount() {
    this.props.cleanInstallation();
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.companyReducer.currentCompany ? state.companyReducer.currentCompany : {},
    installations: state.installationReducer.avaliabledInstallations
      ? state.installationReducer.avaliabledInstallations
      : [],
    isRequiredInstallations:
      (state.headquarterReducer.selectedHeadquarter && !state.installationReducer.isRequiredInstallations) ||
        state.installationReducer.isRequiredInstallations == appConstants.IS_REQUIRED_INSTALLATION_LIST
        ? appConstants.IS_REQUIRED_INSTALLATION_LIST
        : appConstants.IS_NOT_REQUIRED_INSTALLATION_LIST,
    selectedHeadquarter: state.headquarterReducer.selectedHeadquarter
      ? state.headquarterReducer.selectedHeadquarter
      : undefined,
    selectedInstallation: state.installationReducer.selectedInstallation
      ? state.installationReducer.selectedInstallation
      : undefined,
  };
};

const mapDispatchToProps = {
  fetchRequest,
  listInstallation,
  cleanInstallation,
};

export default connect(mapStateToProps, mapDispatchToProps)(InstallationAutocomplete);
