import { appConstants } from '../../appConstants';
import ApiAxios from '../../service/Api';

export const supportActions = {
  FETCHING_SUPPORT_SEND_REQUEST: 'FETCHING_SUPPORT_REQUEST',
  FETCHING_SUPPORT_SEND_SUCCESS: 'FETCHING_SUPPORT_SAVE_SUCCESS',
  FETCHING_SUPPORT_FAILURE: 'FETCHING_SUPPORT_FAILURE',
};

export const fetchSupport = (type, payload = undefined) => {
  return {
    type,
    undefined,
  };
};

export const sendSupport = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.get(
      `${appConstants.CONTEXT_SUPPORT}mail=${item.mail}/${item.subject}/${item.message}`,
      dispatch,
      fetchSupport,
      {
        request: supportActions.FETCHING_SUPPORT_SEND_REQUEST,
        success: supportActions.FETCHING_SUPPORT_SEND_SUCCESS,
        error: supportActions.FETCHING_SUPPORT_FAILURE,
      },
    );
  };
};
