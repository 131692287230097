import { Box, Button, Card } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import Loading from '../../components/Loading';
import {
  cleanlicenseCycles,
  fetchRequest,
  licenseCycleActions,
  listlicenseCycles,
} from '../actions/licenseCycleActions';
const ListLicenseCycle = (props) => {
  const { list } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    {
      id: 'name',
      label: 'Nombre',
    },
    {
      id: 'durationDay',
      label: 'Duración en días',
    },
    {
      id: 'typeLicense',
      label: 'Tipo de licencia',
    },
  ];

  useEffect(() => {
    if (props.isRequiredLicenseCycles == appConstants.IS_REQUIRED_LICENSE_CYCLE_LIST) {
      listItems();
    }
  }, [props.selectedLicenseCycle]);

  const listItems = () => {
    let filterDto = {
      page: 0,
      size: 300,
    };
    props.listlicenseCycles(filterDto);
  };
  const handleEdit = (itemSelected) => {
    props.fetchRequest(licenseCycleActions.SET_LICENSE_CYCLE, { item: itemSelected });
  };

  return (
    <Box width={1}>
      <Card className="border-10">
        <TableContainer className="table-master">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    className="bg-primary fc-white ff-poppins fw-bold fs-Large t-center-important"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell align="center" className="bg-primary fc-white ff-poppins fw-bold fs-Large" key={'action'}>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} className="t-center-important">
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell key={'action'} className="center-info" align="center">
                      <Button
                        color="primary"
                        className="btn-transparent ff-poppins fc-green min-width-1px"
                        onClick={(e) => handleEdit(row)}
                      >
                        <EditIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Card>
      <Loading />
    </Box>
  );
};

const mapDispatchToProps = {
  cleanlicenseCycles,
  listlicenseCycles,
  fetchRequest,
};

const mapStateToProps = (state) => {
  return {
    list: state.licenseCycleReducer.avaliabledLicenseCycles ? state.licenseCycleReducer.avaliabledLicenseCycles : [],
    isRequiredLicenseCycles: state.licenseCycleReducer.isRequiredLicenseCycles
      ? state.licenseCycleReducer.isRequiredLicenseCycles
      : appConstants.IS_REQUIRED_LICENSE_CYCLE_LIST,
    selectedLicenseCycle: state.licenseCycleReducer.selectedDevice
      ? state.licenseCycleReducer.selectedLicenseCycle
      : {},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListLicenseCycle);
