import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';

const columns = [
  { id: 'initialCreatedAt', label: 'Fecha de obtención' },
  { id: 'initialValue', label: 'Valor' },
  { id: 'comparativeCreatedAt', label: 'Fecha de obtención' },
  { id: 'comparativeValue', label: 'Valor' },
];

const TableBar = (props) => {
  const [info, setInfo] = useState([]);

  useEffect(() => {
    //let values = props.valuesPerField[props.field?.name]?.content;
    buildTable();
  }, [props.valuesPerField]);

  const buildTable = () => {
    if (props.valuesPerField && props.valuesPerField[props.field?.name]) {
      const { series } = props.valuesPerField[props.field?.name];
      let initialRange = [];
      let comparativeRange = [];
      let newTable = [];

      series.forEach((element) => {
        if (element.name === appConstants.BAR_INITIAL_RANGE_COMPARATIVE) {
          initialRange = element.content.content;
        } else if (element.name === appConstants.BAR_SECOND_RANGE_COMPARATIVE) {
          comparativeRange = element.content.content;
        }
      });

      let newLength = initialRange.length > comparativeRange.length ? initialRange.length : comparativeRange.length;

      for (let i = 0; i < newLength; i++) {
        let newRow = {};
        if (i < initialRange.length - 1) {
          newRow.initialCreatedAt = initialRange[i].createdAt;
          newRow.initialValue = initialRange[i].value;
        } else {
          newRow.initialCreatedAt = '';
          newRow.initialValue = '';
        }

        if (i < comparativeRange.length - 1) {
          newRow.comparativeCreatedAt = comparativeRange[i].createdAt;
          newRow.comparativeValue = comparativeRange[i].value;
        } else {
          newRow.comparativeCreatedAt = '';
          newRow.comparativeValue = '';
        }
        newTable.push(newRow);
      }
      setInfo(newTable);
    }
  };

  return (
    <Box>
      {splitInfo(info).map((table, index) => {
        return (
          <Box paddingTop={index > 0 ? 25 : 2} id={index} key={index}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="bg-secundary fc-third ff-poppins fw-bold fs-Large "
                      key={'comparative1'}
                      align="center"
                      height="1.2cm"
                      colSpan={2}
                    >
                      {appConstants.BAR_INITIAL_RANGE_COMPARATIVE}
                    </TableCell>
                    <TableCell
                      className="bg-secundary fc-third ff-poppins fw-bold fs-Large "
                      key={'comparative2'}
                      align="center"
                      height="1.2cm"
                      colSpan={2}
                    >
                      {appConstants.BAR_SECOND_RANGE_COMPARATIVE}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        className="bg-secundary fc-third ff-poppins fw-bold fs-Large "
                        key={column.id}
                        align="center"
                        height="1.2cm"
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {table.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align="center" height="1.2cm">
                              {column.format && typeof value === 'number' ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        );
      })}
    </Box>
  );
};

const splitInfo = (info) => {
  let replic = info;

  let futureTable = [];
  if (replic.length > 4) {
    let array = replic.splice(0, 3);
    futureTable = [...futureTable, array];

    let timeBucle = Math.ceil(replic.length / appConstants.ITEMS_PER_PAGES_BAR);

    for (let i = 0; i < timeBucle; i++) {
      if (replic.length >= appConstants.ITEMS_PER_PAGES_BAR) {
        array = replic.splice(0, appConstants.ITEMS_PER_PAGES_BAR);
        futureTable = [...futureTable, array];
      } else {
        futureTable = [...futureTable, replic];
      }
    }
  }

  return futureTable;
};

const mapStateToProps = (state) => {
  return {
    field: state.fieldReducer.selectedField,
    valuesPerField: state.dashboardReducer.valuesPerFieldReport ? state.dashboardReducer.valuesPerFieldReport : [],
  };
};

export default connect(mapStateToProps, null)(TableBar);
