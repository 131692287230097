import { Box, Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { listCregAlarms,getFactorMForAllDevices,cleanCregAlarm,cleanFactorM,cleanSerieFilter } from '../action/CregActions';


const Consolidated = (props) => {

  const { listAlermCreg, serieFilter, listFactorM,device } = props;

  const [textConsolidated,setTextConsolidated] = React.useState({factorm:0,consecutiveMonths:0});
  const [dateConsolidated] = React.useState({from:'Fecha'});
  const [kavrhConsolidated,setKavrhConsolidated] = React.useState({sum:0,consecutiveDays:0,capacitiva:0,inductiva:0})

  useEffect(() => {

    if(serieFilter!=undefined && props.isRequiredListCregAlarms === appConstants.IS_REQUIRED_LIST_CREG_ALARMS){
      listAlermCregItem();
    }
    if (device != undefined && props.isRequiredFactorMForAllDevice === appConstants.IS_REQUIRED_FACTORM_FOR_ALL_DEVICE) {
      props.getFactorMForAllDevices(device?.id);
    }

    if(listFactorM.length > 0 && serieFilter!=undefined){
      getFactorM(listFactorM);
      dateConsolidated.from=serieFilter?.fromText;

      if(listAlermCreg.length > 0 )
      getSumKavarh(listAlermCreg) 
        
    }


  }, [serieFilter,listFactorM,listAlermCreg]);

  
  const listAlermCregItem = () => {
    let filter = {
      page: 0,
      size: 3000,
      filters: {},
    };
    if (device) filter.filters = { ...filter.filters, deviceId: device?.id };
    if (serieFilter) filter.from = serieFilter.from;
    if (serieFilter) filter.to = serieFilter.to;

    props.listCregAlarms(filter);

  };



  const cleanKavrh = () => {
    setKavrhConsolidated({sum:0,consecutiveDays:0,capacitiva:0,inductiva:0});
    setTextConsolidated({factorm:0,consecutiveMonths:0});
    dateConsolidated.from="fecha";
  };

  const getFactorM= (data) => {
    data.forEach((element) => {
      setTextConsolidated({factorm:element.cregBreach.factorM,consecutiveMonths:element.cregBreach.consecutiveMonths});
    });
 };



  const getSumKavarh = (data) => {
    let result = {sum:0,consecutiveDays:0,capacitiva:0,inductiva:0};
      data.forEach((element) => {
        if(element.energyReactivaPenalized!=null){
          result.sum +=  (element.energyReactivaPenalized*1);
        }

        if(element.typeReactiveEnergy==="CAPACITIVA"){     
          if(element.energyReactiva!=null){
            result.capacitiva += (element.energyReactivaPenalized*1);
          }   
            
        } 
        if(element.typeReactiveEnergy==="INDUCTIVA"){  
          if(element.energyActiva!=null){
            result.inductiva += (element.energyReactivaPenalized*1);
          }        
        } 
      });
      setKavrhConsolidated(result);
   };

  return (
      <Grid item xs={12}>
        <Paper className="paper ">
          <Box boxShadow={3} height={1} borderRadius={10} padding={2}>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              xs={12}
              report
              sm={12}
              className="center-info bg-secundary border-10  "
            >
              <Typography variant="h4" component="h3" className="ff-poppins fc-third">
                Consolidado
              </Typography>
            </Grid>

            <Grid container spacing={2} className="mt-16px">
                  <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info ">
                <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                  <Typography className="ff-poppins t-center"> {dateConsolidated.from}</Typography>
                </Box>
              </Grid>
              <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="center-info ">
                <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                  <Typography className="ff-poppins t-center">Factor M  </Typography>
                </Box>
              </Grid>
               <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="center-info ">
                <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                  <Typography className="ff-poppins t-center"> {textConsolidated.factorm}</Typography>
                </Box>
              </Grid>
              <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="center-info ">
                <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                  <Typography className="ff-poppins t-center">Meses penalizado</Typography>
                </Box>
              </Grid>
              <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="center-info ">
                <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                  <Typography className="ff-poppins t-center">{textConsolidated.consecutiveMonths}</Typography>
                </Box>
              </Grid>
              <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="center-info ">
                <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                  <Typography className="ff-poppins t-center">Dias penalizados</Typography>
                </Box>
              </Grid>
               <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="center-info ">
                <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                  <Typography className="ff-poppins t-center">{listAlermCreg.length}</Typography>
                </Box>
              </Grid> 
                <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info ">
                  <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                    <Typography className="ff-poppins t-center ">Penalizado acumulado</Typography>
                  </Box>
                </Grid>
                <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info ">
                  <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                    <Typography className="ff-poppins t-center fs-t-cell">{kavrhConsolidated.sum.toFixed(2)}-KVAr-h</Typography>
                  </Box>
                </Grid>       
              <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="center-info ">
                <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                  <Typography className="ff-poppins t-center">Total Capacitiva</Typography>
                </Box>
              </Grid>

                  <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="center-info ">
                <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                  <Typography className="ff-poppins t-center">Total inductiva</Typography>
                </Box>
              </Grid>
              <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="center-info ">
                <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                  <Typography className="ff-poppins t-center">{kavrhConsolidated.capacitiva.toFixed(2)}-KVAr-h</Typography>
                </Box>
              </Grid>
               <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="center-info ">
                <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                  <Typography className="ff-poppins t-center">{kavrhConsolidated.inductiva.toFixed(2)}-KVAr-h</Typography>
                </Box>
              </Grid>
             
            </Grid>
          </Box>
        </Paper>
      </Grid>
  );
};

const mapDispatchToProps = {
  listCregAlarms,
  getFactorMForAllDevices,
  cleanCregAlarm,
  cleanFactorM,
  cleanSerieFilter,
};

const mapStateToProps = (state) => {
  return {
    serieFilter: state.CregReducer.serieFilterConsolidated,
    listFactorM: state.CregReducer.avaliabledFactorMForAllDevice ? state.CregReducer.avaliabledFactorMForAllDevice:[],
    listAlermCreg: state.CregReducer.avaliabledListCregAlarms ? state.CregReducer.avaliabledListCregAlarms : [],
    isRequiredListCregAlarms: state.CregReducer.isRequiredListCregAlarms
      ? state.CregReducer.isRequiredListCregAlarms
      : appConstants.IS_REQUIRED_LIST_CREG_ALARMS,
    isRequiredFactorMForAllDevice: state.CregReducer.isRequiredFactorMForAllDevice
        ? state.CregReducer.isRequiredFactorMForAllDevice
        : appConstants.IS_REQUIRED_FACTORM_FOR_ALL_DEVICE,
    device: state.deviceReducer.selectedDevice ? state.deviceReducer.selectedDevice : undefined,
    isCleanConsolidate: state.CregReducer.isCleanConsolidate ? state.CregReducer.isCleanConsolidate:true,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Consolidated);
