import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgActivasIcon(props) {
  const { height, width, color } = props;
  return (
    <svg  viewBox="-20 0 480 480" xmlns="http://www.w3.org/2000/svg" {...props}
           height={height ? height : appConstants.ICON_DEFAULT_HEIGHT}
      width={width ? width : appConstants.ICON_DEFAULT_WIDTH}
    >
      <path
        d="M415.91 24a65.846 65.846 0 0116 48h-39.84V16a8 8 0 00-8-8h7.84a60.97 60.97 0 0124 16zm0 0M384.07 472h-288a60.97 60.97 0 01-24-16 66.222 66.222 0 01-16-48h288a66.222 66.222 0 0016 48 60.97 60.97 0 0024 16zm0 0"
        fill="#898890"
      />
      <path
        d="M384.07 8h-288a8 8 0 00-8 8v24c17.368-.008 32.758 11.2 38.086 27.73s-.62 34.614-14.726 44.75L128.07 168l-40-16v152h8v16l48-24 48 40-48 40-48-24v16h-8v40h256a66.222 66.222 0 0016 48 60.97 60.97 0 0024 16 8 8 0 008-8V16a8 8 0 00-8-8zm0 0"
        fill="#ffde55"
      />
      <path d="M192.07 336l-48 40-48-24v-32l48-24zm0 0" fill="#2488ff" />
      <path
        d="M111.43 112.48L128.07 168l-40-16-40 16 16.64-55.52c13.958 10.028 32.763 10.028 46.72 0zm0 0"
        fill="#d80027"
      />
      <path d="M88.07 40c22.09 0 40 17.91 40 40s-17.91 40-40 40-40-17.91-40-40 17.91-40 40-40zm0 0" fill="#2488ff" />
      <path d="M96.07 320v48h-24v-64h24zm0 0" fill="#898890" />
      <path d="M72.07 320v32h-48c-8.824-.027-15.972-7.176-16-16 .067-8.809 7.192-15.934 16-16zm0 0" fill="#a78966" />
      <path d="M152.07 352v-8h8v-16h-8v-8h-16v8h-16v16h16v8zm0 0" />
      <path d="M421.824 18.602A69.034 69.034 0 00394.621.488a7.92 7.92 0 00-2.71-.488H96.07c-8.837 0-16 7.164-16 16v16.719a47.887 47.887 0 00-38.012 33.586 47.886 47.886 0 0013.484 48.894l-15.137 50.504a8.003 8.003 0 0010.633 9.73l29.031-11.617V296h-8a8 8 0 00-8 8v8h-40c-13.254 0-24 10.746-24 24s10.746 24 24 24h40v8a8 8 0 008 8h8v24h-24a8.002 8.002 0 00-8 7.473 73.59 73.59 0 0018.078 53.925 68.676 68.676 0 0027.122 18.106 8.153 8.153 0 002.8.496h288c8.836 0 16-7.164 16-16V80h31.84a8.002 8.002 0 008-7.383 73.687 73.687 0 00-18.086-54.015zM88.07 48c17.672 0 32 14.328 32 32s-14.328 32-32 32-32-14.328-32-32 14.328-32 32-32zm-18.543 76.273a47.997 47.997 0 0037.086 0l9.032 30.125-24.606-9.847a8 8 0 00-5.937 0l-24.606 9.847zm34.543 200.672l38.895-19.449L179.566 336l-36.601 30.504-38.895-19.45zM80.07 312h8v48h-8zm-56 32a8 8 0 010-16h40v16zm72 32a8 8 0 008-8v-3.055l36.426 18.254a7.998 7.998 0 008.703-1.008l48-40a8 8 0 000-12.304l-48-40a8.083 8.083 0 00-8.703-1.008l-36.426 18.176V304a8 8 0 00-8-8V163.816l29.032 11.618c2.851 1.136 6.105.546 8.37-1.528s3.145-5.261 2.262-8.203L120.598 115.2a47.886 47.886 0 0013.484-48.894A47.887 47.887 0 0096.07 32.719V16h288v447.29a52.525 52.525 0 01-18.097-12.688 57.499 57.499 0 01-13.903-42.075 8.002 8.002 0 00-8-8.527h-248zm1.489 88a52.534 52.534 0 01-19.586-13.398A55.611 55.611 0 0164.07 416h272a71.749 71.749 0 0018.153 45.398c.8.891 1.656 1.762 2.504 2.602zM400.07 64V20.992c3.657 2.371 7 5.195 9.946 8.406A56.378 56.378 0 01423.94 64zm0 0" />
      <path d="M264.07 344h80v16h-80zm0 0M240.07 368h32v16h-32zm0 0M280.07 368h48v16h-48zm0 0M336.07 368h32v16h-32zm0 0M240.07 320h128v16h-128zm0 0M80.07 72h16v16h-16zm0 0M176.016 111.09a20.48 20.48 0 00-12.395-14.403l-1.277-.75c.613-.378 1.222-.73 1.726-1.015 5.063-2.922 12-6.922 12-14.922s-6.93-12-12-14.922a63.176 63.176 0 01-1.742-1.023l1.285-.75a20.48 20.48 0 0012.395-14.403c1.285-11.093-10.89-19-20.16-24a8.008 8.008 0 00-10.762 3.305A8.003 8.003 0 00148.277 39a38.66 38.66 0 0111.282 7.938 44.799 44.799 0 01-4 2.476C151.008 52.13 144.07 56.152 144.07 64c0 8 6.938 12 12 14.93.52.3 1.176.68 1.832 1.07-.664.398-1.32.8-1.84 1.078C151 84 144.07 88 144.07 96c0 7.848 6.938 11.871 11.528 14.535a45.758 45.758 0 014 2.48 38.66 38.66 0 01-11.282 7.938 8.004 8.004 0 00-4.285 6.813 8.003 8.003 0 0011.856 7.28c9.238-4.968 21.414-12.87 20.129-23.956zm0 0M.07 80c0 8 6.938 12 12 14.93.496.285 1.114.636 1.727 1.015l-1.277.75A20.48 20.48 0 00.125 111.098c-1.285 11.093 10.89 19 20.16 24a8.002 8.002 0 008.047-.196 7.998 7.998 0 003.809-7.09A8.014 8.014 0 0027.855 121a38.66 38.66 0 01-11.28-7.938 44.798 44.798 0 014-2.476C25.132 107.87 32.07 103.848 32.07 96c0-8-6.93-12-12-14.922-.52-.3-1.175-.68-1.84-1.078.657-.39 1.313-.8 1.833-1.07C25.133 76 32.07 72 32.07 64c0-7.848-6.937-11.871-11.527-14.535a45.758 45.758 0 01-4-2.48 38.66 38.66 0 0111.281-7.938c3.84-2.113 5.262-6.926 3.188-10.793a7.997 7.997 0 00-10.758-3.3c-9.274 4.968-21.45 12.87-20.16 24a20.476 20.476 0 0012.39 14.398l1.29.753c-.626.375-1.239.735-1.743 1.024C7 68 .071 72 .071 80zm0 0M208.07 48h64v16h-64zm0 0M184.07 112h112v16h-112zm0 0M160.07 144h160v16h-160zm0 0M160.07 176h160v16h-160zm0 0M160.07 208h160v16h-160zm0 0M160.07 240h160v16h-160zm0 0M160.07 272h160v16h-160zm0 0M328.07 144h16v16h-16zm0 0M136.07 176h16v16h-16zm0 0M328.07 208h16v16h-16zm0 0M328.07 272h16v16h-16zm0 0M136.07 240h16v16h-16zm0 0M360.07 24h16v16h-16zm0 0M336.07 24h16v16h-16zm0 0M360.07 48h16v16h-16zm0 0" />
    </svg>
  );
}

export default SvgActivasIcon;
