import { appConstants } from '../appConstants';
import { fetchAction } from '../components/actions/componentActions';
import { axiosInstance } from './axiosInstance';
const ApiAxios = {
  crud: {
    save(context, item, dispatch, action) {
      dispatch(action.requesting());
      axiosInstance()
        .post(`${context}`, item)
        .then((response) => {
          const itemSaved = response.data;
          dispatch(action.received(itemSaved));
        })
        .catch((error) => {
          dispatch(action.error(error));
        });
    },
    list(context, dto, dispatch, action) {
      dispatch(action.requesting());
      axiosInstance()
        .post(`${context}`, dto)
        .then((response) => {
          const list = response.data;
          dispatch(action.received(list));
        })
        .catch((error) => {
          dispatch(action.error(error));
        });
    },
    download(dispatch, actions) {
      dispatch(fetchAction(actions.request));
      axiosInstance()
        .get(`${appConstants.CONTEXT_MANUAL}`, { responseType: 'blob' })
        .then((resp) => {
          dispatch(fetchAction(actions.success, resp.data));
        })
        .catch((error) => {
          dispatch(fetchAction(actions.error, error));
        });
    },
  },

  crudGeneric: {
    post(context, item, dispatch, action, actions) {
      dispatch(action(actions.request));
      axiosInstance()
        .post(`${context}`, item)
        .then((response) => {
          dispatch(action(actions.success, response.data));
        })
        .catch((error) => {
          dispatch(action(actions.error, error));
        });
    },
    put(context, item, dispatch, action, actions) {
      dispatch(action(actions.request));
      axiosInstance()
        .put(`${context}`, item)
        .then((response) => {
          dispatch(action(actions.success, response.data));
        })
        .catch((error) => {
          dispatch(action(actions.error, error));
        });
    },
    get(url, dispatch, action, actions) {

      dispatch(action(actions.request));
      axiosInstance()
        .get(`${url}`)
        .then((response) => {
          dispatch(action(actions.success, response.data));
        })
        .catch((error) => {
          dispatch(action(actions.error, error));
        });
    },
    delete(url, dispatch, action, actions) {
      dispatch(action(actions.request));
      axiosInstance()
        .delete(`${url}`)
        .then((response) => {
          dispatch(action(actions.success, response.data));
        })
        .catch((error) => {
          dispatch(action(actions.error, error));
        });
    },
  },
};

export default ApiAxios;
