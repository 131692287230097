import { Box, Button, Modal } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PmIcon } from '../../../assets/icons/index';
import '../../../assets/styles/App.scss';
import { fetchCleanValues } from '../../../values/actions/valueActions';
import ListValue from '../../../values/components/ListValue';

const RenderDevices = (props) => {
  const { devices, selectedDevice } = props;

  useEffect(() => {}, []);

  const [open, setOpen] = useState(false);
  const [device, setDevice] = useState(false);

  const handleClickItem = (element) => {
    setDevice(element);
    setOpen(true);
  };

  const handleClose = () => {
    props.fetchCleanValues();
    setOpen(false);
  };

  const body = (
    <Box
      style={{
        height: '60%',
        width: '40%',
        overflow: 'auto',
        position: 'absolute',
        top: '20%',
        left: '30%',
      }}
    >
      <Box height={9 / 10} overflow="auto">
        <ListValue device={device} />
      </Box>
      <Box height={1 / 10} className="bg-white" display="flex" justifyContent="center">
        <label htmlFor="select-file-button">
          <Button
            className="bg-green-gradient "
            variant="contained"
            color="primary"
            component="span"
            onClick={handleClose}
          >
            Atrás
            <ArrowBackIcon>back</ArrowBackIcon>
          </Button>
        </label>
      </Box>
    </Box>
  );

  return (
    <>
      {selectedDevice == null && (
        <Box zIndex={10} position="absolute" top={0} left={0} width="100%" height="100%">
          {devices.map((element, index) => {
            return (
              <Box
                key={index}
                height="30px"
                width="30px"
                style={JSON.parse(element.style)}
                position="absolute"
                onClick={(e) => handleClickItem(element)}
                className="cursor-pointer"
              >
                <b>{element.name}</b>
                <PmIcon height="30px" width="30px" />
              </Box>
            );
          })}

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {body}
          </Modal>
        </Box>
      )}
    </>
  );
};
const mapDispatchToProps = {
  fetchCleanValues,
};
const mapStateToProps = (state) => {
  let valueState = {
    devices: state.deviceReducer.avaliabledDevices
      ? state.deviceReducer.avaliabledDevices.filter((element) => element.style)
      : [],
    selectedDevice: state.deviceReducer.selectedDevice ? state.deviceReducer.selectedDevice : undefined,
  };

  return valueState;
};

export default connect(mapStateToProps, mapDispatchToProps)(RenderDevices);
