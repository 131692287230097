import { appConstants } from '../../appConstants';
import axiosInstance from '../../service/axiosInstance';
import { getDifferentBetweenDates, getGroupBy } from '../../service/util';
import { fetchRequest } from '../action/dashboardActions';

const dashboardApi = {
  getIntervals(dispatch, actions) {
    dispatch(fetchRequest(actions.request));
    axiosInstance()
      .get(`${appConstants.CONTEXT_TREND}intervals`)
      .then((data) => {
        dispatch(fetchRequest(actions.success, data.data));
      })
      .catch((error) => {
        dispatch(fetchRequest(actions.error, error));
      });
  },

  listHistory(dispatch, actions, item) {
    axiosInstance()
      .post(`${appConstants.CONTEXT_TREND}`, item)
      .then((data) => {
        dispatch(actions.success(data.data));
      })
      .catch((error) => {
        dispatch(actions.error(error));
      });
  },

  post(dispatch, actions, item, context = '') {
    dispatch(fetchRequest(actions.request));
    axiosInstance()
      .post(`${appConstants.CONTEXT_TREND}${context}`, item)
      .then((resp) => {
        if (!resp.data || isEmpty(resp.data)) {
          if (actions.empty) {
            dispatch(fetchRequest(actions.empty));
          }
          return;
        }
        dispatch(fetchRequest(actions.success, resp.data));
      })
      .catch((error) => {
        dispatch(fetchRequest(actions.error, error));
      });
  },

  dowload(dispatch, actions, item, context = '') {
    dispatch(fetchRequest(actions.request));
    axiosInstance()
      .post(`${appConstants.CONTEXT_TREND}${context}`, item, { responseType: 'blob' })
      .then((resp) => {
        dispatch(fetchRequest(actions.success, resp.data));
      })
      .catch((error) => {
        dispatch(fetchRequest(actions.error, error));
      });
  },

  listSeries(dispatch, actions, data, serieName, genericAction) {
    dispatch(genericAction(actions.request));
    axiosInstance()
      .post(`${appConstants.CONTEXT_TREND}list/filters`, [data])
      .then((response) => {
        let reduxResponse = {
          serie: serieName,
          result: response.data,
        };
        if (!reduxResponse.result || isEmpty(reduxResponse.result)) {
          if (actions.empty) {
            dispatch(fetchRequest(actions.empty));
          }
          return;
        }
        dispatch(genericAction(actions.success, reduxResponse));
      })
      .catch((error) => {
        dispatch(genericAction(actions.error, error));
      });
  },

  listSeriesComparate(dispatch, actions, data, field, genericAction) {
    const frecuency = getDifferentBetweenDates(Date.parse(data[0].from), Date.parse(data[0].to)) / 60000;
    const frecuency2 = getDifferentBetweenDates(Date.parse(data[1].from), Date.parse(data[1].to)) / 60000;
    let gb = getGroupBy(frecuency);
    let gb2 = getGroupBy(frecuency2);

    let fi = {
      page: 0,
      size: 3000,
      from: `${data[0].from}`.replace('T', ' '),
      to: `${data[0].to}`.replace('T', ' '),
      groupBy: gb,
      filters: field,
    };
    let fi2 = {
      page: 0,
      size: 3000,
      from: `${data[1].from}`.replace('T', ' '),
      to: `${data[1].to}`.replace('T', ' '),
      groupBy: gb2,
      filters: field,
    };

    dispatch(genericAction(actions.request));
    axiosInstance()
      .post(`${appConstants.CONTEXT_TREND}list/filters`, [fi])
      .then((response) => {
        axiosInstance()
          .post(`${appConstants.CONTEXT_TREND}list/filters`, [fi2])
          .then((response2) => {
            let reduxResponse = [
              { serie: data[0].name, result: response.data },
              { serie: data[1].name, result: response2.data },
            ];

            dispatch(genericAction(actions.success, reduxResponse));
          })
          .catch((error) => {
            dispatch(genericAction(actions.error, error));
          });
      })
      .catch((error) => {
        dispatch(genericAction(actions.error, error));
      });
  },
};

const isEmpty = (data) => {
  let empty = true;
  try {
    data = Object.values(data);

    data.forEach((element) => {
      if (element.content.length > 0) {
        empty = false;
      }
    });
  } catch (e) {}
  return empty;
};

export default dashboardApi;
