import { appConstants } from '../../appConstants';
import ApiAxios from '../../service/Api';
import axiosInstance from '../../service/axiosInstance';

const finishSaveInstallation = (installation, devices, dispatch, action, actions) => {
  axiosInstance()
    .post(`${appConstants.CONTEXT_INSTALLATION}devices/`, {
      installation: installation,
      styledDevices: devices,
    })
    .then((responseInstallation) => {
      dispatch(action(actions.success, responseInstallation.data));
    })
    .catch((error) => {
      dispatch(action(actions.error, error));
    });
};

const installationApi = {
  post: ApiAxios.crudGeneric.post,
  put: ApiAxios.crudGeneric.put,

  saveDistribution(file, installation, devices, dispatch, action, actions) {
    dispatch(action(actions.request));
    let data = new FormData();

    data.append('file', file);

    if (installation.urlDiagram) {
      data.append('id', installation.urlDiagram);
    }

    let headersDefine = { 'Content-Type': 'multipart/form-data', Accept: 'application/json' };
    const options = { headers: headersDefine };

    dispatch(action(actions.request));
    axiosInstance()
      .post(`file`, data, options)
      .then((response) => {
        installation = { ...installation, urlDiagram: response.data.id };

        finishSaveInstallation(installation, devices, dispatch, action, actions);
      })
      .catch((error) => {
        dispatch(action(actions.error, error));
      });
  },
  saveInstallationWithoutFile: finishSaveInstallation,
};

export default installationApi;
