import { Box, Card } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { listCregAlarms } from '../action/CregActions';

const columns = [
  { id: 'createdAt', label: 'Fecha ' },
  { id: 'energyReactivaPenalized', label: 'kVAr-h' },
  { id: 'typeReactiveEnergy', label: 'Tipo ' },
];

const AlarmCregList = (props) => {
  const { list, serieFilter, device } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    listItems();
  }, [serieFilter]);

  const listItems = () => {
    let filter = {
      page: 0,
      size: 3000,
      filters: {},
    };
    if (device) filter.filters = { ...filter.filters, deviceId: device?.id };
    if (serieFilter) filter.from = serieFilter.from;
    if (serieFilter) filter.to = serieFilter.to;

    props.listCregAlarms(filter);
  };

  return (
    <Box width={1}>
      <Card className="border-10">
        <TableContainer className="table-master-500px">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    className="bg-secundary fc-third ff-poppins fw-bold fs-Large t-center-important"
                    key={column.id}
                    align="center"
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row) => {
                return (
                  <TableRow tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      if (column.id === 'typeReactiveEnergy')
                        return (
                          <TableCell key={column.id} className="t-center-important">
                            {(value * 1).toFixed(4) != 'NaN' ? '-' : value}
                          </TableCell>
                        );
                      return (
                        <TableCell key={column.id} className="t-center-important">
                          {(value * 1).toFixed(4) != 'NaN' ? (value * 1).toFixed(4) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Card>
    </Box>
  );
};

const mapDispatchToProps = {
  listCregAlarms,
};

const mapStateToProps = (state) => {
  return {
    list: state.CregReducer.avaliabledListCregAlarms ? state.CregReducer.avaliabledListCregAlarms : [],
    isRequiredListCregAlarms: state.CregReducer.isRequiredListCregAlarms
      ? state.CregReducer.isRequiredListCregAlarms
      : appConstants.IS_REQUIRED_LIST_CREG_ALARMS,
    serieFilter: state.CregReducer.serieFilterConsolidated,
    device: state.deviceReducer.selectedDevice ? state.deviceReducer.selectedDevice : undefined,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlarmCregList);
