import { Box, Button, Card } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { fetchRequest as fetchHeadquarter, headquarterActions } from '../../headquarter/actions/headquarterActions';
import { fetchRequest as fetchRequestIot, iotActions } from '../../iot/actions/iotActions';
import { cleanDevices, deleteDevice, deviceActions, fetchRequest, listDevices } from '../actions/deviceActions';

const columns = [
  { id: 'id', label: 'ID' },
  { id: 'name', label: 'Nombre' },
  { id: 'machineIdentification', label: 'Carga Asignada' },
  {
    id: 'classification',
    label: 'Tipificación',
  },
  {
    id: 'protocol',
    label: 'Protocolo',
  },
  { id: 'ratedPower', label: 'Potencia N.' },
  { id: 'transformerVoltage', label: 'Tensión T. (V)' },
  {
    id: 'reference',
    label: 'Referencia',
  },
  {
    id: 'transferType',
    label: 'Transferencia',
  },
];

const ListDevice = (props) => {
  const { list } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (props.isRequiredDevices == appConstants.IS_REQUIRED_DEVICE_LIST) {
      listItems();
    }
    if (props.selectedDevice != undefined) {
      return function () {
        props.cleanDevices();
      };
    }
  }, [props.device]);

  const listItems = () => {
    let filterDto = {
      page: 0,
      size: 300,
    };
    props.listDevices(filterDto);
  };

  const handleEdit = (itemSelected) => {
    props.fetchRequest(deviceActions.SET_DEVICE, { item: itemSelected });
    props.fetchHeadquarter(headquarterActions.SEARCH_SET_BY_ID_HQ, {
      id: itemSelected.headquarterId,
      disabledAutocomplete: true,
    });
    props.fetchRequestIot(iotActions.SEARCH_SET_BY_ID_IOT, { id: itemSelected.iot.id, disabledAutocomplete: true });
  };

  const handleDelete = (itemSelected) => {
    swal({
      title: appConstants.DEVICE_VIEW_TITLE,
      text: appConstants.WARNING_DELETE,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteDevice(itemSelected?.id);
      }
    });
  };

  return (
    <Box width={1}>
      <Card className="border-10">
        <TableContainer className="table-master">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    className="bg-primary fc-white ff-poppins fw-bold fs-Large t-center-important"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell align="center" className="bg-primary fc-white ff-poppins fw-bold fs-Large" key={'action'}>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} className="t-center-important">
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell key={'action'} className="center-info" align="center">
                      <Button
                        color="primary"
                        className="btn-transparent ff-poppins fc-green min-width-1px"
                        onClick={(e) => handleEdit(row)}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        color="primary"
                        className="btn-transparent ff-poppins fc-green min-width-1px"
                        onClick={(e) => handleDelete(row)}
                      >
                        <DeleteIcon color="secondary" />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Card>
    </Box>
  );
};

const mapDispatchToProps = {
  listDevices,
  cleanDevices,
  fetchRequest,
  deleteDevice,
  fetchHeadquarter,
  fetchRequestIot,
};

const mapStateToProps = (state) => {
  return {
    list: state.deviceReducer.avaliabledDevices ? state.deviceReducer.avaliabledDevices : [],
    isRequiredDevices: state.deviceReducer.isRequiredDevices
      ? state.deviceReducer.isRequiredDevices
      : appConstants.IS_REQUIRED_DEVICE_LIST,
    device: state.deviceReducer.selectedDevice ? state.deviceReducer.selectedDevice : {},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListDevice);
