import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgFilterIcon(props) {
  const { height, width } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.001 512.001"
      {...props}
      height={height ? height : appConstants.ICON_DEFAULT_HEIGHT}
      width={width ? width : appConstants.ICON_DEFAULT_WIDTH}
    >
      <path
        d="M214.71 306.106v197.626c0 6.139 6.46 10.132 11.951 7.386l61.5-30.75a16.518 16.518 0 009.13-14.772v-159.49a24.776 24.776 0 015.568-15.649L473.4 81.157a24.77 24.77 0 005.568-15.649V16.516C478.968 7.395 471.573 0 462.452 0H49.548c-9.122 0-16.516 7.395-16.516 16.516v48.991A24.776 24.776 0 0038.6 81.156l170.541 209.3a24.776 24.776 0 015.569 15.65z"
        fill="#edf0f7"
      />
      <path
        d="M404.355 66.065H198.194a8.258 8.258 0 01-8.258-8.258V0H49.548c-9.122 0-16.516 7.395-16.516 16.516v48.991A24.776 24.776 0 0038.6 81.156l170.541 209.3a24.774 24.774 0 015.569 15.65v197.625c0 6.139 6.46 10.132 11.951 7.386l17.105-8.552V305.548a8.258 8.258 0 018.258-8.258h11.74a16.517 16.517 0 0013.667-7.242L415.346 86.804c5.984-8.819-.333-20.739-10.991-20.739z"
        fill="#edf0f7"
      />
      <path
        d="M123.502 66.065H33.08c.126 5.502 2.037 10.817 5.52 15.091l170.541 209.3c1.681 2.064 2.968 4.383 3.903 6.835h6.065c5.821 0 9.814-5.86 7.686-11.278L146.561 81.78a24.774 24.774 0 00-23.059-15.715z"
        fill="#edf0f7"
      />
    </svg>
  );
}

export default SvgFilterIcon;
