import { Box, Button, Card, CardContent } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { fetchRequest as fetchRequestReport, reportsActions } from '../../reports/action/reportsActions';
import { fetchRequest, fieldActions } from '../actions/fieldActions';
import { listFieldsMaster } from '../actions/masterFieldActions';

const columns = [{ id: 'name', label: 'Variables' }];

const FieldSelectList = (props) => {
  const [checked, setChecked] = useState([]);
  const { list } = props;

  useEffect(() => {
    if (props.isRequiredFields == appConstants.IS_NOT_REQUIRED_FIELDS_LIST && props.device == undefined) {
      props.fetchRequest(fieldActions.CLEAN_FIELD_STORE);
      props.fetchRequestReport(reportsActions.CLEAN_REPORT_FIELD);
    }
  }, [list]);

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    props.fetchRequestReport(reportsActions.SET_FIELD_REPORT, newChecked);
  };

  return (
    <Box width={1}>
      <Card className="border-10 ">
        <CardContent>
          <TableContainer className="table-master-443 ">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      className="bg-secundary fc-third ff-poppins t-center-important fs-t-cell"
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell
                    align="center"
                    className="bg-secundary fc-third ff-poppins center-info fs-t-cell"
                    key={'action'}
                  >
                    Selección
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} className="t-center-important fs-t-text">
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                      <TableCell key={'action'} className="center-info fs-t-text" align="center">
                        <Button
                          onClick={(e) => handleToggle(row)}
                          className="btn-transparent ff-poppins fc-green"
                          style={{ color: '#2979ff' }}
                        >
                          <Checkbox
                            edge="start"
                            checked={checked.indexOf(row) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': 'cn' }}
                            color="primary"
                          />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Box>
  );
};

const mapDispatchToProps = {
  listFieldsMaster,
  fetchRequest,
  fetchRequestReport,
};

const mapStateToProps = (state) => {
  return {
    list: state.fieldReducer.avaliabledFields ? state.fieldReducer.avaliabledFields : [],

    headquarter: state.headquarterReducer.selectedHeadquarter
      ? state.headquarterReducer.selectedHeadquarter
      : undefined,
    installation: state.installationReducer.selectedInstallation
      ? state.installationReducer.selectedInstallation
      : undefined,
    device: state.deviceReducer.selectedDevice ? state.deviceReducer.selectedDevice : undefined,
    iot: state.iotReducer.selectedIot ? state.iotReducer.selectedIot : undefined,

    isRequiredFields: state.fieldReducer.isRequiredFields
      ? state.fieldReducer.isRequiredFields
      : appConstants.IS_REQUIRED_FIELDS_LIST,

    isRequiredChangefieldReport: state.reportReducers.isRequiredChangefieldReport
      ? state.reportReducers.isRequiredChangefieldReport
      : false,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FieldSelectList);
