import { esES } from '@material-ui/core/locale';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import App from './App';
import reducers from './reducers';
import reportWebVitals from './reportWebVitals';

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  esES,
);

const initialState = {
  selectedIot: undefined,
  selectedDevice: undefined,
  devicesFilterByIot: [],
  imageShowed: undefined,
  iots: [
    {
      name: 'iot-numero 1',
      id: 1,
    },
    {
      name: 'iot-numero 2',
      id: 2,
    },
    {
      name: 'iot-numero 3',
      id: 3,
    },
    {
      name: 'iot-numero 4',
      id: 4,
    },
  ],

  devices: [
    {
      iotId: 1,
      name: 'medidor-1',
      id: 5,
    },
    {
      iotId: 1,
      name: 'medidor 2',
      id: 6,
    },
    {
      iotId: 2,
      name: 'medidor 1',
      id: 7,
    },
    {
      iotId: 2,
      name: 'medidor 2',
      id: 8,
    },

    {
      iotId: 2,
      name: 'medidor 3',
      id: 9,
    },
    {
      iotId: 2,
      name: 'medidor 4',
      id: 10,
    },
    {
      iotId: 2,
      name: 'numero 5',
      id: 11,
    },
    {
      iotId: 3,
      name: 'medidor 1',
      id: 8,
    },
    {
      iotId: 3,
      name: 'medidor 2',
      id: 12,
    },
    {
      iotId: 4,
      name: 'medidor 1',
      id: 13,
    },
    {
      iotId: 4,
      name: 'numero 2',
      id: 14,
    },
  ],
};

const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunkMiddleware)));

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

reportWebVitals();
