import * as React from 'react';
import { appConstants } from '../../appConstants';


function SvgAlarmHistoric(props) {
  const { height, width, color } = props;
  return (
    <svg  viewBox="0 0 511.88 511.88" xmlns="http://www.w3.org/2000/svg" {...props}
              height={height ? height : appConstants.ICON_DEFAULT_HEIGHT}
      width={width ? width : appConstants.ICON_DEFAULT_WIDTH}
    >
      <path d="M15 15h286.12v481.88H15z" fill="#9ee2f8" />
      <path d="M158.06 15h143.06v481.88H158.06z" fill="#8cbcff" />
      <g fill="#e6f1ff">
        <path d="M141.39 173.06H15c-8.284 0-4.869-6.716-4.869-15s-3.416-15 4.869-15h126.39c8.284 0 15 6.716 15 15s-6.716 15-15 15zM94.66 233.29H15c-8.284 0-5.566-6.716-5.566-15s-2.718-15 5.566-15h79.66c8.284 0 15 6.716 15 15s-6.716 15-15 15zM94.66 293.53H15c-8.284 0 0-6.6 0-14.884s-8.284-15.116 0-15.116h79.66c8.284 0 15 6.716 15 15s-6.716 15-15 15zM141.39 353.76H15c-8.284 0-3.682-6.596-3.682-14.88S6.716 323.76 15 323.76h126.39c8.284 0 15 6.716 15 15s-6.716 15-15 15z" />
      </g>
      <path
        d="M301.12 0H15C6.71 0 0 6.72 0 15v481.88c0 8.28 6.71 15 15 15h286.12c8.28 0 15-6.72 15-15V15c0-8.281-6.713-15-15-15zm-15 436.65H30V30h256.12z"
        fill="#195d80"
      />
      <path
        d="M316.12 15v481.88c0 8.28-6.72 15-15 15H158.06v-75.23h128.06V30H158.06V0h143.06c8.281 0 15 6.713 15 15z"
        fill="#143d66"
      />
      <path
        d="M195.7 75.23c0 8.32-6.74 15.05-15.05 15.05h-45.18c-8.31 0-15.05-6.73-15.05-15.05 0-8.31 6.74-15.05 15.05-15.05h45.18c8.31 0 15.05 6.74 15.05 15.05z"
        fill="#195d80"
      />
      <path
        d="M180.65 90.28h-22.59v-30.1h22.59c8.31 0 15.05 6.74 15.05 15.05 0 8.32-6.74 15.05-15.05 15.05zM112.94 436.65h90.24v75.23h-90.24z"
        fill="#143d66"
      />
      <path d="M158.06 436.65h45.12v75.23h-45.12z" fill="#102840" />
      <path
        d="M376.35 248.41c0 8.28-6.71 15-15 15h-60.23c-8.28 0-6.9-7.525-6.9-15.83 0-8.29-1.39-14.17 6.9-14.17h60.23c8.29 0 15 6.72 15 15z"
        fill="#73ca7a"
      />
      <path d="M376.35 248.41c0 8.28-6.71 15-15 15h-60.23c-8.01 0-6.98-7.05-6.91-15z" fill="#00a66c" />
      <path
        d="M511.88 225.76v45.3c0 8.28-6.71 15-15 15H361.35c-8.28 0-15-6.72-15-15v-45.3c0-8.28 6.72-15 15-15h135.53c8.29 0 15 6.72 15 15z"
        fill="#aae071"
      />
      <path d="M511.88 248.41v22.65c0 8.28-6.71 15-15 15H361.35c-8.28 0-15-6.72-15-15v-22.65z" fill="#73ca7a" />
      <path
        d="M302.18 248.41c0 58.466-47.36 105.47-105.42 105.47-58.022 0-105.41-46.98-105.41-105.47.03-58.19 47.22-105.35 105.41-105.35 58.2 0 105.39 47.16 105.42 105.35z"
        fill="#e6f1ff"
      />
      <path
        d="M302.18 248.41c0 58.466-47.36 105.47-105.42 105.47-58.022 0-105.41-46.98-105.41-105.47z"
        fill="#d5e8fe"
      />
      <path
        d="M196.764 248.41c-8.284 0-15-6.716-15-15v-30.118c0-8.284 6.716-15 15-15s15 6.716 15 15v30.118c0 8.285-6.716 15-15 15z"
        fill="#ff448e"
      />
      <path
        d="M196.769 308.656c-8.284 0-15.005-6.721-15.005-15.005s6.721-15.005 15.005-15.005 15.005 6.721 15.005 15.005-6.721 15.005-15.005 15.005z"
        fill="#c43970"
      />
      <path
        d="M196.76 128.06c-66.343 0-120.41 53.963-120.41 120.41 0 66.39 54.02 120.41 120.41 120.41 66.4 0 120.42-120.41 120.42-120.41 0-66.339-53.959-120.41-120.42-120.41zm90.42 120.41c0 49.85-40.56 90.41-90.42 90.41-49.85 0-90.41-40.56-90.41-90.41 0-49.809 40.52-90.41 90.41-90.41 49.81 0 90.42 40.51 90.42 90.41z"
        fill="#aae071"
      />
      <path
        d="M317.18 248.41c0 66.656-54.201 120.47-120.42 120.47-66.123 0-120.41-53.734-120.41-120.47 32.261 0 30-.04 30 .06 0 49.85 40.56 90.41 90.41 90.41 49.86 0 90.42-40.56 90.42-90.41 0-.1-2.286-.06 30-.06z"
        fill="#73ca7a"
      />
    </svg>
  );
}

export default SvgAlarmHistoric;
