import { appConstants } from '../../appConstants';
import { commonMessage, errorHandler, icon } from '../../service/messageHandler';
import { userActions } from '../actions/userActions';
import { getGreatestRol } from '../util/roleRate';

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case userActions.FETCHING_UPDATE_USER_REQUEST:
    case userActions.FETCHING_SAVE_USER_REQUEST:
    case userActions.FETCHING_CHANGE_PASSWORD:
    case userActions.FETCHING_LOGIN:
      return {
        ...state,
        loading: { open: true },
      };
    case userActions.FETCHING_LIST_USER_REQUEST:
      return {
        ...state,
        loading: { open: true },
        isRequiredUsers: appConstants.IS_NOT_REQUIRED_USER_LIST,
      };
    case userActions.FETCHING_LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.data.accessToken);
      return {
        ...state,
        loading: { open: false },
        logged: true,
      };

    case userActions.FETCHING_REQUEST_FAILED:
      errorHandler(action);
      return {
        ...state,
        loading: { open: false },
        error: action.error,
        isRequiredUsers: appConstants.IS_NOT_REQUIRED_USER_LIST,
      };
    case userActions.FETCHING_LIST_USER_SUCCESS:
      return {
        ...state,
        avaliabledUsers: action.payload.content,
        loading: { open: false },
      };
    case userActions.CLEAN_USER_STORE:
      return {
        ...state,
        avaliabledUsers: [],
        roles: [],
        isRequiredUsers: appConstants.IS_REQUIRED_USER_LIST,
        isRequiredRoles: appConstants.IS_REQUIRED_ROLE_LIST,
      };
    case userActions.FETCH_USER_LIST:
      return {
        ...state,
        isRequiredValues: appConstants.IS_USER_NOT_REQUIRE_LIST,
      };
    case userActions.FETCHING_LIST_USER_SUCCESS: {
      return {
        ...state,
        avaliabledValues: action.payload.items.content,
        loading: { open: false },
      };
    }
    case userActions.FETCHING_SAVE_USER_SUCCESS:
      commonMessage(appConstants.SAVE_USER_SUCCESS_MESSAGE, appConstants.SUCCESFUL_SAVED_TITLE, icon.success);
      return {
        ...state,
        loading: { open: false },
        selectedUser: {},
        isRequiredUsers: appConstants.IS_REQUIRED_USER_LIST,
      };
    case userActions.FETCHING_UPDATE_USER_SUCCESS:
      commonMessage(appConstants.UPDATE_USER_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);
      return {
        ...state,
        selectedUser: {},
        loading: { open: false },
        isRequiredUsers: appConstants.IS_REQUIRED_USER_LIST,
      };
    case userActions.FETCHING_CURRENT_USER_REQUEST:
      return {
        ...state,
        loading: { open: true },
        isRequiredCurrentUser: appConstants.IS_NOT_REQUIRED_CURRENT_USER,
      };
    case userActions.FETCHING_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: { open: false },
        currentUser: action.payload,
        logged: true,
        roleUser: getGreatestRol(action.payload.roles),
      };
    case userActions.FETCHING_LIST_ROLES_SUCCESS:
      return {
        ...state,
        loading: { open: false },
        roles: action.payload.content,
      };
    case userActions.FETCHING_LIST_ROLES:
      return {
        ...state,
        loading: { open: true },
        isRequiredRoles: appConstants.IS_NOT_REQUIRED_ROLE_LIST,
      };

    case userActions.FETCHING_CHANGE_PASSWORD_SUCCESS:
      commonMessage(appConstants.MESSAGE_SUCCESS_CHANGE_PASSWORD, '', icon.success);
      return {
        ...state,
        isLogOut: appConstants.IS_REQUIRED_LOG_OUT,
        loading: { open: false },
        currentUser: { ...state.currentUser, changePassword: false },
      };
    case userActions.SET_USER:
      return {
        ...state,
        selectedUser: action.payload.item,
      };

    case userActions.LOG_OUT:
      localStorage.clear();
      return {state: {} };

    case userActions.FETCHING_USER_DELETE_SUCCESS:
      commonMessage(appConstants.DELETE_USER_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);
      return {
        ...state,
        isRequiredUsers: appConstants.IS_REQUIRED_USER_LIST,
        selectedUser: undefined,
        loading: { open: false },
      };

    case userActions.FETCHING_RECOVER_PASSWORD_SUCCESS:
      commonMessage(
        appConstants.RECOVER_PASSWORD_SUCCESS_MESSAGE,
        appConstants.SUCCESFUL_RECOVER_PASSWORD_TITLE,
        icon.success,
      );
      return {
        ...state,
        selectedUser: {},
        loading: { open: false },
      };

    default:
      return state;
  }
};

export default userReducer;
