import { appConstants } from '../../appConstants';
import apiValue from '../services/apiValues';

export const valueActions = {
  FETCH_VALUE_LIST: 'FETCH_VALUE_LIST',
  FETCH_VALUE_LIST_SUCCES: 'FETCH_VALUE_LIST_SUCCES',
  FETCH_VALUE_ERROR: 'FETCH_VALUE_ERROR',
  FETCH_CLEAN_VALUES: 'FETCH_CLEAN_VALUES',
};

export const fetchListValues = () => {
  return {
    type: valueActions.FETCH_VALUE_LIST,
  };
};
export const fetchCleanValues = () => {
  return {
    type: valueActions.FETCH_CLEAN_VALUES,
  };
};

export const fetchListValuesSuccess = (items) => {
  return {
    type: valueActions.FETCH_VALUE_LIST_SUCCES,
    payload: { items: items },
  };
};

export const fetchError = (error) => {
  return {
    type: valueActions.FETCH_VALUE_ERROR,
    payload: { error: error },
  };
};

export const listValues = (filter) => {
  return function (dispatch) {
    apiValue.list(appConstants.CONTEXT_VALUES, filter, dispatch, {
      requesting: fetchListValues,
      received: fetchListValuesSuccess,
      error: fetchError,
    });
  };
};
