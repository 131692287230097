import { appConstants } from '../../appConstants';
import { commonMessage, errorHandler, icon } from '../../service/messageHandler';
import { iotActions } from './../actions/iotActions';

const iotReducer = (state = {}, action) => {
  switch (action.type) {
    case iotActions.CLEAN_IOT_STORE:
      return {
        ...state,
        isRequiredIots: appConstants.IS_REQUIRED_IOT_LIST,
        avaliabledIots: [],
        selectedIot: undefined,
        disabledAutocomplete: false,
      };

    case iotActions.SET_IOT:
      return { ...state, selectedIot: action.payload.item, disabledAutocomplete: false };

    case iotActions.FETCHING_IOT_SAVE_SUCCESS:
      commonMessage(appConstants.SAVE_IOT_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);
      return {
        ...state,
        loading: { open: false },
        selectedIot: {},
        isRequiredIots: appConstants.IS_REQUIRED_IOT_LIST,
      };

    case iotActions.FETCHING_IOT_REQUEST:
      return { ...state, loading: { open: false } };
    case iotActions.FETCHING_IOT_REQUEST_LIST:
      let newState = {
        ...state,
        isRequiredIots: appConstants.IS_NOT_REQUIRED_IOT_LIST,
        loading: { open: true },
      };
      return newState;
    case iotActions.FETCHING_IOT_FAILURE:
      errorHandler(action);
      return state;
    case iotActions.FETCHING_IOT_LIST_SUCCESS:
      return {
        ...state,
        avaliabledIots: action.payload.content ? action.payload.content : [],
        isRequiredIots: appConstants.IS_NOT_REQUIRED_IOT_LIST,
        loading: { open: false },
      };
    case iotActions.FETCHING_IOT_UPDATE_SUCCESS:
      commonMessage(appConstants.UPDATE_IOT_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);
      return {
        ...state,
        selectedIot: {},
        loading: { open: false },
        isRequiredIots: appConstants.IS_REQUIRED_IOT_LIST,
      };
    case iotActions.FETCHING_IOT_DELETE_SUCCESS:
      commonMessage(appConstants.DELETE_IOT_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);
      return {
        ...state,
        isRequiredIots: appConstants.IS_REQUIRED_IOT_LIST,
        selectedIot: undefined,
        loading: { open: false },
      };

    case iotActions.SEARCH_SET_BY_ID_IOT:
      let selectedIotById = undefined;
      let filterediot = [];
      if (state.avaliabledIots && state.avaliabledIots.length > 0)
        filterediot = state.avaliabledIots.filter((item) => item.id === action.payload.id);

      if (filterediot && filterediot.length > 0) selectedIotById = filterediot[0];

      let requiredFindById = !selectedIotById ? appConstants.IS_REQUIRED_IOT_FIND_BY_ID : undefined;

      return {
        ...state,
        selectedIot: selectedIotById,
        isRequiredIots: appConstants.IS_NOT_REQUIRED_IOT_LIST,
        disabledAutocomplete: action.payload.disabledAutocomplete,
        requiredFindById: { value: requiredFindById, id: action.payload.id },
      };

    case iotActions.FETCHING_IOT_FIND_BY_ID_SUCCESS:
      return {
        ...state,
        requiredFindById: appConstants.IS_NOT_REQUIRED_IOT_FIND_BY_ID,
        selectedIot: action.payload,
        loading: { open: false },
      };
    default:
      return state;
  }
};

export default iotReducer;
