import { Button } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import React from 'react';
import { connect } from 'react-redux';
import { setImageUpload } from '../../components/actions/componentActions';

class UploadButton extends React.Component {
  handleUploadFile(event) {
    event.preventDefault();
    if (event.target.files.length > 0) {
      // this.savingImageBackend(event.target.files[0]);
      // this.props.setFileUpload({
      //   type: this.props.typeUpload
      //     ? this.props.typeUpload
      //     : appConstants.SOW_IMAGE,
      //   file: event.target.files[0],
      // });
      this.props.setImageUpload({ file: event.target.files[0] });
    }
  }

  async savingImageBackend(image, uuid = 'c56de80e-6245-11eb-ae93-0242ac130002') {}

  render() {
    return (
      <>
        <input
          id="select-file-button"
          type="file"
          accept={this.props.accept}
          onChange={(e) => this.handleUploadFile(e)}
          className="display-none"
          style={{ display: 'none' }}
        />

        <label htmlFor="select-file-button">
          <Button variant="contained" className="bg-green-gradient mr-2 pl-5 pr-5" component="span">
            <BackupIcon className="mr-2" />
            Seleccionar Diagrama
          </Button>
        </label>
      </>
    );
  }
}

const mapDispatchToProps = {
  setImageUpload,
};

export default connect(null, mapDispatchToProps)(UploadButton);
