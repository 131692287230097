import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { default as React } from 'react';
import { Link } from 'react-router-dom';
import {
  CompanyIcon,
  HomeIcon,
  InstallationIcon,
  LicenciasIcon, ManualIcon, SupportIcon,
  UserIcon
} from '../../assets/icons';
import '../../assets/styles/App.scss';
export const menuSuperAdmin = (
  <div>
    <Link to="/" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <HomeIcon height={30} width={30} />
        </ListItemIcon>
        <ListItemText primary="Inicio" className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>
    <Link to="/company" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <CompanyIcon height={30} width={30} />
        </ListItemIcon>
        <ListItemText primary="Compañia" className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>
    <Link to="/users" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <UserIcon height={30} width={30} />
        </ListItemIcon>
        <ListItemText primary="Usuarios" className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>
    <Link to="/master-field/create" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <InstallationIcon height={30} width={30} />
        </ListItemIcon>
        <ListItemText primary="Maestro variables " className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>
    <Link to="/license/menu" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <LicenciasIcon height={30} width={30} />
        </ListItemIcon>
        <ListItemText primary="Licencia" className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>
    <Link to="/documentation" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <ManualIcon height={30} width={30} />
        </ListItemIcon>
        <ListItemText primary="Configurar Inicio" className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>
    <Link to="/support" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <SupportIcon height={30} width={30} />
        </ListItemIcon>
        <ListItemText primary="Soporte" className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>

  </div>
);
