import { Button } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import SyncIcon from '@material-ui/icons/Sync';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { dashboardAction, fetchRequest } from '../action/dashboardActions';

const SyncTrend = (props) => {
  useEffect(() => {
    props.fetchRequest(dashboardAction.FETCHING_SYNC_TRENDS, props.isRequieredSyncTrend);
  }, [props.isRequieredSyncTrend]);

  const getFilterByField = (event) => {
    props.fetchRequest(dashboardAction.FETCHING_SYNC_TRENDS, event);
    if(event!=false){
      props.fetchRequest(dashboardAction.SET_GLOBAL_FRECUENCY,'15')
    }

  };

  return (
    <>
      {props.isRequieredSyncTrend === true ? (
        <Tooltip title="Desactivar sincronización" aria-label="add">
          <Button type="button" className="btn-rounded-secundary" onClick={(e) => getFilterByField(false)}>
            <SyncDisabledIcon />
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title="Activar sincronización" aria-label="add">
          <Button type="button" className="btn-rounded-secundary" onClick={(e) => getFilterByField(true)}>
            <SyncIcon />
          </Button>
        </Tooltip>
      )}
    </>
  );
};
const mapDispatchToProps = {
  fetchRequest,
};

const mapStateToProps = (state) => {
  return {
    isRequieredSyncTrend:
      state.dashboardReducer.isRequieredSyncTrend === true || state.dashboardReducer.isRequieredSyncTrend === false
        ? state.dashboardReducer.isRequieredSyncTrend
        : true,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SyncTrend);
