import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgDistributedIcon(props) {
  const { height, width } = props;
  return (
    <svg
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      height={height ? height : appConstants.ICON_DEFAULT_HEIGHT}
      width={width ? width : appConstants.ICON_DEFAULT_WIDTH}
    >
      <path d="M30 8h4v4h-4zM34 56h-4v-4h4zM8 34v-4h4v4zM56 30v4h-4v-4z" fill="#57a4ff" />
      <circle cx={32} cy={32} fill="#57a4ff" r={2} />
      <circle cx={10} cy={10} fill="#57a4ff" r={2} />
      <circle cx={10} cy={54} fill="#57a4ff" r={2} />
      <circle cx={54} cy={10} fill="#57a4ff" r={2} />
      <circle cx={54} cy={54} fill="#57a4ff" r={2} />
      <g fill="#004fac">
        <path d="M40 41H24a1 1 0 01-1-1V24a1 1 0 011-1h16a1 1 0 011 1v16a1 1 0 01-1 1zm-15-2h14V25H25zM18 19H2a1 1 0 01-1-1V2a1 1 0 011-1h16a1 1 0 011 1v16a1 1 0 01-1 1zM3 17h14V3H3zM62 63H46a1 1 0 01-1-1V46a1 1 0 011-1h16a1 1 0 011 1v16a1 1 0 01-1 1zm-15-2h14V47H47zM62 19H46a1 1 0 01-1-1V2a1 1 0 011-1h16a1 1 0 011 1v16a1 1 0 01-1 1zm-15-2h14V3H47zM18 63H2a1 1 0 01-1-1V46a1 1 0 011-1h16a1 1 0 011 1v16a1 1 0 01-1 1zM3 61h14V47H3zM34 13h-4a1 1 0 01-1-1V8a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1zm-3-2h2V9h-2z" />
        <path d="M32 24a1 1 0 01-1-1V13a1 1 0 012 0v10a1 1 0 01-1 1zM45 11H35a1 1 0 010-2h10a1 1 0 010 2zM34 57h-4a1 1 0 01-1-1v-4a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1zm-3-2h2v-2h-2z" />
        <path d="M32 52a1 1 0 01-1-1V41a1 1 0 012 0v10a1 1 0 01-1 1zM29 55H19a1 1 0 010-2h10a1 1 0 010 2zM12 35H8a1 1 0 01-1-1v-4a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1zm-3-2h2v-2H9z" />
        <path d="M23 33H13a1 1 0 010-2h10a1 1 0 010 2zM10 30a1 1 0 01-1-1V19a1 1 0 012 0v10a1 1 0 01-1 1zM56 35h-4a1 1 0 01-1-1v-4a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1zm-3-2h2v-2h-2z" />
        <path d="M51 33H41a1 1 0 010-2h10a1 1 0 010 2zM54 46a1 1 0 01-1-1V35a1 1 0 012 0v10a1 1 0 01-1 1zM32 35a3 3 0 113-3 3 3 0 01-3 3zm0-4a1 1 0 101 1 1 1 0 00-1-1zM10 13a3 3 0 113-3 3 3 0 01-3 3zm0-4a1 1 0 101 1 1 1 0 00-1-1zM10 57a3 3 0 113-3 3 3 0 01-3 3zm0-4a1 1 0 101 1 1 1 0 00-1-1zM54 13a3 3 0 113-3 3 3 0 01-3 3zm0-4a1 1 0 101 1 1 1 0 00-1-1zM54 57a3 3 0 113-3 3 3 0 01-3 3zm0-4a1 1 0 101 1 1 1 0 00-1-1zM55 60h-6a1 1 0 010-2h6a1 1 0 010 2zM59 60h-1a1 1 0 010-2h1a1 1 0 010 2z" />
      </g>
    </svg>
  );
}

export default SvgDistributedIcon;
