import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgSchemeIcon(props) {
  const { height, width } = props;
  return (
    <svg viewBox="0 -25 512 511" xmlns="http://www.w3.org/2000/svg" {...props}
          height={height ? height : appConstants.DEFAULT_HEIGHT}
      width={width ? width : appConstants.DEFAULT_WIDTH}
    >
      <path
        d="M130 270.5v-80.004c0-10.996-9-20-20-20H81.543c-11 0-19.996 9.004-19.996 20V270.5c0 11 8.996 20 19.996 20H110c11 0 20-9 20-20zm0 0"
        fill="#7db2ff"
        fillRule="evenodd"
      />
      <path
        d="M281.773 176.305c-20.238 9.644-34.23 30.28-34.23 54.195 0 23.91 13.992 44.55 34.23 54.191C302.012 275.047 316 254.411 316 230.5c0-23.914-13.988-44.55-34.227-54.195zm0 0"
        fill="#59c671"
        fillRule="evenodd"
      />
      <path d="M121.547 370.5h-60v80H130v-80zm0 0" fill="#ffd371" fillRule="evenodd" />
      <path
        d="M467.773 190.926c-19.289 2.816-34.226 19.531-34.226 39.574 0 20.04 14.937 36.754 34.226 39.574C487.066 267.254 502 250.54 502 230.496c0-20.039-14.934-36.754-34.227-39.57zm0 0"
        fill="#7db2ff"
        fillRule="evenodd"
      />
      <path
        d="M502 430.5v-40c0-11.004-9-20-20-20h-28.453c-11.004 0-20 8.996-20 20v40c0 11 8.996 20 20 20H482c11 0 20-9 20-20zm0 0"
        fill="#ff4863"
        fillRule="evenodd"
      />
      <path
        d="M502 70.5v-40c0-11-9-20-20-20h-28.453c-11.004 0-20 9-20 20v40c0 11 8.996 20 20 20H482c11 0 20-9 20-20zm0 0"
        fill="#ffd371"
        fillRule="evenodd"
      />
      <path
        d="M95.773 90.074C115.063 87.254 130 70.54 130 50.496c0-20.039-14.938-36.75-34.227-39.57C76.48 13.746 61.547 30.457 61.547 50.5c0 20.04 14.933 36.754 34.226 39.574zm0 0"
        fill="#ff4863"
        fillRule="evenodd"
      />
      <g fill="#000001">
        <path d="M482 100.5c16.543 0 30-13.46 30-30v-40c0-16.543-13.457-30-30-30h-80c-16.543 0-30 13.457-30 30v40c0 16.54 13.457 30 30 30h30v80h-10c-24.148 0-44.348 17.203-48.992 40h-47.735c-4.398-30.61-28.664-54.879-59.273-59.277v-31.258c0-5.524-4.477-10-10-10-5.52 0-10 4.476-10 10v31.258c-30.605 4.398-54.871 28.668-59.27 59.277H140v-30.004c0-16.539-13.457-30-30-30H80.004V100.5H90c27.57 0 50-22.434 50-50.004C140 22.93 117.57.5 90 .5H50C22.43.5 0 22.93 0 50.5s22.43 50 50 50h10v59.996H29.996c-16.539 0-29.996 13.461-29.996 30V270.5c0 16.543 13.457 30 29.996 30H60v60H10c-5.523 0-10 4.477-10 10v79.996c0 5.524 4.477 10 10 10h120c5.523 0 10-4.476 10-10V370.5c0-5.523-4.477-10-10-10H80v-60h30c16.543 0 30-13.457 30-30v-30l46.727-.004c4.398 30.61 28.664 54.879 59.273 59.277V360.5h-30.535c-5.52 0-10 4.48-10 10 0 5.523 4.48 10 10 10H246v29.996c0 5.524 4.477 10 10 10h116V430.5c0 16.543 13.457 30 30 30h80c16.543 0 30-13.457 30-30v-40.004c0-16.539-13.457-30-30-30h-80c-16.543 0-30 13.461-30 30v10H266V299.773c30.61-4.398 54.875-28.668 59.273-59.277l47.73.004c4.65 22.793 24.849 39.996 48.997 39.996h40c27.57 0 50-22.43 50-50 0-27.566-22.43-49.996-50-49.996h-10v-80zM20 50.496C20 33.957 33.457 20.5 50 20.5h40c16.543 0 30 13.457 30 30s-13.457 30-30 30H50c-16.543 0-30-13.46-30-30.004zm100 390H20V380.5h100zm0-169.996c0 5.516-4.484 10-10 10H29.996c-5.512 0-9.996-4.484-9.996-10v-80.004c0-5.512 4.484-10 9.996-10H110c5.516 0 10 4.488 10 10zm272 120c0-5.516 4.484-10 10-10h80c5.516 0 10 4.484 10 10v40c0 5.516-4.484 10-10 10h-80c-5.516 0-10-4.484-10-10zm-136-110c-27.57 0-50-22.43-50-50s22.43-50.004 50-50.004 50 22.434 50 50.004-22.43 50-50 50zm236-50c0 16.54-13.457 30-30 30h-40c-16.543 0-30-13.46-30-30.004 0-16.539 13.457-29.996 30-29.996h40c16.543 0 30 13.457 30 30zm-100-160v-40c0-5.516 4.484-10 10-10h80c5.516 0 10 4.484 10 10v40c0 5.512-4.484 10-10 10h-80c-5.516 0-10-4.488-10-10zm0 0" />
        <path d="M352.168 45.492c-2.762-4.785-8.879-6.422-13.66-3.66-4.785 2.762-6.422 8.879-3.66 13.66 2.761 4.785 8.879 6.422 13.66 3.66 4.785-2.761 6.422-8.879 3.66-13.66zm0 0M317.168 45.492c-2.762-4.781-8.879-6.418-13.66-3.656-4.785 2.762-6.422 8.875-3.66 13.66 2.761 4.781 8.879 6.422 13.66 3.66 4.781-2.765 6.422-8.879 3.66-13.664zm0 0M282.168 45.496c-2.762-4.785-8.879-6.422-13.66-3.66-4.785 2.762-6.422 8.879-3.66 13.66 2.761 4.781 8.879 6.422 13.66 3.66s6.422-8.879 3.66-13.66zm0 0M247.156 45.508c-2.761-4.781-8.879-6.418-13.66-3.66-4.781 2.761-6.422 8.879-3.66 13.66 2.762 4.785 8.879 6.422 13.66 3.66s6.422-8.875 3.66-13.66zm0 0M212.164 45.496c-2.762-4.785-8.875-6.422-13.66-3.66-4.781 2.762-6.422 8.879-3.66 13.66 2.761 4.785 8.879 6.422 13.664 3.66 4.781-2.761 6.422-8.879 3.656-13.66zm0 0M177.168 45.496a10.003 10.003 0 00-17.324 10c2.761 4.785 8.879 6.422 13.664 3.66 4.781-2.761 6.422-8.879 3.66-13.66zm0 0M450.652 332.969c-2.761-4.785-8.875-6.422-13.66-3.66-4.781 2.757-6.422 8.875-3.66 13.66 2.762 4.781 8.879 6.422 13.66 3.66 4.785-2.762 6.422-8.879 3.66-13.66zm0 0M450.676 297.996c-2.762-4.785-8.88-6.422-13.66-3.66-4.782 2.762-6.422 8.879-3.66 13.66 2.761 4.785 8.878 6.422 13.664 3.66 4.78-2.761 6.418-8.879 3.656-13.66zm0 0M246 100.879c0 5.523 4.477 10 10 10s10-4.477 10-10v-.473c0-5.523-4.477-10-10-10s-10 4.477-10 10zm0 0M186.383 360.504h-.477c-5.523 0-10 4.476-10 10 0 5.523 4.477 10 10 10h.477c5.523 0 10-4.477 10-10 0-5.524-4.477-10-10-10zm0 0" />
      </g>
    </svg>
  );
}

export default SvgSchemeIcon;
