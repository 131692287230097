import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import UpdateIcon from '@material-ui/icons/Update';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { CancelIcon } from '../../assets/icons';
import '../../assets/styles/App.scss';
import { commonMessage, icon } from '../../service/messageHandler';
import { reactiveLicense } from '../actions/licenseActions';
import { listlicenseCycles } from '../actions/licenseCycleActions';
import LicenseCycleAutocomplete from '../components/LicenseCycleAutocomplete';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '90vh',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const LicenseReactiveModal = (props) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const [licenseForm, setLicenseForm] = useState({
    startDate: '',
    endDate: '',
    licenseCycleId: '',
    cantDevice: '',
    value: 0,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { license, company } = props;
  useEffect(() => {
    deserialize();
    listAutoCompleteLC();
  }, [props.licenseForm, license,props.selectedLicenseCycle]);

  const deserialize = () => {
    let valueForm = {};
    valueForm.id = license.id ? license.id : '';
    valueForm.value = license.value ? license.value : '';
    valueForm.startDate = license.startDate ? license.startDate : '';
    valueForm.endDate = license.endDate ? license.endDate : '';
    valueForm.cantDevice = license.cantDevice ? license.cantDevice : '';
    valueForm.licenseCycleId = license.licenseCycleId ? license.licenseCycleId : '';

    setLicenseForm({
      ...valueForm,
    });
  };

  const handleSave = () => {
    let liceneCicleId = props.selectedLicenseCycle?.id ? props.selectedLicenseCycle?.id : licenseForm.licenseCycleId;
    let LicenseSended = {
      ...licenseForm,
      company: company,
      licenseCycleId: liceneCicleId,
    };
    let msn = validate(licenseForm);

    if (msn == '') {
      props.reactiveLicense(LicenseSended);
    } else {
      commonMessage(msn, appConstants.VALIDATION_ERROR_TITLE, icon.info);
    }
  };

  const listAutoCompleteLC =()=>{
        if(props.isRequiredLicenseCycles===appConstants.IS_REQUIRED_LICENSE_CYCLE_LIST){
        props.listlicenseCycles({
        page: 0,
        size: 300,
      });
    }
  }

  const validate = (licenseParam) => {
    let msn = '';

    msn += !props.selectedLicenseCycle?.id == {} ? 'Debe especificar un periodo  \n' : '';

    msn +=
      !licenseParam?.cantDevice || licenseParam?.cantDevice == ''
        ? 'Debe especificar la cantidad de dispositivos \n'
        : '';

    msn += !licenseParam?.value || licenseParam?.value == '' ? 'Debe especificar el costo de licencia \n' : '';

    return msn;
  };
  return (
    <div>
      <Button color="primary" className="btn-transparent ff-poppins  min-width-1px" onClick={handleOpen}>
        <UpdateIcon />
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="license-modal-title"
        aria-describedby="license-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Box height={1}>
            <Card className="border-10">
              <CardContent>
                <form noValidate={false} autoComplete="off">
                  <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info bg-secundary border-10">
                      <Typography variant="h5" className="ff-poppins fw-bold  fc-third">
                        Ajuste de licencia
                      </Typography>
                    </Grid>
                    <Grid item md={12} xl={12} lg={12} sm={12} xs={12}>
                      <LicenseCycleAutocomplete></LicenseCycleAutocomplete>
                    </Grid>

                    <Grid item md={6} xl={6} lg={6} sm={6} xs={6}>
                      <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                        <TextField
                          required
                          type="number"
                          id="cantDevice"
                          label="Catidad de Dispositivos"
                          fullWidth
                          variant="outlined"
                          value={licenseForm.cantDevice}
                          onChange={(valueEvent) =>
                            setLicenseForm({ ...licenseForm, cantDevice: valueEvent.target.value })
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item md={6} xl={6} lg={6} sm={6} xs={6}>
                      <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                        <TextField
                          required
                          type="number"
                          id="value"
                          label="Costos de licencia"
                          fullWidth
                          variant="outlined"
                          value={licenseForm.value}
                          onChange={(valueEvent) => setLicenseForm({ ...licenseForm, value: valueEvent.target.value })}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
              <CardActions className="center d-flex">
                <Grid item md={6} xl={6} lg={6} sm={6} xs={6} className="center-info">
                  <ButtonGroup variant="text" color="primary" aria-label="text primary button group" className="center">
                    <Button className="bg-green-gradient mr-2 pl-5 pr-5" onClick={handleSave}>
                      <UpdateIcon />
                      Ajustar
                    </Button>
                   <Button variant="contained" color="primary" className="bg-green-gradient" onClick={handleClose}>
                    <CancelIcon className="mr-2" height="25px" width="25px" />
                      Cancelar
                    </Button>
                  </ButtonGroup>
                </Grid>
              </CardActions>
            </Card>
          </Box>
        </div>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = { reactiveLicense,listlicenseCycles };
const mapStateToProps = (state) => {
  return {
    selectedLicenseCycle: state.licenseCycleReducer.selectedLicenseCycle,
    license: state.licenseReducer.selectedLicense ? state.licenseReducer.selectedLicense : {},
        isRequiredLicenseCycles: state.licenseCycleReducer.isRequiredLicenseCycles
      ? state.licenseCycleReducer.isRequiredlicenseCycles
      : appConstants.IS_REQUIRED_LICENSE_CYCLE_LIST,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LicenseReactiveModal);
