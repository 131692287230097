import React from 'react';
import { Box, Button, Grid, Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { saveManual } from '../actions/DocumentationActions';
import { commonMessage, icon } from '../../service/messageHandler';
import { ManualIcon } from '../../assets/icons';
import { appConstants } from '../../appConstants';

class ManualUpload extends React.Component {
  handleUploadFile(event) {
    event.preventDefault();
    if (event.target.files.length > 0) {
      this.props.saveManual(event.target.files[0]);
    } else {
      commonMessage('No se encrontro el archivo', appConstants.VALIDATION_ERROR_TITLE, icon.info);
    }
  }

  render() {
    return (
      <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
        <Paper className="paper mr-3">
          <Box boxShadow={3} height={1} borderRadius={10} padding={2}>
            <Box marginTop={2}>
              <Grid container className=" border-10  ">
                <input
                  id="select-file-button"
                  type="file"
                  onChange={(e) => this.handleUploadFile(e)}
                  className="display-none"
                  style={{ display: 'none' }}
                />

                <label htmlFor="select-file-button">
                  <Button variant="contained" className="bg-green-gradient mr-2 pl-5 pr-5" component="span">
                    <ManualIcon height={40} width={40} />
                    Seleccione el manual
                  </Button>
                </label>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Grid>
    );
  }
}

const mapDispatchToProps = {
  saveManual,
};

export default connect(null, mapDispatchToProps)(ManualUpload);
