import { Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { setGlobalTitle } from '../../components/actions/componentActions';
import ConfigHomeForm from '../../configHome/components/ConfigHomeForm';
import ListConfigHome from '../../configHome/components/ListConfigHome';
import ManualUpload from '../components/ManualUpload';

const DocumentationView = (props) => {
    useEffect(() => {
    props.setGlobalTitle(appConstants.CONFIG_HOME_TITLE);
  }, []);
  return (
    <Box height={1} width={1} padding={3} className="background-image-bubble ">
      <Box height={9 / 10}>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
            <ConfigHomeForm />
          </Grid>

          <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
            <ManualUpload />
          </Grid>
          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
            <ListConfigHome/>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  setGlobalTitle,
};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentationView);
