import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import Brush from '@material-ui/icons/Brush';
import Save from '@material-ui/icons/Save';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { commonMessage, icon } from '../../service/messageHandler';
import {
  getTypeGraphics,
  getTypeSignals,
  getTypeValue,
  getTypeValueField,
  getUnits,
  saveFieldMaster,
  setFieldMaster,
  updateFieldMaster
} from '../actions/masterFieldActions';

const MasterFieldForm = (props) => {
  const { field } = props;

  const [fieldForm, setFieldForm] = useState({
    name: '',
    signalType: '',
    unitMeasurement: '',
    typeValue: '',
    typeField: '',
    typeGraphic: '',
  });

  useEffect(() => {
    deserialize();
    if (props.isRequiredUnits === appConstants.IS_REQUIRED_MASTER_FIELDS_UNITS) props.getUnits();
    if (props.isRequiredGaphicsType === appConstants.IS_REQUIRED_MASTER_FIELDS_GRAPHICS) props.getTypeGraphics();
    if (props.isRequiredTypeSignal === appConstants.IS_REQUIRED_MASTER_FIELDS_TYPESIGNAL) props.getTypeSignals();
    if (props.isRequiredTypeValue === appConstants.IS_REQUIRED_MASTER_FIELDS_TYPEVALUE) props.getTypeValue();
    if (props.isRequiredTypeValueField === appConstants.IS_REQUIRED_MASTER_FIELDS_TYPEVALUE_FILE)
      props.getTypeValueField();
  }, [props.field]);

  const handleSave = () => {
    let msn = validate();

    if (msn == '') {
      let itemSaved = {
        ...fieldForm,
      };

      !itemSaved.id ? props.saveFieldMaster(itemSaved) : props.updateFieldMaster(itemSaved);
    } else {
      commonMessage(msn, appConstants.VALIDATION_ERROR_TITLE, icon.error);
    }
  };

  const validate = () => {
    let msn = '';

    msn += !fieldForm.name ? 'Debe ingresar el nombre \n' : '';
    msn += !fieldForm.signalType ? 'Debe seleccionar el tipo de Señal \n' : '';
    msn += !fieldForm.unitMeasurement ? 'Debe seleccionar  la Unidad de medida \n' : '';
    msn += !fieldForm.typeField ? 'Debe seleccionar el tipo de Variable \n' : '';
    msn += !fieldForm.typeValue ? 'Debe seleccionar el tipo de Valor  \n' : '';
    msn += !fieldForm.typeGraphic ? 'Debe seleccionar el tipo de Gráfica  \n' : '';

    return msn;
  };

  const deserialize = () => {
    let valueForm = {};
    valueForm.id = field.id ? field.id : '';
    valueForm.name = field.name ? field.name : '';
    valueForm.signalType = field.signalType ? field.signalType : '';
    valueForm.unitMeasurement = field.unitMeasurement ? field.unitMeasurement : '';
    valueForm.typeField = field.typeField ? field.typeField : '';
    valueForm.typeValue = field.typeValue ? field.typeValue : '';
    valueForm.typeGraphic = field.typeGraphic ? field.typeGraphic : '';
    setTypeValue(field.typeValue ? field.typeValue : '');
    setTypeField(field.typeField ? field.typeField : '');
    setSignal(field.signalType ? field.signalType : '');
    setTypeGraphic(field.typeGraphic ? field.typeGraphic : '');
    setUnitMeasurement(field.unitMeasurement ? field.unitMeasurement : '');
    setFieldForm({
      ...valueForm,
    });
  };

  const handleClean = () => {
    setUnitMeasurement('');
    setSignal('');
    setTypeField('');
    setTypeValue('');
    props.setFieldMaster({ item: undefined });
  };

  const [signal, setSignal] = React.useState('');
  const [unitMeasurement, setUnitMeasurement] = React.useState('');
  const [typeField, setTypeField] = React.useState('');
  const [typeValue, setTypeValue] = React.useState('');
  const [typeGraphic, setTypeGraphic] = React.useState('');

  const handleSignalChange = (event) => {
    setSignal(event.target.value);
    fieldForm.signalType = event.target.value;
  };

  const handleUnitMeasurementChange = (event) => {
    setUnitMeasurement(event.target.value);
    fieldForm.unitMeasurement = event.target.value;
  };

  const handleTypeFieldChange = (event) => {
    setTypeField(event.target.value);
    fieldForm.typeField = event.target.value;
  };

  const handleTypeValueChange = (event) => {
    setTypeValue(event.target.value);
    fieldForm.typeValue = event.target.value;
  };

  const handleTypeGraphicsChange = (event) => {
    setTypeGraphic(event.target.value);
    fieldForm.typeGraphic = event.target.value;
  };
  const listUnits = () => {
    return props.units;
  };
  const listSignal = () => {
    return props.typeSignal;
  };
  const listValue = () => {
    return props.typeValue;
  };
  const listField = () => {
    return props.typeField;
  };
  const listGraphics = () => {
    return props.gaphicsType;
  };
  return (
    <Box>
      <Card className="border-10 ">
        <CardContent>
          <form noValidate={false} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    id="name"
                    label="Nombre"
                    fullWidth
                    variant="outlined"
                    value={fieldForm.name}
                    onChange={(valueEvent) => setFieldForm({ ...fieldForm, name: valueEvent.target.value })}
                  />
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="typeSignal">Tipo de señal</InputLabel>
                    <Select
                      labelId="typeSignal"
                      id="typeSignal"
                      variant="outlined"
                      fullWidth={true}
                      value={signal}
                      onChange={handleSignalChange}
                    >
                      <MenuItem value="" key="none">
                        <em>Ninguna</em>
                      </MenuItem>
                      {listSignal().map((element) => {
                        return (
                          <MenuItem value={element} key={element}>
                            {element}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="typeSignal">Unidad de medida</InputLabel>
                    <Select
                      labelId="unitMeasurement"
                      id="unitMeasurement"
                      variant="outlined"
                      fullWidth={true}
                      value={unitMeasurement}
                      onChange={handleUnitMeasurementChange}
                    >
                      <MenuItem value="" key="none">
                        <em>Ninguna</em>
                      </MenuItem>

                      {listUnits().map((element) => {
                        return (
                          <MenuItem value={element} key={element}>
                            {element}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="typeValue">Tipo de valor</InputLabel>
                    <Select
                      labelId="typeValue"
                      id="typeValue"
                      variant="outlined"
                      fullWidth={true}
                      value={typeValue}
                      onChange={handleTypeValueChange}
                    >
                      <MenuItem value="" key="none">
                        <em>Ninguna</em>
                      </MenuItem>

                      {listValue().map((element) => {
                        return (
                          <MenuItem value={element} key={element}>
                            {element}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="typeField">Tipo de variable</InputLabel>
                    <Select
                      labelId="typeField"
                      id="typeField"
                      variant="outlined"
                      fullWidth={true}
                      value={typeField}
                      onChange={handleTypeFieldChange}
                    >
                      <MenuItem value="" key="none">
                        <em>Ninguna</em>
                      </MenuItem>

                      {listField().map((element) => {
                        return (
                          <MenuItem value={element} key={element}>
                            {element}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="typeGraphic">Tipo de grafica</InputLabel>
                    <Select
                      labelId="typeGraphic"
                      id="typeGraphic"
                      variant="outlined"
                      fullWidth={true}
                      value={typeGraphic}
                      onChange={handleTypeGraphicsChange}
                    >
                      <MenuItem value="" key="none">
                        <em>Ninguna</em>
                      </MenuItem>

                      {listGraphics().map((element) => {
                        return (
                          <MenuItem value={element} key={element}>
                            {element}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <CardActions className="center d-flex">
          <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
            <Button color="primary" className="bg-green-gradient mr-2 pl-5 pr-5" onClick={handleSave}>
              <Save className="mr-2" />
              {field.id ? 'Actualizar' : 'Guardar'}
            </Button>

            <Button className="bg-green-gradient mr-2 pl-5 pr-5" disabled={field == {}} onClick={handleClean}>
              <Brush className="mr-2" />
              Limpiar
            </Button>
          </ButtonGroup>
        </CardActions>
      </Card>
    </Box>
  );
};

const mapStateToprops = (state) => {
  return {
    isRequiredUnits: state.masterFieldReducer.isRequiredUnits
      ? state.masterFieldReducer.isRequiredUnits
      : appConstants.IS_REQUIRED_MASTER_FIELDS_UNITS,

    isRequiredGaphicsType: state.masterFieldReducer.isRequiredGaphicsType
      ? state.masterFieldReducer.isRequiredGaphicsType
      : appConstants.IS_REQUIRED_MASTER_FIELDS_GRAPHICS,

    isRequiredTypeSignal: state.masterFieldReducer.isRequiredTypeSignal
      ? state.masterFieldReducer.isRequiredTypeSignal
      : appConstants.IS_REQUIRED_MASTER_FIELDS_TYPESIGNAL,

    isRequiredTypeValue: state.masterFieldReducer.isRequiredTypeValue
      ? state.masterFieldReducer.isRequiredTypeValue
      : appConstants.IS_REQUIRED_MASTER_FIELDS_TYPEVALUE,

    isRequiredTypeValueField: state.masterFieldReducer.isRequiredTypeValueField
      ? state.masterFieldReducer.isRequiredTypeValueField
      : appConstants.IS_REQUIRED_MASTER_FIELDS_TYPEVALUE_FILE,

    units: state.masterFieldReducer.units ? state.masterFieldReducer.units : [],
    gaphicsType: state.masterFieldReducer.gaphicsType ? state.masterFieldReducer.gaphicsType : [],
    typeSignal: state.masterFieldReducer.typeSignal ? state.masterFieldReducer.typeSignal : [],
    typeValue: state.masterFieldReducer.typeValue ? state.masterFieldReducer.typeValue : [],
    typeField: state.masterFieldReducer.typeValueField ? state.masterFieldReducer.typeValueField : [],

    field: state.masterFieldReducer.selectedMasterField ? state.masterFieldReducer.selectedMasterField : [],
  };
};

const mapDispatchToProps = {
  saveFieldMaster,
  setFieldMaster,
  updateFieldMaster,
  getUnits,
  getTypeGraphics,
  getTypeSignals,
  getTypeValue,
  getTypeValueField,
};

export default connect(mapStateToprops, mapDispatchToProps)(MasterFieldForm);
