import { appConstants } from '../../appConstants';
import { commonMessage, errorHandler, icon } from '../../service/messageHandler';
import { masterFieldActions } from './../actions/masterFieldActions';

const masterFieldReducer = (state = {}, action) => {
  switch (action.type) {
    case masterFieldActions.CLEAN_FIELD_MASTER_STORE:
      return {
        ...state,
        isRequiredMasterFields: appConstants.IS_REQUIRED_MASTER_FIELDS_LIST,
        avaliabledMasterFields: [],
        selectedMasterField: undefined,
      };
    case masterFieldActions.SET_FIELD_MASTER:
      return { ...state, selectedMasterField: action.payload.item };

    case masterFieldActions.FETCHING_FIELD_MASTER_SAVE_SUCCESS:
      commonMessage(appConstants.SAVE_FIELD_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);

      return { ...state, loading: { open: false }, selectedMasterField: {} };
    case masterFieldActions.FETCHING_FIELD_MASTER_REQUEST_UPDATE:
    case masterFieldActions.FETCHING_FIELD_MASTER_REQUEST_SAVE:
      return {
        ...state,
        loading: { open: true, message: '' },
        isRequiredMasterFields: appConstants.IS_REQUIRED_MASTER_FIELDS_LIST,
      };
    case masterFieldActions.FETCHING_FIELD_MASTER_REQUEST_LIST:
      return {
        ...state,
        loading: { open: true, message: '' },
        isRequiredMasterFields: appConstants.IS_NOT_REQUIRED_MASTER_FIELDS_LIST,
      };

    case masterFieldActions.FETCHING_FIELD_MASTER_FAILURE:
      errorHandler(action);
      return { ...state, loading: { open: false } };
    case masterFieldActions.FETCHING_FIELD_MASTER_LIST_SUCCESS:
      return {
        ...state,

        avaliabledMasterFields: action.payload.content ? action.payload.content : [],
        loading: { open: false, message: '' },
      };

    case masterFieldActions.FETCHING_FIELD_MASTER_UPDATE_SUCCESS:
      commonMessage(appConstants.UPDATE_FIELD_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);
      return {
        ...state,
        loading: { open: false },
        selectedMasterField: {},
        isRequiredMasterFields: appConstants.IS_REQUIRED_MASTER_FIELDS_LIST,
      };

    case masterFieldActions.FETCH_REQUEST_UNITS:
      return {
        ...state,
        loading: { open: true },
        isRequiredUnits: appConstants.IS_NOT_REQUIRED_MASTER_FIELDS_UNITS,
      };
    case masterFieldActions.FETCH_REQUEST_UNITS_SUCCESS:
      return {
        ...state,
        loading: { open: false },
        units: action.payload,
      };

    case masterFieldActions.FETCH_REQUEST_GRAPHICS:
      return {
        ...state,
        loading: { open: true },
        isRequiredGaphicsType: appConstants.IS_NOT_REQUIRED_MASTER_FIELDS_GRAPHICS,
      };
    case masterFieldActions.FETCH_REQUEST_GRAPHICS_SUCCESS:
      return {
        ...state,
        loading: { open: false },
        gaphicsType: action.payload,
      };

    case masterFieldActions.FETCH_REQUEST_SIGNALTYPES:
      return {
        ...state,
        loading: { open: true },
        isRequiredTypeSignal: appConstants.IS_NOT_REQUIRED_MASTER_FIELDS_TYPESIGNAL,
      };
    case masterFieldActions.FETCH_REQUEST_SIGNALTYPES_SUCCESS:
      return {
        ...state,
        loading: { open: false },
        typeSignal: action.payload,
      };

    case masterFieldActions.FETCH_REQUEST_TYPEVALUES:
      return {
        ...state,
        loading: { open: true },
        isRequiredTypeValue: appConstants.IS_NOT_REQUIRED_MASTER_FIELDS_TYPEVALUE,
      };
    case masterFieldActions.FETCH_REQUEST_TYPEVALUES_SUCCESS:
      return {
        ...state,
        loading: { open: false },
        typeValue: action.payload,
      };
    case masterFieldActions.FETCH_REQUEST_TYPEVALUES_FIELD:
      return {
        ...state,
        loading: { open: true },
        isRequiredTypeValueField: appConstants.IS_NOT_REQUIRED_MASTER_FIELDS_TYPEVALUE_FIELD,
      };
    case masterFieldActions.FETCH_REQUEST_TYPEVALUES_FIELD_SUCCESS:
      return {
        ...state,
        loading: { open: false },
        typeValueField: action.payload,
      };

    case masterFieldActions.FETCHING_MASTER_FIELD_DELETE_SUCCESS:
      commonMessage(
        appConstants.DELETE_MASTER_FIELD_SUCCESS_MESSAGE,
        appConstants.SUCCESFUL_UPDATE_TITLE,
        icon.success,
      );
      return {
        ...state,
        isRequiredMasterFields: appConstants.IS_REQUIRED_MASTER_FIELDS_LIST,
        selectedMasterField: {},
        loading: { open: false },
      };

    default:
      return state;
  }
};

export default masterFieldReducer;
