import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { componentActions, fetchAction, setGlobalTitle } from '../../components/actions/componentActions';
import Loading from '../../components/Loading';
import { cleanFields, listFields } from '../../field/actions/fieldActions';
import {
  dashboardAction,
  fetchRequest,
  getIntervals,
  listFilterValuesPerField,
  listValuesPerField,
} from '../action/dashboardActions';
import SelectedGlobalFrecuency from '../components/SelectedGlobalFrecuency';
import Trend from '../components/Trend';
import TrendTitle from '../components/TrendTitle';
class TrendsDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reload: false,
      interval: {
        ref: undefined,
        reload: false,
      },
    };

    this.toolbarButtons = [
      { id: appConstants.BACK_BUTTON_ID, history: props.history },
      { id: appConstants.FILTER_TRENDS_BUTTON },
      { id: appConstants.SYNC_TRENS_BUTTON },
    ];
  }

  buildToolbar = () => {
    this.props.setGlobalTitle(appConstants.TREND_DASHBOARD_TITLE);

    this.props.fetchAction(componentActions.ADD_TOOLBAR_BUTTON, this.toolbarButtons);
  };

  componentWillUnmount() {
    clearInterval(this.state.interval.ref);
    this.props.cleanFields();
    this.props.fetchRequest(dashboardAction.CLEAN_DASHBOARD);
    this.props.fetchRequest(componentActions.RESET_HOME);
    this.props.fetchRequest(dashboardAction.CLEAN_SELECTED_FILTER);
  }

  componentDidMount() {
    if (this.props.isRequiredIntervals === appConstants.IS_REQUIRED_DASHBOARD_INTERVALS) this.props.getIntervals();
    if (this.props.isRequiredFields === appConstants.IS_REQUIRED_FIELDS_LIST) {
      this.listInfo();
    }

    if (this.props.isRequiredAddButtons) this.buildToolbar();
  }

  componentDidUpdate(previosProp, prevState, snapshot) {
    if (
      this.props.isRequiredFields === appConstants.IS_REQUIRED_FIELDS_LIST &&
      previosProp.isRequiredFields === appConstants.IS_NOT_REQUIRED_FIELDS_LIST
    ) {
      this.listInfo();
    }
    if (previosProp.list.length != this.props.list.length) {
      let field = Date.now() - 15 * 60000;
    }

    if (!this.state.interval.ref && this.props.isRequieredSyncTrend == true) {
      this.reloadInfo();
    }
  }

  reloadInfo() {
    this.state.interval.ref = setInterval(() => {
      if (this.props.isRequieredSyncTrend == true) {
        this.props.fetchRequest(dashboardAction.SET_FRECUENCY_RELOAD);
      }
    }, 120000);
  }

  listInfo() {
    let filterOfMaster = {
      typeGraphic: 'TRENDS',
    };
    this.props.listFields({
      sort: 'name',
      page: 0,
      size: 300,
      filters: { masterField: filterOfMaster },
    });
  }

  getFilterByField() {
    let listFilters = [];

    this.props.list.forEach((element) => {
      let filter = { page: 0, size: 3000, filters: element };

      listFilters.push(filter);
    });
    return listFilters;
  }

  render() {
    return (
      <Box className="background-image-bubble " width={1} padding={1}>
        <Grid className="secundary-title" container spacing={2} alignContent="center">
          <TrendTitle />
          <SelectedGlobalFrecuency />
        </Grid>
        <Box className="background-image-bubble " width={1} padding={0}>
          <Grid container spacing={2}>
            {this.props.list.map((element) => {
              return (
                <Grid key={element.id} item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Box key={element.id} id={element.id} boxShadow={3} height={1} width={1}>
                    <Trend showHeader={true} field={element} />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <Loading />
        </Box>
      </Box>
    );
  }
}

const mapDispatchToProps = {
  getIntervals,
  setGlobalTitle,
  fetchAction,
  cleanFields,
  listFields,
  listValuesPerField,
  fetchRequest,
  listFilterValuesPerField,
};

const mapStateTotProps = (state) => {
  return {
    list: state.fieldReducer.avaliabledFields ? state.fieldReducer.avaliabledFields : [],
    isRequiredFields: state.fieldReducer.isRequiredFields
      ? state.fieldReducer.isRequiredFields
      : appConstants.IS_REQUIRED_FIELDS_LIST,
    isRequiredIntervals: state.dashboardReducer.isRequiredIntervals
      ? state.dashboardReducer.isRequiredIntervals
      : appConstants.IS_REQUIRED_DASHBOARD_INTERVALS,
    isRequiredAddButtons: state.componentsReducer.isRequiredAddButtons
      ? state.componentsReducer.isRequiredAddButtons
      : appConstants.IS_REQUIRED_ADD_BUTTON,
    frecuency: state.dashboardReducer.frecuency,
    isRequieredSyncTrend: state.dashboardReducer.isRequieredSyncTrend,
    globalfrecuency: state.dashboardReducer.globalfrecuency,
  };
};

export default connect(mapStateTotProps, mapDispatchToProps)(TrendsDashboard);
