import { Box, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import {
  cleanlicenseCycles,
  fetchRequest,
  licenseCycleActions,
  listlicenseCycles,
  findById,
} from '../actions/licenseCycleActions';

class LicenseCycleAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.selectedLicenseCycle = props.selectedLicenseCycle;
    this.ref = undefined;
    this.state =  {selectedLicenseCycle: props.selectedLicenseCycle}
  }

  componentDidUpdate(prevProps, prevState, snap) {
    if((!prevProps.selectedLicenseCycle?.id && this.props.selectedLicenseCycle?.id ) || (this.state.selectedLicenseCycle?.id != this.props.selectedLicenseCycle?.id )){
      this.setState({selectedLicenseCycle: this.props.selectedLicenseCycle})
    }

    if (this.props.requiredFindById?.value === appConstants.IS_REQUIRED_LICENSE_CYCLE_FIND_BY_ID)
    this.props.findById(this.props.requiredFindById?.id);

  }

  render() {
    return (
      <Grid container spacing={1} className="grid-input-element">
        <Grid item xs={12}>
          <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element ">
            <Autocomplete
              ref={(ref) => (this.ref = ref)}
              id="licenseCycleAutocomplete"
              options={this.props.licenseCycles}
              getOptionLabel={(option) => option.name}
              onChange={(e, option) => {
                this.handleSelectLicense(option);
              }}
              fullWidth
              noOptionsText="No existe periodode licencia creado"
              inputValue={this.state.selectedLicenseCycle?.id ? this.state.selectedLicenseCycle.name : ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={this.props.required}
                  label={this.props.label ? this.props.label : 'Periodo licenciamiento'}
                  variant="outlined"
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }

  handleSelectLicense(option) {
    this.selectedLicenseCycle = option;

    this.props.fetchRequest(licenseCycleActions.SET_LICENSE_CYCLE, { item: option });
  }

  componentDidMount() {
    if(this.props.isRequiredLicenseCycles === appConstants.IS_REQUIRED_LICENSE_CYCLE_LIST){
      this.props.listlicenseCycles({
        page: 0,
        size: 300,
      });
    }
  }

  componentWillUnmount() {
    this.props.cleanlicenseCycles();
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.companyReducer.currentCompany ? state.companyReducer.currentCompany : {},
    licenseCycles: state.licenseCycleReducer.avaliabledLicenseCycles
      ? state.licenseCycleReducer.avaliabledLicenseCycles
      : [],
    isRequiredLicenseCycles: state.licenseCycleReducer.isRequiredLicenseCycles
      ? state.licenseCycleReducer.isRequiredLicenseCycles
      : appConstants.IS_REQUIRED_LICENSE_CYCLE_LIST,
    selectedLicenseCycle: state.licenseCycleReducer.selectedLicenseCycle,
    requiredFindById: state.licenseCycleReducer.requiredFindById,
  };
};

const mapDispatchToProps = {
  listlicenseCycles,
  fetchRequest,
  cleanlicenseCycles,
  findById,
};

export default connect(mapStateToProps, mapDispatchToProps)(LicenseCycleAutocomplete);
