import { Box, Button, ButtonGroup, Card, CardActions, CardContent } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Save from '@material-ui/icons/Save';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { CheckIcon } from '../../assets/icons';
import '../../assets/styles/App.scss';
import { commonMessage, icon } from '../../service/messageHandler';
import { saveField } from '../actions/fieldActions';
import { cleanFieldMasters, listFieldsMaster, setFieldMaster } from '../actions/masterFieldActions';

const columns = [
  { id: 'name', label: 'Nombre' },
  { id: 'signalType', label: 'Tipo de señal', align: 'center' },
  {
    id: 'unitMeasurement',
    label: 'Unidad de medida',
    align: 'center',
  },
];

const MasterFieldSelectList = (props) => {
  const [checked, setChecked] = React.useState([]);
  const { list } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChange = (event) => {
    let newChecked = [...checked];
    let canItems = checked.length;
    if (canItems <= 0) {
      newChecked = newChecked.concat(list);
    } else {
      newChecked.splice(0, canItems);
    }
    setChecked(newChecked);
  };

  useEffect(() => {
    if (props.isRequiredMasterFields == appConstants.IS_REQUIRED_MASTER_FIELDS_LIST) {
      listItems();
    }
  }, []);

  const listItems = () => {
    let filterDto = {
      page: 0,
      size: 300,
    };
    props.listFieldsMaster(filterDto);
  };

  const handleSave = () => {
    let msn = validate();
    if (msn == '') {
      let itemSaved = {
        masterFields: checked,
        device: props.selectedDevice,
        headquarter: props.selectedHeadquarter,
        iot: props.selectedIot,
      };
      props.saveField(itemSaved);
    } else {
      commonMessage(msn, appConstants.VALIDATION_ERROR_TITLE, icon.error);
    }
  };

  const validate = () => {
    let msn = '';
    msn += !checked.length > 0 ? 'Debe selecionar una variable \n' : '';
    msn += !props.selectedHeadquarter ? 'Debe selecionar una sede \n' : '';
    msn += !props.selectedIot ? 'Debe selecionar un IOT\n' : '';
    msn += !props.selectedDevice ? 'Debe selecionar un dispositivo \n' : '';
    return msn;
  };

  return (
    <Box width={1}>
      <Card className="border-10">
        <CardContent>
          <TableContainer className="table-master">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      className="bg-secundary fc-third ff-poppins fw-bold fs-Large t-center-important"
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell
                    align="center"
                    className="bg-secundary fc-third ff-poppins fw-bold fs-Large center-info"
                    key={'action'}
                  >
                    Acciones
                    <Button onClick={handleChange}>
                      <CheckIcon className="mr-2" height="25px" width="25px" />
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} className="t-center-important">
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                      <TableCell key={'action'} className="center-info" align="center">
                        <Button
                          className="btn-transparent ff-poppins fc-green"
                          onClick={(e) => handleToggle(row)}
                          style={{ color: '#2979ff' }}
                        >
                          <Checkbox
                            edge="start"
                            checked={checked.indexOf(row) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': 'cn' }}
                            color="primary"
                          />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={list.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </CardContent>
        <CardActions className="center d-flex">
          <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
            <Button color="primary" className="bg-green-gradient mr-2 pl-5 pr-5" onClick={handleSave}>
              <Save className="mr-2" />
              Guardar
            </Button>
          </ButtonGroup>
        </CardActions>
      </Card>
    </Box>
  );
};

const mapDispatchToProps = {
  cleanFieldMasters,
  listFieldsMaster,
  setFieldMaster,
  saveField,
};

const mapStateToProps = (state) => {
  return {
    list: state.masterFieldReducer.avaliabledMasterFields ? state.masterFieldReducer.avaliabledMasterFields : [],
    selectedHeadquarter: state.headquarterReducer.selectedHeadquarter,
    selectedIot: state.iotReducer.selectedIot,
    selectedDevice: state.deviceReducer.selectedDevice ? state.deviceReducer.selectedDevice : undefined,
    isRequiredMasterFields: state.fieldReducer.isRequiredMasterFields
      ? state.fieldReducer.isRequiredMasterFields
      : appConstants.IS_REQUIRED_MASTER_FIELDS_LIST,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterFieldSelectList);
