import { saveAs } from 'file-saver';
import { appConstants } from '../../appConstants';
import { installationActions } from '../../installation/actions/installationActions';
import { commonMessage, icon } from '../../service/messageHandler';
import { componentActions } from '../actions/componentActions';

const componentsReducer = (state = {}, action) => {
  switch (action.type) {
    case componentActions.FETCH_REQUEST_DOWNLOAD:
      return {
        ...state,
        loading: { open: true, message: '' },
      };
    case componentActions.setFileUpload:
      return {
        ...state,
      };
    case componentActions.SET_IMAGE_UPLOAD:
      return {
        ...state,
        imageShowed: URL.createObjectURL(action.payload.file),
        file: action.payload.file,
      };
    case componentActions.SET_GLOBAL_TITLE:
      return {
        ...state,
        globalTitle: action.payload,
        isRequiereTitle: appConstants.IS_NOT_REQUIRED_SET_TITLE,
      };
    case componentActions.REMOVE_BUTTON:
      if (state.toolbarButtons) {
        action.payload.forEach((button) => {
          let exists = state.toolbarButtons.filter((element) => element.id == button.id);

          if (exists.length > 0) {
            state.toolbarButtons.splice(state.toolbarButtons.indexOf(exists[0]));
          }
        });
      }

      return {
        ...state,
        isRequiredAddButtons: appConstants.IS_REQUIRED_ADD_BUTTON,
      };
    case componentActions.ADD_BUTTON:
      let buttons = !state.buttons ? [] : state.buttons;

      let news = [];
      action.payload.forEach((element) => {
        let exists = buttons.filter((button) => button.key == element.key);

        if (exists.length == 0) news.push(element);
      });

      buttons = buttons.concat(news);

      return {
        ...state,
        isRequiredAddButtons: appConstants.IS_NOT_REQUIRED_ADD_BUTTON,
        buttons: buttons,
      };
    case componentActions.RESET_HOME:
      return {
        ...state,
        toolbarButtons: state.toolbarButtons
          ? state.toolbarButtons.filter((element) => element.id == appConstants.LOG_OUT_KEY_BUTTON)
          : [],
      };

    case componentActions.ADD_TOOLBAR_BUTTON:
      let toolbarButtons = !state.toolbarButtons ? [] : state.toolbarButtons;

      let newsButtons = [];
      action.payload.forEach((element) => {
        let exists = toolbarButtons.filter((button) => button.id === element.id);

        if (exists.length === 0) newsButtons.push(element);
      });

      toolbarButtons = toolbarButtons.concat(newsButtons);
      return {
        ...state,
        toolbarButtons: toolbarButtons,
      };
    case componentActions.LIST_ROUTES_AND_MENU:
      return {
        ...state,
        isNotRequiredListMenuAndRoutes: appConstants.IS_NOT_REQUIRED_MENU_ROUTE_LIST,
      };

    case componentActions.FETCH_REQUEST_INTEGRATION_PAGES:
      return {
        ...state,
        loading: { open: true },
        isRequiredIntegrationPages: appConstants.IS_NOT_REQUIRED_INTEGRATION_PAGE,
      };
    case componentActions.FETCH_REQUEST_INTEGRATION_PAGES_SUCCESS:
      return {
        ...state,
        loading: { open: false },
        integrationPage: action.payload,
      };
    case componentActions.FETCH_REQUEST_ERROR:
      return {
        error: action.payload,
      };

    case componentActions.FETCH_DOWNLOAD_SUCCESS:
      saveAs(action.payload, 'manual.pdf');
      return {
        ...state,
        loading: { open: false },
      };
    case installationActions.CLEAN_INSTALLATION:
      return {
        ...state,
        imageShowed: undefined,
      };
    case componentActions.FETCH_REQUEST_ERROR_DOWNLOAD:
      commonMessage(appConstants.ERROR_MANUAL_DOWNLOAD_MESSAGE, appConstants.SUCCESFUL_SAVED_MANUAL_TITLE, icon.error);
      return {
        ...state,
        loading: { open: false },
      };

    default:
      return state;
  }
};

export default componentsReducer;
