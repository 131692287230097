import { appConstants } from '../../appConstants';
import { commonMessage, errorHandler, icon } from '../../service/messageHandler';
import { companyActions } from '../actions/companyActions';

const companyReducer = (state = {}, action) => {
  switch (action.type) {
    case companyActions.FETCHING_UPDATE_COMPANY_REQUEST:

    case companyActions.FETCHING_SAVE_COMPANY_REQUEST:
      return {
        ...state,
        loading: { open: true },
        isRequiredCompanies: appConstants.IS_REQUIRED_COMPANY_LIST,
      };
    case companyActions.FETCH_REQUEST_CURRENT:
      return {
        ...state,
        loading: { open: true },
      };

    case companyActions.FETCH_REQUEST_CURRENT_SUCCESS:
      return {
        ...state,
        currentCompany: action.payload ? action.payload : undefined,
        isRequiredCompany: appConstants.IS_NOT_REQUIRED_COMPANY,
        loading: { open: false },
      };
    case companyActions.FETCH_REQUEST_ERROR:
      errorHandler(action);
      return {
        ...state,
        loading: { open: false },
        isRequiredCompany: appConstants.IS_REQUIRED_COMPANY,
        error: action.payload,
      };

    case companyActions.FETCHING_SAVE_COMPANY_SUCCESS:
      commonMessage(appConstants.SAVE_COMPANY_SUCCESS_MESSAGE, appConstants.SUCCESFUL_SAVED_TITLE, icon.success);
      return {
        ...state,
        loading: { open: false },
        selectedCompany: {},
        isRequiredCompanies: appConstants.IS_REQUIRED_COMPANY_LIST,
      };

    case companyActions.FETCHING_LIST_COMPANY_REQUEST:
      return {
        ...state,
        loading: { open: true, message: '' },
        isRequiredCompanies: appConstants.IS_NOT_REQUIRED_COMPANY_LIST,
      };

    case companyActions.SET_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload.item,
      };
    case companyActions.FETCHING_LIST_COMPANY_SUCCESS:
      return {
        ...state,
        avaliabledCompanies: action.payload.content,
        loading: { open: false },
      };
    case companyActions.CLEAN_COMPANY_STORE:
      return {
        ...state,
        avaliabledCompanies: [],
        roles: [],
        selectedCompany: undefined,
        isRequiredCompanies: appConstants.IS_REQUIRED_COMPANY_LIST,
      };
    case companyActions.FETCH_COMPANY_LIST:
      return {
        ...state,
        isRequiredValues: appConstants.IS_NOT_REQUIRED_COMPANY_LIST,
      };
    case companyActions.FETCHING_UPDATE_COMPANY_SUCCESS:
      commonMessage(appConstants.UPDATE_COMPANY_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);
      return {
        ...state,
        selectedCompany: {},
        loading: { open: false },
        isRequiredCompanies: appConstants.IS_REQUIRED_COMPANY_LIST,
      };

    case companyActions.LOG_OUT:
      return { state: {} };

    case companyActions.FETCHING_COMPANY_DELETE_SUCCESS:
      commonMessage(appConstants.DELETE_COMPANY_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);
      return {
        ...state,
        isRequiredCompanies: appConstants.IS_REQUIRED_COMPANY_LIST,
        selectedCompany: undefined,
        loading: { open: false },
      };

    default:
      return state;
  }
};

export default companyReducer;
