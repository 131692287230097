import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import Brush from '@material-ui/icons/Brush';
import Save from '@material-ui/icons/Save';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { commonMessage, icon } from '../../service/messageHandler';
import { validateEmail } from '../../util/validations';
import { sendSupport } from '../actions/supportActions';

const SupportForm = (props) => {
  const [issueState, setIssueState] = useState({
    email: '',
    subject: appConstants.DEFAUL_SUBJECT,
    onWhat: '',
    where: '',
    happened: '',
  });

  useEffect(() => {}, [issueState]);

  const handleClean = () => {
    let values = {
      email: '',
      subject: appConstants.DEFAUL_SUBJECT,
      onWhat: '',
      where: '',
      happened: '',
    };

    setIssueState({ ...values });
  };

  const handleSave = () => {
    let supportSended = {
      ...issueState,
    };

    let msn = validate();

    if (msn == '') {
      let object = {
        mail: issueState.email,
        subject: issueState.subject,
        message: `en que: ${issueState.onWhat} <br> donde:${issueState.where}  <br>  sucedido: ${issueState.where}`,
      };

      props.sendSupport(object);
      handleClean();
    } else {
      commonMessage(msn, appConstants.VALIDATION_ERROR_TITLE, icon.info);
    }
  };

  const validate = () => {
    let msn = '';

    msn += !issueState.subject || issueState.subject?.trim() == '' ? 'debe especificar nombre del dispositivo  \n' : '';

    msn += !issueState.where || issueState.where?.trim() == '' ? 'debe especificar la mac \n' : '';

    msn += !issueState.onWhat || issueState.onWhat?.trim() == '' ? 'debe especificar el sistema operativo \n' : '';

    msn += !issueState.happened || issueState.happened?.trim() == '' ? 'debe especificar el sistema operativo \n' : '';

    msn += validateEmail(issueState.email);

    return msn;
  };

  return (
    <Box height={1}>
      <Card className="border-10">
        <CardContent>
          <form noValidate={false} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info bg-secundary">
                <Typography variant="h5" className="ff-poppins fw-bold  fc-third">
                  Creación
                </Typography>
              </Grid>
              <Grid item md={12} xl={12} lg={12} sm={12} xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    id="mail"
                    type="email"
                    label="Ingrese su correo"
                    fullWidth
                    className="border-none"
                    variant="outlined"
                    value={issueState.email}
                    onChange={(valueEvent) => setIssueState({ ...issueState, email: valueEvent.target.value })}
                  />
                </Box>
              </Grid>

              <Grid item md={12} xl={12} lg={12} sm={12} xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    fullWidth
                    id="subject"
                    label="Asunto"
                    variant="outlined"
                    required
                    value={issueState.subject}
                    onChange={(valueEvent) => setIssueState({ ...issueState, subject: valueEvent.target.value })}
                  />
                </Box>
              </Grid>

              <Grid item md={12} xl={12} lg={12} sm={12} xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    fullWidth
                    id="onWhat"
                    label="¿Qué estabas haciendo cuando se presentó el incidente?"
                    variant="outlined"
                    value={issueState.onWhat}
                    multiline
                    required
                    rows={3}
                    onChange={(valueEvent) => setIssueState({ ...issueState, onWhat: valueEvent.target.value })}
                  />
                </Box>
              </Grid>

              <Grid item md={12} xl={12} lg={12} sm={12} xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    fullWidth
                    id="happened"
                    label="¿Cuál fue el incidente presentado?"
                    variant="outlined"
                    multiline
                    rows={3}
                    value={issueState.happened}
                    onChange={(valueEvent) => setIssueState({ ...issueState, happened: valueEvent.target.value })}
                  />
                </Box>
              </Grid>

              <Grid item md={12} xl={12} lg={12} sm={12} xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    id="where"
                    label="¿En qué parte la aplicación se presento el incidente?"
                    fullWidth
                    variant="outlined"
                    value={issueState.where}
                    multiline
                    rows={3}
                    onChange={(valueEvent) => setIssueState({ ...issueState, where: valueEvent.target.value })}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <CardActions className="center d-flex">
          <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
            <Button color="primary" className="bg-green-gradient mr-2 pl-5 pr-5" onClick={handleSave}>
              <Save />
              Enviar
            </Button>

            <Button className="bg-green-gradient mr-2 pl-5 pr-5" disabled={issueState == {}} onClick={handleClean}>
              <Brush />
              Limpiar
            </Button>
          </ButtonGroup>
        </CardActions>
      </Card>
    </Box>
  );
};

const mapDispatchToProps = {
  sendSupport,
};

export default connect(null, mapDispatchToProps)(SupportForm);
