import { BehaviorSubject } from 'rxjs';

let filterSubject = new BehaviorSubject({});

export const handleFieldChange = (e) => {
  const newValue = e;
  filterSubject.next(newValue);
};

export function getFieldObservable() {
  return filterSubject;
}
