import { appConstants } from '../../appConstants';
import ApiAxios from '../../service/Api';

export const licenseCycleActions = {
  SET_LICENSE_CYCLE: 'SET_LICENSE_CYCLE',
  ADD_LICENSE_CYCLE: 'ADD_LICENSE_CYCLE',
  FETCHING_LICENSE_CYCLE_REQUEST: 'FETCHING_LICENSE_CYCLE_REQUEST',

  FETCHING_LICENSE_CYCLE_SAVE_SUCCESS: 'FETCHING_LICENSE_CYCLE_SAVE_SUCCESS',
  FETCHING_LICENSE_CYCLE_UPDATE_SUCCESS: 'FETCHING_LICENSE_CYCLE_UPDATE_SUCCESS',
  FETCHING_LICENSE_CYCLE_LIST_SUCCESS: 'FETCHING_LICENSE_CYCLE_LIST_SUCCESS',
  FETCHING_LICENSE_CYCLE_FAILURE: 'FETCHING_LICENSE_CYCLE_FAILURE',
  FETCHING_LICENSE_CYCLE_REQUEST_LIST: 'FETCHING_LICENSE_CYCLE_REQUEST_LIST',
  CLEAN_LICENSE_CYCLE_STORE: 'CLEAN_LICENSE_CYCLE_STORE',
  FETCHING_LICENSE_CYCLE_DELETE_SUCCESS: 'FETCHING_LICENSE_CYCLE_DELETE_SUCCESS',
  FETCH_REQUEST_TYPE_LICENSE: 'FETCH_REQUEST_TYPE_LICENSE',
  FETCH_REQUEST_TYPE_LICENSE_SUCCESS: 'FETCH_REQUEST_TYPE_LICENSE_SUCCESS',
  FETCHING_TYPE_LICENSE_FIND_BY_ID_SUCCESS:'FETCHING_TYPE_LICENSE_FIND_BY_ID_SUCCESS',
  FETCHING_TYPE_LICENSE_FIND_BY_ID_REQUEST:'FETCHING_TYPE_LICENSE_FIND_BY_ID_REQUEST',

};

export const setlicenseCycle = (payload) => {
  return {
    type: licenseCycleActions.SET_LICENSE_CYCLE,
    payload,
  };
};

export const cleanlicenseCycles = () => {
  return {
    type: licenseCycleActions.CLEAN_LICENSE_CYCLE_STORE,
  };
};

export const fetchRequest = (type, payload= undefined) => {
  return {
    type,
    payload,
  };
};

export const savelicenseCycle = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_LICENSE_CYCLE, item, dispatch, fetchRequest, {
      request: licenseCycleActions.FETCHING_LICENSE_CYCLE_REQUEST,
      success: licenseCycleActions.FETCHING_LICENSE_CYCLE_SAVE_SUCCESS,
      error: licenseCycleActions.FETCHING_LICENSE_CYCLE_FAILURE,
    });
  };
};

export const updatelicenseCycle = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.put(appConstants.CONTEXT_LICENSE_CYCLE, item, dispatch, fetchRequest, {
      request: licenseCycleActions.FETCHING_LICENSE_CYCLE_REQUEST,
      success: licenseCycleActions.FETCHING_LICENSE_CYCLE_UPDATE_SUCCESS,
      error: licenseCycleActions.FETCHING_LICENSE_CYCLE_FAILURE,
    });
  };
};

export const listlicenseCycles = (dto) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_LICENSE_CYCLES, dto, dispatch, fetchRequest, {
      request: licenseCycleActions.FETCHING_LICENSE_CYCLE_REQUEST_LIST,
      success: licenseCycleActions.FETCHING_LICENSE_CYCLE_LIST_SUCCESS,
      error: licenseCycleActions.FETCHING_LICENSE_CYCLE_FAILURE,
    });
  };
};

export const deletelicenseCycle = (id) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.delete(`${appConstants.CONTEXT_LICENSE_CYCLE}${id}`, dispatch, fetchRequest, {
      request: licenseCycleActions.FETCHING_LICENSE_CYCLE_REQUEST_LIST,
      success: licenseCycleActions.FETCHING_LICENSE_CYCLE_DELETE_SUCCESS,
      error: licenseCycleActions.FETCHING_LICENSE_CYCLE_FAILURE,
    });
  };
};

export const getTypeLicense = () => {
  return function (dispatch) {
    ApiAxios.crudGeneric.get(`${appConstants.CONTEXT_LICENSE_CYCLE}type-licenses`, dispatch, fetchRequest, {
      request: licenseCycleActions.FETCH_REQUEST_TYPE_LICENSE,
      success: licenseCycleActions.FETCH_REQUEST_TYPE_LICENSE_SUCCESS,
      error: licenseCycleActions.FETCHING_LICENSE_CYCLE_FAILURE,
    });
  };
};

export const findById = (id) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.get(`${appConstants.CONTEXT_LICENSE_CYCLE}${id}`, dispatch, fetchRequest, {
      request: licenseCycleActions.FETCHING_TYPE_LICENSE_FIND_BY_ID_REQUEST,
      success: licenseCycleActions.FETCHING_TYPE_LICENSE_FIND_BY_ID_SUCCESS,
      error: licenseCycleActions.FETCHING_LICENSE_CYCLE_FAILURE,
    });
  };
};