import { Box, Button, ButtonGroup, Card, CardActions, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Brush from '@material-ui/icons/Brush';
import Save from '@material-ui/icons/Save';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { ConfigHomeActions, fetchRequest, getTypeHome, saveConfigHome, updateConfigHome } from '../../configHome/actions/ConfigHomeActions';
import { commonMessage, icon } from '../../service/messageHandler';

const ConfigHomeForm = (props) => {
  const { configHome } = props;

  const [configHomeForm, setConfigHomeForm] = useState({
    name: '',
    type:'',
    info: '',
  });

const [type, setType] = React.useState('');

 useEffect(()=>{
    deserialize();
    if (props.isRequiredTypeHome === appConstants.IS_REQUIRED_TYPE_HOME)
    {
      props.getTypeHome();
    } 
 },[props.configHomeForm,configHome]);

  const deserialize = () => {
    let valueForm = {};
    valueForm.id = configHome?.id ? configHome.id :'';
    valueForm.name = configHome?.name ? configHome.name :'';
    setType(configHome?.type ? configHome.type:'')
    valueForm.type = configHome?.type ? configHome.type:'';
    valueForm.info = configHome?.info ? configHome.info:'';
    
    setConfigHomeForm({
      ...valueForm,
    });
  };

  const handleClean = () => {
    props.fetchRequest(ConfigHomeActions.SET_CONFIG_HOME,{item: {}})
    setType('');
  };

  const handleSave = () => {
    let configHomeSended = {
      ...configHomeForm,
    };
    let msn = validate(configHomeForm);

    if (msn == '') {
      if(configHomeSended?.id){
        props.updateConfigHome(configHomeSended);
      }else{
         commonMessage('Error al editar nose pudo cargar el id recarga la pagina', appConstants.VALIDATION_ERROR_TITLE, icon.info);
      }
     
    }else{
      commonMessage(msn, appConstants.VALIDATION_ERROR_TITLE, icon.info);
    }
    handleClean();
  };

  const validate = (configHomeParam) => {
    let msn = '';

    msn += !configHomeParam.name || configHomeParam.name?.trim() == '' ? 'Debe espesificar el nombre \n':'';
    msn += !configHomeParam.type || configHomeParam.type?.trim() == '' ? 'Debe espesificar el el tipo \n':'';
    msn += !configHomeParam.info || configHomeParam.info?.trim() == '' ? 'Debe espesificar la información \n':'';
    return msn;
  };

  const handleTypeHome = (event) => {
    setType(event.target.value);
    configHomeForm.type = event.target.value;
  };

  return (
    <Box height={1}>
      <Card className="border-10">
        <CardContent>
          <form noValidate={false} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item md={12} xl={12} lg={12} sm={12} xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    id="name"
                    label="Nombre de la del campo"
                    fullWidth
                    className="border-none"
                    variant="outlined"
                    value={configHomeForm.name}
                    onChange={(valueEvent) => setConfigHomeForm({ ...configHomeForm, name: valueEvent.target.value })}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="type">Tipo</InputLabel>
                    <Select
                      labelId="type"
                      id="type"
                      variant="outlined"
                      fullWidth={true}
                      value={type}
                      onChange={handleTypeHome}
                    >
                      <MenuItem value="" key="none">
                        <em>Ninguna</em>
                      </MenuItem>

                      {props.typeHome.map((element) => {
                        return (
                          <MenuItem value={element} key={element}>
                            {element}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
               </Box>
              </Grid>

              <Grid item md={12} xl={12} lg={12} sm={12} xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    id="info"
                    label="Información"
                    fullWidth
                    inputProps={{
                      maxLength: 255,
                    }}
                    variant="outlined"
                    multiline
                    rows={3}
                    value={configHomeForm.info}
                    onChange={(valueEvent) =>
                      setConfigHomeForm({ ...configHomeForm, info: valueEvent.target.value })
                    }
                  />
                </Box>
              </Grid>


            </Grid>
          </form>
        </CardContent>
        <CardActions className="center d-flex">
          <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
            <Button className="bg-green-gradient mr-2 pl-5 pr-5" onClick={handleSave}>
              <Save />
                Actualizar
            </Button>

            <Button disabled={setConfigHomeForm == {}} className="bg-green-gradient mr-2 pl-5 pr-5" onClick={handleClean}>
              <Brush />
              Limpiar
            </Button>
          </ButtonGroup>
        </CardActions>
      </Card>
    </Box>
  );
};

const mapDispatchToProps = {
getTypeHome,
saveConfigHome,
fetchRequest,
updateConfigHome
};

const mapStateToProps = (state) => {
  return {
    typeHome: state.ConfigHomeReducers.typeHome ? state.ConfigHomeReducers.typeHome : [],
    isRequiredTypeHome: state.ConfigHomeReducers.isRequiredTypeHome ? state.ConfigHomeReducers.isRequiredTypeHome :appConstants.IS_REQUIRED_TYPE_HOME,
    configHome: state.ConfigHomeReducers.selectedConfigHome ? state.ConfigHomeReducers.selectedConfigHome :{},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigHomeForm);
