import { appConstants } from '../../appConstants';
import ApiAxios from '../../service/Api';

export const componentActions = {
  SET_IMAGE_UPLOAD: 'SET_IMAGE_UPLOAD',
  SELECT_MENU_ITEM: 'SELECT_MENU_ITEM',
  SET_GLOBAL_TITLE: 'SET_GLOBAL_TITLE',
  ADD_BUTTON: 'ADD_BUTTON',
  REMOVE_BUTTON: 'REMOVE_BUTTON',
  RESET_HOME: 'RESET_HOME',
  ADD_TOOLBAR_BUTTON: 'ADD_TOOLBAR_BUTTON',
  LIST_ROUTES_AND_MENU: 'LIST_ROUTES_AND_MENU',
  FETCH_REQUEST_INTEGRATION_PAGES: 'FETCH_REQUEST_INTEGRATION_PAGES',
  FETCH_REQUEST_INTEGRATION_PAGES_SUCCESS: 'FETCH_REQUEST_INTEGRATION_PAGES_SUCCESS',
  FETCH_REQUEST_ERROR: 'FETCH_REQUEST_COMPONENT_ERROR',
  CLEAN_STORE_DASHBOARD: 'CLEAN_STORE_DASHBOARD',
  FETCH_REQUEST_DOWNLOAD: 'FETCH_REQUEST_DOWNLOAD',
  FETCH_DOWNLOAD_SUCCESS: 'FETCH_DOWNLOAD_SUCCESS',
  FETCH_REQUEST_ERROR_DOWNLOAD:'FETCH_REQUEST_ERROR_DOWNLOAD',
};

export const fetchAction = (type, payload = undefined) => {
  return {
    type,
    payload,
  };
};

export const resetHome = () => {
  return { type: componentActions.RESET_HOME };
};

export const setFileUpload = (payload) => ({
  type: componentActions.setFileUpload,
  payload,
});

export const setImageUpload = (payload) => {
  return {
    type: componentActions.SET_IMAGE_UPLOAD,
    payload,
  };
};

export const selectMenuItem = (payload) => {
  return {
    type: componentActions.SELECT_MENU_ITEM,
    payload,
  };
};

export const setGlobalTitle = (payload) => {
  return {
    type: componentActions.SET_GLOBAL_TITLE,
    payload,
  };
};

export const addButton = (payload) => {
  return {
    type: componentActions.ADD_BUTTON,
    payload: payload,
  };
};

export const removeButton = (payload) => {
  return {
    type: componentActions.REMOVE_BUTTON,
    payload,
  };
};

export const downloadManual = () => {
  return function (dispatch) {
    ApiAxios.crud.download(dispatch, {
      request: componentActions.FETCH_REQUEST_DOWNLOAD,
      success: componentActions.FETCH_DOWNLOAD_SUCCESS,
      error: componentActions.FETCH_REQUEST_ERROR_DOWNLOAD,
    });
  };
};

export const getIntegrationPage = () => {
  return function (dispatch) {
    ApiAxios.crudGeneric.get(`${appConstants.CONTEXT_INTEGRATION}`, dispatch, fetchAction, {
      request: componentActions.FETCH_REQUEST_INTEGRATION_PAGES,
      success: componentActions.FETCH_REQUEST_INTEGRATION_PAGES_SUCCESS,
      error: componentActions.FETCH_REQUEST_ERROR,
    });
  };
};
