import { Box, Button, Card } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { fetchRequest as fetchHeadquarter, headquarterActions } from '../../headquarter/actions/headquarterActions';
import { cleanIot, deleteIot, listIots, setIot } from '../actions/iotActions';

const columns = [
  { id: 'id', label: 'ID' },
  { id: 'name', label: 'Nombre' },
  { id: 'nameControlPanel', label: 'Panel' },
  {
    id: 'mac',
    label: 'Mac',
  },
  {
    id: 'serial',
    label: 'Serial',
  },
];

const IotList = (props) => {
  const { list } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (props.isRequiredIots == appConstants.IS_REQUIRED_IOT_LIST) {
      props.listIots({
        page: 0,
        size: 300,
      });
    }
    if (props.selectedIot) {
      return function () {
        props.cleanIot();
      };
    }
  }, [props.iot]);

  const handleEdit = (itemSelected) => {
    props.fetchHeadquarter(headquarterActions.SEARCH_SET_BY_ID_HQ, {
      id: itemSelected.headquarterId,
      disabledAutocomplete: true,
    });
    props.setIot({ item: itemSelected });
  };
  const handleDelete = (itemSelected) => {
    swal({
      title: appConstants.IOT_VIEW_TITLE,
      text: appConstants.WARNING_DELETE,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteIot(itemSelected?.id);
      }
    });
  };

  return (
    <Box width={1} height={1}>
      <Card className="border-10">
        <TableContainer className="table-master">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    className="bg-secundary fc-third ff-poppins fw-bold fs-Large t-center-important"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}

                <TableCell className="bg-secundary fc-third ff-poppins fw-bold fs-Large " key={'action'}>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} className="t-center-important">
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}

                    <TableCell key={'action'} className="center-info" align="center">
                      <Button
                        color="primary"
                        className="btn-transparent ff-poppins fc-green min-width-1px"
                        onClick={(e) => handleEdit(row)}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        color="primary"
                        className="btn-transparent ff-poppins fc-green min-width-1px"
                        onClick={(e) => handleDelete(row)}
                      >
                        <DeleteIcon color="secondary" />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Card>
    </Box>
  );
};

const mapDispatchToProps = {
  listIots,
  cleanIot,
  setIot,
  fetchHeadquarter,
  deleteIot,
};

const mapStateToProps = (state) => {
  return {
    company: state.companyReducer.currentCompany ? state.companyReducer.currentCompany : {},
    list: state.iotReducer.avaliabledIots ? state.iotReducer.avaliabledIots : [],
    isRequiredIots: state.iotReducer.isRequiredIots
      ? state.iotReducer.isRequiredIots
      : appConstants.IS_REQUIRED_IOT_LIST,
    iot: state.iotReducer.selectedIot ? state.iotReducer.selectedIot : {},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IotList);
