import { Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { addButton, componentActions, fetchAction, setGlobalTitle } from '../../components/actions/componentActions';
import Loading from '../../components/Loading';
import { listFieldsMaster } from '../actions/masterFieldActions';
import MasterFieldForm from '../components/MasterFieldForm';
import MasterFieldList from '../components/MasterFieldList';
const MasterFieldView = (props) => {
  const buttonsToolbar = [{ id: appConstants.BACK_BUTTON_ID, history: props.history }];

  useEffect(() => {
    props.setGlobalTitle(appConstants.FIELD_VIEW_TITLE);

    props.fetchAction(componentActions.ADD_TOOLBAR_BUTTON, buttonsToolbar);
  }, []);

  const handleFilter = (event) => {
    event.preventDefault();
    // handleFieldChange(true);

    let filter = {
      page: 0,
      size: 3000,
      filters: {
        iot: props.iot,
        device: props.device,
      },
    };

    props.listFieldsMaster(filter);
  };

  return (
    <Box height={1} width={1} padding={3} className="background-image-bubble ">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box boxShadow={4} borderRadius={10}>
            <MasterFieldForm />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box boxShadow={4} borderRadius={10}>
            <MasterFieldList />
          </Box>
        </Grid>
      </Grid>
      <Loading />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    iot: state.iotReducer.selectedIot,
    device: state.deviceReducer.selectedDevice,
    headquarter: state.headquarterReducer.selectedHeadquarter,
  };
};

const mapDispatchToProps = {
  setGlobalTitle,
  addButton,
  fetchAction,
  listFieldsMaster,
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterFieldView);
