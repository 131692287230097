import { Button } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import '../../assets/styles/App.scss';
import { companyActions, fetchRequest as fetchCompany } from '../../company/actions/companyActions';
import { fetchRequest, userActions } from '../actions/userActions';
import '../assets/styles/user.scss';

function LogOutModalButton(props) {
  const logOut = (event) => {
    event.preventDefault();
    props.fetchRequest(userActions.LOG_OUT);
    props.fetchCompany(companyActions.LOG_OUT);
    history.push('');
  };

  const history = useHistory();

  return (
    <Button className="bg-green-gradient mr-2 pl-5 pr-5" onClick={logOut}>
      Cerrar
    </Button>
  );
}

const mapDispatchToProps = {
  fetchRequest,
  fetchCompany,
};

export default connect(null, mapDispatchToProps)(LogOutModalButton);
