import { Grid, Modal, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../../assets/styles/App.scss';
import { getModalStyle, useModalStyles } from '../../util/styles';
import ChangePassword from '../components/ChangePassword';

const ModalChangePassword = (props) => {
  const [open, setOpen] = useState(false);
  const classes = useModalStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const changeOpen = (event) => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info bg-secundary">
          <Typography className="ff-poppins fw-bold  fc-third">CAMBIO OBLIGATORIO DE CONTRASEÑA</Typography>
        </Grid>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
          <ChangePassword />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <>
      <Modal
        open={open}
        onClose={changeOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        {body}
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isOpen: state.userReducer.currentUser && state.userReducer.currentUser.changePassword === true ? true : false,
  };
};

export default connect(mapStateToProps, null)(ModalChangePassword);
