import { Box, Button, Grid, Paper } from '@material-ui/core';
import BrushIcon from '@material-ui/icons/Brush';
import CloudDownload from '@material-ui/icons/CloudDownload';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { componentActions, fetchAction, setGlobalTitle } from '../../components/actions/componentActions';
import Loading from '../../components/Loading';
import { downloadTrend, getIntervals } from '../../dashboard/action/dashboardActions';
import { deviceActions, fetchRequest as fetchRequestDevice } from '../../device/actions/deviceActions';
import FieldSelectList from '../../field/components/FieldSelectList';
import {
  fetchRequest as fetchRequesHeadquarters,
  headquarterActions,
} from '../../headquarter/actions/headquarterActions';
import {
  fetchRequest as fetchRequestInstallation,
  installationActions,
} from '../../installation/actions/installationActions';
import { fetchRequest as fetchRequestIot, iotActions } from '../../iot/actions/iotActions';
import { commonMessage, icon } from '../../service/messageHandler';
import { getDifferentBetweenDates, getGroupBy } from '../../service/util';
import {
  downloadComparativeReport,
  downloadIndividualReport,
  fetchRequest as fetchRequestReport,
  reportsActions,
} from '../action/reportsActions';
import ComparativeDateReport from '../components/ComparativeDateReport';
import GeneratorReport from '../components/GeneratorReport';

const GeneratorReportView = (props) => {
  const toolbarButtons = [{ id: appConstants.BACK_BUTTON_ID, history: props.history }];
  const { serie, fieldReport, typeReport } = props;
  useEffect(() => {
    props.setGlobalTitle(appConstants.REPORT_VIEW_TITLE);
    if (props.isRequiredIntervals == appConstants.IS_REQUIRED_DASHBOARD_INTERVALS) props.getIntervals();

    props.fetchAction(componentActions.ADD_TOOLBAR_BUTTON, toolbarButtons);

    return function () {
      props.fetchAction(componentActions.CLEAN_STORE_DASHBOARD);
    };
  }, [props.typeReport]);

  const validateDate = (response) => {
    let msn = '';
    if (response.typeReport == undefined || response.typeReport == '') msn += 'Debe seleccionar un tipo de reporte \n ';
    if (typeReport === appConstants.INDIVIDUAL && response.fields.length > 5)
      msn += 'Puede seleccionar máximo 5 variables \n';
    if (typeReport === appConstants.COMPARATIVO && response.fields.length > 3)
      msn += 'Puede seleccionar máximo 3 variables \n';
    if (response.fields.length <= 0) msn += 'Debe seleccionar mínimo 1 variable \n';
    if (response.from == undefined || response.from == '') msn += 'Debe seleccionar la fechas inicial \n';
    if (response.to == undefined || response.to == '') msn += 'Debe seleccionar la fecha final \n';
    if (response.from == response.to) msn += 'Debe seleccionar fechas diferentes \n';
    return msn;
  };

  const handelGeneratePDF = () => {
    let response = {
      from: `${serie.from}`.replace('T', ' '),
      to: `${serie.to}`.replace('T', ' '),
      fields: fieldReport,
      typeReport: typeReport,
    };

    let msn = validateDate(response);
    if (msn != '') {
      commonMessage(msn, '', icon.info);
      return;
    }
    const frecuency = getDifferentBetweenDates(Date.parse(response.from), Date.parse(response.to)) / 60000;
    let gb = getGroupBy(frecuency);
    if (typeReport === appConstants.INDIVIDUAL) {
      fieldReport.forEach((element) => {
        var sendIndividualReport = {
          from: response.from,
          to: response.to,
          groupBy: gb,
          filters: element,
        };
        props.downloadIndividualReport(sendIndividualReport);
      });
    }
    if (typeReport === appConstants.COMPARATIVO) {
      var sendComoarativeReport = {
        from: response.from,
        to: response.to,
        groupBy: gb,
        filters: fieldReport,
      };
      props.downloadComparativeReport(sendComoarativeReport);
    }
  };
  const handelGenerateCVS = (event) => {
    let response = { from: serie.from, to: serie.to, fields: fieldReport, typeReport: typeReport };

    let msn = validateDate(response);
    if (msn != '') {
      commonMessage(msn, '', icon.info);
      return;
    }
    if (typeReport === appConstants.INDIVIDUAL) {
      fieldReport.forEach((element) => {
        props.downloadTrend(getFilter(element));
      });
    }
  };

  const getFilter = (field) => {
    const frecuency =
      getDifferentBetweenDates(
        Date.parse(`${serie.from}`.replace('T', ' ')),
        Date.parse(`${serie.to}`.replace('T', ' ')),
      ) / 60000;
    let gb = getGroupBy(frecuency);
    return {
      page: 0,
      size: 300,
      from: `${serie.from}`.replace('T', ' '),
      to: `${serie.to}`.replace('T', ' '),
      groupBy: gb,
      filters: { fieldId: field.id, deviceId: field.device.id },
    };
  };

  const handleClean = () => {
    props.fetchRequestReport(reportsActions.SET_TYPE_REPORT, { item: undefined });
    props.fetchRequestIot(iotActions.SET_IOT, { item: undefined });
    props.fetchRequesHeadquarters(headquarterActions.SET_HEADQUARTER, { item: undefined });
    props.fetchRequestDevice(deviceActions.SET_DEVICE, { item: undefined });
    props.fetchRequestInstallation(installationActions.SET_INSTALLATION, { item: undefined });
  };

  return (
    <Box width={1} padding={1} justifyContent="center" alignItems="center" className="background-image-bubble mt-10px">
      <Grid container spacing={1} height={1}>
        <Grid item xl={6} md={6} lg={6} sm={12} xs={12}>
          <GeneratorReport />
        </Grid>
        <Grid item xl={6} md={6} lg={6} sm={12} xs={12}>
          <FieldSelectList />
        </Grid>
        <Grid item xl={12} md={12} lg={12} sm={12} xs={12} spacing={2}>
          <Paper className="paper w-100 h-100">
            <Box boxShadow={3} height={1} borderRadius={10} padding={2}>
              <Grid container spacing={2}>
                <Grid item xl={6} md={6} lg={6} sm={12} xs={12} className="center-info mt-10px">
                  <ComparativeDateReport series={[{ name: 'serie a', title: 'Comparativo Reportes' }]} />
                </Grid>
                <Grid item xl={6} md={6} lg={6} sm={12} xs={12} spacing={2} margin={2}>
                  <Button
                    disabled={props.typeReport === 'COMPARATIVO' ? true : false}
                    variant="contained"
                    onClick={(e) => handelGenerateCVS(e)}
                    className={
                      'ff-poppins mt-10px mr-2 w-120px ' +
                      (props.typeReport === 'COMPARATIVO' ? 'bg-third' : 'bg-green-gradient')
                    }
                  >
                    <CloudDownload className="mr-2" />
                    CSV
                  </Button>
                  <Button
                    variant="contained"
                    className="ff-poppins bg-green-gradient mr-2 w-120px mt-10px"
                    onClick={handelGeneratePDF}
                  >
                    <CloudDownload className="mr-2" />
                    PDF
                  </Button>
                  <Button color="primary" className="bg-green-gradient w-120px mt-10px" onClick={handleClean}>
                    <BrushIcon className="mr-2" />
                    Limpiar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Loading />
    </Box>
  );
};

const mapDispatchToProps = {
  fetchAction,
  setGlobalTitle,
  getIntervals,
  downloadIndividualReport,
  downloadComparativeReport,
  downloadTrend,
  getDifferentBetweenDates,
  getGroupBy,
  fetchRequesHeadquarters,
  fetchRequestIot,
  fetchRequestDevice,
  fetchRequestInstallation,
  fetchRequestReport,
};

const mapStateToProps = (state) => {
  return {
    isRequiredIntervals: state.dashboardReducer.isRequiredIntervals
      ? state.dashboardReducer.isRequiredIntervals
      : appConstants.IS_REQUIRED_DASHBOARD_INTERVALS,
    field: state.fieldReducer.selectedField,
    serie: state.reportReducers.globalSeriesReport ? state.reportReducers.globalSeriesReport : [],
    fieldReport: state.reportReducers.fieldReport ? state.reportReducers.fieldReport : [],
    installation: state.installationReducer.selectedInstallation
      ? state.installationReducer.selectedInstallation
      : undefined,
    typeReport: state.reportReducers.setTypeReport ? state.reportReducers.setTypeReport : undefined,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneratorReportView);
