import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';

const columns = [
  { id: 'createdAt', label: 'Fecha de obtención' },
  { id: 'value', label: 'Valor' },
];

const TableTrend = (props) => {
  const [info, setInfo] = useState([]);

  useEffect(() => {
    let values = props.valuesPerField[props.field?.name]?.content;

    setInfo(values ? values : []);
  }, [props.valuesPerField]);

  return (
    <Box>
      {splitInfo(info).map((table, index) => {
        return (
          <Box paddingTop={index > 0 ? 25 : 2} id={index} key={index}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        className="bg-secundary fc-third ff-poppins fw-bold fs-Large "
                        key={column.id}
                        align="center"
                        height="1.2cm"
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {table.map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align="center" height="1.2cm">
                              {column.format && typeof value === 'number' ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        );
      })}
    </Box>
  );
};

const splitInfo = (info) => {
  let replic = info;

  let futureTable = [];
  if (replic.length > 4) {
    let array = replic.splice(0, 3);
    futureTable = [...futureTable, array];

    let timeBucle = Math.ceil(replic.length / appConstants.ITEMS_PER_PAGES);

    for (let i = 0; i < timeBucle; i++) {
      if (replic.length >= appConstants.ITEMS_PER_PAGES) {
        array = replic.splice(0, appConstants.ITEMS_PER_PAGES);
        futureTable = [...futureTable, array];
      } else {
        futureTable = [...futureTable, replic];
      }
    }
  }
  return futureTable;
};

const mapStateToProps = (state) => {
  return {
    field: state.fieldReducer.selectedField,
    valuesPerField: state.dashboardReducer.valuesPerField ? state.dashboardReducer.valuesPerField : {},
  };
};

export default connect(mapStateToProps, null)(TableTrend);
