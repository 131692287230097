import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgChargeabilityIcon(props) {
  const { height, width, color } = props;
  return (
    <svg
      height={height ? height : appConstants.DEFAULT_HEIGHT}
      width={width ? width : appConstants.DEFAULT_WIDTH}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 187.5 187.5"
      {...props}
    >
      <defs>
        <clipPath id="ChargeabilityIcon_svg__b">
          <path d="M0 0h187v187H0zm0 0" />
        </clipPath>
        <clipPath id="ChargeabilityIcon_svg__c">
          <path d="M8 36h179v132.816H8zm0 0" />
        </clipPath>
        <clipPath id="ChargeabilityIcon_svg__d">
          <path d="M0 164h6v4.816H0zm0 0" />
        </clipPath>
        <clipPath id="ChargeabilityIcon_svg__g">
          <path d="M3 0h8v3H3zm0 0" />
        </clipPath>
        <clipPath id="ChargeabilityIcon_svg__h">
          <path d="M12 0h8v4h-8zm0 0" />
        </clipPath>
        <clipPath id="ChargeabilityIcon_svg__f">
          <path d="M0 0h21v15H0z" />
        </clipPath>
        <clipPath id="ChargeabilityIcon_svg__k">
          <path d="M3 0h7v4H3zm0 0" />
        </clipPath>
        <clipPath id="ChargeabilityIcon_svg__l">
          <path d="M11 6h4v8h-4zm0 0" />
        </clipPath>
        <clipPath id="ChargeabilityIcon_svg__m">
          <path d="M11 0h4v5h-4zm0 0" />
        </clipPath>
        <clipPath id="ChargeabilityIcon_svg__j">
          <path d="M0 0h15v15H0z" />
        </clipPath>
        <clipPath id="ChargeabilityIcon_svg__p">
          <path d="M11 2h7.855v8H11zm0 0" />
        </clipPath>
        <clipPath id="ChargeabilityIcon_svg__o">
          <path d="M0 0h19v20H0z" />
        </clipPath>
        <clipPath id="ChargeabilityIcon_svg__q">
          <path d="M36 84.063h22V88H36zm0 0" />
        </clipPath>
        <clipPath id="ChargeabilityIcon_svg__r">
          <path d="M36 84.063h23V89H36zm0 0" />
        </clipPath>
        <clipPath id="ChargeabilityIcon_svg__s">
          <path d="M29 97h36v38.063H29zm0 0" />
        </clipPath>
        <clipPath id="ChargeabilityIcon_svg__t">
          <path d="M62 109h5.492v5H62zm0 0" />
        </clipPath>
        <clipPath id="ChargeabilityIcon_svg__u">
          <path d="M128 84.262h35V122h-35zm0 0" />
        </clipPath>
        <clipPath id="ChargeabilityIcon_svg__v">
          <path d="M130 84.262h32V120h-32zm0 0" />
        </clipPath>
        <clipPath id="ChargeabilityIcon_svg__w">
          <path d="M135 84.262h21V115h-21zm0 0" />
        </clipPath>
        <clipPath id="ChargeabilityIcon_svg__x">
          <path d="M134 130h23v5.262h-23zm0 0" />
        </clipPath>
        <clipPath id="ChargeabilityIcon_svg__y">
          <path d="M127 84.262h37V122h-37zm0 0" />
        </clipPath>
        <clipPath id="ChargeabilityIcon_svg__z">
          <path d="M125.262 106H131v4h-5.738zm0 0" />
        </clipPath>
        <clipPath id="ChargeabilityIcon_svg__A">
          <path d="M160 106h5.762v4H160zm0 0" />
        </clipPath>
        <mask id="ChargeabilityIcon_svg__e">
          <g filter="url(#ChargeabilityIcon_svg__a)">
            <path fillOpacity={0.047} d="M-18.75-18.75h225v225h-225z" />
          </g>
        </mask>
        <mask id="ChargeabilityIcon_svg__i">
          <g filter="url(#ChargeabilityIcon_svg__a)">
            <path fillOpacity={0.047} d="M-18.75-18.75h225v225h-225z" />
          </g>
        </mask>
        <mask id="ChargeabilityIcon_svg__n">
          <g filter="url(#ChargeabilityIcon_svg__a)">
            <path fillOpacity={0.047} d="M-18.75-18.75h225v225h-225z" />
          </g>
        </mask>
        <filter x="0%" y="0%" width="100%" height="100%" id="ChargeabilityIcon_svg__a">
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" colorInterpolationFilters="sRGB" />
        </filter>
      </defs>
      <g clipPath="url(#ChargeabilityIcon_svg__b)" fill="#FFF">
        <path d="M0 0h187.5v195H0zm0 0" />
        <path d="M0 0h187.5v187.5H0zm0 0" />
        <path d="M0 0h187.5v187.5H0zm0 0" />
        <path d="M0 0h187.5v187.5H0zm0 0" />
      </g>
      <path
        fill="#3F4040"
        d="M181.305 55.578v6.41c0 2.203-1.797 4.004-3.996 4.004H16.19c-2.199 0-3.996-1.8-3.996-4.004v-6.41c0-2.203 1.797-4.004 3.996-4.004H177.11c2.399 0 4.196 1.801 4.196 4.004zm0 0"
      />
      <path
        fill="#FFF"
        d="M181.305 55.578v2.203H24.789c-2.2 0-4 1.805-4 4.008v4.203h-4.598c-2.199 0-3.996-1.8-3.996-4.004v-6.41c0-2.203 1.797-4.004 3.996-4.004H177.11c2.399 0 4.196 1.801 4.196 4.004zm0 0"
        fillOpacity={0.2}
      />
      <path
        fill="#3F4040"
        d="M181.305 156.121v6.406c0 2.203-1.797 4.008-3.996 4.008H16.19c-2.199 0-3.996-1.805-3.996-4.008v-6.406c0-2.203 1.797-4.008 3.996-4.008H177.11c2.399 0 4.196 1.805 4.196 4.008zm0 0"
      />
      <path
        fill="#FFF"
        d="M181.305 156.121v2H24.789c-2.2 0-4 1.805-4 4.008v4.406h-4.598c-2.199 0-3.996-1.805-3.996-4.008v-6.406c0-2.203 1.797-4.008 3.996-4.008H177.11c2.399 0 4.196 1.805 4.196 4.008zm0 0"
        fillOpacity={0.2}
      />
      <path fill="#E2E2E2" d="M21.79 65.992h149.92v86.121H21.79zm0 0" />
      <path fill="#E2E2E2" d="M21.79 65.992h149.92v86.121H21.79zm0 0" />
      <path
        fill="#FFF"
        d="M171.71 65.992v28.242c-14.593-11.218-32.983-17.824-52.773-17.824-44.175 0-80.558 32.844-86.156 75.703H21.79v-86.12zm0 0"
      />
      <path
        fill="#EF4D4D"
        d="M46.176 42.16v9.215h-20.79V42.16c0-2.203 1.802-4.004 4-4.004h12.59c2.403 0 4.2 1.801 4.2 4.004zm0 0"
      />
      <path
        fill="#FFF"
        d="M46.176 42.16v.403H33.984c-2.199 0-4 1.8-4 4.003v5.008h-4.398V42.36c0-2.203 1.8-4.004 4-4.004H42.18c2.199-.199 3.996 1.602 3.996 3.805zm0 0"
        fillOpacity={0.4}
      />
      <path
        fill="#EF4D4D"
        d="M76.559 42.16v9.215H55.973V42.16c0-2.203 1.797-4.004 3.996-4.004h12.593c2.2 0 3.997 1.801 3.997 4.004zm0 0"
      />
      <path
        fill="#FFF"
        d="M76.559 42.16v.403H64.367c-2.199 0-4 1.8-4 4.003v5.008H55.77V42.36c0-2.203 1.8-4.004 4-4.004h12.593c2.399-.199 4.196 1.602 4.196 3.805zm0 0"
        fillOpacity={0.4}
      />
      <path
        fill="#EF4D4D"
        d="M107.145 42.16v9.215h-20.79V42.16c0-2.203 1.801-4.004 3.997-4.004h12.593c2.399 0 4.2 1.801 4.2 4.004zm0 0"
      />
      <path
        fill="#FFF"
        d="M107.145 42.16v.403H94.949c-2.195 0-3.996 1.8-3.996 4.003v5.008h-4.598V42.36c0-2.203 1.801-4.004 3.997-4.004h12.593c2.399-.199 4.2 1.602 4.2 3.805zm0 0"
        fillOpacity={0.4}
      />
      <path
        fill="#EF4D4D"
        d="M137.527 42.16v9.215h-20.586V42.16c0-2.203 1.797-4.004 3.996-4.004h12.594c2.2 0 3.996 1.801 3.996 4.004zm0 0"
      />
      <path
        fill="#FFF"
        d="M137.527 42.16v.403h-12.191c-2.2 0-4 1.8-4 4.003v5.008h-4.598V42.36c0-2.203 1.801-4.004 4-4.004h12.594c2.398-.199 4.195 1.602 4.195 3.805zm0 0"
        fillOpacity={0.4}
      />
      <path
        fill="#EF4D4D"
        d="M168.113 42.16v9.215h-20.789V42.16c0-2.203 1.797-4.004 3.996-4.004h12.594c2.399 0 4.2 1.801 4.2 4.004zm0 0"
      />
      <path
        fill="#FFF"
        d="M168.113 42.16v.403h-12.195c-2.2 0-3.996 1.8-3.996 4.003v5.008h-4.598V42.36c0-2.203 1.797-4.004 3.996-4.004h12.594c2.399-.199 4.2 1.602 4.2 3.805zm0 0"
        fillOpacity={0.4}
      />
      <path
        fill="#FDCE23"
        d="M95.55 78.21h16.192c1.2 0 1.801 1.204 1.2 2.204l-11.993 18.023c-.601 1.004 0 2.204 1.2 2.204h10.593c1.2 0 1.797 1.402 1.2 2.406l-29.587 36.05c-1 1.2-2.996 0-2.398-1.402l9.395-23.433c.402-1-.399-2.004-1.399-2.004h-9.195c-1 0-1.797-1.2-1.399-2.004L94.152 79.21c.399-.602.797-1 1.399-1zm0 0"
      />
      <path
        fill="#FFF"
        d="M109.941 85.219H99.75c-.602 0-1 .402-1.2.804l-12.593 26.235h-5.2c-1 0-1.796-1.2-1.398-2.004L94.352 79.21c.199-.602.8-.8 1.398-.8h16.191c1.2 0 1.801 1.202 1.2 2.202zm0 0"
        fillOpacity={0.4}
      />
      <g clipPath="url(#ChargeabilityIcon_svg__c)">
        <path
          fill="#2F1212"
          d="M191.5 164.531h-8.594c.2-.601.399-1.203.399-2.004v-6.406c0-3.406-2.598-6.008-5.996-6.008h-3.598V67.996h3.598c3.398 0 5.996-2.601 5.996-6.008v-6.41c0-3.402-2.598-6.008-5.996-6.008h-7.196v-7.21c0-3.403-2.601-6.008-6-6.008h-12.59c-3.398 0-6 2.605-6 6.007v7.211h-5.796v-7.21c0-3.403-2.598-6.008-5.997-6.008h-12.593c-3.399 0-5.996 2.605-5.996 6.007v7.211h-5.797v-7.21c0-3.403-2.598-6.008-5.996-6.008H90.754c-3.399 0-5.996 2.605-5.996 6.007v7.211H78.96v-7.21c0-3.403-2.602-6.008-6-6.008H60.367c-3.398 0-5.996 2.605-5.996 6.007v7.211h-5.797v-7.21c0-3.403-2.597-6.008-5.996-6.008H29.984c-3.398 0-5.996 2.605-5.996 6.007v7.211h-7.797c-3.398 0-5.996 2.606-5.996 6.008v6.41c0 3.407 2.598 6.008 5.996 6.008h3.598v82.117h-3.598c-3.398 0-5.996 2.602-5.996 6.008v6.406c0 .801.2 1.403.399 2.004h-.2c-1.199 0-2 .801-2 2.004 0 1.2.801 2.004 2 2.004H191.5c1.2 0 2-.805 2-2.004 0-1.203-1-2.004-2-2.004zm-42.176-122.37c0-1.204.797-2.005 1.996-2.005h12.594c1.2 0 2 .801 2 2.004v7.211h-16.59zm-30.387 0c0-1.204.801-2.005 2-2.005h12.594c1.2 0 2 .801 2 2.004v7.211h-16.594zm-30.582 0c0-1.204.797-2.005 1.997-2.005h12.593c1.2 0 2 .801 2 2.004v7.211h-16.59zm-30.386 0c0-1.204.8-2.005 2-2.005h12.593c1.2 0 2 .801 2 2.004v7.211H57.97zm-30.582 0c0-1.204.797-2.005 2-2.005h12.59c1.199 0 2 .801 2 2.004v7.211h-16.59zM14.19 61.987v-6.41c0-1.2.801-2 2-2H177.11c1.2 0 1.996.8 1.996 2v6.41c0 1.203-.796 2.004-1.996 2.004H16.191c-1 0-2-.8-2-2.004zm9.598 6.008h145.922v82.117H23.789zm-7.598 96.535c-1.199 0-2-.8-2-2.004v-6.406c0-1.203.801-2.004 2-2.004H177.11c1.2 0 1.996.801 1.996 2.004v6.406c0 1.203-.796 2.004-1.996 2.004zm0 0"
        />
      </g>
      <g clipPath="url(#ChargeabilityIcon_svg__d)">
        <path
          fill="#2F1212"
          d="M3.598 164.531H2c-1.2 0-2 .801-2 2.004 0 1.2.8 2.004 2 2.004h1.598c1.199 0 2-.805 2-2.004 0-1.203-.801-2.004-2-2.004zm0 0"
        />
      </g>
      <path
        fill="#2F1212"
        d="M112.742 98.84h-9.594l11.391-17.223c.602-1.004.8-2.406.203-3.605-.601-1.203-1.8-1.805-3-1.805H95.551c-1.399 0-2.598.8-3.2 2.004L77.56 109.254c-.598 1-.399 2.402.203 3.406.597 1 1.797 1.602 2.996 1.602h8.195l-8.996 22.633c-.598 1.601 0 3.402 1.402 4.203.598.402 1.2.402 1.797.402 1 0 2-.402 2.598-1.203l29.586-36.05c.8-1 1-2.403.398-3.606-.398-1-1.597-1.801-2.996-1.801zm-27.785 36.45l7.996-20.227c.399-1 .2-2.204-.402-3.204-.598-1.004-1.797-1.605-2.797-1.605h-8.195l14.39-30.04h14.793L99.348 97.439c-.598 1-.797 2.402-.2 3.605.602 1.203 1.801 1.8 3 1.8h9.395zm0 0"
      />
      <g mask="url(#ChargeabilityIcon_svg__e)">
        <g clipPath="url(#ChargeabilityIcon_svg__f)" transform="translate(4)">
          <g clipPath="url(#ChargeabilityIcon_svg__g)">
            <path
              fill="#2F1212"
              d="M8.395 2.504c-.602 0-1.2-.2-1.602-.8L4.195-1.302c-.597-.8-.597-2.004.399-2.804.8-.801 2.199-.602 2.8.203L9.993-.898c.801.8.602 2.203-.199 2.8-.602.403-1 .602-1.398.602zm0 0"
            />
          </g>
          <path
            fill="#2F1212"
            d="M16.79 12.52c-.599 0-1.2-.2-1.599-.801l-2.398-3.004c-.8-.8-.602-2.004.2-2.805.796-.8 2.198-.601 2.796.2l2.598 3.003c.8.801.601 2.203-.2 2.805-.597.402-1 .602-1.398.602zm0 0"
          />
          <g clipPath="url(#ChargeabilityIcon_svg__h)">
            <path
              fill="#2F1212"
              d="M14.79 3.105c-.599 0-1.2-.199-1.599-.8-.8-.801-.601-2.203.2-2.805l3-2.602c.797-.8 2.199-.601 2.797.2.8.8.601 2.203-.2 2.804l-3 2.602c-.199.402-.797.601-1.199.601zm0 0"
            />
          </g>
          <path
            fill="#2F1212"
            d="M4.797 11.516c-.602 0-1.2-.2-1.602-.801-.597-.8-.597-2 .403-2.8l2.996-2.606c.8-.801 2.199-.602 2.8.199.797.8.598 2.203-.203 2.804l-2.996 2.606c-.398.398-1 .598-1.398.598zm0 0"
          />
        </g>
      </g>
      <g mask="url(#ChargeabilityIcon_svg__i)">
        <g clipPath="url(#ChargeabilityIcon_svg__j)" transform="translate(172)">
          <g clipPath="url(#ChargeabilityIcon_svg__k)">
            <path
              fill="#2F1212"
              d="M7.707 3.906c-.602 0-1.2-.199-1.598-.8L3.508.101C2.71-.7 2.91-2.102 3.71-2.703c.996-.602 2.398-.399 2.996.402L9.305.703c.8.8.601 2.203-.2 2.805-.398.398-1 .398-1.398.398zm0 0"
            />
          </g>
          <g clipPath="url(#ChargeabilityIcon_svg__l)">
            <path
              fill="#2F1212"
              d="M16.102 13.922c-.598 0-1.2-.203-1.598-.8l-2.598-3.005c-.8-.805-.601-2.203.2-2.805.8-.8 2.199-.601 2.796.2l2.602 3.004c.797.8.598 2.203-.203 2.804-.2.602-.598.602-1.2.602zm0 0"
            />
          </g>
          <g clipPath="url(#ChargeabilityIcon_svg__m)">
            <path
              fill="#2F1212"
              d="M14.105 4.508c-.601 0-1.199-.2-1.601-.801-.8-.8-.598-2.203.2-2.805l3-2.601c1-.602 2.398-.403 3 .398C19.5-.5 19.3.902 18.5 1.504l-2.996 2.601c-.402.204-.8.403-1.399.403zm0 0"
            />
          </g>
          <path
            fill="#2F1212"
            d="M4.11 12.918c-.602 0-1.2-.2-1.598-.8-.801-.802-.602-2.204.199-2.806l2.996-2.601c.8-.8 2.2-.602 2.8.2.798.8.598 2.202-.198 2.804l-3 2.605c-.2.598-.602.598-1.2.598zm0 0"
          />
        </g>
      </g>
      <g mask="url(#ChargeabilityIcon_svg__n)">
        <g clipPath="url(#ChargeabilityIcon_svg__o)" transform="translate(80 7)">
          <path
            fill="#2F1212"
            d="M7.355 8.523c-.601 0-1.199-.199-1.601-.8L3.156 4.719c-.797-.801-.597-2.203.2-2.805.8-.8 2.199-.601 2.8.2l2.598 3.003c.8.801.601 2.203-.2 2.805-.398.402-.8.601-1.199.601zm0 0M15.75 18.535c-.598 0-1.2-.2-1.598-.8l-2.601-3.005c-.797-.8-.598-2.203.203-2.8.797-.805 2.2-.602 2.797.199l2.597 3.004c.801.8.602 2.203-.199 2.805-.398.398-.8.597-1.199.597zm0 0"
          />
          <g clipPath="url(#ChargeabilityIcon_svg__p)">
            <path
              fill="#2F1212"
              d="M13.75 9.125c-.598 0-1.2-.203-1.598-.8-.8-.802-.601-2.204.2-2.805l3-2.606c.796-.8 2.199-.602 2.796.203.801.801.602 2.203-.199 2.801l-3 2.605c-.199.399-.797.602-1.199.602zm0 0"
            />
          </g>
          <path
            fill="#2F1212"
            d="M3.758 17.535c-.602 0-1.2-.2-1.602-.8-.797-.801-.597-2.204.203-2.805l2.996-2.602c.801-.8 2.2-.601 2.801.2.797.8.598 2.202-.203 2.804l-2.996 2.602c-.2.402-.8.601-1.2.601zm0 0"
          />
        </g>
      </g>
      <path fill="#041F60" d="M36.875 90.594H57.57v3.144H36.875zm0 0" />
      <g clipPath="url(#ChargeabilityIcon_svg__q)">
        <path fill="#041F60" d="M36.875 84.68H57.57v3.14H36.875zm0 0" />
      </g>
      <path fill="#041F60" d="M57.57 117.441V98.094H36.875v19.347h-6.71l17.058 16.965 17.054-16.965zm0 0" />
      <path
        fill="#004AAD"
        d="M36.875 92.164H57.57v1.574H36.875zm0 0M36.875 86.25H57.57v1.57H36.875zm0 0M31.742 119.012l15.48 15.394L62.7 119.012H57.57V99.664H36.875v19.348zm0 0"
      />
      <path fill="#004AAD" d="M36.875 124.012h-.105l10.453 10.394 10.449-10.394h-.102v-19.348H36.875zm0 0" />
      <path
        strokeLinecap="round"
        transform="matrix(.40214 0 0 .4 21.082 83.542)"
        fill="none"
        strokeLinejoin="round"
        d="M39.271 17.628h51.463v7.862H39.27zm0 0"
        stroke="#33363A"
        strokeWidth={3}
        strokeMiterlimit={10}
      />
      <g clipPath="url(#ChargeabilityIcon_svg__r)">
        <path
          strokeLinecap="round"
          transform="matrix(.40214 0 0 .4 21.082 83.542)"
          fill="none"
          strokeLinejoin="round"
          d="M39.271 2.843h51.463v7.852H39.27zm0 0"
          stroke="#33363A"
          strokeWidth={3}
          strokeMiterlimit={10}
        />
      </g>
      <g clipPath="url(#ChargeabilityIcon_svg__s)">
        <path
          strokeLinecap="round"
          transform="matrix(.40214 0 0 .4 21.082 83.542)"
          fill="none"
          strokeLinejoin="round"
          d="M90.734 84.748v-48.37H39.27v48.37H22.583l42.42 42.412 42.409-42.412zm0 0"
          stroke="#33363A"
          strokeWidth={3}
          strokeMiterlimit={10}
        />
      </g>
      <path
        fill="#5CE1E6"
        d="M44.672 112.914v-4.84c0-1.664-.918-2.62-2.512-2.62-1.594 0-2.508.956-2.508 2.62v4.84c0 1.668.914 2.625 2.508 2.625 1.594 0 2.512-.957 2.512-2.625zm-3.004-4.941c0-.61.254-.66.492-.66.242 0 .492.078.492.66v5.047c0 .582-.246.66-.492.66-.238 0-.492-.051-.492-.66zm0 0M50.984 105.676a.26.26 0 00-.218-.117H49.39a.264.264 0 00-.25.168l-5.84 14.726a.283.283 0 00.027.25c.05.07.133.113.223.113h1.375c.113 0 .21-.066.25-.168l5.836-14.726a.252.252 0 00-.028-.246zm0 0M52.281 110.84c-1.593 0-2.508.953-2.508 2.62v4.84c0 1.669.914 2.626 2.508 2.626 1.598 0 2.512-.957 2.512-2.625v-4.84c0-1.668-.914-2.621-2.512-2.621zm-.492 2.515c0-.605.254-.656.492-.656.246 0 .492.078.492.656v5.051c0 .582-.246.66-.492.66-.238 0-.492-.054-.492-.66zm0 0"
      />
      <path
        strokeLinecap="round"
        transform="matrix(.40214 0 0 .4 21.082 83.542)"
        fill="#FFF"
        strokeLinejoin="round"
        d="M57.99 69.972v-12.1c0-3.788-1.943-5.888-5.576-5.888-3.633 0-5.576 2.1-5.576 5.889v12.1c0 3.788 1.943 5.888 5.576 5.888 3.633 0 5.575-2.1 5.575-5.889zm-7.47.264V57.609c0-1.63.68-2.315 1.894-2.315 1.156 0 1.894.694 1.894 2.315v12.627c0 1.63-.738 2.314-1.894 2.314-1.214 0-1.894-.683-1.894-2.314zm0 0M70.394 52.248L55.872 89.064h3.419l14.522-36.816zm0 0M77.582 65.45c-3.624 0-5.576 2.1-5.576 5.89v12.09c0 3.788 1.952 5.898 5.576 5.898 3.632 0 5.575-2.11 5.575-5.899V71.34c0-3.789-1.943-5.888-5.575-5.888zm1.894 18.253c0 1.63-.729 2.314-1.894 2.314-1.215 0-1.885-.683-1.885-2.314V71.076c0-1.631.67-2.315 1.885-2.315 1.165 0 1.894.684 1.894 2.315zm0 0"
        stroke="#FFF"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <g clipPath="url(#ChargeabilityIcon_svg__t)">
        <path
          strokeLinecap="round"
          transform="matrix(.40214 0 0 .4 21.082 83.542)"
          fill="none"
          strokeLinejoin="round"
          d="M113.58 67.609l-4.614 4.62-4.624-4.62"
          stroke="#33363A"
          strokeWidth={3}
          strokeMiterlimit={10}
        />
      </g>
      <path
        strokeLinecap="round"
        transform="matrix(.40214 0 0 .4 21.082 83.542)"
        fill="none"
        strokeLinejoin="round"
        d="M108.956 71.525V60.773M108.956 55.636v-.85M108.956 50.285v-.85M108.956 44.933v-.85M108.956 39.591v-.85M108.956 34.24v-.85M25.653 67.609l-4.614 4.62-4.624-4.62M21.039 71.525V60.773M21.039 55.636v-.85M21.039 50.285v-.85M21.039 44.933v-.85M21.039 39.591v-.85M21.039 34.24v-.85"
        stroke="#33363A"
        strokeWidth={3}
        strokeMiterlimit={10}
      />
      <path fill="#041F60" d="M155.879 128.727h-20.695v-3.141h20.695zm0 0M155.879 134.645h-20.695v-3.141h20.695zm0 0" />
      <g clipPath="url(#ChargeabilityIcon_svg__u)">
        <path fill="#041F60" d="M135.184 101.883v19.344h20.695v-19.344h6.71l-17.058-16.965-17.054 16.965zm0 0" />
      </g>
      <path fill="#004AAD" d="M155.879 127.156h-20.695v-1.57h20.695zm0 0M155.879 133.074h-20.695v-1.57h20.695zm0 0" />
      <g clipPath="url(#ChargeabilityIcon_svg__v)">
        <path fill="#004AAD" d="M161.008 100.313L145.53 84.918l-15.476 15.394h5.129v19.344h20.695v-19.344zm0 0" />
      </g>
      <g clipPath="url(#ChargeabilityIcon_svg__w)">
        <path fill="#004AAD" d="M155.879 95.313h.105l-10.453-10.395-10.449 10.394h.102v19.348h20.695zm0 0" />
      </g>
      <path
        strokeLinecap="round"
        transform="matrix(-.40214 0 0 -.4 171.671 135.782)"
        fill="none"
        strokeLinejoin="round"
        d="M39.27 17.638h51.463v7.851H39.27zm0 0"
        stroke="#33363A"
        strokeWidth={3}
        strokeMiterlimit={10}
      />
      <g clipPath="url(#ChargeabilityIcon_svg__x)">
        <path
          strokeLinecap="round"
          transform="matrix(-.40214 0 0 -.4 171.671 135.782)"
          fill="none"
          strokeLinejoin="round"
          d="M39.27 2.843h51.463v7.851H39.27zm0 0"
          stroke="#33363A"
          strokeWidth={3}
          strokeMiterlimit={10}
        />
      </g>
      <g clipPath="url(#ChargeabilityIcon_svg__y)">
        <path
          strokeLinecap="round"
          transform="matrix(-.40214 0 0 -.4 171.671 135.782)"
          fill="none"
          strokeLinejoin="round"
          d="M90.733 84.747v-48.36H39.27v48.36H22.582l42.42 42.412 42.409-42.412zm0 0"
          stroke="#33363A"
          strokeWidth={3}
          strokeMiterlimit={10}
        />
      </g>
      <path
        fill="#5CE1E6"
        d="M148.082 106.41v4.84c0 1.664.918 2.621 2.512 2.621 1.594 0 2.508-.957 2.508-2.621v-4.84c0-1.668-.915-2.625-2.508-2.625-1.594 0-2.512.957-2.512 2.625zm3.004 4.942c0 .609-.254.66-.492.66-.246 0-.496-.078-.496-.66v-5.047c0-.582.25-.66.496-.66.238 0 .492.05.492.66zm0 0M141.77 113.648a.26.26 0 00.218.118h1.375c.11 0 .211-.067.25-.168l5.84-14.727a.272.272 0 00-.031-.25.261.261 0 00-.219-.113h-1.379a.263.263 0 00-.246.168l-5.84 14.726a.263.263 0 00.032.246zm0 0M140.469 108.484c1.597 0 2.511-.957 2.511-2.62v-4.84c0-1.669-.914-2.626-2.511-2.626-1.594 0-2.508.957-2.508 2.625v4.84c0 1.668.914 2.621 2.508 2.621zm.496-2.515c0 .605-.254.656-.496.656s-.492-.078-.492-.656v-5.051c0-.582.25-.66.492-.66s.496.05.496.66zm0 0"
      />
      <path
        strokeLinecap="round"
        transform="matrix(-.40214 0 0 -.4 171.671 135.782)"
        fill="#FFF"
        strokeLinejoin="round"
        d="M57.988 69.972v-12.1c0-3.78-1.942-5.889-5.575-5.889s-5.576 2.11-5.576 5.889v12.1c0 3.789 1.943 5.888 5.576 5.888 3.633 0 5.575-2.1 5.575-5.888zm-7.47.263V57.608c0-1.63.69-2.314 1.895-2.314 1.156 0 1.894.693 1.894 2.314v12.627c0 1.631-.738 2.315-1.894 2.315-1.214 0-1.894-.684-1.894-2.315zm0 0M70.393 52.247L55.87 89.063H59.3l14.512-36.816zm0 0M77.59 65.45c-3.633 0-5.585 2.1-5.585 5.889v12.1c0 3.779 1.952 5.888 5.585 5.888 3.623 0 5.566-2.1 5.566-5.889v-12.1c0-3.788-1.943-5.888-5.566-5.888zm1.885 18.252c0 1.63-.729 2.314-1.885 2.314-1.214 0-1.894-.683-1.894-2.314V71.075c0-1.63.68-2.314 1.894-2.314 1.156 0 1.885.683 1.885 2.314zm0 0"
        stroke="#FFF"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <g clipPath="url(#ChargeabilityIcon_svg__z)">
        <path
          strokeLinecap="round"
          transform="matrix(-.40214 0 0 -.4 171.671 135.782)"
          fill="none"
          strokeLinejoin="round"
          d="M113.579 67.608l-4.614 4.62-4.624-4.62"
          stroke="#33363A"
          strokeWidth={3}
          strokeMiterlimit={10}
        />
      </g>
      <path
        strokeLinecap="round"
        transform="matrix(-.40214 0 0 -.4 171.671 135.782)"
        fill="none"
        strokeLinejoin="round"
        d="M108.955 71.524V60.772M108.955 55.636v-.85M108.955 50.284v-.85M108.955 44.942v-.85M108.955 39.59v-.849M108.955 34.24v-.85"
        stroke="#33363A"
        strokeWidth={3}
        strokeMiterlimit={10}
      />
      <g clipPath="url(#ChargeabilityIcon_svg__A)">
        <path
          strokeLinecap="round"
          transform="matrix(-.40214 0 0 -.4 171.671 135.782)"
          fill="none"
          strokeLinejoin="round"
          d="M25.652 67.608l-4.614 4.62-4.624-4.62"
          stroke="#33363A"
          strokeWidth={3}
          strokeMiterlimit={10}
        />
      </g>
      <path
        strokeLinecap="round"
        transform="matrix(-.40214 0 0 -.4 171.671 135.782)"
        fill="none"
        strokeLinejoin="round"
        d="M21.038 71.524V60.772M21.038 55.636v-.85M21.038 50.284v-.85M21.038 44.942v-.85M21.038 39.59v-.849M21.038 34.24v-.85"
        stroke="#33363A"
        strokeWidth={3}
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default SvgChargeabilityIcon;
