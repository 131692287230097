import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { IotIcon } from '../../assets/icons/';
import { setIot } from '../../iot/actions/iotActions';

const SideBarIotItem = (props) => {
  const { item, divider } = props;
  const handleSetIot = () => {
    props.setIot({ item: item });
  };
  return (
    <ListItem button divider={divider} key={props.id} onClick={handleSetIot}>
      <ListItemIcon>
        <IotIcon height="30px" width="30px" />
      </ListItemIcon>

      <ListItemText>{item.name} </ListItemText>
    </ListItem>
  );
};

const mapDispatchToProps = {
  setIot,
};

export default connect(null, mapDispatchToProps)(SideBarIotItem);
