import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { loadImage } from '../actions/installationActions';
import RenderDistribution from './RenderDistribution';

const RealDistribution = (props) => {
  useEffect(() => {
    props.loadImage(props.installation?.urlDiagram);
  }, []);

  return <RenderDistribution />;
};

const mapStateToProps = (state) => {
  return {
    installation: state.installationReducer.selectedInstallation,
    isRequiredImage: state.installationReducer.imageShowed
      ? appConstants.IS_NOT_REQUIRED_LOAD_IMAGE
      : appConstants.IS_REQUIRED_LOAD_IMAGE,
  };
};

const mapDispatchToProps = {
  loadImage,
};

export default connect(mapStateToProps, mapDispatchToProps)(RealDistribution);
