import { Box, Button, Grid, Modal, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CancelIcon, EnergyIcon, TreeEnergyIcon } from '../../assets/icons';
import '../../assets/styles/App.scss';

const ModalInfoCreg = (props) => {
  const [open, setOpen] = useState(false);

  const changeOpen = (event) => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);
  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <Box className="mt-20px center-info">
      <Grid container spacing={2} className="size-modal">
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info bg-co2-secundary">
          <Typography className="ff-poppins fs-info fc-white t-center mt-20px">
            COMO INTERPRETAR LA PENALIZACIÓN DEL EXCESO DE ENERGÍA REACTIVA - CREG 015 DE 2018
          </Typography>
          <Grid className="icon-co2">
            <EnergyIcon height={300} width={200} />
          </Grid>
        </Grid>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info bg-co2-primary ">
          <Grid className="icon-co2">
            <TreeEnergyIcon height={450} width={200} />
          </Grid>
          <Grid>
            <Typography className="ff-poppins fc-white fs-t-text t-justify center-midd mt-20px">
              Queremos explicarle de forma clara y sencilla, el proceso de penalización económica por consumo elevado de
              potencia reactiva, a los que puede incurrir al ser usuario perteneciente al STN (Sistema de transmisión
              Nacional) de Colombia.
            </Typography>
            <Typography className="ff-poppins fc-white fs-t-text t-justify mt-20px">
              Creamos un documento detallado, donde iniciamos abordando el concepto y las implicaciones de consumo de
              potencia reactiva y a su vez, se realiza una análisis normativo que evalúa los decretos que han sido
              emitidos por la CREG (Comisión de Regulación de energía y gas) enfocados en esta temática.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info bg-co2-primary">
          <Grid>
            <Typography className="ff-poppins fc-white fs-title-graphic">
              <Link className="fc-white" href="https://www.glingenieros.com.co/">
                Si requiere más información presiona Clic
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={6} className="center-info bg-co2-secundary">
          <Grid item xl={10} lg={10} md={10} xs={10} sm={10}>
            <Typography className="ff-poppins   fc-text-co2-primary t-center">
              "GLEM, LA HERRAMIENTA PARA UNA RED ELÉCTRICA MÁS INTELIGENTE” GLEM ES: GL ENERGY MANAGEMENT
            </Typography>
          </Grid>
          <Grid item xl={2} lg={2} md={2} xs={2} sm={2} className="center-right bg-co2-secundary">
            <Button onClick={handleClose}>
              <CancelIcon className="mr-2" height="25px" width="25px" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <>
      <Tooltip title="Click para mas información" aria-label="add">
        <Button onClick={changeOpen}>
          <InfoIcon style={{ color: green[500] }} />
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={changeOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="o-scroll"
      >
        {body}
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isOpen: state.userReducer.currentUser && state.userReducer.currentUser.changePassword === true ? true : false,
  };
};

export default connect(mapStateToProps, null)(ModalInfoCreg);
