import { Box, Grid } from '@material-ui/core';
import 'c3/c3.css';
import React from 'react';
import C3Chart from 'react-c3js';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../dashboard/styles/dashboard.scss';
import { convertDate } from '../../service/util';

class BarReport extends React.Component {
  constructor(props) {
    super(props);

    this.info = {
      updateIn: 5,
      frecuency: '',
      average: 0,
      current: undefined,
    };
    this.state = {
      graphic: {
        data: {
          columns: [[props.field.name]],
          type: 'bar',
        },
        bar: {
          width: {
            ratio: 0.5,
          },
        },

        tooltip: {
          format: {
            title: function (x) {
              return x;
            },
          },
        },
      },

      valuesHistory: this.props.valuesPerField && this.props.valuesPerField[this.props.field?.name],
    };

    this.ref = {};

    this.timeOutRefres = undefined;
    this.reload = true;
    this.subscription = [];
  }
  getMedida = () => {
    if (this.props.field?.masterField?.unitMeasurement === '') {
      return '';
    }
    if (this.props.field?.masterField?.unitMeasurement === 'NA') {
      return '';
    }
    if (this.props.field?.masterField?.unitMeasurement === 'PORCENTAJE') {
      return '%';
    }
    return this.props.field?.masterField?.unitMeasurement;
  };
  getFilter = () => {
    let from = new Date(Date.now() - this.info.frecuency * 60000);
    let fromFormatted = convertDate(from);
    let currentDate = convertDate(new Date(Date.now()));
    return {
      page: 0,
      size: 300,
      from: fromFormatted,
      to: currentDate,
      filters: { fieldId: this.props.field.id, deviceId: this.props.field.device.id },
    };
  };

  getFilterByField = () => {
    let from = new Date(Date.now() - this.info.frecuency * 60000);
    let fromFormatted = convertDate(from);
    let currentDate = convertDate(new Date(Date.now()));
    return {
      page: 0,
      size: 3000,
      from: fromFormatted,
      to: currentDate,
      filters: [this.props.field],
    };
  };

  handleDownload = (event) => {
    this.props.downloadTrend(this.getFilter());
  };

  getValuesSerie(serie) {
    return serie.content.content.map((element) => {
      return Number(element.value);
    });
  }

  putInfoInGraphic() {
    let values = this.props.valuesPerField[this.props.field?.name]?.series;
    this.reload = false;
    if (values?.length >= 2) {
      let dataColumns = [
        [values[0].name, ...this.getValuesSerie(values[0])],
        [values[1].name, ...this.getValuesSerie(values[1])],
      ];
      this.reload = true;
      let translate = {
        data: {
          columns: dataColumns,
          type: 'bar',
        },
        bar: {
          width: {
            ratio: 0.5,
          },
        },
      };

      this.setState({ graphic: translate, valuesHistory: { series: values } });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const currentSerie = this.props.valuesPerField[this.props.field.name]?.series;
    const prevSeries = prevState.valuesHistory?.series;

    if (!prevSeries && currentSerie) {
      this.putInfoInGraphic();
    } else if (prevSeries && currentSerie) {
      if (prevSeries.length != currentSerie.length) {
        this.putInfoInGraphic();
      } else {
        if (prevSeries.length == 2) {
          if (
            prevSeries[0].content.length != currentSerie[0].content.length ||
            prevSeries[1].content.length != currentSerie[1].content.length
          ) {
            this.putInfoInGraphic();
          } else {
            if (
              prevSeries[0].content.content[0]?.id != currentSerie[0].content.content[0]?.id ||
              prevSeries[0].content.content[prevSeries[0].content.content.length - 1]?.id !=
                currentSerie[0].content.content[prevSeries[0].content.content.length - 1]?.id ||
              prevSeries[1].content.content[1]?.id != currentSerie[1].content.content[1]?.id ||
              prevSeries[1].content.content[prevSeries[1].content.content.length - 1]?.id !=
                currentSerie[1].content.content[prevSeries[1].content.content.length - 1]?.id
            ) {
              this.putInfoInGraphic();
            }
          }
        }
      }
    }
  }

  render() {
    return (
      <Box height={1} width={1}>
        <Grid container>
          <Box height={9 / 10} width={1} padding={2}>
            {
              <C3Chart
                data={this.state.graphic.data}
                bar={this.state.graphic.bar}
                zoom={{ type: 'scroll', rescale: true, enbaled: true }}
                regions={this.state.graphic.regions}
                tooltip={this.state.graphic.tooltip}
                colors={this.state.graphic.colors}
              />
            }
          </Box>
        </Grid>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    intervals: state.dashboardReducer.intervals ? state.dashboardReducer.intervals : [],
    values: state.dashboardReducer.values ? state.dashboardReducer.values : [],
    valuesPerField: state.dashboardReducer.valuesPerFieldReport ? state.dashboardReducer.valuesPerFieldReport : {},
    isRequiredAddButtons: state.componentsReducer.buttons
      ? appConstants.IS_NOT_REQUIRED_ADD_BUTTON
      : appConstants.IS_REQUIRED_ADD_BUTTON,
  };
};

export default connect(mapStateToProps, undefined)(BarReport);
