import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgEdit(props) {
  const { height, width } = props;
  return (
    <svg  viewBox="0 -80 512 511" xmlns="http://www.w3.org/2000/svg" {...props}
          height={height ? height : appConstants.DEFAULT_HEIGHT}
      width={width ? width : appConstants.DEFAULT_WIDTH}
    >
      <path
        d="M379.734 17.863a15.978 15.978 0 00-6.402-1.324 16.067 16.067 0 00-10.898 4.266L287.766 90.14a16.054 16.054 0 000 23.464l74.668 69.336c4.671 4.329 11.453 5.438 17.3 2.942a16.054 16.054 0 009.598-14.7V32.52c0-6.36-3.773-12.118-9.598-14.657zm0 0"
        fill="#42a5f5"
      />
      <path d="M378.668 352.52h-85.336c-8.832 0-16-7.168-16-16s7.168-16 16-16h85.336C434.539 320.52 480 275.059 480 219.184c0-55.872-45.46-101.332-101.332-101.332-8.832 0-16-7.168-16-16s7.168-16 16-16C452.18 85.852 512 145.672 512 219.184c0 73.515-59.82 133.336-133.332 133.336zm0 0M197.332 352.52H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h181.332c8.832 0 16 7.167 16 16s-7.168 16-16 16zm0 0M197.332 117.852H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h181.332c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0M197.332 235.184H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h181.332c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0" />
      <path d="M378.668 203.184c-4.16 0-8.258-1.621-11.309-4.692l-85.332-85.332c-6.25-6.254-6.25-16.387 0-22.637L367.36 5.191c4.586-4.566 11.457-5.953 17.43-3.476a16.013 16.013 0 019.879 14.781v170.668a16.022 16.022 0 01-9.879 14.785 15.78 15.78 0 01-6.121 1.235zm-62.7-101.332l46.7 46.699V55.152zm0 0" />
    </svg>
  );
}

export default SvgEdit;
