export const appConstants = {
  BACK_DEVICE_TO_IOT: 'back_device_to_iot',
  SOW_IMAGE: 'show_image',
  CHANGE_LOCATION_DEVICE: 'change_location_device',
  //*********************************** */
  CONTEXT_INSTALLATION: 'installation/',
  CONTEXT_INSTALLATIONS: 'installations/',
  CONTEXT_IOT: 'iot/',
  CONTEXT_IOTS: 'iots/',
  CONTEXT_DEVICE: 'device/',
  CONTEXT_DEVICES: 'devices/',
  CONTEXT_FIELD: 'field/',
  CONTEXT_INTEGRATION: 'integration/',
  CONTEXT_FIELDS: 'fields/',
  CONTEXT_FIELDS_DELETE: 'fields-deletes/',
  CONTEXT_COMPANY: 'company/',
  CONTEXT_TREND: 'trends/',
  CONTEXT_HEADQUARTER: 'headquarter/',
  CONTEXT_HEADQUARTERS: 'headquarters/',
  CONTEXT_VALUE: 'value/',
  CONTEXT_VALUES: 'values/',
  CONTEXT_AUTH: 'auth/',
  CONTEXT_SUPPORT: 'support/',
  CONTEXT_USER: 'user/',
  CONTEXT_ALARM: 'alarm/',
  CONTEXT_ALARMS: 'alarms/',
  CONTEXT_USERS: 'users/',
  CONTEXT_FILE: 'file/',
  CONTEXT_ROLE: 'role/',
  CONTEXT_ROLES: 'roles/',
  CONTEXT_COMPANIES: 'companies/',
  CONTEXT_MASTER_FIELDS: 'master-fields/',
  CONTEXT_MASTER_FIELD: 'master-field/',
  CONTEXT_ALARMS_CREG: 'alarms-creg/',
  CONTEXT_LICENSE_CYCLE: 'license/cycle/',
  CONTEXT_LICENSE_CYCLES: 'license/cycles/',
  CONTEXT_LICENSE: 'license/',
  CONTEXT_LICENSES: 'licenses/',
  CONTEXT_RECOVER_PASSWORD: 'recover-password/',
  CONTEXT_REPORT: 'report/',
  CONTEXT_MANUAL: 'manual/',
  CONTEX_DOCUMENTATION: 'documentation/',
  CONTEXT_LICENSE_HISTORY: 'license-history/',
  CONTEXT_LICENSES_HISTORIES: 'licenses-histories/',
  CONTEXT_CREG_BEACH: 'creg-breach/',
  CONTEXT_CONFIG_HOME: 'setting/home/',
  CONTEXT_NOTIFICATION: 'notification/',

  //*********************************** */

  POST_METHOD: 'POST',
  PUT_METHOD: 'PUT',
  IOT_CHANGE: 'changeIot',
  IOT_SAVE: 'saveIot',
  //*********************************** */

  SUPPORT_CHANGE: 'changeSuport',
  SUPPORT_SAVE: 'saveSuport',

  /******************* */

  IS_REQUIRED_IOT_LIST: 'IS_REQUIRED_IOT_LIST',
  IS_NOT_REQUIRED_IOT_LIST: 'IS_NOT_REQUIRED_IOT_LIST',
  IS_REQUIRED_DEVICE_LIST: 'IS_REQUIRED_DEVICE_LIST',
  IS_NOT_REQUIRED_DEVICE_LIST: 'IS_NOT_REQUIRED_DEVICE_LIST',
  IS_NOT_REQUIRED_VALUE_LIST: 'IS_NOT_REQUIRED_VALUE_LIST',
  IS_REQUIRED_VALUE_LIST: 'IS_REQUIRED_VALUE_LIST',
  IS_REQUIRED_FIELDS_LIST: 'IS_REQUIRED_FIELDS_LIST',
  IS_NOT_REQUIRED_FIELDS_LIST: 'IS_NOT_REQUIRED_FIELDS_LIST',
  IS_NOT_REQUIRED_DASHBOARD_INTERVALS: 'IS_NOT_REQUIRED_DASHBOARD_INTERVALS',
  IS_REQUIRED_DASHBOARD_INTERVALS: 'IS_REQUIRED_DASHBOARD_INTERVALS',
  IS_NOT_REQUIRED_ADD_BUTTON: 'IS_NOT_REQUIRED_ADD_BUTTON',
  IS_REQUIRED_ADD_BUTTON: 'IS_REQUIRED_ADD_BUTTON',
  IS_REQUIRED_COMPANY: 'IS_REQUIRED_COMPANY',
  IS_NOT_REQUIRED_COMPANY: 'IS_NOT_REQUIRED_COMPANY',
  IS_REQUIRED_HEADQUARTERS: 'IS_REQUIRED_HEADQUARTERS',
  IS_NOT_REQUIRED_HEADQUARTERS: 'IS_NOT_REQUIRED_HEADQUARTERS',
  IS_REQUIRED_USER_LIST: 'IS_REQUIRED_USER_LIST',
  IS_NOT_REQUIRED_USER_LIST: 'IS_NOT_REQUIRED_USER_LIST',
  IS_REQUIRED_ALARMS: 'IS_REQUIRED_ALARMS',
  IS_NOT_REQUIRED_ALARMS: 'IS_NOT_REQUIRED_ALARMS',
  IS_REQUIRED_INSTALLATION_LIST: 'IS_REQUIRED_INSTALLATION_LIST',
  IS_NOT_REQUIRED_INSTALLATION_LIST: 'IS_NOT_REQUIRED_INSTALLATION_LIST',
  IS_NOT_REQUIRED_LOAD_IMAGE: 'IS_NOT_REQUIRED_LOAD_IMAGE',
  IS_REQUIRED_LOAD_IMAGE: 'IS_REQUIRED_LOAD_IMAGE',
  IS_REQUIRED_CURRENT_USER: 'IS_REQUIRED_CURRENT_USER',
  IS_NOT_REQUIRED_CURRENT_USER: 'IS_NOT_REQUIRED_CURRENT_USER',
  IS_NOT_REQUIRED_ROLE_LIST: 'IS_NOT_REQUIRED_ROLE_LIST',
  IS_REQUIRED_ROLE_LIST: 'IS_REQUIRED_ROLE_LIST',
  IS_NOT_REQUIRED_MENU_ROUTE_LIST: 'IS_NOT_REQUIRED_MENU_ROUTE_LIST',
  IS_REQUIRED_MENU_ROUTE_LIST: 'IS_REQUIRED_MENU_ROUTE_LIST',
  IS_NOT_REQUIRED_INTEGRATION_PAGE: 'IS_NOT_REQUIRED_INTEGRATION_PAGE',
  IS_REQUIRED_INTEGRATION_PAGE: 'IS_REQUIRED_INTEGRATION_PAGE',
  IS_REQUIRED_COMPANY_LIST: 'IS_REQUIRED_COMPANY_LIST',
  IS_NOT_REQUIRED_COMPANY_LIST: 'IS_NOT_REQUIRED_COMPANY_LIST',
  IS_REQUIRED_MASTER_FIELDS_LIST: 'IS_REQUIRED_MASTER_FIELDS_LIST',
  IS_NOT_REQUIRED_MASTER_FIELDS_LIST: 'IS_NOT_REQUIRED_MASTER_FIELDS_LIST',
  IS_REQUIRED_MASTER_FIELDS_UNITS: 'IS_REQUIRED_MASTER_FIELDS_UNITS',
  IS_NOT_REQUIRED_MASTER_FIELDS_UNITS: 'IS_NOT_REQUIRED_MASTER_FIELDS_UNITS',
  IS_NOT_REQUIRED_MASTER_FIELDS_GRAPHICS: 'IS_NOT_REQUIRED_MASTER_FIELDS_GRAPHICS',
  IS_REQUIRED_MASTER_FIELDS_GRAPHICS: 'IS_REQUIRED_MASTER_FIELDS_GRAPHICS',
  IS_REQUIRED_MASTER_FIELDS_TYPESIGNAL: 'IS_REQUIRED_MASTER_FIELDS_TYPESIGNAL',
  IS_NOT_REQUIRED_MASTER_FIELDS_TYPESIGNAL: 'IS_NOT_REQUIRED_MASTER_FIELDS_TYPESIGNAL',
  IS_REQUIRED_MASTER_FIELDS_TYPEVALUE: 'IS_REQUIRED_MASTER_FIELDS_TYPEVALUE',
  IS_NOT_REQUIRED_MASTER_FIELDS_TYPEVALUE: 'IS_NOT_REQUIRED_MASTER_FIELDS_TYPEVALUE',
  IS_REQUIRED_MASTER_FIELDS_TYPEVALUE_FILE: 'IS_REQUIRED_MASTER_FIELDS_TYPEVALUE_FILE',
  IS_NOT_REQUIRED_MASTER_FIELDS_TYPEVALUE_FIELD: 'IS_NOT_REQUIRED_MASTER_FIELDS_TYPEVALUE_FIELD',
  IS_REQUIRED_COPANY_LIST: 'IS_REQUIRED_COPANY_LIST',
  IS_REQUIRED_FIELD_LIST: 'IS_REQUIRED_FIELD_LIST',
  IS_REQUIRED_ALARM_LIST: 'IS_REQUIRED_ALARM_LIST',
  IS_REQUIRED_HEADQUARTERS_CLEAN: 'IS_REQUIRED_HEADQUARTERS_CLEAN',
  IS_REQUIRED_HISTORY_ALARMS: 'IS_REQUIRED_HISTORY_ALARMS',
  IS_NOT_REQUIRED_HISTORY_ALARMS: 'IS_NOT_REQUIRED_HISTORY_ALARMS',
  IS_REQUIRED_MASTER_FIELDS_TYPEPROTOCOL: 'IS_REQUIRED_MASTER_FIELDS_TYPEPROTOCOL',
  IS_NOT_REQUIRED_MASTER_FIELDS_TYPEPROTOCOL: 'IS_NOT_REQUIRED_MASTER_FIELDS_TYPEPROTOCOL',
  IS_REQUIRED_LIST_CREG_ALARMS: 'IS_REQUIRED_LIST_CREG_ALARMS',
  IS_REQUIRED_CLASIFICATION: 'IS_REQUIRED_CLASIFICATION',
  IS_NOT_REQUIRED_CLASIFICATION: 'IS_NOT_REQUIRED_CLASIFICATION',
  IS_NOT_REQUIRED_LIST_CREG_ALARMS: 'IS_NOT_REQUIRED_LIST_CREG_ALARMS',
  IS_REQUIRED_SYNC_TRENS: 'IS_REQUIRED_SYNC_TRENS',
  IS_NOT_REQUIRED_SYNC_TRENS: 'IS_NOT_REQUIRED_SYNC_TRENS',
  IS_REQUIRED_LICENSE_CYCLE_LIST: 'IS_REQUIRED_LICENSE_CYCLE_LIST',
  IS_NOT_REQUIRED_LICENSE_CYCLE_LIST: 'IS_NOT_REQUIRED_LICENSE_CYCLE_LIST',
  IS_NOT_REQUIRED_TYPE_LICENSE: 'IS_NOT_REQUIRED_TYPE_LICENSE',
  IS_REQUIRED_TYPE_LICENSE: 'IS_REQUIRED_TYPE_LICENSE',
  IS_REQUIRED_CHANGE_GLOBAL_FRECUENCY: 'IS_REQUIRED_CHANGE_GLOBAL_FRECUENCY',
  IS_NOT_REQUIRED_CHANGE_GLOBAL_FRECUENCY: 'IS_NOT_REQUIRED_CHANGE_GLOBAL_FRECUENCY',
  IS_REQUIERED_TYPE_REPORT: 'IS_REQUIERED_TYPE_REPORT',
  IS_NOT_REQUIERED_TYPE_REPORT: 'IS_NOT_REQUIERED_TYPE_REPORT',
  IS_NOT_REQUIRED_LICENSE_HISTORY_LIST: 'IS_NOT_REQUIRED_LICENSE_HISTORY_LIST',
  IS_REQUIRED_LICENSE_HISTORY_LIST: 'IS_REQUIRED_LICENSE_HISTORY_LIST',
  IS_REQUIRED_FACTORM_FOR_ALL_DEVICE: 'IS_REQUIRED_FACTORM_FOR_ALL_DEVICE',
  IS_NOT_REQUIRED_FACTORM_FOR_ALL_DEVICE: 'IS_NOT_REQUIRED_FACTORM_FOR_ALL_DEVICE',
  IS_NOT_REQUIRED_LIST_CONSOLIDATED_CREG: 'IS_NOT_REQUIRED_LIST_CONSOLIDATED_CREG',
  IS_REQUIRED_LIST_CONSOLIDATED_CREG: 'IS_REQUIRED_LIST_CONSOLIDATED_CREG',
  IS_REQUIRED_TYPE_HOME: 'IS_REQUIRED_TYPE_HOME',
  IS_NOT_REQUIRED_TYPE_HOME: 'IS_NOT_REQUIRED_TYPE_HOME',
  IS_REQUIERED_CONFIG_HOME_LIST: 'IS_REQUIERED_CONFIG_HOME_LIST',
  IS_NOT_REQUIERED_CONFIG_HOME_LIST: 'IS_NOT_REQUIERED_CONFIG_HOME_LIST',
  IS_REQUIERED_NOTIFICATION_LIST: 'IS_REQUIERED_NOTIFICATION_LIST',
  IS_NOT_REQUIERED_NOTIFICATION_LIST: 'IS_NOT_REQUIERED_NOTIFICATION_LIST',
  IS_REQUIRED_IOT_FIND_BY_ID: 'IS_REQUIRED_IOT_FIND_BY_ID',
  IS_NOT_REQUIRED_IOT_FIND_BY_ID: 'IS_NOT_REQUIRED_IOT_FIND_BY_ID',
  IS_REQUIRED_HQ_FIND_BY_ID: 'IS_REQUIRED_HQ_FIND_BY_ID',
  IS_NOT_REQUIRED_HQ_FIND_BY_ID: 'IS_NOT_REQUIRED_HQ_FIND_BY_ID',
  IS_REQUIRED_LICENSE_CYCLE_FIND_BY_ID: 'IS_REQUIRED_LICENSE_CYCLE_FIND_BY_ID',
  IS_NOT_REQUIRED_LICENSE_CYCLE_FIND_BY_ID: 'IS_NOT_REQUIRED_LICENSE_CYCLE_FIND_BY_ID',
  IS_REQUIRED_LOG_OUT: 'IS_REQUIRED_LOG_OUT',
  IS_NOT_REQUIRED_LOG_OUT: 'IS_NOT_REQUIRED_LOG_OUT',
  IS_REQUIRED_SET_TITLE: 'IS_REQUIRED_SET_TITLE',
  IS_NOT_REQUIRED_SET_TITLE: 'IS_NOT_REQUIRED_SET_TITLE',
  //*****************TITLES *//
  GLOBAL_TITLE: 'BIENVENIDOS',
  IOT_VIEW_TITLE: 'MAESTRO DE IOTS',
  DEVICE_VIEW_TITLE: 'MAESTRO DE DISPOSITIVOS',
  FIELD_VIEW_TITLE: 'MAESTRO DE VARIABLES',
  TREND_DASHBOARD_TITLE: 'TRENDS',
  SUPPORT_TITLE: 'SOPORTE',
  USER_VIEW_TITLE: 'CREACIÓN DE USUARIOS',
  INSTALLATION_MENU: 'MENÚ DE INSTALACIONES',
  DISTRIBUTION_TITLE: 'DISTRIBUCIÓN',
  ALARM_VIEW_TITLE: 'CONFIGURACIÓN DE ALARMAS',
  INSTALLATION_VIEW_TITLE: 'VISUALIZACIÓN DE INSTALACIÓN',
  ERROR_TITLE: 'Error',
  SUCCESFUL_SAVED_TITLE: '',
  SUCCESFUL_UPDATE_TITLE: '',
  SUCCESFUL_UPDATED_TITLE: '',
  DASHBOARD_TITLE: 'DASHBOARD',
  ALARM_MENU_TITLE: 'MENÚ ALARMAS',
  AOA_VIEW_TITLE: 'AOA',
  NAS_VIEW_TITLE: 'INTEGRACIÓN CON NAS',
  COMPANY_MENU_TITLE: 'CREACIÓN DE COMPAÑIAS',
  REPORT_VIEW_TITLE: 'GENERADOR DE REPORTES',
  HEADQUARTER_VIEW_TITLE: 'SEDES',
  LICENSE_VIEW_TITLE: 'LICENCIA',
  SUCCESFUL_RECOVER_PASSWORD_TITLE: '',
  MENU_ENERGY_REACTIVE: 'ANÁLISIS ENERGÍA REACTIVA',
  SUCCESFUL_SAVED_MANUAL_TITLE: 'MANUAL',
  ENERGY_PENALTY_TITLE: 'ANÁLISIS DE REACTIVOS',
  CONFIG_HOME_TITLE: 'CONFIGURACION DE INICIO',
  NOTIFICATION_TITLE: 'NOTIFICACIÓN',
  USER_TITLE: 'USUARIO',
  INSTALATION_TITLE: 'INSTALACIÓN',
  TREND_CHARGEABILITY_TITLE: 'CARGABILIDAD',

  //*************************MESSAGE */
  DEFAULT_ERROR: ' Se ha generado una alerta, comuníquese con soporte técnico.',
  SUCCESS_IOT_SAVE: 'IOT guardado exitosamente',
  VALIDATION_ERROR_TITLE: 'Atención',

  DEFAUL_SUBJECT: 'Reporte de falla en la aplicación',
  SUCCES_SUPPORT_SEND: 'El reporte fue enviado exitosamente.',

  MESSAGE_UN_STYLED_DEVICE: 'Existen dispositivos sin ser asignados',
  MESSAGE_SUCCESS_CHANGE_PASSWORD: 'La contraseña se ha cambiado exitosamente',

  SAVE_USER_SUCCESS_MESSAGE: 'El usuario se ha guardado exitosamente',
  UPDATE_USER_SUCCESS_MESSAGE: 'El usuario se ha actualizado exitosamente',
  SAVE_IOT_SUCCESS_MESSAGE: 'El IOT se ha guardado exitosamente',
  UPDATE_IOT_SUCCESS_MESSAGE: 'El IOT se ha actualizado exitosamente',
  SAVE_DEVICE_SUCCESS_MESSAGE: 'El dispositivo se ha guardado exitosamente',
  UPDATE_DEVICE_SUCCESS_MESSAGE: 'El dispositivo se ha actualizado exitosamente',
  SAVE_FIELD_SUCCESS_MESSAGE: 'La variables se han guardado exitosamente',
  UPDATE_FIELD_SUCCESS_MESSAGE: 'La variable se ha actualizado exitosamente',
  SAVE_INSTALLATION_SUCCESS_MESSAGE: 'La instalación se ha guardado exitosamente',
  UPDATE_INSTALLATION_SUCCESS_MESSAGE: 'La instalación se ha  actualizado exitosamente',
  UPDATE_COMPANY_SUCCESS_MESSAGE: 'La compañia se ha actualizado exitosamente',
  SAVE_COMPANY_SUCCESS_MESSAGE: 'La compañia se ha guardado exitosamente',
  SAVE_HEADQUARTER_SUCCESS_MESSAGE: 'La sede se ha guardado exitosamente',
  UPDATE_HEADQUARTER_SUCCESS_MESSAGE: 'La sede se ha actualizado exitosamente',
  DELETE_IOT_SUCCESS_MESSAGE: 'El IOT se ha borrado exitosamente',
  DELETE_DEVICE_SUCCESS_MESSAGE: 'El dispositivo se ha borrado exitosamente',
  DELETE_USER_SUCCESS_MESSAGE: 'El usuario se ha borrado exitosamente',
  DELETE_COMPANY_SUCCESS_MESSAGE: 'La compañia se ha borrado exitosamente',
  DELETE_FIELD_SUCCESS_MESSAGE: 'La variable se ha borrado exitosamente',
  DELETE_HEADQUARTER_SUCCESS_MESSAGE: 'La sede se ha borrado exitosamente',
  DELETE_ALARM_SUCCESS_MESSAGE: 'La alarma se ha borrado exitosamente',
  DELETE_INSTALLATION_SUCCESS_MESSAGE: 'La instalación se ha borrado exitosamente.',
  DELETE_MASTER_FIELD_SUCCESS_MESSAGE: 'La variable maestra se ha borrado exitosamente',
  SAVE_ALARM_SUCCESS_MESSAGE: 'La alarma se ha creado exitosamente',
  UPDATE_ALARM_SUCCESS_MESSAGE: 'La alarma se ha actualizado exitosamente',
  DELETE_LICENSE_CYCLE_SUCCESS_MESSAGE: 'El periodo ha sido eliminado exitosamente',
  UPDATE_LICENSE_CYCLE_SUCCESS_MESSAGE: 'El periodo ha sido actualizado exitosamente',
  SAVE_LICENSE_CYCLE_SUCCESS_MESSAGE: 'El periodo ha sido creada exitosamente',
  DELETE_LICENSE_SUCCESS_MESSAGE: 'La licencia ha sido eliminado exitosamente',
  UPDATE_LICENSE_SUCCESS_MESSAGE: 'La licencia ha sido actualizado exitosamente',
  SAVE_LICENSE_SUCCESS_MESSAGE: 'La licencia ha sido creada exitosamente',
  ACTIVE_COMPANY_LICENSE_SUCCESS_MESSAGE: 'La licencia ha sido activada exitosamente',
  RECOVER_PASSWORD_SUCCESS_MESSAGE: 'La solicitud fue enviada',
  REACTIVE_COMPANY_LICENSE_SUCCESS_MESSAGE: 'La licencia ha sido renovada exitosamente',
  GENERATE_REPORT_MESSAGE: 'Se genero el reporte exitosamente',
  SAVE_MANUAL_MESSAGE: 'Se guardo el manual exitosamente',
  ERROR_MANUAL_MESSAGE: 'Error al guardo el manual',
  DELETE_LICENSE_HISTORY_SUCCESS_MESSAGE: 'La licencia ha sido eliminado exitosamente',
  UPDATE_LICENSE_HISTORY_SUCCESS_MESSAGE: 'La licencia ha sido actualizado exitosamente',
  SAVE_LICENSE_HISTORY_SUCCESS_MESSAGE: 'La licencia ha sido creada exitosamente',
  UPDATE_FACTOR_M: 'Se modifico el factor M y los meses consecutivos exitosamente',
  SAVE_CONFIG_HOME_SUCCESS: 'La configuracion del home se ha guardado exitosamente',
  UPDATE_CONFIG_HOME_SUCCESS: 'La configuracion del home se ha actualizado exitosamente',
  UPDATE_NOTIFICATION_SUCCESS: 'La notificación se elimino correctamente',
  ERROR_MANUAL_DOWNLOAD_MESSAGE: 'Error al descargar el manual',
  WARNING_DELETE: 'Esta seguro que desea eliminar el registro',

  //********************************ICONS */

  ICON_DEFAULT_HEIGHT: '15px',
  ICON_DEFAULT_WIDTH: '15px',

  UNITS_MEASUREMENTS: [
    { nameShowed: 'V - voltio', key: 'V' },
    { nameShowed: 'A - amperio', key: 'A' },
    { nameShowed: 'kW - kilovatio', key: 'KW' },
    { nameShowed: 'kWh - kilovatio hora', key: 'KWH' },
    { nameShowed: 'kVAr- kilovoltio amperio reactivo', key: 'KVAR' },
    { nameShowed: 'kVArh- kilovoltio amperio reactivo hora', key: 'KVARH' },
    { nameShowed: 'kVA - kilovoltio amperio', key: 'KVA' },
    { nameShowed: 'kVAh - kilovoltio amperio hora', key: 'KVAH' },
    { nameShowed: '°C - Celsius', key: '°C' },
    { nameShowed: '% - Porcentaje', key: 'PORCENTAJE' },
    { nameShowed: 'N/A', key: 'NA' },
  ],

  //*********************COLORs */

  COLOR_SECUNDARY: '#3dcd58',
  COLOR_THIRD: '#edf0f7',
  COLOR_GREEN: '#3dcd58',

  //*********************ENTITY */

  IOT_ENTITY: 'ENTITY_IOT',
  DEVICE_ENTITY: 'DEVICE_IOT',

  BACK_BUTTON_ID: 'BACK_BUTTON_ID',
  LOG_OUT_KEY_BUTTON: 'LOG_OUT_KEY_BUTTON',
  MODAL_BUTTON: 'MODAL_BUTTON',
  FILTER_ALARM_BUTTON: 'FILTER_ALARM_BUTTON',
  FILTER_DASHBOARD_BUTTON: 'FILTER_DASHBOARD_BUTTON',
  FILTER_TRENDS_BUTTON: 'FILTER_TRENDS_BUTTON',
  FILTER_INSTALLATION_BUTTON: 'FILTER_INSTALLATION_BUTTON',
  SYNC_TRENS_BUTTON: 'SYNC_TRENS_BUTTON',
  FILTER_CHARGEABILITY: 'FILTER_CHARGEABILITY',

  //****************EVENTS */
  FILTER_EVENT: 'FILTER_EVENT',
  CLOSE_EVENT: 'CLOSE_EVENT',
  CANCEL_EVENT: 'CANCEL_EVENT',
  //****************BUCKET */
  BUCKET_NAME: 'sismo',
  FOLDER_INSTALLATION_IMAGE: 'sismo/',
  ACL: 'public-read',

  //*****************ROLE_NAME */
  SUPERADMIN: 'SUPERADMIN',
  ADMIN: 'ADMIN',
  SUPERVISOR: 'SUPERVISOR',

  //*******report images */

  ITEMS_PER_PAGES: 15,
  ITEMS_PER_PAGES_BAR: 14,

  //****** DASHBOARD */
  BAR_INITIAL_RANGE_COMPARATIVE: 'Rango1',
  BAR_SECOND_RANGE_COMPARATIVE: 'Rango2',

  //*****TYPE_GRAPHYC*/
  BAR: 'BAR',
  TRENDS: 'TRENDS',
  CARGABILIDAD: 'CARGABILIDAD',

  //**DONT_INFO */
  DONT_INFO_MESSAGE: 'Visualizará la última gráfica en la que se obtuvo información',
  DONT_INFO_TITLE: 'No hay datos en este Rango de Tiempo',

  //*****TYPE_REPORT*/
  INDIVIDUAL: 'INDIVIDUAL',
  COMPARATIVO: 'COMPARATIVO',

  //*****TYPE_DEVICE**********/
  PRINCIPAL_DEVICE: 'PRINCIPAL',
  NORMAL_DEVICE: 'NORMAL',

  //**VIEWS  */
  INSTALLATION_VIEW: '/installation',
};
