export const isActive = (license) => {
  return license?.active;
};

export const haveLicense = (company) => {
  if (company?.license) return true;

  return false;
};

export const equalsLicense = (companyId, licenseId) => {
  if (companyId === licenseId) return true;

  return false;
};
