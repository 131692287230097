import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { Link } from 'react-router-dom';
import { ChargeabilityIcon, DashboardIcon, HomeIcon, SupportIcon, TrendIcon } from '../../assets/icons';
import '../../assets/styles/App.scss';
export const menuSupervisor = (
  <div>
    <Link to="/" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <HomeIcon height={30} width={30} />
        </ListItemIcon>
        <ListItemText primary="Inicio" className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>
    <Link to="/trends" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <TrendIcon height={30} width={30} />
        </ListItemIcon>
        <ListItemText primary="Trends" className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>
    <Link to="/dashboard" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon height={30} width={30} />
        </ListItemIcon>
        <ListItemText primary="Dashboard" className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>

    <Link to="/chargeability" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <ChargeabilityIcon height={42} width={38} />
        </ListItemIcon>
        <ListItemText primary="Cargabilidad" className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>

    <Link to="/support" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <SupportIcon height={30} width={30} />
        </ListItemIcon>
        <ListItemText primary="Soporte" className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>
  </div>
);
