import { combineReducers } from 'redux';
import alarmHistoryReducer from '../alarms/reducers/alarmHistoryReducer';
import alarmReducer from '../alarms/reducers/alarmReducer';
import companyReducer from '../company/reducers/companyReducer';
import componentsReducer from '../components/reducers/componentsReducer';
import ConfigHomeReducers from '../configHome/reducers/ConfigHomeReducers';
import dashboardReducer from '../dashboard/reducers/dashboardReducer';
import deviceReducer from '../device/reducers/deviceReducer';
import DocumentationReducer from '../documentation/reducers/DocumentationReducer';
import fieldReducer from '../field/reducers/fieldReducer';
import masterFieldReducer from '../field/reducers/masterFieldReducer';
import headquarterReducer from '../headquarter/reducers/headquarterReducer';
import installationReducer from '../installation/reducers/installationReducer';
import iotReducer from '../iot/reducers/iotReducer';
import licenseCycleReducer from '../license/reducers/licenseCycleReducer';
import licenseHistoryReducer from '../license/reducers/licenseHistoryReducer';
import licenseReducer from '../license/reducers/licenseReducer';

import reportReducers from '../reports/reducers/reportReducers';
import supportReducer from '../support/reducers/supportReducer';
import userReducer from '../user/reducer/userReducer';
import valueReducer from '../values/reducers/valueReducer';
import CregReducer from '../reactiveAnalysis/reducers/CregReducer';

export default combineReducers({
  iotReducer,
  componentsReducer,
  deviceReducer,
  fieldReducer,
  valueReducer,
  userReducer,
  dashboardReducer,
  headquarterReducer,
  companyReducer,
  supportReducer,
  installationReducer,
  alarmReducer,
  alarmHistoryReducer,
  masterFieldReducer,
  licenseCycleReducer,
  licenseReducer,
  reportReducers,
  DocumentationReducer,
  licenseHistoryReducer,
  ConfigHomeReducers,
  CregReducer,
});
