import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import Brush from '@material-ui/icons/Brush';
import Save from '@material-ui/icons/Save';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { commonMessage, icon } from '../../service/messageHandler';
import { updateAlarm } from '../action/alarmActions';
  
  const AlarmEditForm = (props) => {
    const { alarm } = props;
   let field = alarm?.field;
    const [alarmForm, setAlarmForm] = useState({
      name: '',
      mailsToNotify: '',
      cellphoneToNotify: '',
      min: 0,
      max: 0,
      messageMax: '',
      messageMin: '',
      waitTime: 0,
      typeAlarm: '',
    });
    const [type, setType] = React.useState('');
  
    useEffect(() => {
      deserialize();
    }, [alarm]);

    const deserialize = () => {
  
      if (alarm) {
        let valueForm = {};
        let tmpAlarm = alarm.alarm;
        valueForm.name = tmpAlarm.name;
        valueForm.mailsToNotify = tmpAlarm.mailsToNotify;
        valueForm.cellphoneToNotify = tmpAlarm.cellphoneToNotify;
        valueForm.min = tmpAlarm.min;
        valueForm.max = tmpAlarm.max;
        valueForm.messageMax = tmpAlarm.messageMax;
        valueForm.messageMin = tmpAlarm.messageMin;
        valueForm.typeAlarm = tmpAlarm.typeAlarm;
        valueForm.waitTime = tmpAlarm.waitTime;
        valueForm.id = field.id ? field.id : '';
  
        setAlarmForm({
          ...valueForm,
        });
        setType({...valueForm.typeAlarm});
      }
    };
    const handleUpdate = () => {
    
      let previousAlarm = alarm.field;
      let msn = validate();
      if (msn == '') {
      if (previousAlarm?.length === 1) {
        previousAlarm?.alarms?.splice(0, 1, alarmForm);
      } else {
        let position = previousAlarm?.alarms?.indexOf(alarm.alarm);
        previousAlarm?.alarms?.splice(position, 1, alarmForm);
      }  
      
        props.updateAlarm({
          ...previousAlarm,
        });
        handleClean();
      } else {
        commonMessage(msn, appConstants.VALIDATION_ERROR_TITLE, icon.error);
      }
    };
  
    const validate = () => {
      let msn = '';
  
      msn += !alarmForm.name ? 'Debe ingresar el nombre \n' : '';
      msn += !alarmForm.messageMax ? 'Debe ingresar el mensaje del maximo \n' : '';
      msn += !alarmForm.messageMin ? 'Debe ingresar el mensaje del minimo \n' : '';
      msn += !alarmForm.min ? 'Debe ingresar el valor minimo \n' : '';
      msn += !alarmForm.max ? 'Debe ingresar el valor maximo \n' : '';
      msn += !alarmForm.typeAlarm ? 'Debe ingresar el tipo de alarma \n' : '';
      
  
    // msn += validateEmail(alarmForm.mailsToNotify);
  
      if (alarmForm.waiteTime < 0) {
        msn += 'La cantidad del tiempo de espera debe ser positiva';
      }
  
      return msn;
    };
  
    const serializeObject = () => {
      let result = {
        ...alarmForm,
        mailsToNotify: [alarmForm.mailsToNotify],
        cellphoneToNotify: [alarmForm.cellphoneToNotify],
      };
  
      return result;
    };
  
    const handleTypeChange = (event) => {
      setType(event.target.value);
      alarmForm.typeAlarm = event.target.value;
    };
  
    const handleClean = () => {
      setAlarmForm({
        ...alarmForm,
        
        name: '',
        mailsToNotify: '',
        cellphoneToNotify: '',
        min: 0,
        max: 0,
        messageMax: '',
        messageMin: '',
        waitTime: 0,
      });
      setType('');
    };
  
    return (
      <Box width={1} height={1}>
        <Card className="border-10">
          <CardContent>
            <form noValidate={false} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                    <TextField
                      required
                      fullWidth
                      id="nameAlarm"
                      label="Nombre"
                      variant="standard"
                      value={alarmForm.name}
                      onChange={(valueEvent) => setAlarmForm({ ...alarmForm, name: valueEvent.target.value })}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                    <TextField
                      required
                      id="min"
                      label="Mínimo"
                      variant="outlined"
                      type="number"
                      value={alarmForm.min}
                      onChange={(valueEvent) => setAlarmForm({ ...alarmForm, min: valueEvent.target.value })}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box height={1} width={1} boxShadow={5} borderRadius={10}>
                    <TextField
                      required
                      variant="filled"
                      id="unitmin"
                      value={field?.masterField?.unitMeasurement}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                    <TextField
                      fullWidth
                      required
                      id="messageMin"
                      label="Mensaje valor Mínimo"
                      variant="outlined"
                      value={alarmForm.messageMin}
                      inputProps={{
                        maxLength: 200,
                      }}
                      onChange={(valueEvent) => setAlarmForm({ ...alarmForm, messageMin: valueEvent.target.value })}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                    <TextField
                      required
                      id="max"
                      label="Máximo"
                      variant="outlined"
                      type="number"
                      value={alarmForm.max}
                      onChange={(valueEvent) => setAlarmForm({ ...alarmForm, max: valueEvent.target.value })}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                    <TextField
                      fullWidth
                      variant="filled"
                      id="unitmax"
                      value={field?.masterField?.unitMeasurement}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                    <TextField
                      fullWidth
                      id="messageMax"
                      label="Mensaje Máximo"
                      variant="outlined"
                      required
                      value={alarmForm.messageMax}
                      inputProps={{
                        maxLength: 200,
                      }}
                      onChange={(valueEvent) => setAlarmForm({ ...alarmForm, messageMax: valueEvent.target.value })}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="type">Tipo Alarma</InputLabel>
                      <Select
                        required
                        labelId="type"
                        id="typeAlarm"
                        variant="outlined"
                        fullWidth={true}
                        value={type}
                        onChange={handleTypeChange}
                      >
                        <MenuItem value="" key="none">
                          <em>Ninguna</em>
                        </MenuItem>
                        <MenuItem value="inmediata" key="inmediata">
                          Inmediata
                        </MenuItem>
                        <MenuItem value="tiempo" key="tiempo">
                          Tiempo
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                    <TextField
                      fullWidth
                      required
                      id="waiteTime"
                      label="Tiempo de Espera"
                      variant="outlined"
                      type="number"
                      value={alarmForm.waitTime}
                      onChange={(valueEvent) => setAlarmForm({ ...alarmForm, waitTime: valueEvent.target.value })}
                    />
                  </Box>
                </Grid>
  
                <Grid item xs={6}>
                  <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                    <TextField
                      fullWidth
                      id="cellphone"
                      label="Celular a notificar"
                      variant="outlined"
                      inputProps={{
                        maxLength: 50,
                      }}
                      value={alarmForm.cellphoneToNotify}
                      onChange={(valueEvent) => setAlarmForm({ ...alarmForm, cellphoneToNotify: valueEvent.target.value })}
                    />
                  </Box>
                </Grid>
  
                <Grid item xs={6}>
                  <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                    <TextField
                      required
                      fullWidth
                      id="emailNotify"
                      label="Correo a notificar"
                      variant="outlined"
                      inputProps={{
                        maxLength: 50,
                      }}
                      value={alarmForm.mailsToNotify}
                      onChange={(valueEvent) => setAlarmForm({ ...alarmForm, mailsToNotify: valueEvent.target.value })}
                    />
                  </Box>
                </Grid>
              </Grid>
            </form>
          </CardContent>
          <CardActions className="center d-flex">
            <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
              <Button
                className="bg-green-gradient mr-2 pl-5 pr-5"
                color="primary"
                style={{ background: '#e3dafb', borderRadius: '30px', width: '100%', marginRight: '5px' }}
                onClick={handleUpdate}
              >
                <Save />
                Actualizar
              </Button>
  
              <Button
                className="bg-green-gradient mr-2 pl-5 pr-5"
                style={{ background: '#5404b4', color: 'white', borderRadius: '30px', width: '100%' }}
                disabled={alarmForm == {}}
                onClick={handleClean}
              >
                <Brush />
                Limpiar
              </Button>
            </ButtonGroup>
          </CardActions>
        </Card>
      </Box>
    );
  };
  
  const mapStateToProps = (state) => {
    return {
      iot: state.iotReducer.selectedIot ? state.iotReducer.selectedIot : undefined,
      headquarter: state.headquarterReducer.selectedHeadquarter
        ? state.headquarterReducer.selectedHeadquarter
        : undefined,
      device: state.deviceReducer.selectedDevice ? state.deviceReducer.selectedDevice : {},
  
      isRequiredFields: state.fieldReducer.isRequiredFields,
    };
  };
  
  const mapDispatchToProps = {
    updateAlarm,
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(AlarmEditForm);
  