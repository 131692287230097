import { appConstants } from '../../appConstants';
import { commonMessage, errorHandler, icon } from '../../service/messageHandler';
import { licenseHistoryActions } from '../actions/licenseHistoryActions';

const licenseHistoryReducer = (state = {}, action) => {
  switch (action.type) {
    case licenseHistoryActions.FETCHING_LICENSE_HISTORY_SAVE_SUCCESS:
      commonMessage(
        appConstants.SAVE_LICENSE_HISTORY_SUCCESS_MESSAGE,
        appConstants.SUCCESFUL_UPDATE_TITLE,
        icon.success,
      );

      return {
        ...state,
        loading: { open: false },
        selectedLicense: {},
        isRequiredLicenses: appConstants.IS_REQUIRED_LICENSE_HISTORY_LIST,
      };
    case licenseHistoryActions.FETCHING_LICENSE_HISTORY_REQUEST_LIST:
      return {
        ...state,
        isRequiredLicenses: appConstants.IS_NOT_REQUIRED_LICENSE_HISTORY_LIST,
        loading: { open: true },
      };
    case licenseHistoryActions.FETCHING_LICENSE_HISTORY_REQUEST:
      return state;
    case licenseHistoryActions.FETCHING_LICENSE_HISTORY_FAILURE:
      errorHandler(action);
      return {
        ...state,
        isRequiredLicenses: appConstants.IS_NOT_REQUIRED_LICENSE_HISTORY_LIST,
      };
    case licenseHistoryActions.FETCHING_LICENSE_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        avaliabledLicenses: action.payload.content ? action.payload.content : [],
        isRequiredLicenses: appConstants.IS_NOT_REQUIRED_LICENSE_HISTORY_LIST,
        loading: { open: false },
      };
    case licenseHistoryActions.CLEAN_LICENSE_HISTORY_STORE:
      return {
        ...state,
        avaliabledLicenses: [],
        isRequiredLicenses: appConstants.IS_REQUIRED_LICENSE_HISTORY_LIST,
        selectedLicense: undefined,
      };
    case licenseHistoryActions.FETCHING_LICENSE_HISTORY_UPDATE_SUCCESS:
      commonMessage(
        appConstants.UPDATE_LICENSE_HISTORY_SUCCESS_MESSAGE,
        appConstants.SUCCESFUL_UPDATE_TITLE,
        icon.success,
      );
      return {
        ...state,
        selectedLicense: {},
        loading: { open: false },
        isRequiredLicenses: appConstants.IS_REQUIRED_LICENSE_HISTORY_LIST,
      };
    case licenseHistoryActions.FETCHING_LICENSE_HISTORY_DELETE_SUCCESS:
      commonMessage(
        appConstants.DELETE_LICENSE_HISTORY_SUCCESS_MESSAGE,
        appConstants.SUCCESFUL_UPDATE_TITLE,
        icon.success,
      );
      return {
        ...state,
        isRequiredLicenses: appConstants.IS_REQUIRED_LICENSE_HISTORY_LIST,
        selectedLicense: undefined,
        loading: { open: false },
      };

    default:
      return state;
  }
};

export default licenseHistoryReducer;
