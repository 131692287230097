import { appConstants } from '../../appConstants';
import axiosReportInstance from '../../service/axiosReportInstance';
import { fetchRequest } from '../action/reportsActions';

const reportApi = {
  dowload(dispatch, actions, item, context = '') {
    dispatch(fetchRequest(actions.request));
    axiosReportInstance()
      .post(`${appConstants.CONTEXT_REPORT}${context}`, item, { responseType: 'blob' })
      .then((resp) => {
        dispatch(fetchRequest(actions.success, resp.data));
      })
      .catch((error) => {
        dispatch(fetchRequest(actions.error, error));
      });
  },
};

export default reportApi;
