import { Button } from '@material-ui/core';
import React from 'react';
import '../assets/styles/App.scss';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import Tooltip from '@material-ui/core/Tooltip';

const BackButton = (props) => {
  const { history, id } = props;

  const handleBackClick = (event) => {
    history.goBack();
  };

  return (
    <>
      <Tooltip title="Atrás" aria-label="add">
        <Button key={id} id={id} onClick={handleBackClick}>
          <ReplyAllIcon height={30} width={30} className="fc-third fw-bold ff-poppins" />
        </Button>
      </Tooltip>
    </>
  );
};

export default BackButton;
