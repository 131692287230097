import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { CheckIcon, DeviceIcon } from '../../../assets/icons';
import { setDevice } from '../../../device/actions/deviceActions';
import '../../styles/installation.scss';

const SideBarDeviceItem = (props) => {
  const { item, divider } = props;

  const handleClick = (selectedDevice) => {
    props.setDevice({ item: selectedDevice });
  };

  return (
    <ListItem
      button
      divider={divider}
      key={props.id}
      onClick={(e) => handleClick(item)}
      className={item.style ? 'styled-device' : 'no-styled-device'}
    >
      <ListItemIcon>
        {!item.style ? <DeviceIcon height="30px" width="30px" /> : <CheckIcon height="30px" width="30px" />}
      </ListItemIcon>
      <ListItemText>{item.name}</ListItemText>
    </ListItem>
  );
};

const mapDispatchToProps = {
  setDevice,
};

export default connect(null, mapDispatchToProps)(SideBarDeviceItem);
