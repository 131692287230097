import { Box, Button, Card } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { fetchRequest as fetchHeadquarter } from '../../headquarter/actions/headquarterActions';
import { cleanUser, deleteUser, fetchRequest, listUsers, userActions } from '../actions/userActions';

const columns = [
  { id: 'name', label: 'Nombre' },
  { id: 'identification', label: 'Identificación' },
  {
    id: 'username',
    label: 'Correo electrónico',
  },
  { id: 'role', label: 'Rol' },
];

const UserList = (props) => {
  const { list } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (props.isRequiredUsers == appConstants.IS_REQUIRED_USER_LIST) {
      props.listUsers({
        page: 0,
        size: 300,
      });
    }
    if (props.selectedUser != undefined) {
      return function () {
        props.fetchRequest(userActions.CLEAN_USER_STORE);
      };
    }
  }, [props.user]);

  const handleEdit = (itemSelected) => {
    props.fetchRequest(userActions.SET_USER, { item: itemSelected });
  };

  const handleDelete = (itemSelected) => {
    swal({
      title: appConstants.USER_TITLE,
      text: appConstants.WARNING_DELETE,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteUser(itemSelected?.id);
      }
    });
  };
  const addRoleOnListUser = (item) => {
    return { ...item, role: item?.roles[0]?.role.name };
  };
  return (
    <Box width={1} height={1}>
      <Card className="border-10">
        <TableContainer className="table-master">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    className="bg-secundary fc-third ff-poppins fw-bold fs-Large t-center-important"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}

                <TableCell align="center" className="bg-secundary fc-third ff-poppins fw-bold fs-Large " key={'action'}>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((item) => {
                let row = addRoleOnListUser(item);
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} className="t-center-important">
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}

                    <TableCell key={'action'} className="center-info" align="center">
                      <Button
                        color="primary"
                        className="btn-transparent ff-poppins fc-green min-width-1px"
                        onClick={(e) => handleEdit(item)}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        color="primary"
                        className="btn-transparent ff-poppins fc-green min-width-1px"
                        onClick={(e) => handleDelete(item)}
                      >
                        <DeleteIcon color="secondary" />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Card>
    </Box>
  );
};

const mapDispatchToProps = {
  listUsers,
  cleanUser,
  fetchHeadquarter,
  fetchRequest,
  deleteUser,
};

const mapStateToProps = (state) => {
  return {
    list: state.userReducer.avaliabledUsers ? state.userReducer.avaliabledUsers : [],
    isRequiredUsers: state.userReducer.isRequiredUsers
      ? state.userReducer.isRequiredUsers
      : appConstants.IS_REQUIRED_USER_LIST,
    user: state.userReducer.selectedUser ? state.userReducer.selectedUser : {},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
