import { Box, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import {
  cleanHeadquarter,
  fetchRequest,
  findById,
  headquarterActions,
  listHeadquarters,
} from '../actions/headquarterActions';

class HeadquarterAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.ref = undefined;
    this.state = { selectedHeadquarter: props.selectedHeadquarter, headquarterName: props.selectedHeadquarter?.name };
  }

  componentDidUpdate(prevProps, prevState, snap) {
    if (
      (!prevProps.selectedHeadquarter?.id && this.props.selectedHeadquarter?.id) ||
      this.state.selectedHeadquarter?.id != this.props.selectedHeadquarter?.id
    ) {
      this.setState({
        selectedHeadquarter: this.props.selectedHeadquarter,
        headquarterName: this.props.selectedHeadquarter?.name,
      });
    }
  }

  render() {
    return (
      <Grid container spacing={1} className="grid-input-element">
        <Grid item xs={12}>
          <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element ">
            <Autocomplete
              ref={(ref) => (this.ref = ref)}
              id="headquarterAutocomplete"
              options={this.props.headquarters}
              getOptionLabel={(option) => option.name}
              onChange={(e, option) => {
                this.handleSelectHeadquarter(option);
              }}
              fullWidths
              noOptionsText="No existen sedes creadas"
              inputValue={this.state.headquarterName ? this.state.headquarterName : ''}
              disabled={this.props.disabledAutocomplete}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={this.props.required}
                  label={this.props.label ? this.props.label : 'Selecciona una sede'}
                  variant="outlined"
                  value={this.state.headquarterName}
                  onChange={(event) => {
                    this.setState({ ...this.state, headquarterName: event.target.value });
                  }}
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }

  handleSelectHeadquarter(option) {
    if (option) {
      this.props.fetchRequest(headquarterActions.SET_HEADQUARTER, { item: option });
    } else {
      this.props.fetchRequest(headquarterActions.SET_HEADQUARTER, { item: undefined });
    }
  }

  componentDidMount() {
    if (this.props.isRequiredHeadquarters === appConstants.IS_REQUIRED_HEADQUARTERS) {
      this.props.listHeadquarters({
        page: 0,
        size: 300,
        filters: {
          companyId: this.props.company?.id,
          company: this.props.company,
        },
      });
    }

    if (this.props.requiredFindById?.value === appConstants.IS_REQUIRED_HQ_FIND_BY_ID)
      this.props.findById(this.props.requiredFindById?.id);
  }

  componentWillUnmount() {
    this.props.cleanHeadquarter();
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.companyReducer.currentCompany ? state.companyReducer.currentCompany : {},
    headquarters: state.headquarterReducer.avaliabledHeadquarters
      ? state.headquarterReducer.avaliabledHeadquarters
      : [],
    isRequiredHeadquarters: state.headquarterReducer.isRequiredHeadquarters
      ? state.headquarterReducer.isRequiredHeadquarters
      : appConstants.IS_REQUIRED_HEADQUARTERS,
    selectedHeadquarter: state.headquarterReducer.selectedHeadquarter,
    disabledAutocomplete: state.headquarterReducer.disabledAutocomplete,
    requiredFindById: state.headquarterReducer.requiredFindById,
  };
};

const mapDispatchToProps = {
  listHeadquarters,
  fetchRequest,
  cleanHeadquarter,
  findById,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeadquarterAutocomplete);
