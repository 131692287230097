import { Box, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { cleanIot, findById, listIots, setIot } from '../actions/iotActions';

class IotAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.selectedIot = undefined;
    this.ref = undefined;
    this.state = { selectedIot: props.selectedIot, iotName: props.selectedIot?.name };
  }

  render() {
    return (
      <Grid container spacing={1} className="grid-input-element">
        <Grid item xs={12}>
          <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element ">
            <Autocomplete
              ref={(ref) => (this.ref = ref)}
              id="iotSelect"
              options={this.props.iots}
              getOptionLabel={(option) => option.name}
              onChange={(e, option) => {
                this.handleSelectIot(option);
              }}
              noOptionsText="No hay IOTs asociados a esta SEDE"
              inputValue={this.state.iotName ? this.state.iotName : ''}
              disabled={this.props.disabledAutocomplete}
              renderInput={(params) => (
                <TextField
                  required={this.props.required}
                  {...params}
                  label={this.props.label ? this.props.label : 'Selecciona un IOT'}
                  variant="outlined"
                  value={this.state.iotName}
                  onChange={(event) => {
                    this.setState({ ...this.state, iotName: event.target.value });
                  }}
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }

  handleSelectIot(option) {
    this.selectedIot = option;
    if (option) {
      this.props.setIot({ item: option });
    } else {
      this.props.setIot({ item: undefined });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevProps.selectedHeadquarter && this.props.selectedHeadquarter) ||
      (prevProps.selectedHeadquarter?.id &&
        this.props.selectedHeadquarter?.id &&
        prevProps.selectedHeadquarter?.id != this.props.selectedHeadquarter?.id)
    ) {
      this.list();
    }
    if (prevProps.selectedHeadquarter && !this.props.selectedHeadquarter) {
      this.props.cleanIot();
    }

    if (
      (!prevProps.selectedIot?.id && this.props.selectedIot?.id) ||
      this.state.selectedIot?.id != this.props.selectedIot?.id
    ) {
      this.setState({ selectedIot: this.props.selectedIot, iotName: this.props.selectedIot?.name });
    }

    if (this.props.requiredFindById?.value === appConstants.IS_REQUIRED_IOT_FIND_BY_ID)
      this.props.findById(this.props.requiredFindById?.id);
  }

  list() {
    this.props.listIots({
      page: 0,
      size: 300,
      filters: {
        headquarterId: this.props.selectedHeadquarter?.id,
      },
    });
  }

  componentWillUnmount() {
    this.props.cleanIot();
  }
}

const mapStateToProps = (state) => {
  return {
    iots: state.iotReducer.avaliabledIots ? state.iotReducer.avaliabledIots : [],
    selectedIot: state.iotReducer.selectedIot,
    isRequiredIots:
      (state.headquarterReducer.selectedHeadquarter && !state.iotReducer.isRequiredIots) ||
      state.iotReducer.isRequiredIots == appConstants.IS_REQUIRED_IOT_LIST
        ? appConstants.IS_REQUIRED_IOT_LIST
        : appConstants.IS_NOT_REQUIRED_IOT_LIST,
    selectedHeadquarter: state.headquarterReducer.selectedHeadquarter,
    selectedInstallation: state.installationReducer.selectedInstallation,
    disabledAutocomplete: state.iotReducer.disabledAutocomplete,
    requiredFindById: state.iotReducer.requiredFindById,
  };
};

const mapDispatchToProps = {
  listIots,
  cleanIot,
  setIot,
  findById,
};

export default connect(mapStateToProps, mapDispatchToProps)(IotAutocomplete);
