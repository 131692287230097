import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { componentActions, fetchAction, setGlobalTitle } from '../../components/actions/componentActions';
import Loading from '../../components/Loading';
import SupportForm from '../components/SupportForm';

const SupportView = (props) => {
  const toolbarButtons = [{ id: appConstants.BACK_BUTTON_ID, history: props.history }];

  useEffect(() => {
    props.setGlobalTitle(appConstants.SUPPORT_TITLE);
    props.fetchAction(componentActions.ADD_TOOLBAR_BUTTON, toolbarButtons);
  }, []);

  return (
    <Box height={1} width={1} padding={1} className="background-image-bubble ">
      <SupportForm />
      <Loading />
    </Box>
  );
};

const mapDispatchToProps = {
  setGlobalTitle,
  fetchAction,
};

export default connect(null, mapDispatchToProps)(SupportView);
