import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import AutoCompleteFilters from '../../components/filters/AutoCompleteFilters';
import { dashboardAction, fetchRequest as fetchRequestDashboard } from '../../dashboard/action/dashboardActions';
import { fetchRequest as fetchRequestField, fieldActions, listFields } from '../../field/actions/fieldActions';
import { commonMessage, icon } from '../../service/messageHandler';
import { fetchRequest as fetchRequestReport, getTypereport, reportsActions } from '../action/reportsActions';

const GeneratorReport = (props) => {
  const handleClean = () => {
    props.fetchRequestDashboard(dashboardAction.CLEAN_VALUES_PER_FIELD_REPORT_DASHBOARD);
    props.fetchRequestField(fieldActions.CLEAN_SELECTED_FIELD_STORE);
  };
  const [filtreForm, setFiltreForm] = useState({
    typeReport: '',
  });
  const [typeReport, setTypeReport] = React.useState('');
  useEffect(() => {
    if (props.isRequiredTypeReport === appConstants.IS_REQUIERED_TYPE_REPORT){ props.getTypereport();}
     setTypeReport(props.setTypeReport);
  }, [props.setTypeReport]);

  const validate = () => {
    let msn = '';

    msn += !props.typeReport ? 'Debe selecccionar un tipo de reporte \n ' : '';
    msn += !props.headquarter ? 'Debe seleccionar una sede \n' : '';
    msn += !props.installation ? 'Debe seleccionar una instalación \n' : '';
    msn += !props.iot ? 'Debe seleccionar un IOT \n' : '';
    msn += !props.device ? 'Debe seleccionar un dispositivo  \n' : '';

    return msn;
  };

  const listUnits = () => {
    return props.typeReport;
  };

  const handleUnitMeasurementChange = (event) => {
    setTypeReport(event.target.value);
    filtreForm.typeReport = event.target.value;
    props.fetchRequestReport(reportsActions.SET_TYPE_REPORT, {item:event.target.value});
  };

  const handleFilter = () => {
    let msn = validate();
    if (msn == '') {
      listItems();
    } else {
      commonMessage(msn, appConstants.VALIDATION_ERROR_TITLE, icon.info);
    }
  };

  const listItems = () => {
    let filter = {
      sort: 'name',
      page: 0,
      size: 3000,
      filters: {
        headquarter: props.headquarter,
        iot: props.iot,
        device: props.device,
        installation: props.installation,
      },
    };

    props.listFields(filter);
  };

  return (
    <Box height={1} width={1}>
      <Grid container>
        <Grid item xs={12}>
          <Paper className="paper w-80 h-98">
            <Box boxShadow={3} height={1} borderRadius={10} padding={2}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  xs={12}
                  report
                  sm={12}
                  className="center-info bg-secundary border-10 mt-8px"
                >
                  <Typography variant="h4" component="h3" className="ff-poppins fc-third">
                    Filtro
                  </Typography>
                </Grid>
                <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info">
                  <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="typeReport">Tipo de reporte</InputLabel>
                      <Select
                        labelId="typeReport"
                        id="typeReport"
                        variant="outlined"
                        fullWidth={true}
                        value={typeReport}
                        onChange={handleUnitMeasurementChange}
                      >
                        <MenuItem value="" key="none">
                          <em>Ninguna</em>
                        </MenuItem>

                        {listUnits().map((element) => {
                          return (
                            <MenuItem value={element} key={element}>
                              {element}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                  <AutoCompleteFilters
                    headquarter={true}
                    installation={true}
                    iot={true}
                    device={true}
                    xl={12}
                    lg={12}
                    md={12}
                  />
                </Grid>
                <Grid item xl={12} md={12} lg={12} xs={12} sm={12} className="center-info">
                  <ButtonGroup
                    variant="text"
                    color="primary"
                    aria-label="text primary button group"
                    className="center-info"
                  >
                    <Button
                      color="primary"
                      className="bg-green-gradient mr-2 pl-6 pr-6 btn-size "
                      onClick={handleFilter}
                    >
                      <SearchIcon className="mr-2" />
                      Buscar
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
const mapDispatchToProps = {
  fetchRequestDashboard,
  fetchRequestField,
  getTypereport,
  listFields,
  fetchRequestReport,
};
const mapStateToProps = (state) => {
  return {
    headquarter: state.headquarterReducer.selectedHeadquarter
      ? state.headquarterReducer.selectedHeadquarter
      : undefined,
    installation: state.installationReducer.selectedInstallation
      ? state.installationReducer.selectedInstallation
      : undefined,
    device: state.deviceReducer.selectedDevice ? state.deviceReducer.selectedDevice : undefined,
    iot: state.iotReducer.selectedIot ? state.iotReducer.selectedIot : undefined,

    isRequiredTypeReport: state.reportReducers.isRequiredTypeReport
      ? state.reportReducers.isRequiredTypeReport
      : appConstants.IS_REQUIERED_TYPE_REPORT,

    typeReport: state.reportReducers.typeReport ? state.reportReducers.typeReport : [],
    setTypeReport: state.reportReducers.setTypeReport ? state.reportReducers.setTypeReport : [],

    isRequiredFields: state.fieldReducer.isRequiredFields
      ? state.fieldReducer.isRequiredFields
      : appConstants.IS_REQUIRED_FIELDS_LIST,
    isRequiredChangeTypeReport: state.reportReducers.isRequiredChangeTypeReport
      ? state.reportReducers.isRequiredChangeTypeReport
      : false,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GeneratorReport);
