import { appConstants } from '../../appConstants';
import ApiAxios from '../../service/Api';

export const fieldActions = {
  SET_FIELD: 'SET_FIELD',
  setFields: 'setFields',
  FETCHING_FIELD_REQUEST_SAVE: 'FETCHING_FIELD_REQUEST_SAVE',
  FETCHING_FIELD_REQUEST_UPDATE: 'FETCHING_FIELD_REQUEST_UPDATE',
  FETCHING_FIELD_REQUEST_LIST: 'FETCHING_FIELD_REQUEST_LIST',
  FETCHING_FIELD_SAVE_SUCCESS: 'FETCHING_FIELD_SAVE_SUCCESS',
  FETCHING_FIELD_UPDATE_SUCCESS: 'FETCHING_FIELD_UPDATE_SUCCESS',
  FETCHING_FIELD_LIST_SUCCESS: 'FETCHING_FIELD_LIST_SUCCESS',
  FETCHING_FIELD_FAILURE: 'FETCHING_FIELD_FAILURE',
  CLEAN_FIELD_STORE: 'CLEAN_FIELD_STORE',
  FETCHING_FIELD_REQUEST: 'FETCHING_FIELD_REQUEST',
  FETCHING_FIELD_DELETE_SUCCESS: 'FETCHING_FIELD_DELETE_SUCCESS',
  CLEAN_SELECTED_FIELD_STORE: 'CLEAN_SELECTED_FIELD_STORE',
};

export const setField = (payload) => ({
  type: fieldActions.SET_FIELD,
  payload,
});

export const setFields = (payload) => ({
  type: fieldActions.setFields,
  payload,
});

export const cleanFields = () => {
  return {
    type: fieldActions.CLEAN_FIELD_STORE,
  };
};

export const fetchRequest = (type, payload = undefined) => {
  return {
    type: type,
    payload: payload,
  };
};

export const saveField = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_FIELD, item, dispatch, fetchRequest, {
      request: fieldActions.FETCHING_FIELD_REQUEST_SAVE,
      success: fieldActions.FETCHING_FIELD_SAVE_SUCCESS,
      error: fieldActions.FETCHING_FIELD_FAILURE,
    });
  };
};

export const updateField = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.put(appConstants.CONTEXT_FIELD, item, dispatch, fetchRequest, {
      request: fieldActions.FETCHING_FIELD_REQUEST_UPDATE,
      success: fieldActions.FETCHING_FIELD_UPDATE_SUCCESS,
      error: fieldActions.FETCHING_FIELD_FAILURE,
    });
  };
};

export const listFields = (dto) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_FIELDS, dto, dispatch, fetchRequest, {
      request: fieldActions.FETCHING_FIELD_REQUEST_LIST,
      success: fieldActions.FETCHING_FIELD_LIST_SUCCESS,
      error: fieldActions.FETCHING_FIELD_FAILURE,
    });
  };
};

export const listFieldsDeletes = (dto) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_FIELDS_DELETE, dto, dispatch, fetchRequest, {
      request: fieldActions.FETCHING_FIELD_REQUEST_LIST,
      success: fieldActions.FETCHING_FIELD_LIST_SUCCESS,
      error: fieldActions.FETCHING_FIELD_FAILURE,
    });
  };
};

export const deleteField = (id) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.delete(`${appConstants.CONTEXT_FIELD}${id}`, dispatch, fetchRequest, {
      request: fieldActions.FETCHING_FIELD_REQUEST,
      success: fieldActions.FETCHING_FIELD_DELETE_SUCCESS,
      error: fieldActions.FETCHING_FIELD_FAILURE,
    });
  };
};
