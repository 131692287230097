import { appConstants } from '../../appConstants';
import ApiAxios from '../../service/Api';

export const licenseActions = {
  SET_LICENSE: 'SET_LICENSE',
  ADD_LICENSE: 'ADD_LICENSE',
  FETCHING_LICENSE_REQUEST: 'FETCHING_LICENSE_REQUEST',
  SET_OPEN_MODAL:'SET_OPEN_MODAL',
  FETCHING_LICENSE_SAVE_SUCCESS: 'FETCHING_LICENSE_SAVE_SUCCESS',
  FETCHING_LICENSE_UPDATE_SUCCESS: 'FETCHING_LICENSE_UPDATE_SUCCESS',
  FETCHING_LICENSE_LIST_SUCCESS: 'FETCHING_LICENSE_LIST_SUCCESS',
  FETCHING_LICENSE_FAILURE: 'FETCHING_LICENSE_FAILURE',
  FETCHING_LICENSE_REQUEST_LIST: 'FETCHING_LICENSE_REQUEST_LIST',
  CLEAN_LICENSE_STORE: 'CLEAN_LICENSE_STORE',
  FETCHING_LICENSE_DELETE_SUCCESS: 'FETCHING_LICENSE_DELETE_SUCCESS',
  FETCH_REQUEST_TYPE_LICENSE: 'FETCH_REQUEST_TYPE_LICENSE',
  FETCH_REQUEST_TYPE_LICENSE_SUCCESS: 'FETCH_REQUEST_TYPE_LICENSE_SUCCESS',
  FETCH_REQUEST_ACTIVE_LICENSE: 'FETCH_REQUEST_ACTIVE_LICENSE',
  FETCH_REQUEST_ACTIVE_LICENSE_SUCCESS: 'FETCH_REQUEST_ACTIVE_LICENSE_SUCCESS',
  FETCHING_LICENSE_ACTIVE_FAILURE: 'FETCHING_LICENSE_ACTIVE_FAILURE',
  FETCH_REQUEST_REACTIVE_LICENSE: 'FETCH_REQUEST_REACTIVE_LICENSE',
  FETCH_REQUEST_REACTIVE_LICENSE_SUCCESS: 'FETCH_REQUEST_REACTIVE_LICENSE_SUCCESS',
};

export const setlicense = (payload) => {
  return {
    type: licenseActions.SET_LICENSE,
    payload,
  };
};

export const cleanlicenses = () => {
  return {
    type: licenseActions.CLEAN_LICENSE_STORE,
  };
};

export const fetchRequest = (type, payload) => {
  return {
    type,
    payload,
  };
};

export const savelicense = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_LICENSE, item, dispatch, fetchRequest, {
      request: licenseActions.FETCHING_LICENSE_REQUEST,
      success: licenseActions.FETCHING_LICENSE_SAVE_SUCCESS,
      error: licenseActions.FETCHING_LICENSE_FAILURE,
    });
  };
};

export const updatelicense = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.put(appConstants.CONTEXT_LICENSE, item, dispatch, fetchRequest, {
      request: licenseActions.FETCHING_LICENSE_REQUEST,
      success: licenseActions.FETCHING_LICENSE_UPDATE_SUCCESS,
      error: licenseActions.FETCHING_LICENSE_FAILURE,
    });
  };
};

export const listlicenses = (dto) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_LICENSES, dto, dispatch, fetchRequest, {
      request: licenseActions.FETCHING_LICENSE_REQUEST_LIST,
      success: licenseActions.FETCHING_LICENSE_LIST_SUCCESS,
      error: licenseActions.FETCHING_LICENSE_FAILURE,
    });
  };
};

export const deletelicense = (id) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.delete(`${appConstants.CONTEXT_LICENSE}${id}`, dispatch, fetchRequest, {
      request: licenseActions.FETCHING_LICENSE_REQUEST_LIST,
      success: licenseActions.FETCHING_LICENSE_DELETE_SUCCESS,
      error: licenseActions.FETCHING_LICENSE_FAILURE,
    });
  };
};

export const getTypeLicense = () => {
  return function (dispatch) {
    ApiAxios.crudGeneric.get(`${appConstants.CONTEXT_LICENSE}type-licenses`, dispatch, fetchRequest, {
      request: licenseActions.FETCH_REQUEST_TYPE_LICENSE,
      success: licenseActions.FETCH_REQUEST_TYPE_LICENSE_SUCCESS,
      error: licenseActions.FETCHING_LICENSE_FAILURE,
    });
  };
};

export const activeCompany = (id) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.get(`${appConstants.CONTEXT_LICENSE}active-company/${id}`, dispatch, fetchRequest, {
      request: licenseActions.FETCH_REQUEST_ACTIVE_LICENSE,
      success: licenseActions.FETCH_REQUEST_ACTIVE_LICENSE_SUCCESS,
      error: licenseActions.FETCHING_LICENSE_ACTIVE_FAILURE,
    });
  };
};
export const reactiveLicense = (itemSelected) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(`${appConstants.CONTEXT_LICENSE}reactive`, itemSelected, dispatch, fetchRequest, {
      request: licenseActions.FETCH_REQUEST_REACTIVE_LICENSE,
      success: licenseActions.FETCH_REQUEST_REACTIVE_LICENSE_SUCCESS,
      error: licenseActions.FETCHING_LICENSE_FAILURE,
    });
  };
};
