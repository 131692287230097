import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgSupportIcon(props) {
  const { height, width, color } = props;
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}
         height={height ? height : appConstants.ICON_DEFAULT_HEIGHT}
      width={width ? width : appConstants.ICON_DEFAULT_WIDTH}>
      <path
        d="M11.25 16a.749.749 0 01-.509-.199L7.707 13H2.75A2.752 2.752 0 010 10.25v-7.5A2.752 2.752 0 012.75 0h9.5A2.752 2.752 0 0115 2.75v7.5A2.752 2.752 0 0112.25 13H12v2.25a.752.752 0 01-.75.75z"
        fill="#ffc107"
      />
      <path
        d="M15 6.5H0v3.75A2.752 2.752 0 002.75 13h4.957l3.034 2.801A.749.749 0 0012 15.25V13h.25A2.752 2.752 0 0015 10.25z"
        fill="#dea806"
      />
      <circle cx={18.5} cy={14.5} fill="#2196f3" r={3} />
      <path
        d="M21.25 19h-5.5A2.752 2.752 0 0013 21.75v1.5c0 .414.336.75.75.75h9.5a.75.75 0 00.75-.75v-1.5A2.752 2.752 0 0021.25 19z"
        fill="#2196f3"
      />
      <circle cx={4} cy={17} fill="#4caf50" r={2} />
      <path
        d="M5.25 20h-2.5A2.752 2.752 0 000 22.75v.5c0 .414.336.75.75.75h6.5a.75.75 0 00.75-.75v-.5A2.752 2.752 0 005.25 20z"
        fill="#4caf50"
      />
      <path
        d="M18.5 11.5c-1.654 0-3 1.346-3 3s1.346 3 3 3zM18.5 19h-2.75A2.752 2.752 0 0013 21.75v1.5c0 .414.336.75.75.75h4.75z"
        fill="#1d83d4"
      />
      <path
        d="M4 15c-1.103 0-2 .896-2 2s.897 2 2 2zM4 20H2.75A2.752 2.752 0 000 22.75v.5c0 .414.336.75.75.75H4z"
        fill="#429846"
      />
      <path
        d="M10.25 5h-5.5a.75.75 0 010-1.5h5.5a.75.75 0 010 1.5zM10.25 8.5h-5.5a.75.75 0 010-1.5h5.5a.75.75 0 010 1.5z"
        fill="#fafafa"
      />
      <g fill="#dadada">
        <path d="M7.5 3.5H4.75a.75.75 0 000 1.5H7.5zM7.5 7H4.75a.75.75 0 000 1.5H7.5z" />
      </g>
    </svg>
  );
}

export default SvgSupportIcon;
