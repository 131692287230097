import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgHeadquarter(props) {
  const { height, width } = props;
  return (
    <svg viewBox="0 0 496 496" xmlns="http://www.w3.org/2000/svg" {...props}
          height={height ? height : appConstants.DEFAULT_HEIGHT}
      width={width ? width : appConstants.DEFAULT_WIDTH}
    >
      <g fill="#cf9e76">
        <path d="M488 456v32H8v-32zm0 0M376 424v32H120v-32zm0 0M344 392v32H152v-32zm0 0" />
      </g>
      <path d="M376 232v192h-32V232zm0 0M152 392v32h-32V232h32zm0 0" fill="#838f9b" />
      <path d="M376 232h96v224h-32v-64h-32v64h-32zm0 0" fill="#ccd1d9" />
      <path
        d="M344 232v160h-72v-80h-48v80h-72V152h79.281l4.88 8.96c2.398 4.321 6.878 7.04 11.839 7.04s9.441-2.719 11.84-7.04l4.879-8.96H344zm0 0"
        fill="#e6e9ed"
      />
      <path d="M24 232h96v224H88v-64H56v64H24zm0 0" fill="#ccd1d9" />
      <path d="M408 392h32v64h-32zm0 0M224 312h48v80h-48zm0 0M56 392h32v64H56zm0 0" fill="#fcd770" />
      <path
        d="M264.719 152l-4.88 8.96c-2.398 4.321-6.878 7.04-11.839 7.04s-9.441-2.719-11.84-7.04l-4.879-8.96-32.48-59.441A56.029 56.029 0 01192 65.762V64c0-30.96 25.04-56 56-56 15.52 0 29.441 6.238 39.602 16.398C297.762 34.558 304 48.48 304 64v1.762c0 9.36-2.32 18.558-6.8 26.797zm0 0"
        fill="#ff826e"
      />
      <path d="M272 64c0 13.254-10.746 24-24 24s-24-10.746-24-24 10.746-24 24-24 24 10.746 24 24zm0 0" fill="#fcd770" />
      <path d="M480 448V224H352v-80h-73.8l25.984-47.633A64.205 64.205 0 00312 65.72V64c0-35.29-28.71-64-64-64s-64 28.71-64 64v1.719a64.205 64.205 0 007.816 30.648L217.801 144H144v80H16v224H0v48h496v-48zm-16 0h-16v-64h-48v64h-16V240h80zm-32 0h-16v-48h16zm-304-16h240v16H128zm32-32h176v16H160zm104-16h-32v-64h32zm104-144v176h-16V240zM200 65.719V64c0-26.473 21.527-48 48-48s48 21.527 48 48v1.719c0 8.008-2.023 15.96-5.855 22.992l-37.336 68.441c-1.922 3.512-7.688 3.512-9.61 0l-37.336-68.441A48.11 48.11 0 01200 65.719zM226.527 160l2.625 4.809C232.91 171.71 240.137 176 248 176s15.09-4.29 18.848-11.191l2.625-4.809H336v224h-56v-80h-64v80h-56V160zM144 416h-16V240h16zM32 240h80v208H96v-64H48v64H32zm48 208H64v-48h16zm400 32H16v-16h464zm0 0" />
      <path d="M176 208h56v16h-56zm0 0M264 208h56v16h-56zm0 0M176 240h56v16h-56zm0 0M264 240h56v16h-56zm0 0M176 272h56v16h-56zm0 0M264 272h56v16h-56zm0 0M280 64c0-17.648-14.352-32-32-32s-32 14.352-32 32 14.352 32 32 32 32-14.352 32-32zm-48 0c0-8.824 7.176-16 16-16s16 7.176 16 16-7.176 16-16 16-16-7.176-16-16zm0 0M48 256h16v16H48zm0 0M80 256h16v16H80zm0 0M48 288h16v16H48zm0 0M80 288h16v16H80zm0 0M48 320h16v16H48zm0 0M80 320h16v16H80zm0 0M48 352h16v16H48zm0 0M80 352h16v16H80zm0 0M432 256h16v16h-16zm0 0M400 256h16v16h-16zm0 0M432 288h16v16h-16zm0 0M400 288h16v16h-16zm0 0M432 320h16v16h-16zm0 0M400 320h16v16h-16zm0 0M432 352h16v16h-16zm0 0M400 352h16v16h-16zm0 0" />
    </svg>
  );
}

export default SvgHeadquarter;
