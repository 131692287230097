import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MobileStepper from '@material-ui/core/MobileStepper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import React from 'react';
import DeviceView from '../../device/layouts/DeviceView';
import FieldView from '../../field/layouts/FieldView';
import InstallationView from '../../installation/layouts/InstallationView';
import IotView from '../../iot/layouts/IotView';

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
});

const CreateInstallationStepper = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const steps = [<IotView showList={false} />, <DeviceView />, <FieldView />, <InstallationView />];
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    if (activeStep < steps.length) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep >= 0) setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStep = () => {
    return steps[activeStep];
  };

  return (
    <Box width={1} height={1} padding={1} className="background-image-bubble ">
      <Box height={9 / 10} overflow="auto">
        {getStep()}
      </Box>
      <Box height={1 / 10} width={1} display="flex" justifyContent="center">
        <MobileStepper
          variant="dots"
          steps={4}
          position="static"
          activeStep={activeStep}
          className={classes.root}
          nextButton={
            <Button size="small" onClick={handleNext} disabled={activeStep === 3}>
              Siguiente
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              Anterior
            </Button>
          }
        />
      </Box>
    </Box>
  );
};

export default CreateInstallationStepper;
