import { appConstants } from '../../appConstants';
import { commonMessage, errorHandler, icon } from '../../service/messageHandler';
import { fieldActions } from './../actions/fieldActions';

const fieldReducer = (state = {}, action) => {
  switch (action.type) {
    case fieldActions.CLEAN_FIELD_STORE:
      return {
        ...state,
        isRequiredFields: appConstants.IS_REQUIRED_FIELDS_LIST,
        avaliabledFields: [],
        selectedField: undefined,
      };
    case fieldActions.CLEAN_SELECTED_FIELD_STORE:
      return {
        ...state,
        selectedField: undefined,
      };
    case fieldActions.SET_FIELD:
      return { ...state, selectedField: action.payload.item };

    case fieldActions.FETCHING_FIELD_SAVE_SUCCESS:
      commonMessage(appConstants.SAVE_FIELD_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);

      return {
        ...state,
        loading: { open: false },
        selectedField: undefined,
        isRequiredFields: appConstants.IS_REQUIRED_FIELDS_LIST,
      };

    case fieldActions.FETCHING_FIELD_REQUEST_UPDATE:
    case fieldActions.FETCHING_FIELD_REQUEST_SAVE:
      return {
        ...state,
        loading: { open: true, message: '' },
      };
    case fieldActions.FETCHING_FIELD_REQUEST_LIST:
      return {
        ...state,
        loading: { open: true, message: '' },
        isRequiredFields: appConstants.IS_NOT_REQUIRED_FIELDS_LIST,
      };

    case fieldActions.FETCHING_FIELD_FAILURE:
      errorHandler(action);
      return { ...state, loading: { open: false } };

    case fieldActions.FETCHING_FIELD_LIST_SUCCESS:
      return {
        ...state,
        avaliabledFields: action.payload.content ? action.payload.content : [],
        isRequiredFields: appConstants.IS_NOT_REQUIRED_FIELDS_LIST,
        loading: { open: false, message: '' },
      };

    case fieldActions.FETCHING_FIELD_UPDATE_SUCCESS:
      commonMessage(appConstants.UPDATE_FIELD_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);
      return {
        ...state,
        loading: { open: false },
        selectedField: {},
        isRequiredFields: appConstants.IS_REQUIRED_FIELDS_LIST,
      };

    case fieldActions.FETCHING_FIELD_DELETE_SUCCESS:
      commonMessage(appConstants.DELETE_FIELD_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);
      return {
        ...state,
        isRequiredFields: appConstants.IS_REQUIRED_FIELDS_LIST,
        selectedField: undefined,
        loading: { open: false },
      };

    default:
      return state;
  }
};

export default fieldReducer;
