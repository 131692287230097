import { appConstants } from '../../appConstants';
import { commonMessage, errorHandler, icon } from '../../service/messageHandler';
import { licenseActions } from '../actions/licenseActions';

const licenseReducer = (state = {}, action) => {
  switch (action.type) {
    case licenseActions.SET_LICENSE:
      return { ...state, selectedLicense: action.payload.item };
    case licenseActions.ADD_LICENSE:
      let removeItem = state.avaliabledLicenses.filter((item) => item.id == action.payload.item.id)[0];
      state.avaliabledLicenses.splice(state.avaliabledLicenses.indexOf(removeItem), 1);

      state.avaliabledLicenses.push(action.payload.item);

      return {
        ...state,
        avaliabledLicenses: state.avaliabledLicenses,
        selectedLicense: undefined,
      };
    case licenseActions.FETCHING_LICENSE_SAVE_SUCCESS:
      commonMessage(appConstants.SAVE_LICENSE_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);

      return {
        ...state,
        loading: { open: false },
        selectedLicense: {},
        isRequiredLicenses: appConstants.IS_REQUIRED_LICENSE_LIST,
      };
    case licenseActions.FETCHING_LICENSE_REQUEST_LIST:
      return {
        ...state,
        isRequiredLicenses: appConstants.IS_NOT_REQUIRED_LICENSE_LIST,
        loading: { open: true },
      };
    case licenseActions.FETCHING_LICENSE_REQUEST:
      return state;
    case licenseActions.FETCHING_LICENSE_FAILURE:
      errorHandler(action);
      return {
        ...state,
        isRequiredLicenses: appConstants.IS_NOT_REQUIRED_LICENSE_LIST,
      };
    case licenseActions.FETCHING_LICENSE_LIST_SUCCESS:
      return {
        ...state,
        avaliabledLicenses: action.payload.content ? action.payload.content : [],
        isRequiredLicenses: appConstants.IS_NOT_REQUIRED_LICENSE_LIST,
        loading: { open: false },
      };
    case licenseActions.CLEAN_LICENSE_STORE:
      return {
        ...state,
        avaliabledLicenses: [],
        isRequiredLicenses: appConstants.IS_REQUIRED_LICENSE_LIST,
        selectedLicense: undefined,
      };
    case licenseActions.FETCHING_LICENSE_UPDATE_SUCCESS:
      commonMessage(appConstants.UPDATE_LICENSE_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);
      return {
        ...state,
        selectedLicense: {},
        loading: { open: false },
        isRequiredLicenses: appConstants.IS_REQUIRED_LICENSE_LIST,
      };
    case licenseActions.FETCHING_LICENSE_DELETE_SUCCESS:
      commonMessage(appConstants.DELETE_LICENSE_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);
      return {
        ...state,
        isRequiredLicenses: appConstants.IS_REQUIRED_LICENSE_LIST,
        selectedLicense: undefined,
        loading: { open: false },
      };

    case licenseActions.FETCH_REQUEST_TYPE_LICENSE:
      return {
        ...state,
        loading: { open: true },
        isRequiredTypeLicense: appConstants.IS_NOT_REQUIRED_TYPE_LICENSE,
      };
    case licenseActions.FETCH_REQUEST_TYPE_LICENSE_SUCCESS:
      return {
        ...state,
        loading: { open: false },
        typeLicense: action.payload,
      };
    case licenseActions.FETCH_REQUEST_ACTIVE_LICENSE:
    case licenseActions.FETCH_REQUEST_REACTIVE_LICENSE:
      return {
        ...state,
        loading: { open: true },
      };
    case licenseActions.FETCH_REQUEST_ACTIVE_LICENSE_SUCCESS:
      commonMessage(
        appConstants.ACTIVE_COMPANY_LICENSE_SUCCESS_MESSAGE,
        appConstants.SUCCESFUL_SAVED_TITLE,
        icon.success,
      );
      return {
        ...state,
        loading: { open: false },
        licenseFound: action.payload ? action.payload : undefined,
      };

    case licenseActions.FETCHING_LICENSE_ACTIVE_FAILURE:
      errorHandler(action);
      return {
        ...state,
      };

    case licenseActions.FETCH_REQUEST_REACTIVE_LICENSE_SUCCESS:
      commonMessage(
        appConstants.REACTIVE_COMPANY_LICENSE_SUCCESS_MESSAGE,
        appConstants.SUCCESFUL_SAVED_TITLE,
        icon.success,
      );
      return {
        ...state,
        loading: { open: false },
        licenseReactived: action.payload ? action.payload : undefined,
      };
      case licenseActions.SET_OPEN_MODAL:
        return { ...state, isOpenModal: action.payload.item };

    default:
      return state;
  }
};

export default licenseReducer;
