import { Box, Button, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { appConstants } from '../../appConstants';
import { DistributedIcon } from '../../assets/icons';
import '../../assets/styles/App.scss';
import { componentActions, fetchAction, setGlobalTitle } from '../../components/actions/componentActions';
import Loading from '../../components/Loading';
import ListInstallation from '../components/lists/InstallationList';

const IotView = (props) => {
  const buttonsToolbar = [
    { id: appConstants.BACK_BUTTON_ID, history: props.history },
    { id: appConstants.FILTER_INSTALLATION_BUTTON, open: true },
  ];

  const history = useHistory();

  useEffect(() => {
    props.setGlobalTitle(appConstants.INSTALLATION_VIEW_TITLE);

    props.fetchAction(componentActions.ADD_TOOLBAR_BUTTON, buttonsToolbar);
  }, []);

  const handleOpen = (event) => {};

  const handleCreate = (event) => {
    event.preventDefault();

    history.push('/installation/edit/distribution');
  };

  return (
    <Box height={1} width={1} padding={1} className="background-image-bubble ">
      <Box height={9 / 10}>
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
            <Box boxShadow={4} borderRadius={10}>
              <ListInstallation />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box height={1 / 10} justifyContent="center" display="flex" width={1}>
        <Box borderRadius={30} boxShadow={3}>
          <Button type="button" onClick={handleCreate} className="bg-green-gradient h-100 w-100">
            <DistributedIcon height="20px" width="20px" className="mr-2" />
            Crear Instalacion
          </Button>
        </Box>
      </Box>

      <Loading />
    </Box>
  );
};

const mapDispatchToProps = {
  setGlobalTitle,
  fetchAction,
};

export default connect(null, mapDispatchToProps)(IotView);
