import DateFnsUtils from '@date-io/date-fns';
import { Box, Grid } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import esLocale from 'date-fns/locale/es';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../../assets/styles/App.scss';
import { convertDate, convertDateMonth, getPlusMonth, getYear } from '../../service/util';
import { CregActions, fetchRequest } from '../action/CregActions';

const localeMap = {
  es: esLocale,
};

const DateFilterCreg = (props) => {
  const [locale, setLocale] = useState('es');
  const [localSeries, setLocalSerie] = useState(props.series);
  const [shouldReload, setShouldReload] = useState(false);

  useEffect(() => {
   
  }, [localSeries, props.serieFilter]);

  const handleClickOne = (serie, type, event, index) => {
    serie = { ...serie, [type]: convertDate(event).replace(' ', 'T') };
    let month = getPlusMonth(event, 1);
    let year = getYear(event);
    let from = convertDateMonth(event) + '-01 00:00:00';
    let to = year + '-' + month + '-01 00:00:00';
    let fromT= convertDateMonth(event);
    let sendDate = { from: from, to: to,fromText:fromT };

    let serieToChange = localSeries.filter((element) => element.name == serie.name);

    localSeries.splice(localSeries.indexOf(serieToChange[0]), 1);

    setLocalSerie([serie, ...localSeries]);
    setShouldReload(!shouldReload);
if(props.isRequiredChangeDateFilterConsolidated){
      props.fetchRequest(CregActions.SET_SERIE_FILTER_CONSOLIDATED, {item:sendDate});
}

  };



  return (
    
      <Grid container spacing={1} className="grid-input-element mt-20px">
        {localSeries && 
          localSeries.map((serie, index) => {
            return (
              <Grid item xs={12} >
                <Box boxShadow={3} height={1} width={1}  borderRadius={10} padding={2} >
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                   <DatePicker
                   
                      views={['year', 'month']}
                      value={serie.from}
                      disableFuture
                      locale="es"
                      onChange={(event) => handleClickOne(serie, 'from', event, index)}
                      label="Periodo"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </Box>
              </Grid>
            );
          })}
      </Grid>
  
  );
};
const mapDispatchToProps = {
  fetchRequest,
};
const mapStateToProps = (state) => {
  return {
    isRequiredChangeDateFilterConsolidated: state.CregReducer.isRequiredChangeDateFilterConsolidated
      ? state.CregReducer.isRequiredChangeDateFilterConsolidated
      : true,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DateFilterCreg);
