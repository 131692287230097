import { Button, Grid, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { CancelIcon, CheckIcon, FilterIcon } from '../../assets/icons';
import '../../assets/styles/App.scss';
import AutoCompleteFilters from '../../components/filters/AutoCompleteFilters';
import { commonMessage } from '../../service/messageHandler';
import { listAlarms } from '../action/alarmActions';
import { getButtonFilterModal, handleFilterModalEvent } from '../services/alarmObservables';
import Tooltip from '@material-ui/core/Tooltip';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '100vh',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const AlarmFilterModal = (props) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(props.open);

  const [subs, setSubs] = useState([]);

  useEffect(() => {
    setSubs([
      ...subs,
      getButtonFilterModal().subscribe((data) => {
        if (data == appConstants.CLOSE_EVENT) {
          setOpen(false);
          handleFilterModalEvent('');
        }
      }),
    ]);

    return function () {
      subs.forEach((element) => {
        element.unsubscribe();
      });
    };
  }, [open]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleFilterModalEvent(appConstants.CANCEL_EVENT);
  };

  const handleSave = () => {
    let msnValidation = validate();

    if (msnValidation != '') {
      commonMessage(msnValidation);
      return;
    }

    props.listAlarms({
      page: 0,
      size: 3000,
      filters: props.selectedField,
    });

    setOpen(!open);
  };

  const validate = () => {
    let msn = '';

    msn += !props.selectedField?.id ? 'Debe seleccionar una variable' : '';

    return msn;
  };

  return (
    <div>
      <Tooltip title="Filtro" aria-label="add">
        <Button type="button" onClick={handleOpen} className="btn-rounded-secundary">
          <FilterIcon />
        </Button>
      </Tooltip>
      <Modal open={open} aria-labelledby="alarm-modal-title" aria-describedby="alarm-modal-description">
        <div style={modalStyle} className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info bg-secundary">
              <Typography className="fc-third" variant="h4" component="h4">
                Filtro de alarmas
              </Typography>
            </Grid>

            <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
              <AutoCompleteFilters iot={true} field={true} headquarter={true} device={true} xl={12} lg={12} md={12} />
            </Grid>
            <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="pt-2">
              <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className="center-info">
                  <Button variant="contained" color="primary" onClick={handleSave} className="bg-green-gradient">
                    <CheckIcon className="mr-2 " height="25px" width="25px" />
                    Aceptar
                  </Button>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className="center-info">
                  <Button
                    onClick={(e) => handleClose()}
                    variant="contained"
                    color="secondary"
                    className="border-30 ff-poppins fw-bold bg-green-gradient "
                  >
                    <CancelIcon className="mr-2" height="25px" width="25px" />
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedField: state.fieldReducer.selectedField,
  };
};

const mapDispatchToProps = {
  listAlarms,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlarmFilterModal);
