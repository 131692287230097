import { appConstants } from '../../appConstants';
import ApiAxios from '../../service/Api';

export const headquarterActions = {
  FETCH_LIST_HA_REQUEST: 'FETCH_LIST_REQUEST',
  FETCH_LIST_HA_REQUEST_SUCCES: 'FETCH_LIST_REQUEST_SUCCES',
  FETCH_REQUEST_ERROR: 'FETCH_REQUEST_HEADQUARTER_ERROR',
  SET_HEADQUARTER: 'SET_HEADQUARTER',
  CLEAN_HEADQUARTER: 'CLEAN_HEADQUARTER',
  SEARCH_SET_BY_ID_HQ: 'SEARCH_SET_BY_ID_HQ',
  ENABLE_HQ_AUTOCOMPLETE: 'ENABLE_HQ_AUTOCOMPLETE',
  FETCHING_HEADQUARTER_SAVE_SUCCESS: 'FETCHING_HEADQUARTER_SAVE_SUCCESS',
  FETCHING_HEADQUARTER_UPDATE_SUCCESS: 'FETCHING_HEADQUARTER_UPDATE_SUCCESS',
  FETCHING_HEADQUARTER_REQUEST_SAVE: 'FETCHING_HEADQUARTER_REQUEST',
  FETCHING_HEADQUARTER_REQUEST_UPDATE: 'FETCHING_HEADQUARTER_REQUEST_UPDATE',
  FETCHING_HEADQUARTER_DELETE_SUCCESS: 'FETCHING_HEADQUARTER_DELETE_SUCCESS',
  FETCHING_HEADQUARTER_REQUEST: 'FETCHING_HEADQUARTER_REQUEST',
  FETCHING_HQ_FIND_BY_ID_SUCCESS: 'FETCHING_HQ_FIND_BY_ID_SUCCESS',
};

export const fetchRequest = (type, payload = undefined) => {
  return {
    type,
    payload,
  };
};

export const cleanHeadquarter = () => {
  return {
    type: headquarterActions.CLEAN_HEADQUARTER,
  };
};

export const listHeadquarters = (filters) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_HEADQUARTERS, filters, dispatch, fetchRequest, {
      request: headquarterActions.FETCH_LIST_HA_REQUEST,
      success: headquarterActions.FETCH_LIST_HA_REQUEST_SUCCES,
      error: headquarterActions.FETCH_REQUEST_ERROR,
    });
  };
};

export const saveHeadquarter = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_HEADQUARTER, item, dispatch, fetchRequest, {
      request: headquarterActions.FETCHING_HEADQUARTER_REQUEST_SAVE,
      success: headquarterActions.FETCHING_HEADQUARTER_SAVE_SUCCESS,
      error: headquarterActions.FETCH_REQUEST_ERROR,
    });
  };
};

export const updateHeadquarter = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.put(appConstants.CONTEXT_HEADQUARTER, item, dispatch, fetchRequest, {
      request: headquarterActions.FETCHING_HEADQUARTER_REQUEST_UPDATE,
      success: headquarterActions.FETCHING_HEADQUARTER_UPDATE_SUCCESS,
      error: headquarterActions.FETCH_REQUEST_ERROR,
    });
  };
};

export const deleteHeadquarter = (id) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.delete(`${appConstants.CONTEXT_HEADQUARTER}${id}`, dispatch, fetchRequest, {
      request: headquarterActions.FETCHING_HEADQUARTER_REQUEST,
      success: headquarterActions.FETCHING_HEADQUARTER_DELETE_SUCCESS,
      error: headquarterActions.FETCH_REQUEST_ERROR,
    });
  };
};

export const findById = (id) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.get(`${appConstants.CONTEXT_HEADQUARTER}${id}`, dispatch, fetchRequest, {
      request: headquarterActions.FETCHING_HEADQUARTER_REQUEST,
      success: headquarterActions.FETCHING_HQ_FIND_BY_ID_SUCCESS,
      error: headquarterActions.FETCH_REQUEST_ERROR,
    });
  };
};
