import { appConstants } from '../../appConstants';
import { commonMessage, errorHandler, icon } from '../../service/messageHandler';
import { installationActions } from '../actions/installationActions';

const installationReducer = (state = {}, action) => {
  switch (action.type) {
    case installationActions.FETCHING_INSTALLATION_UPDATE:
    case installationActions.FETCHING_INSTALLATION_IMAGE:
      return {
        ...state,
        loading: { open: true },
      };
    case installationActions.FETCHING_INSTALLATION_SAVE:
      return {
        ...state,
        loading: { open: true },
        isRequiredImage: appConstants.IS_NOT_REQUIRED_LOAD_IMAGE,
      };

    case installationActions.FETCHING_INSTALLATION_SAVE_SUCCESS:
      commonMessage(appConstants.SAVE_INSTALLATION_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);

      return {
        selectedInstallation: undefined,
        redirect: true,
        loading: { open: false },
      };

    case installationActions.FETCHING_INSTALLATION_FAILURE:
      errorHandler(action.payload);
      return {
        loading: { open: false },
        error: action.payload,
      };

    case installationActions.FETCHING_INSTALLATION_LIST:
      return {
        ...state,
        loading: { open: true },
        isRequiredInstallations: appConstants.IS_NOT_REQUIRED_INSTALLATION_LIST,
      };
    case installationActions.FETCHING_INSTALLATION_LIST_SUCCESS:
      return {
        ...state,
        loading: { open: false },
        avaliabledInstallations: action.payload.content,
      };

    case installationActions.CLEAN_INSTALLATION:
      return {
        ...state,
        isRequiredInstallations: appConstants.IS_REQUIRED_INSTALLATION_LIST,
        avaliabledInstallations: [],
        selectedInstallation: action.payload?.edit ? state.selectedInstallation : undefined,
        redirect: false,
        loading: { open: false },
        imageShowed: undefined,
      };

    case installationActions.VIEW_INSTALLATION:
      return {
        ...state,
        isRequiredImage: appConstants.IS_REQUIRED_LOAD_IMAGE,
        selectedInstallation: action.payload.item,
      };

    case installationActions.FETCHING_INSTALLATION_IMAGE_SUCCESS:
      const imageB64 = 'data:' + action.payload.type + ';base64,' + action.payload.data;
      return {
        ...state,
        loading: { open: false },
        imageShowed: imageB64,
        imageFile64: action.payload.data,
      };

    case installationActions.FETCHING_INSTALLATION_UPDATE_SUCCESS:
      return {
        ...state,
        selectedInstallation: {},
        loading: { open: false },
      };

    case installationActions.SET_INSTALLATION:
      return {
        ...state,
        selectedInstallation: action.payload.item,
        isRequiredImage: appConstants.IS_REQUIRED_LOAD_IMAGE,
      };

    case installationActions.FETCHING_INSTALLATION_DELETE_SUCCESS:
      commonMessage(
        appConstants.DELETE_INSTALLATION_SUCCESS_MESSAGE,
        appConstants.SUCCESFUL_UPDATE_TITLE,
        icon.success,
      );
      return {
        ...state,
        isRequiredInstallations: appConstants.IS_REQUIRED_INSTALLATION_LIST,
        selectedInstallation: undefined,
        loading: { open: false },
      };

    default:
      return state;
  }
};

export default installationReducer;
