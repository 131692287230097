import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';


const FieldInfo = (props) => {
  return (
    <Grid container spacing={2}>

      {props.iot && (
        <Grid
          item
          xl={props.xl ? props.xl : 12}
          lg={props.lg ? props.lg : 12}
          md={props.md ? props.md : 12}
          sm={12}
          xs={12}
        >
          <TextField
                      fullWidth
                      variant="filled"
                      id="iotDeviceName"
                      value={props.alarm?.field?.device?.iot?.name}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
        </Grid>
      )}

      {props.device && (
        <Grid
          item
          xl={props.xl ? props.xl : 12}
          lg={props.lg ? props.lg : 12}
          md={props.md ? props.md : 12}
          sm={12}
          xs={12}
        >
           <TextField
                      fullWidth
                      variant="filled"
                      id="deviceName"
                      value={props.alarm?.field?.device?.name}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
        </Grid>
      )}

      {props.field && (
        <Grid
          item
          xl={props.xl ? props.xl : 12}
          lg={props.lg ? props.lg : 12}
          md={props.md ? props.md : 12}
          sm={12}
          xs={12}
        >
           <TextField
                      fullWidth
                      variant="filled"
                      id="fieldName"
                      value={props.alarm?.field?.name}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
        </Grid>
      )}
    </Grid>
  );
};


const mapStateToProps = (state) => {
    return {
   
      headquarter: state.headquarterReducer.selectedHeadquarter
        ? state.headquarterReducer.selectedHeadquarter
        : undefined,
      device: state.deviceReducer.selectedDevice ? state.deviceReducer.selectedDevice : {},
    
      isRequiredFields: state.fieldReducer.isRequiredFields,
    };
  };
  
  
  
  export default connect(mapStateToProps)(FieldInfo);
