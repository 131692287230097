import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgWarningIcon(props) {
  const { height, width, color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 -11 704.012 704"  {...props}
      height={height ? height : appConstants.ICON_DEFAULT_HEIGHT}
      width={width ? width : appConstants.ICON_DEFAULT_WIDTH}
    >
      <path d="M109.332 566.406v115.602H594.73V566.406zm0 0" fill="#6d6e71" />
      <path d="M352.031 265.906c-102.101 0-184.902 82.801-184.902 184.899v115.601H352.03zm0 0" fill="#ab2300" />
      <path
        d="M536.93 450.805c0-102.098-82.801-184.899-184.899-184.899v46.2c76.598.101 138.598 62.101 138.7 138.699-.102-76.598-62.102-138.598-138.7-138.7v254.301H536.93zm0 0"
        fill="#cd2a00"
      />
      <path
        d="M352.031 208.105c-6.402 0-11.601-5.199-11.601-11.597V11.605c0-6.398 5.199-11.597 11.601-11.597 6.399 0 11.598 5.199 11.598 11.597v184.903c0 6.398-5.2 11.597-11.598 11.597zm0 0M248.031 235.906c-4.101 0-8-2.199-10-5.8l-92.5-160.098c-3.199-5.5-1.402-12.602 4.098-15.801 5.5-3.2 12.601-1.402 15.8 4.098l.102.101 92.5 160.2c3.2 5.5 1.301 12.601-4.199 15.8-1.8 1-3.8 1.5-5.8 1.5zm0 0M171.832 312.105c-2 0-4-.5-5.8-1.5l-160.2-92.5c-5.5-3.199-7.5-10.199-4.3-15.8 3.198-5.598 10.198-7.5 15.8-4.297l.098.097 160.199 92.5c5.5 3.2 7.402 10.301 4.203 15.801-2.102 3.5-5.902 5.7-10 5.7zm0 0M532.23 312.105c-6.398 0-11.601-5.199-11.601-11.597 0-4.102 2.203-7.903 5.8-10l160.2-92.5c5.5-3.203 12.601-1.403 15.8 4.097 3.2 5.5 1.403 12.602-4.097 15.801l-.102.102-160.199 92.597c-1.8 1-3.8 1.5-5.8 1.5zm0 0M456.031 235.906c-6.402 0-11.601-5.199-11.5-11.601 0-2 .5-4 1.5-5.797l92.5-160.203c3.2-5.5 10.301-7.399 15.801-4.098 5.5 3.2 7.297 10.2 4.2 15.7l-92.5 160.198c-2 3.602-5.802 5.903-10 5.801zm0 0"
        fill="#ff5023"
      />
      <path
        d="M490.73 462.406c-6.398 0-11.601-5.199-11.601-11.601-.098-70.2-57-127.098-127.2-127.2-6.398 0-11.597-5.199-11.597-11.597 0-6.403 5.2-11.602 11.598-11.602 83 .102 150.199 67.301 150.3 150.301.102 6.5-5.101 11.7-11.5 11.7zm0 0"
        fill="#e6e7e8"
      />
    </svg>
  );
}

export default SvgWarningIcon;
