import React from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../../appConstants';
import { addDevice } from '../../../device/actions/deviceActions';

const RenderImage = (props) => {
  const { image, device } = props;

  const handleClickImage = (event) => {
    if (device) {
      let style = { ...defineCoordinate(event) };

      let deviceChangedStyle = { ...device, style: JSON.stringify(style) };

      props.addDevice({
        item: deviceChangedStyle,
        action: appConstants.CHANGE_LOCATION_DEVICE,
      });
    }
  };

  const defineCoordinate = (event) => {
    let percentY = (event.nativeEvent.layerY / event.target.clientHeight) * 100;
    let percentX = (event.nativeEvent.layerX / event.target.clientWidth) * 100;

    return { top: `${percentY}%`, left: `${percentX}%` };
  };
  return <>{image && <img height="100%" width="100%" src={image} onClick={(e) => handleClickImage(e)} />}</>;
};

const mapStateToProps = (state) => {
  return {
    image: state.componentsReducer.imageShowed
      ? state.componentsReducer.imageShowed
      : state.installationReducer.imageShowed,
    device: state.deviceReducer.selectedDevice,
    selectedInstallation: state.installationReducer.selectedInstallation,
  };
};

const mapDispatchToProps = {
  addDevice,
};

export default connect(mapStateToProps, mapDispatchToProps)(RenderImage);
