import { Box, Grid, Hidden } from '@material-ui/core';
import React from 'react';
import '../../assets/styles/App.scss';
import '../assets/styles/user.scss';
import InfoLogin from '../components/InfoLogin';
import LoginForm from '../components/LoginForm';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const LoginView = (props) => {
  let size = getWindowDimensions();

  return (
    <Box height={1} width={1} padding={1} className="background-view">
      <Grid container spacing={2} style={{ height: '100%', padding: '0px' }}>
        <Grid item xl={7} lg={7} md={6} sm={12} xs={12} className="padding-none">
          <Box width={1} height={1} position="relative" className="background" padding={0}>
            <Box
              height={6 / 10}
              width={size.width > 360 ? 4 / 10 : 4 / 6}
              position="absolute"
              top="20vh"
              left={size.width > 360 ? '10%' : '18%'}
            >
              <LoginForm />
            </Box>
          </Box>
        </Grid>

        <Hidden smDown xsDown>
          <Grid item xl={4} lg={4} md={5} sm={12} xs={12}>
            <Box width={1} height={1}>
              <InfoLogin />
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </Box>
  );
};

export default LoginView;
