import { Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { addButton, componentActions, fetchAction, setGlobalTitle } from '../../components/actions/componentActions';
import Loading from '../../components/Loading';
import { listFields } from '../../field/actions/fieldActions';
import HeadquarterForm from '../components/HeadquarterForm';
import HeadquarterList from '../components/HeadquarterLIst';
const HeadquarterView = (props) => {
  const buttonsToolbar = [{ id: appConstants.BACK_BUTTON_ID, history: props.history }];

  useEffect(() => {
    props.setGlobalTitle(appConstants.HEADQUARTER_VIEW_TITLE);

    props.fetchAction(componentActions.ADD_TOOLBAR_BUTTON, buttonsToolbar);
  }, []);

  return (
    <Box height={1} width={1} padding={1} className="background-image-bubble ">
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} xs={12} sm={12}>
          <Box boxShadow={4} borderRadius={10}>
            <HeadquarterForm />
          </Box>
        </Grid>
        <Grid item xl={6} lg={6} md={6} xs={12} sm={12}>
          <Box boxShadow={4} borderRadius={10}>
            <HeadquarterList />
          </Box>
        </Grid>
      </Grid>
      <Loading />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    iot: state.iotReducer.selectedIot,
    device: state.deviceReducer.selectedDevice,
    headquarter: state.headquarterReducer.selectedHeadquarter,
  };
};

const mapDispatchToProps = {
  setGlobalTitle,
  addButton,
  fetchAction,
  listFields,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeadquarterView);
