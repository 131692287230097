import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgAlarmForm(props) {
  const { height, width, color } = props;
  return (
    <svg  viewBox="-98 0 512 512"  xmlns="http://www.w3.org/2000/svg" {...props}
          height={height ? height : appConstants.ICON_DEFAULT_HEIGHT}
      width={width ? width : appConstants.ICON_DEFAULT_WIDTH}
    >
      <path d="M133.648 9.41h175.059v327.531H133.648zm0 0" fill="#faec75" />
      <path d="M133.648 9.41h56.47v327.531h-56.47zm0 0" fill="#f6d841" />
      <path d="M135.531 45.176h173.176V278.59H135.531zm0 0" fill="#9edcff" />
      <path d="M135.531 45.176h54.586V278.59h-54.586zm0 0" fill="#c7ebff" />
      <path d="M7.531 444.234h218.352v60.235H7.53zm0 0" fill="#c7d7e7" />
      <path d="M7.531 444.234h58.352v60.235H7.53zm0 0" fill="#e6f0fb" />
      <path
        d="M15.059 442.352V297.41c0-56.137 45.507-101.644 101.648-101.644 56.137 0 101.645 45.507 101.645 101.644v144.942"
        fill="#e2633f"
      />
      <path
        d="M129.883 196.613c-4.313-.558-8.711-.847-13.176-.847-56.14 0-101.648 45.507-101.648 101.644v144.942H41.41V297.41c0-51.672 38.559-94.336 88.473-100.797zm0 0"
        fill="#cf4f22"
      />
      <path d="M30.117 466.824h15.059v15.059H30.117zm0 0M188.234 466.824h15.059v15.059h-15.059zm0 0" />
      <path
        d="M60.234 399.059v-90.352c0 8.316 6.743 15.059 15.059 15.059 8.316 0 15.059 6.742 15.059 15.058v30.117c0 8.317-6.743 15.059-15.059 15.059-8.316 0-15.059 6.742-15.059 15.059zm0 0M173.176 399.059v-90.352c0 8.316-6.742 15.059-15.059 15.059-8.316 0-15.058 6.742-15.058 15.058v30.117c0 8.317 6.742 15.059 15.058 15.059 8.317 0 15.059 6.742 15.059 15.059zm0 0"
        fill="#de392e"
      />
      <path d="M109.176 466.824h15.058v15.059h-15.058zm0 0" />
      <path d="M225.883 436.707V297.41c0-60.2-48.977-109.176-109.176-109.176-60.203 0-109.176 48.977-109.176 109.176v139.297H0V512h233.41v-75.293zM22.59 297.41c0-51.894 42.219-94.117 94.117-94.117 51.895 0 94.117 42.223 94.117 94.117v139.297h-86.59v-15.059h-15.058v15.059H22.59zm195.762 199.531H15.059v-45.175h203.293zm0 0" />
      <path d="M97.883 361.41h11.293v45.18h15.058v-45.18h11.297v7.531c0 12.454 10.133 22.59 22.586 22.59 4.153 0 7.531 3.375 7.531 7.528h15.059v-90.352h-15.059c0 4.148-3.378 7.527-7.53 7.527-12.454 0-22.587 10.133-22.587 22.59v7.528H97.883v-7.528c0-12.457-10.133-22.59-22.59-22.59-4.152 0-7.527-3.379-7.527-7.527H52.707v90.352h15.059a7.534 7.534 0 017.527-7.528c12.457 0 22.59-10.136 22.59-22.59zm52.707-22.586c0-4.152 3.375-7.531 7.527-7.531 2.64 0 5.172-.453 7.531-1.29v47.759a22.494 22.494 0 00-7.53-1.293 7.534 7.534 0 01-7.528-7.528zm-67.766 30.117c0 4.153-3.379 7.528-7.531 7.528-2.637 0-5.172.457-7.527 1.293v-47.758c2.355.836 4.89 1.289 7.527 1.289 4.152 0 7.531 3.379 7.531 7.531zm0 0" />
      <g fill="#fff">
        <path d="M156.719 253.055a58.378 58.378 0 00-16.676-11.028l6.031-13.797a73.263 73.263 0 0120.973 13.864zm0 0M75.867 253.848l-10.539-10.754c13.805-13.528 32.05-20.977 51.379-20.977 4.219 0 8.45.363 12.574 1.074l-2.562 14.84a59.094 59.094 0 00-10.016-.855c-15.36 0-29.863 5.922-40.836 16.672zm0 0M278.59 82.824H165.648v60.235h73.48c4.294 13.53 9.34 22.59 9.34 22.59h15.063v-22.59h15.059zm0 0" />
      </g>
      <path d="M128 0v173.176h15.059V52.707h158.117v218.352H233.41v15.058h67.766v45.176H240.94v15.059h75.293V0zm15.059 37.648v-22.59h158.117v22.59zm0 0" />
      <path d="M271.059 301.176h15.058v15.058H271.06zm0 0M271.059 173.176h-27.012l-2.152-3.863c-.196-.344-4.192-7.57-8.172-18.723h-75.606V75.293h128v75.297H271.06zm-17.954-15.059H256v-22.586h15.059v-45.18h-97.883v45.18h71.465l1.664 5.25c2.394 7.543 5.05 13.649 6.8 17.336zm0 0" />
      <path d="M188.234 105.41h15.059v15.059h-15.059zm0 0M218.352 105.41H256v15.059h-37.648zm0 0" />
    </svg>
  );
}

export default SvgAlarmForm;
