import { Box, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Co2Icon } from '../../assets/icons';
import { getCo2 } from '../action/dashboardActions';
import ModalInfoCo2 from './ModalInfoCo2';

const Co2 = (props) => {
  const { co2, idDevice } = props;

  useEffect(() => {
    props.getCo2(idDevice);
    return () => {};
  }, []);
  return (
    <Grid container>
      <Box height={2 / 50} width={1} padding={2} marginTop={0} marginRight={2}>
        <ModalInfoCo2 />
      </Box>
      <Box height={9 / 10} width={1} padding={2} marginTop={10}>
        <Co2Icon height="250px" width="100%" />
      </Box>
      <Box height={1 / 10} width={1} margin={2}>
        <Grid container spacing={1}>
          <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
            <Box fontWeight="fontWeightBold" justifyContent="center" display="flex">
              <Typography className="fw-bold" variant="h6">
                {(co2 / 1000000).toFixed(4)} Toneladas
              </Typography>
            </Box>
            <Grid>
              <Typography className="t-center">
                *Cantidad de CO2 emitida desde la instalación del medidor principal
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    co2: state.dashboardReducer.co2 ? state.dashboardReducer.co2 : 0,
  };
};

const mapDispatchToProps = {
  getCo2,
};

export default connect(mapStateToProps, mapDispatchToProps)(Co2);
