import { Box, Button, Card, FormControl, FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { cleanFields, deleteField, listFields, listFieldsDeletes, setField } from '../actions/fieldActions';
import FieldEditModal from '../components/FieldEditModal';
import { getFieldObservable, handleFieldChange } from '../services/filterObservable';
const columns = [
  { id: 'name', label: 'Nombre' },
  { id: 'iotName', label: 'IOT', align: 'center' },
  {
    id: 'deviceName',
    label: 'Dispositivo',
    align: 'center',
  },
];

const FieldList = (props) => {
  const [state, setState] = React.useState({
    jason: false,
  });

  const { list, selectedDevice, selectedHeadquarter, selectedIot } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [observableFilter, setObservable] = useState(undefined);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (props.isRequiredFields == appConstants.IS_REQUIRED_FIELDS_LIST) {
      listItems();
    }
    handleFilterClick();

    if (props.selectedField != undefined) {
      return function () {
        if (observableFilter) observableFilter.unsubscribe();
        props.cleanFields();
      };
    }
  }, [props.field]);

  const handleFilterClick = () => {
    let sub = getFieldObservable().subscribe((data) => {
      if (data == true) {
        listItems();
        handleFieldChange(false);
      }
    });

    setObservable(sub);
  };

  const listItems = () => {
    let filter = getFilter();

    let filterDto = {
      sort: 'name',
      page: 0,
      size: 300,
      filters: filter,
    };
    props.listFields(filterDto);
  };

  const listItemsDeletes = () => {
    let filter = getFilter();

    let filterDto = {
      sort: 'name',
      page: 0,
      size: 300,
      filters: filter,
    };
    props.listFieldsDeletes(filterDto);
  };

  const getFilter = () => {
    let fieldFilter = {};
    fieldFilter.headquarter = selectedHeadquarter;
    fieldFilter.iot = selectedIot;
    fieldFilter.device = selectedDevice;

    return fieldFilter;
  };

  const handleEdit = (itemSelected) => {
    props.setField({ item: itemSelected });
  };
  const deserializarList = () => {
    let newList = [];
    props.list.forEach((element) => {
      newList.push(element.masterField);
    });
    return newList;
  };

  const handleDelete = (itemSelected) => {
    swal({
      title: appConstants.FIELD_VIEW_TITLE,
      text: appConstants.WARNING_DELETE,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteField(itemSelected?.id);
      }
    });
  };

  const formaterListForList = (listItems) => {
    let result = [];
    listItems.forEach((element) => {
      let aux = element;
      aux.deviceName = element?.device?.name;
      aux.iotName = element?.device?.iot?.name;

      result.push(aux);
    });
    return result;
  };

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });

    if (event.target.checked) {
      listItemsDeletes();
    } else {
      listItems();
    }
  };
  return (
    <Box width={1}>
      <Card className="border-10">
        <FormControl component="fieldset" className="w-100">
          <FormGroup aria-label="position" row className="center-info">
            <FormControlLabel
              control={<Switch checked={state.jason} onChange={handleChange} name="jason" color="primary" />}
              label="Datos Eliminados"
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>
        <TableContainer className="table-master">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    className="bg-primary fc-white ff-poppins fw-bold fs-Large t-center-important"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell align="center" className="bg-primary fc-white ff-poppins fw-bold fs-Large" key={'action'}>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formaterListForList(props.list).map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} className="t-center-important">
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell key={'action'} className="center-info" align="center">
                      <Button
                        color="primary"
                        className="btn-transparent ff-poppins fc-green min-width-1px"
                        onClick={(e) => handleEdit(row)}
                      >
                        <FieldEditModal />
                      </Button>
                      <Button
                        if="row.deleteAt == null"
                        color="primary"
                        className="btn-transparent ff-poppins fc-green min-width-1px"
                        onClick={(e) => handleDelete(row)}
                      >
                        <DeleteIcon color="secondary" />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Card>
    </Box>
  );
};

const mapDispatchToProps = {
  cleanFields,
  listFields,
  setField,
  deleteField,
  listFieldsDeletes,
};

const mapStateToProps = (state) => {
  return {
    list: state.fieldReducer.avaliabledFields ? state.fieldReducer.avaliabledFields : [],
    selectedDevice: state.deviceReducer.selectedDevice,
    headquarter: state.headquarterReducer.selectedHeadquarter ? state.headquarterReducer.selectedHeadquarter : {},
    iot: state.iotReducer.selectedIot ? state.iotReducer.selectedIot : {},
    isRequiredFields: state.fieldReducer.isRequiredFields
      ? state.fieldReducer.isRequiredFields
      : appConstants.IS_REQUIRED_FIELDS_LIST,
    field: state.fieldReducer.selectedField ? state.fieldReducer.selectedField : {},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FieldList);
