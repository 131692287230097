import { Grid } from '@material-ui/core';
import React from 'react';
import DeviceAutocomplete from '../../device/components/DeviceAutocomplete';
import FieldAutocomplete from '../../field/components/FieldAutocomplete';
import HeadquarterAutocomplete from '../../headquarter/components/HeadquarterAutocomplete';
import InstallationAutocomplete from '../../installation/components/lists/InstallationAutocomplete';
import IotAutocomplete from '../../iot/components/IotAutocomplete';
import LicenseCycleAutocomplete from '../../license/components/LicenseCycleAutocomplete';

const AutoCompleteFilters = (props) => {
  return (
    <Grid container spacing={2}>
      {props.headquarter && (
        <Grid
          item
          xl={props.xl ? props.xl : 12}
          lg={props.lg ? props.lg : 12}
          md={props.md ? props.md : 12}
          sm={12}
          xs={12}
        >
          <HeadquarterAutocomplete />
        </Grid>
      )}

      {props.installation && (
        <Grid
          item
          xl={props.xl ? props.xl : 12}
          lg={props.lg ? props.lg : 12}
          md={props.md ? props.md : 12}
          sm={12}
          xs={12}
        >
          <InstallationAutocomplete />
        </Grid>
      )}

      {props.iot && (
        <Grid
          item
          xl={props.xl ? props.xl : 12}
          lg={props.lg ? props.lg : 12}
          md={props.md ? props.md : 12}
          sm={12}
          xs={12}
        >
          <IotAutocomplete />
        </Grid>
      )}

      {props.device && (
        <Grid
          item
          xl={props.xl ? props.xl : 12}
          lg={props.lg ? props.lg : 12}
          md={props.md ? props.md : 12}
          sm={12}
          xs={12}
        >
          <DeviceAutocomplete deviceType={props.deviceType} />
        </Grid>
      )}

      {props.field && (
        <Grid
          item
          xl={props.xl ? props.xl : 12}
          lg={props.lg ? props.lg : 12}
          md={props.md ? props.md : 12}
          sm={12}
          xs={12}
        >
          <FieldAutocomplete />
        </Grid>
      )}
      {props.licenseCycle && (
        <Grid
          item
          xl={props.xl ? props.xl : 12}
          lg={props.lg ? props.lg : 12}
          md={props.md ? props.md : 12}
          sm={12}
          xs={12}
        >
          <LicenseCycleAutocomplete />
        </Grid>
      )}
    </Grid>
  );
};

export default AutoCompleteFilters;
