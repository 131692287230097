import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { listValues } from '../actions/valueActions';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: '40%',
    minHeight: '40%',
  },
});

const getMedida = (data) => {
  if (data === '') {
    return '';
  }
  if (data === 'NA') {
    return '';
  }
  if (data === 'PORCENTAJE') {
    return '%';
  }
  if (data === 'C') {
    return '°C';
  }

  return data;
};

const ListValue = (props) => {
  const { list } = props;
  const classes = useStyles();
  useEffect(() => {
    if (props.isRequiredValues == appConstants.IS_REQUIRED_VALUE_LIST) {
      props.listValues({
        page: 0,
        size: 27,
        sort: 'name',
        filters: { deviceId: props?.device?.id },
      });
    }
  }, []);

  return (
    <Box height={1} className="bg-white">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" className="bg-primary fc-white ff-poppins fw-bold fs-Large ">
                Nombre
              </StyledTableCell>
              <StyledTableCell align="center" className="bg-primary fc-white ff-poppins fw-bold fs-Large ">
                Valor
              </StyledTableCell>
              <StyledTableCell align="center" className="bg-primary fc-white ff-poppins fw-bold fs-Large ">
                Unidad
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell component="th" scope="row" align="center">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {(row.value * 1).toFixed(4) != 'NaN' ? (row.value * 1).toFixed(4) : row.value}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                  {getMedida(row.field?.masterField?.unitMeasurement)}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const mapStateToProps = (state) => {
  let stateList = {
    isRequiredValues: state.valueReducer.isRequiredValues
      ? state.valueReducer.isRequiredValues
      : appConstants.IS_REQUIRED_VALUE_LIST,
    list: state.valueReducer.avaliabledValues ? state.valueReducer.avaliabledValues : [],
  };

  return stateList;
};

const mapDispatchToProps = {
  listValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListValue);
