import { Box, Grid } from '@material-ui/core/';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import HeadquarterAutocomplete from '../../../headquarter/components/HeadquarterAutocomplete';

const FormInstallation = (props) => {
  const [formInst, setForm] = useState({ name: '', description: '' });

  const handleChangeName = (event) => {
    props.handleChangeForm('name', event);
    setForm({ ...formInst, name: event.target.value });
  };

  const handleChangeDescription = (event) => {
    props.handleChangeForm('description', event);
    setForm({ ...formInst, description: event.target.value });
  };

  useEffect(() => {
    setForm({ description: props.selectedInstallation?.description, name: props.selectedInstallation?.name });
  }, [props.selectedInstallation]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <HeadquarterAutocomplete label="sede" />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
            <TextField
              required
              id="name"
              label="Nombre"
              fullWidth
              inputProps={{
                maxLength: 100,
              }}
              variant="outlined"
              value={formInst.name}
              onChange={(valueEvent) => handleChangeName(valueEvent)}
            />
          </Box>
        </Grid>
        <Grid item item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
            <TextField
              required
              id="description"
              label="Descripción"
              fullWidth
              inputProps={{
                maxLength: 100,
              }}
              multiline
              rows={3}
              variant="outlined"
              value={formInst.description}
              onChange={(valueEvent) => handleChangeDescription(valueEvent)}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  return {
    selectedInstallation: state.installationReducer.selectedInstallation
      ? state.installationReducer.selectedInstallation
      : undefined,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormInstallation);
