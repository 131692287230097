import { Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { componentActions, fetchAction, setGlobalTitle } from '../../components/actions/componentActions';
import Loading from '../../components/Loading';
import IotForm from '../components/IotForm';
import IotList from '../components/IotList';

const IotView = (props) => {
  const toolbarButtons = [{ id: appConstants.BACK_BUTTON_ID, history: props.history }];

  useEffect(() => {
    props.setGlobalTitle(appConstants.IOT_VIEW_TITLE);

    props.fetchAction(componentActions.ADD_TOOLBAR_BUTTON, toolbarButtons);
  }, []);

  return (
    <Box height={1} width={1} padding={1} className="background-image-bubble mt-10px">
      <Grid container spacing={2}>
        <Grid item xl={5} lg={5} md={6} xs={12} sm={12}>
          <Box boxShadow={4} borderRadius={10}>
            <IotForm />
          </Box>
        </Grid>
        <Grid item xl={7} lg={7} md={6} xs={12} sm={12}>
          <Box boxShadow={4} borderRadius={10}>
            <IotList />
          </Box>
        </Grid>
      </Grid>
      <Loading />
    </Box>
  );
};

const mapDispatchToProps = {
  setGlobalTitle,
  fetchAction,
};

export default connect(null, mapDispatchToProps)(IotView);
