import { appConstants } from '../../appConstants';
import ApiAxios from '../../service/Api';
import apiUser from '../services/apiUser';

export const userActions = {
  USER_LOGIN: 'USER_LOGIN',
  FETCHING_LOGIN: 'FETCHING_LOGIN',
  FETCHING_LOGIN_SUCCESS: 'FETCHING_LOGIN_SUCCESS',
  FETCHING_REQUEST_FAILED: 'USER_LOGIN',
  CLEAN_USER_STORE: 'CLEAN_USER_STORE',
  SET_USER: 'SET_USER',
  FETCHING_SAVE_USER_REQUEST: 'FETCHING_SAVE_USER_REQUEST',
  FETCHING_SAVE_USER_SUCCESS: 'FETCHING_SAVE_USER_SUCCESS',
  FETCHING_UPDATE_USER_REQUEST: 'FETCHING_UPDATE_USER_REQUEST',
  FETCHING_UPDATE_USER_SUCCESS: 'FETCHING_UPDATE_USER_SUCCESS',
  FETCHING_LIST_USER_REQUEST: 'FETCHING_LIST_USER_REQUEST',
  FETCHING_LIST_USER_SUCCESS: 'FETCHING_LIST_USER_SUCCESS',
  FETCHING_CURRENT_USER_REQUEST: 'FETCHING_CURRENT_USER_REQUEST',
  FETCHING_CURRENT_USER_SUCCESS: 'FETCHING_CURRENT_USER_SUCCESS',
  FETCHING_LIST_ROLES: 'FETCHING_LIST_ROLES',
  FETCHING_LIST_ROLES_SUCCESS: 'FETCHING_LIST_ROLES_SUCCESS',

  FETCHING_CHANGE_PASSWORD: 'FETCHING_CHANGE_PASSWORD',
  FETCHING_CHANGE_PASSWORD_SUCCESS: 'FETCHING_CHANGE_PASSWORD_SUCCESS',

  FETCHING_USER_DELETE_SUCCESS: 'FETCHING_USER_DELETE_SUCCESS',
  FETCHING_USER_REQUEST: 'FETCHING_USER_REQUEST',

  LOG_OUT: 'LOG_OUT',

  FETCHING_RECOVER_PASSWORD_REQUEST: 'FETCHING_RECOVER_PASSWORD_REQUEST',
  FETCHING_RECOVER_PASSWORD_SUCCESS: 'FETCHING_RECOVER_PASSWORD_SUCCESS',
};

export const fetchRequest = (type, payload = undefined) => {
  return {
    type: type,
    payload: payload,
  };
};

export const userLogin = (login) => {
  return function (dispatch) {
    apiUser.actions.login(dispatch, login, {
      request: userActions.FETCHING_LOGIN,
      success: userActions.FETCHING_LOGIN_SUCCESS,
      error: userActions.FETCHING_REQUEST_FAILED,
    });
  };
};

export const cleanUser = () => {
  return {
    type: userActions.CLEAN_USER_STORE,
  };
};

export const saveUser = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_USER, item, dispatch, fetchRequest, {
      request: userActions.FETCHING_SAVE_USER_REQUEST,
      success: userActions.FETCHING_SAVE_USER_SUCCESS,
      error: userActions.FETCHING_REQUEST_FAILED,
    });
  };
};

export const updateUser = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.put(appConstants.CONTEXT_USER, item, dispatch, fetchRequest, {
      request: userActions.FETCHING_UPDATE_USER_REQUEST,
      success: userActions.FETCHING_UPDATE_USER_SUCCESS,
      error: userActions.FETCHING_REQUEST_FAILED,
    });
  };
};

export const listUsers = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_USERS, item, dispatch, fetchRequest, {
      request: userActions.FETCHING_LIST_USER_REQUEST,
      success: userActions.FETCHING_LIST_USER_SUCCESS,
      error: userActions.FETCHING_REQUEST_FAILED,
    });
  };
};

export const getCurrentUser = () => {
  return function (dispatch) {
    ApiAxios.crudGeneric.get(`${appConstants.CONTEXT_USER}`, dispatch, fetchRequest, {
      request: userActions.FETCHING_CURRENT_USER_REQUEST,
      success: userActions.FETCHING_CURRENT_USER_SUCCESS,
      error: userActions.FETCHING_REQUEST_FAILED,
    });
  };
};

export const listRoles = () => {
  return function (dispatch) {
    ApiAxios.crudGeneric.get(`${appConstants.CONTEXT_ROLES}user`, dispatch, fetchRequest, {
      request: userActions.FETCHING_LIST_ROLES,
      success: userActions.FETCHING_LIST_ROLES_SUCCESS,
      error: userActions.FETCHING_REQUEST_FAILED,
    });
  };
};

export const changePassowrd = (formChangePassword) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(`${appConstants.CONTEXT_USER}password`, formChangePassword, dispatch, fetchRequest, {
      request: userActions.FETCHING_CHANGE_PASSWORD,
      success: userActions.FETCHING_CHANGE_PASSWORD_SUCCESS,
      error: userActions.FETCHING_REQUEST_FAILED,
    });
  };
};

export const deleteUser = (id) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.delete(`${appConstants.CONTEXT_USER}${id}`, dispatch, fetchRequest, {
      request: userActions.FETCHING_USER_REQUEST,
      success: userActions.FETCHING_USER_DELETE_SUCCESS,
      error: userActions.FETCHING_REQUEST_FAILED,
    });
  };
};

export const recoverPassword = (email) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.get(`${appConstants.CONTEXT_RECOVER_PASSWORD}${email}`, dispatch, fetchRequest, {
      request: userActions.FETCHING_RECOVER_PASSWORD_REQUEST,
      success: userActions.FETCHING_RECOVER_PASSWORD_SUCCESS,
      error: userActions.FETCHING_REQUEST_FAILED,
    });
  };
};
