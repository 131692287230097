import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgHistoricoIcon(props) {
  const { height, width, color } = props;
  return (
    <svg height={512} viewBox="0 0 64 64" width={512} xmlns="http://www.w3.org/2000/svg" {...props}
           height={height ? height : appConstants.ICON_DEFAULT_HEIGHT}
      width={width ? width : appConstants.ICON_DEFAULT_WIDTH}
    >
      <g data-name="Copyright">
        <rect fill="#bcbec0" height={44} rx={2} width={34} x={18} y={3} />
        <rect fill="#d1d3d4" height={44} rx={2} width={34} x={27} y={13} />
        <path d="M39 19v38h-2V19a2.006 2.006 0 00-2-2h2a2.006 2.006 0 012 2z" fill="#bcbec0" />
        <path d="M52 13h-1v8l1-2 1-2 2 4v-8zM46 3v6l-2-3-2 3V3z" fill="#ff5023" />
        <rect fill="#e6e7e8" height={44} rx={2} width={34} x={3} y={17} />
        <path
          d="M37 30.33L34 29l-4.5 2-2.5 1.11-2 .89v6a11.9 11.9 0 00.71 4 12.354 12.354 0 002.4 4 11.766 11.766 0 003.06 2.43L34 51l2.83-1.57a.95.95 0 00.17-.1A11.74 11.74 0 0039.89 47a11.381 11.381 0 001.46-2 11.962 11.962 0 001.47-4 11.69 11.69 0 00.18-2.06V33z"
          fill="#ffd422"
        />
        <path d="M31 17v8l-2-4-2 4v-8z" fill="#ff5023" />
        <path d="M59 12h-6V5a3 3 0 00-3-3H20a3 3 0 00-3 3v11H5a3 3 0 00-3 3v40a3 3 0 003 3h30a3 3 0 003-3v-1h21a3 3 0 003-3V15a3 3 0 00-3-3zm-7 2h2v2.764l-.1-.211a1.042 1.042 0 00-1.79 0l-.1.211zM43 4h2v1.7l-.168-.252a1.039 1.039 0 00-1.664 0L43 5.7zM19 5a1 1 0 011-1h21v5a1 1 0 001.832.555L44 7.8l1.168 1.752A1 1 0 0047 9V4h3a1 1 0 011 1v7H29a3 3 0 00-3 3v1h-7zm9 13h2v2.764l-.105-.211a1.042 1.042 0 00-1.79 0l-.105.211zm7 42H5a1 1 0 01-1-1V19a1 1 0 011-1h21v7a1 1 0 001.895.447L29 23.236l1.105 2.211A1 1 0 0032 25v-7h3a1 1 0 011 1v9.8l-1.594-.709a1 1 0 00-.812 0l-9 4A1 1 0 0024 33v5.939A13.006 13.006 0 0030.687 50.3l2.828 1.571a1 1 0 00.97 0L36 51.033V59a1 1 0 01-1 1zm1.343-11.445L34 49.856l-2.342-1.3A11.007 11.007 0 0126 38.939V33.65l8-3.556 8 3.556v5.289a11.007 11.007 0 01-5.657 9.616zM60 55a1 1 0 01-1 1H38v-6.109a13.005 13.005 0 006-10.952V33a1 1 0 00-.594-.914L38 29.684V19a3 3 0 00-3-3h-7v-1a1 1 0 011-1h21v7a1 1 0 001.9.447l1.1-2.211 1.1 2.211A1 1 0 0056 21v-7h3a1 1 0 011 1z" />
        <path d="M40 24h16v2H40zM42 28h14v2H42zM52 32h4v2h-4zM46 32h4v2h-4zM46 36h10v2H46zM46 40h4v2h-4zM52 40h4v2h-4zM46 44h10v2H46zM44 48h4v2h-4zM50 48h2v2h-2zM54 48h2v2h-2zM8 30h6v2H8zM16 30h6v2h-6zM8 46h6v2H8zM16 46h8v2h-8zM8 50h8v2H8zM18 50h10v2H18zM8 34h2v2H8zM12 34h10v2H12zM8 38h14v2H8zM8 42h14v2H8zM23 7h8v2h-8zM33 7h4v2h-4zM8 22h4v2H8zM14 22h8v2h-8zM8 26h14v2H8zM34 37a3 3 0 012.25 1.016l1.5-1.323a5 5 0 100 6.614l-1.5-1.323A3 3 0 1134 37z" />
      </g>
    </svg>
  );
}

export default SvgHistoricoIcon;
