import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgTrendIcon(props) {
  const { height, width, color } = props;
  return (
    <svg viewBox="-4 1 596 596.718" xmlns="http://www.w3.org/2000/svg" {...props}
         height={height ? height : appConstants.ICON_DEFAULT_HEIGHT}
      width={width ? width : appConstants.ICON_DEFAULT_WIDTH}
    >
      <path d="M30 330h91v167H30zM271 300h91v197h-91z" fill="#fed843" />
      <path d="M151 240h90v257h-90zM392 180h90v317h-90z" fill="#ff641a" />
      <path d="M76 330h45v167H76z" fill="#fabe2c" />
      <path d="M196 240h45v257h-45z" fill="#f03800" />
      <path d="M316 300h46v197h-46z" fill="#fabe2c" />
      <path d="M437 180h45v317h-45z" fill="#f03800" />
      <path
        d="M392 0v30h68.789L316 173.789l-60-60-60-60L4.395 244.395l21.21 21.21L196 96.211l60 60 60 60 166-165V120h30V0z"
        fill="#97de3d"
      />
      <path d="M482 51.211V120h30V0H392v30h68.789L316 173.789l-60-60v42.422l60 60z" fill="#59c36a" />
      <path d="M256 482H0v30h512v-30z" fill="#2d5177" />
      <path d="M256 482h256v30H256z" fill="#32405d" />
    </svg>
  );
}

export default SvgTrendIcon;
