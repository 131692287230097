import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { componentActions, fetchAction, setGlobalTitle } from '../../components/actions/componentActions';
import Loading from '../../components/Loading';
import { cleanFields, listFields } from '../../field/actions/fieldActions';
import { dashboardAction, fetchRequest, getIntervals } from '../action/dashboardActions';
import BarGraphicComparative from '../components/BarGraphicComparative';
import Co2 from '../components/Co2';
import SelectedGlobalComparativeDates from '../components/SelectedGlobalComparativeDates';
import TrendTitle from '../components/TrendTitle';

class BarDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reload: false,
    };

    this.toolbarButtons = [
      { id: appConstants.BACK_BUTTON_ID, history: props.history },
      { id: appConstants.FILTER_DASHBOARD_BUTTON },
    ];
  }

  buildToolbar = () => {
    this.props.setGlobalTitle(appConstants.DASHBOARD_TITLE);
    this.props.fetchAction(componentActions.ADD_TOOLBAR_BUTTON, this.toolbarButtons);
  };

  componentWillUnmount() {
    this.props.cleanFields();
    this.props.fetchRequest(dashboardAction.CLEAN_DASHBOARD);
    this.props.fetchRequest(componentActions.RESET_HOME);
    this.props.fetchRequest(dashboardAction.CLEAN_SELECTED_FILTER);
  }

  componentDidMount() {
    if (this.props.isRequiredIntervals == appConstants.IS_REQUIRED_DASHBOARD_INTERVALS) this.props.getIntervals();

    if (this.props.isRequiredFields == appConstants.IS_REQUIRED_FIELDS_LIST) {
      let filterOfMaster = {
        typeGraphic: 'BAR',
      };
      this.props.listFields({
        sort: 'name',
        page: 0,
        size: 300,
        filters: { masterField: filterOfMaster },
      });
    }

    if (this.props.isRequiredAddButtons) this.buildToolbar();
  }

  componentDidUpdate(previosProp, prevState, snapshot) {
    if (
      this.props.isRequiredFields === appConstants.IS_REQUIRED_FIELDS_LIST &&
      previosProp.isRequiredFields === appConstants.IS_NOT_REQUIRED_FIELDS_LIST
    ) {
      let filterOfMaster = {
        typeGraphic: 'BAR',
      };
      this.props.listFields({
        sort: 'name',
        page: 0,
        size: 300,
        filters: { masterField: filterOfMaster },
      });
    }
  }
  getPrincipalDevices() {
    let devices = [];
    this.props.list.forEach((field) => {
      if (field?.device?.classification == 'PRINCIPAL') devices.push(field?.device);
    });
    const tabla = {};
    return devices.filter((indice) => {
      return tabla.hasOwnProperty(indice) ? false : (tabla[indice] = true);
    });
  }

  render() {
    return (
      <Box width={1} padding={1} className="background-image-bubble">
        <Grid className="secundary-title" container spacing={2} alignContent="center">
          <TrendTitle />
          <SelectedGlobalComparativeDates />
        </Grid>
        <Box width={1} padding={0}>
          <Grid container spacing={2}>
            {this.getPrincipalDevices().map((element) => {
              return (
                <Grid key={element.id} item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Box key={element.id} id={element.id} boxShadow={3} height={1} width={1}>
                    <Co2 idDevice={element.id} />
                  </Box>
                </Grid>
              );
            })}

            {this.props.list.map((element) => {
              return (
                <Grid key={element.id} item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Box key={element.id} id={element.id} boxShadow={3} height={1} width={1}>
                    <BarGraphicComparative field={element} />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <Loading />
        </Box>
      </Box>
    );
  }
}

const mapDispatchToProps = {
  getIntervals,
  setGlobalTitle,
  fetchAction,
  cleanFields,
  listFields,

  fetchRequest,
};

const mapStateTotProps = (state) => {
  return {
    list: state.fieldReducer.avaliabledFields ? state.fieldReducer.avaliabledFields : [],
    isRequiredFields: state.fieldReducer.isRequiredFields
      ? state.fieldReducer.isRequiredFields
      : appConstants.IS_REQUIRED_FIELDS_LIST,
    isRequiredIntervals: state.dashboardReducer.isRequiredIntervals
      ? state.dashboardReducer.isRequiredIntervals
      : appConstants.IS_REQUIRED_DASHBOARD_INTERVALS,
    isRequiredAddButtons: state.componentsReducer.isRequiredAddButtons
      ? state.componentsReducer.isRequiredAddButtons
      : appConstants.IS_REQUIRED_ADD_BUTTON,
    frecuency: state.dashboardReducer.frecuency,
  };
};

export default connect(mapStateTotProps, mapDispatchToProps)(BarDashboard);
