import { Box, Button, ButtonGroup, Card, CardActions, CardContent, Grid, TextField } from '@material-ui/core';
import Brush from '@material-ui/icons/Brush';
import Save from '@material-ui/icons/Save';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { commonMessage, icon } from '../../service/messageHandler';
import { validateEmail } from '../../util/validations';
import { companyActions, fetchRequest, saveCompany, updateCompany } from '../actions/companyActions';

const CompanyForm = (props) => {
  const { company } = props;

  const [companyForm, setCompanyForm] = useState({
    name: '',
    identification: '',
    contactMail: '',
    contactNumber: '',
    representativeName: '',
    roles: {},
  });

  useEffect(() => {
    deserialize();
  }, [props.companyForm, company]);

  const deserialize = () => {
    let valueForm = {};
    valueForm.id = company.id ? company.id : '';
    valueForm.name = company.name ? company.name : '';
    valueForm.identification = company.identification ? company.identification : '';
    valueForm.contactMail = company.contactMail ? company.contactMail : '';
    valueForm.contactNumber = company.contactNumber ? company.contactNumber : '';
    valueForm.representativeName = company.representativeName ? company.representativeName : '';

    setCompanyForm({
      ...valueForm,
    });
  };

  const handleClean = () => {
    props.fetchRequest(companyActions.SET_COMPANY, { item: {} });
  };

  const handleSave = () => {
    let companySended = {
      ...companyForm,
    };

    let msn = validate(companyForm);

    if (msn == '') {
      if (!companySended.id) props.saveCompany(companySended);
      else props.updateCompany(companySended);
    } else {
      commonMessage(msn, appConstants.VALIDATION_ERROR_TITLE, icon.info);
    }
  };

  const validate = (companyParam) => {
    let msn = '';

    msn +=
      !companyParam.identification || companyParam.identification?.trim() == ''
        ? 'Debe especificar la identificación  \n'
        : '';

    msn += !companyParam.name || companyParam.name.trim() == '' ? 'Debe especificar el nombre \n' : '';

    msn +=
      !companyParam.representativeName || companyParam.representativeName.trim() == ''
        ? 'Debe especificar el nombre \n'
        : '';

    msn +=
      !companyParam.contactNumber || companyParam.contactNumber.trim() == '' ? 'Debe especificar el teléfono \n' : '';

    if (companyParam.contactNumber < 0) {
      msn += '  El número de teléfono debe ser positivo \n';
    }

    msn += validateEmail(companyParam.contactMail);

    return msn;
  };

  return (
    <Box height={1}>
      <Card className="border-10">
        <CardContent>
          <form noValidate={false} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item md={12} xl={12} lg={12} sm={12} xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    id="name"
                    label="Nombre de la compañia"
                    fullWidth
                    className="border-none"
                    variant="outlined"
                    inputProps={{
                      maxLength: 100,
                    }}
                    value={companyForm.name}
                    onChange={(valueEvent) => setCompanyForm({ ...companyForm, name: valueEvent.target.value })}
                  />
                </Box>
              </Grid>

              <Grid item md={12} xl={12} lg={12} sm={12} xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    fullWidth
                    id="identification"
                    label="Identificación"
                    type="text"
                    inputProps={{
                      maxLength: 15,
                    }}
                    variant="outlined"
                    value={companyForm.identification}
                    onChange={(valueEvent) =>
                      setCompanyForm({ ...companyForm, identification: valueEvent.target.value })
                    }
                  />
                </Box>
              </Grid>

              <Grid item md={12} xl={12} lg={12} sm={12} xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    type="number"
                    id="contactNumber"
                    label="Teléfono"
                    fullWidth
                    inputProps={{
                      maxLength: 100,
                    }}
                    variant="outlined"
                    value={companyForm.contactNumber}
                    onChange={(valueEvent) =>
                      setCompanyForm({ ...companyForm, contactNumber: valueEvent.target.value })
                    }
                  />
                </Box>
              </Grid>
              <Grid item md={12} xl={12} lg={12} sm={12} xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    type="email"
                    required
                    id="contactMail"
                    label="Correo electrónico"
                    fullWidth
                    inputProps={{
                      maxLength: 100,
                    }}
                    variant="outlined"
                    value={companyForm.contactMail}
                    onChange={(valueEvent) => setCompanyForm({ ...companyForm, contactMail: valueEvent.target.value })}
                  />
                </Box>
              </Grid>
              <Grid item md={12} xl={12} lg={12} sm={12} xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    id="representativeName"
                    label="Nombre del usuario"
                    fullWidth
                    inputProps={{
                      maxLength: 100,
                    }}
                    variant="outlined"
                    value={companyForm.representativeName}
                    onChange={(valueEvent) =>
                      setCompanyForm({ ...companyForm, representativeName: valueEvent.target.value })
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <CardActions className="center d-flex">
          <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
            <Button className="bg-green-gradient mr-2 pl-5 pr-5" onClick={handleSave}>
              <Save />
              {company.id ? 'Actualizar' : 'Guardar'}
            </Button>

            <Button disabled={companyForm == {}} className="bg-green-gradient mr-2 pl-5 pr-5" onClick={handleClean}>
              <Brush />
              Limpiar
            </Button>
          </ButtonGroup>
        </CardActions>
      </Card>
    </Box>
  );
};

const mapDispatchToProps = {
  fetchRequest,
  saveCompany,
  updateCompany,
};

const mapStateToProps = (state) => {
  return {
    company: state.companyReducer.selectedCompany ? state.companyReducer.selectedCompany : {},
    isRequiredCompanies: state.companyReducer.isRequiredCompanies,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyForm);
