import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgDeviceIcon(props) {
  const { height, width, color } = props;
  return (
    <svg  viewBox="-76 0 512 512" xmlns="http://www.w3.org/2000/svg" {...props}
                  height={height ? height : appConstants.ICON_DEFAULT_HEIGHT}
      width={width ? width : appConstants.ICON_DEFAULT_WIDTH}
    >
      <path
        d="M331.5 504.5h-304c-11.047 0-20-8.953-20-20v-457c0-11.047 8.953-20 20-20h304c11.047 0 20 8.953 20 20v457c0 11.047-8.953 20-20 20zm0 0"
        fill="#ffe0b2"
      />
      <path
        d="M331.5 7.5h-30c11.047 0 20 8.953 20 20v457c0 11.047-8.953 20-20 20h30c11.047 0 20-8.953 20-20v-457c0-11.047-8.953-20-20-20zm0 0"
        fill="#ffcc80"
      />
      <path d="M321.5 37.5v437h-284v-437zm0 0" fill="#4380a0" />
      <path
        d="M269.5 358c0 49.707-40.293 90-90 90s-90-40.293-90-90 40.293-90 90-90 90 40.293 90 90zm0 0"
        fill="#ffe0b2"
      />
      <g fill="#224f69">
        <path d="M146.5 348.5c-7.18 0-13-5.82-13-13v-14c0-7.18 5.82-13 13-13s13 5.82 13 13v14c0 7.18-5.82 13-13 13zm0 0M212.5 348.5c-7.18 0-13-5.82-13-13v-14c0-7.18 5.82-13 13-13s13 5.82 13 13v14c0 7.18-5.82 13-13 13zm0 0M190.5 407.5h-22c-1.105 0-2-.895-2-2v-26c0-1.105.895-2 2-2h22c1.105 0 2 .895 2 2v26c0 1.105-.895 2-2 2zm0 0" />
      </g>
      <path d="M268.5 220h-178a8 8 0 01-8-8V88.5a8 8 0 018-8h178a8 8 0 018 8V212a8 8 0 01-8 8zm0 0" fill="#bdf9ff" />
      <path d="M268.5 80.5h-30a8 8 0 018 8V212a8 8 0 01-8 8h30a8 8 0 008-8V88.5a8 8 0 00-8-8zm0 0" fill="#8cf2ff" />
      <path d="M351.5 88.168a7.497 7.497 0 00-7.5 7.5V484.5c0 6.895-5.605 12.5-12.5 12.5h-304c-6.895 0-12.5-5.605-12.5-12.5V448c0-4.14-3.36-7.5-7.5-7.5S0 443.86 0 448v36.5C0 499.664 12.336 512 27.5 512h304c15.164 0 27.5-12.336 27.5-27.5V95.668c0-4.145-3.355-7.5-7.5-7.5zm0 0M331.5 0h-304C12.336 0 0 12.336 0 27.5v336.332a7.5 7.5 0 1015 0V27.5C15 20.605 20.605 15 27.5 15h304c6.895 0 12.5 5.605 12.5 12.5V64c0 4.14 3.355 7.5 7.5 7.5s7.5-3.36 7.5-7.5V27.5C359 12.336 346.664 0 331.5 0zm0 0M7.5 423.668c4.14 0 7.5-3.36 7.5-7.5v-20a7.5 7.5 0 10-15 0v20c0 4.14 3.36 7.5 7.5 7.5zm0 0" />
      <path d="M321.5 482a7.5 7.5 0 007.5-7.5v-437a7.5 7.5 0 00-7.5-7.5h-284c-4.14 0-7.5 3.36-7.5 7.5v437c0 4.14 3.36 7.5 7.5 7.5zM45 45h269v422H45zm0 0" />
      <path d="M179.5 260.5c-53.762 0-97.5 43.738-97.5 97.5s43.738 97.5 97.5 97.5S277 411.762 277 358s-43.738-97.5-97.5-97.5zm0 180c-45.492 0-82.5-37.008-82.5-82.5s37.008-82.5 82.5-82.5c45.488 0 82.5 37.008 82.5 82.5s-37.012 82.5-82.5 82.5zm0 0" />
      <path d="M167 335.5v-14c0-11.305-9.195-20.5-20.5-20.5s-20.5 9.195-20.5 20.5v14c0 11.305 9.195 20.5 20.5 20.5s20.5-9.195 20.5-20.5zm-15 0c0 3.031-2.469 5.5-5.5 5.5s-5.5-2.469-5.5-5.5v-14c0-3.031 2.469-5.5 5.5-5.5s5.5 2.469 5.5 5.5zm0 0M212.5 301c-11.305 0-20.5 9.195-20.5 20.5v14c0 11.305 9.195 20.5 20.5 20.5s20.5-9.195 20.5-20.5v-14c0-11.305-9.195-20.5-20.5-20.5zm5.5 34.5c0 3.031-2.469 5.5-5.5 5.5s-5.5-2.469-5.5-5.5v-14c0-3.031 2.469-5.5 5.5-5.5s5.5 2.469 5.5 5.5zm0 0M179.5 370c-11.305 0-20.5 9.195-20.5 20.5v4c0 11.305 9.195 20.5 20.5 20.5s20.5-9.195 20.5-20.5v-4c0-11.305-9.195-20.5-20.5-20.5zm5.5 24.5c0 3.031-2.469 5.5-5.5 5.5s-5.5-2.469-5.5-5.5v-4c0-3.031 2.469-5.5 5.5-5.5s5.5 2.469 5.5 5.5zm0 0M268.5 73h-178C81.953 73 75 79.953 75 88.5V212c0 8.547 6.953 15.5 15.5 15.5h178c8.547 0 15.5-6.953 15.5-15.5V88.5c0-8.547-6.953-15.5-15.5-15.5zm.5 139c0 .277-.227.5-.5.5h-178a.498.498 0 01-.5-.5V88.5c0-.277.223-.5.5-.5h178c.273 0 .5.223.5.5zm0 0" />
      <path d="M112.5 94.574c-4.14 0-7.5 3.36-7.5 7.5v51a7.5 7.5 0 1015 0v-51c0-4.14-3.36-7.5-7.5-7.5zm0 0M160.035 94.574h-7.383c-11.168 0-20.25 9.086-20.25 20.25 0 4.828 1.7 9.27 4.528 12.75a20.169 20.169 0 00-4.528 12.75c0 11.168 9.082 20.25 20.25 20.25h7.383c11.164 0 20.25-9.082 20.25-20.25 0-4.828-1.7-9.265-4.531-12.75a20.142 20.142 0 004.531-12.75c0-11.164-9.086-20.25-20.25-20.25zm-7.383 15h7.38c2.847 0 5.25 2.406 5.25 5.25 0 2.848-2.403 5.25-5.25 5.25h-7.38c-2.847 0-5.25-2.402-5.25-5.25 0-2.844 2.403-5.25 5.25-5.25zm7.383 36h-7.383c-2.847 0-5.25-2.402-5.25-5.25 0-2.844 2.403-5.25 5.25-5.25h7.383c2.844 0 5.25 2.406 5.25 5.25 0 2.848-2.406 5.25-5.25 5.25zm0 0M208.387 160.574h7.343c11.176 0 20.27-9.09 20.27-20.27v-25.46c0-11.176-9.094-20.27-20.27-20.27h-7.343c-11.176 0-20.27 9.09-20.27 20.27v25.46c0 11.176 9.094 20.27 20.27 20.27zm-5.27-45.73a5.275 5.275 0 015.27-5.27h7.343a5.275 5.275 0 015.27 5.27v25.46a5.275 5.275 0 01-5.27 5.27h-7.343a5.275 5.275 0 01-5.27-5.27zm0 0M249.5 168.027a7.504 7.504 0 00-10.262 2.684l-6.273 10.715-4.668-5.688c-2.996-3.64-8.586-3.652-11.594 0l-4.668 5.688-6.273-10.715a7.5 7.5 0 10-12.945 7.578l11.71 20c2.633 4.5 8.953 5.004 12.27.973l5.703-6.946 5.703 6.946c3.328 4.047 9.645 3.511 12.27-.973l11.71-20a7.5 7.5 0 00-2.683-10.262zm0 0" />
    </svg>
  );
}

export default SvgDeviceIcon;
