import { Box, Button, Grid, Modal, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CancelIcon, FormuCarg, FormuMax, FormuMed } from '../../assets/icons';
import '../../assets/styles/App.scss';

const ModalInfoChar = (props) => {
  const [open, setOpen] = useState(false);

  const changeOpen = (event) => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);
  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <Box className="mt-20px center-info">
      <Grid container spacing={2} className="size-modal">
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info bg-white ">
          <Grid container>
            <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
              <Typography className="ff-poppins fc-primary fs-t-text t-justify center-midd mt-20px">
                Demanda máxima es la demanda de energía más alta producida durante el periodo de tiempo seleccionado.
                Adicionalmente se adiciona el factor de demanda en porcentaje que esta dado por la siguiente formula
              </Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="center-icon-char">
              <FormuMax height={200} width={420} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info bg-white ">
          <Grid container>
            <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
              <Typography className="ff-poppins fc-primary fs-t-text t-justify center-midd mt-20px">
                Demanda media sostenida es la demanda de energía promediada de todos los valores medidos durante el
                periodo de tiempo seleccionado. Adicionalmente se adiciona el factor de demanda media en porcentaje que
                esta dado por la siguiente fórmula
              </Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="center-icon-char">
              <FormuMed height={200} width={420} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info bg-white ">
          <Grid container>
            <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
              <Typography className="ff-poppins fc-primary fs-t-text t-justify center-midd mt-20px">
                El factor de carga indica el grado al cual el pico de la carga es sostenido durante el periodo de carga.
                Esto quiere decir que, si el factor de carga es 1, la demanda máxima se mantiene constante, si el factor
                de carga es alto (por ejemplo 0.9), la curva de carga tiene muy pocas variaciones y en cambio si el
                factor de carga es bajo (por ejemplo 0.5), la curva de carga sufre muchas variaciones con picos y valles
                pronunciados. Para este caso la demanda es muy variante.
              </Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="center-icon-char">
              <FormuCarg height={200} width={420} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={6} className="center-info bg-co2-primary">
          <Grid item xl={10} lg={10} md={10} xs={10} sm={10}>
            <Typography className="ff-poppins   fc-white t-center">
              GLEM, LA HERRAMIENTA PARA UNA RED ELÉCTRICA MÁS INTELIGENTE” GLEM ES: GL ENERGY MANAGEMENT
            </Typography>
          </Grid>
          <Grid item xl={2} lg={2} md={2} xs={2} sm={2} className="center-right bg-co2-primary">
            <Button onClick={handleClose}>
              <CancelIcon className="mr-2" height="25px" width="25px" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <>
      <Tooltip title="Click para mas información" aria-label="add">
        <Button onClick={changeOpen}>
          <InfoIcon style={{ color: green[500] }} />
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={changeOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="o-scroll"
      >
        {body}
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isOpen: state.userReducer.currentUser && state.userReducer.currentUser.changePassword === true ? true : false,
  };
};

export default connect(mapStateToProps, null)(ModalInfoChar);
