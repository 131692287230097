import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import Brush from '@material-ui/icons/Brush';
import Save from '@material-ui/icons/Save';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { commonMessage, icon } from '../../service/messageHandler';
import { validateEmail } from '../../util/validations';
import { fetchRequest, listRoles, saveUser, updateUser, userActions } from '../actions/userActions';

const UserCreateForm = (props) => {
  const { user } = props;

  const [userForm, setUserForm] = useState({
    identification: '',
    companyId: '',
    changePassword: false,
    username: '',
    name: '',
    roles: {},
  });

  const [role, setRole] = useState('');

  useEffect(() => {
    deserialize();
    needRoles();
  }, [props.userForm, user]);

  const deserialize = () => {
    let valueForm = {};
    valueForm.identification = user.identification ? user.identification : '';
    valueForm.companyId = user.companyId ? user.companyId : '';
    valueForm.changePassword = user.changePassword ? true : false;
    valueForm.username = user.username ? user.username : '';
    valueForm.id = user.id ? user.id : undefined;
    valueForm.name = user.name ? user.name : '';
    let localRole = user.roles && user.roles.length > 0 ? user.roles[0].role.name : 'x';
    setRole(localRole);
    setUserForm({
      ...valueForm,
      role: props?.roles?.filter((element) => element.name == localRole)[0],
    });
  };

  const handleClean = () => {
    setRole('');
    props.fetchRequest(userActions.SET_USER, { item: {} });
  };

  const handleSave = () => {
    let userSended = {
      ...userForm,
      companyId: props.company.id,
    };

    let msn = validate(userForm);
    if (msn == '') {
      if (!userSended.id) {
        props.saveUser(userSended);
      } else {
        userSended = {
          ...userForm,
          companyId: user.companyId ? user.companyId : '',
        };
        props.updateUser(userSended);
      }
    } else {
      commonMessage(msn, appConstants.VALIDATION_ERROR_TITLE, icon.info);
    }
  };

  const validate = (userParam) => {
    let msn = '';

    msn +=
      !userParam.identification || userParam.identification?.trim() == ''
        ? 'Debe especificar la identificación  \n'
        : '';

    msn += !userParam.name || userParam.name.trim() == '' ? 'Debe especificar el nombre \n' : '';

    if (!role.length > 0) {
      msn += 'Debe seleccionar el rol \n';
    }

    msn += validateEmail(userParam.username);

    return msn;
  };

  const needRoles = () => {
    if (props.isRequiredRoles == appConstants.IS_REQUIRED_ROLE_LIST) {
      props.listRoles();
    }
  };

  const handleRole = (event) => {
    event.preventDefault();

    if (event.target.value != '') {
      setRole(event.target.value);
      setUserForm({ ...userForm, role: props?.roles?.filter((element) => element.name == event.target.value)[0] });
    }
  };

  const isSuperAdmin = () => {
    if (props.roleUser.name === appConstants.SUPERADMIN && !userForm.id) {
      return true;
    }

    return false;
  };

  return (
    <Box height={1}>
      <Card className="border-10">
        <CardContent>
          <form noValidate={false} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info bg-secundary">
                <Typography variant="h5" className="ff-poppins fw-bold  fc-third">
                  Creación
                </Typography>
              </Grid>
              <Grid item md={12} xl={12} lg={12} sm={12} xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    id="name"
                    label="Nombre del usuario"
                    fullWidth
                    className="border-none"
                    variant="outlined"
                    inputProps={{
                      maxLength: 100,
                    }}
                    value={userForm.name}
                    onChange={(valueEvent) => setUserForm({ ...userForm, name: valueEvent.target.value })}
                  />
                </Box>
              </Grid>

              <Grid item md={12} xl={12} lg={12} sm={12} xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    fullWidth
                    id="identification"
                    label="Identificación"
                    type="text"
                    inputProps={{
                      maxLength: 15,
                    }}
                    variant="outlined"
                    value={userForm.identification}
                    onChange={(valueEvent) => setUserForm({ ...userForm, identification: valueEvent.target.value })}
                  />
                </Box>
              </Grid>

              <Grid item md={12} xl={12} lg={12} sm={12} xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    id="username"
                    label="Correo electrónico"
                    fullWidth
                    inputProps={{
                      maxLength: 100,
                    }}
                    variant="outlined"
                    value={userForm.username}
                    onChange={(valueEvent) => setUserForm({ ...userForm, username: valueEvent.target.value })}
                  />
                </Box>
              </Grid>
              <Grid item md={12} xl={12} lg={12} sm={12} xs={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="role">Rol</InputLabel>
                    <Select
                      labelId="role"
                      id="role"
                      variant="outlined"
                      value={role}
                      fullWidth={true}
                      onChange={handleRole}
                    >
                      <MenuItem value="undefined" key="none">
                        <em>Ninguno</em>
                      </MenuItem>
                      {props.roles &&
                        props.roles.map((roleItem) => {
                          return (
                            <MenuItem value={roleItem.name} key={roleItem.id}>
                              {roleItem.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item md={12} xl={12} lg={12} sm={12} xs={12}>
                <Box paddingLeft={1} height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event) => {
                          {
                            setUserForm({ ...userForm, changePassword: !userForm.changePassword });
                          }
                        }}
                        checked={userForm.changePassword}
                        name="checkedA"
                        className="check-box"
                      />
                    }
                    label="Cambio de contraseña"
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <CardActions className="center d-flex">
          <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
            <Button
              color="primary"
              className="bg-green-gradient mr-2 pl-5 pr-5"
              onClick={handleSave}
              disabled={isSuperAdmin()}
            >
              <Save />
              {user.id ? 'Actualizar' : 'Guardar'}
            </Button>

            <Button disabled={userForm == {}} className="bg-green-gradient mr-2 pl-5 pr-5" onClick={handleClean}>
              <Brush />
              Limpiar
            </Button>
          </ButtonGroup>
        </CardActions>
      </Card>
    </Box>
  );
};

const mapDispatchToProps = {
  fetchRequest,
  saveUser,
  updateUser,
  listRoles,
};

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.selectedUser ? state.userReducer.selectedUser : {},
    company: state.companyReducer.currentCompany ? state.companyReducer.currentCompany : {},
    roles: state.userReducer.roles,
    isRequiredRoles: state.userReducer.isRequiredRoles
      ? state.userReducer.isRequiredRoles
      : appConstants.IS_REQUIRED_ROLE_LIST,
    isRequiredUsers: state.userReducer.isRequiredUsers,
    roleUser: state.userReducer.currentUser.roles[0].role ? state.userReducer.currentUser.roles[0].role : {},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCreateForm);
