import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { ActivasIcon, HistoricoIcon, PeriodoIcon } from '../../assets/icons';
import { componentActions, fetchAction, setGlobalTitle } from '../../components/actions/componentActions';
import MenuButttons from '../../components/menu/MenuButtons';

const menu = [
  {
    name: 'Periodo de licencias',
    icon: <PeriodoIcon height="130px" width="130px" className="icon-rotate-90" />,
    route: '/license/cycle',
  },
  {
    name: 'Licencias',
    icon: <ActivasIcon height="130px" width="130px" />,
    route: '/license/license',
  },
  {
    name: 'Historial',
    icon: <HistoricoIcon height="130px" width="130px" />,
    route: '/license/history',
  },
];

const PrincipalMenu = (props) => {
  const buttonsToolbar = [{ id: appConstants.BACK_BUTTON_ID, history: props.history }];

  useEffect(() => {
    props.setGlobalTitle(appConstants.LICENSE_VIEW_TITLE);

    props.fetchAction(componentActions.ADD_TOOLBAR_BUTTON, buttonsToolbar);
  }, []);

  return (
    <Box width={1} height={1} display="flex" justifyContent="center" className="background-image-bubble " padding={3}>
      <MenuButttons items={menu} perRow={2} />
    </Box>
  );
};

const mapDispatchToProps = {
  setGlobalTitle,
  fetchAction,
};

export default connect(null, mapDispatchToProps)(PrincipalMenu);
