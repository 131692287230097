import { appConstants } from '../../appConstants';
import { valueActions } from '../actions/valueActions';

const valueReducer = (state = {}, action) => {
  switch (action.type) {
    case valueActions.FETCH_VALUE_LIST: {
      return {
        ...state,
        isRequiredValues: appConstants.IS_NOT_REQUIRED_VALUE_LIST,
      };
    }

    case valueActions.FETCH_VALUE_ERROR: {
      return {
        ...state,
        isRequiredValues: appConstants.IS_NOT_REQUIRED_VALUE_LIST,
        error: action.payload.error,
      };
    }

    case valueActions.FETCH_VALUE_LIST_SUCCES: {
      return {
        ...state,
        isRequiredValues: appConstants.IS_NOT_REQUIRED_VALUE_LIST,
        avaliabledValues: action.payload.items.content,
      };
    }

    case valueActions.FETCH_CLEAN_VALUES: {
      return {
        ...state,
        isRequiredValues: appConstants.IS_REQUIRED_VALUE_LIST,
        avaliabledValues: undefined,
      };
    }

    default:
      return state;
  }
};

export default valueReducer;
