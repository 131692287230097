import DateFnsUtils from '@date-io/date-fns';
import { Box, Grid } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import esLocale from 'date-fns/locale/es';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../../assets/styles/App.scss';
import { handleAddserie } from '../../dashboard/services/filterDateObservable';
import { convertDate } from '../../service/util';
import { fetchRequest, reportsActions } from '../action/reportsActions';

const localeMap = {
  es: esLocale,
};

const ComparativeDateReport = (props) => {
  const [locale, setLocale] = useState('es');
  const [localSeries, setLocalSerie] = useState(props.series);
  const [shouldReload, setShouldReload] = useState(false);

  useEffect(() => {
    globalFilter(props.globalSeriesReport);
  }, [localSeries, props.globalSeriesReport]);

  const handleClickOne = (serie, type, event, index) => {
    let today = new Date(Date.now());

    serie = { ...serie, [type]: convertDate(event).replace(' ', 'T') };

    let serieToChange = localSeries.filter((element) => element.name == serie.name);

    localSeries.splice(localSeries.indexOf(serieToChange[0]), 1);

    setLocalSerie([serie, ...localSeries]);
    setShouldReload(!shouldReload);
    if (serie.from != undefined && serie.to == undefined) serie.to = convertDate(today);

    props.fetchRequest(reportsActions.SET_GLOBAL_SERIES_REPORT, serie);
  };

  const globalFilter = (event) => {
    handleAddserie({ series: props.globalSeriesReport, name: props.nameItem });
  };

  return (
    <Box width={1} height={1}>
      <Grid container spacing={2} className="center-info">
        {localSeries &&
          localSeries.map((serie, index) => {
            return (
              <Grid spacing={4} className="center-info">
                <Box boxShadow={3} height={1} borderRadius={10} padding={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                    <DateTimePicker
                      value={serie.from}
                      disableFuture
                      locale="es"
                      onChange={(event) => handleClickOne(serie, 'from', event, index)}
                      label={serie.firstNameTitle}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </Box>

                <Box boxShadow={3} height={1} borderRadius={10} padding={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                    <DateTimePicker
                      locale="es"
                      value={serie.to}
                      disableFuture={index != 0 ? false : true}
                      onChange={(event) => handleClickOne(serie, 'to', event, index)}
                      label={serie.secondNameTitle}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </Box>
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};
const mapDispatchToProps = {
  fetchRequest,
};
const mapStateToProps = (state) => {
  return {
    isRequiredChangeComparativeDatesReport: state.reportReducers.isRequiredChangeComparativeDatesReport
      ? state.reportReducers.isRequiredChangeComparativeDatesReport
      : false,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ComparativeDateReport);
