import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../appConstants';
import { resetHome, setGlobalTitle } from '../components/actions/componentActions';
import { ConfigHomeActions, fetchRequest } from '../configHome/actions/ConfigHomeActions';
import InfoHome from '../configHome/components/InfoHome';

const Home = (props) => {
  useEffect(() => {
    props.resetHome();
    props.setGlobalTitle(appConstants.GLOBAL_TITLE);
    return function () {
      props.fetchRequest(ConfigHomeActions.CLEAN_ON_EXIT_HOME);
    };
  }, []);

  return <InfoHome />;
};

const mapDispatchToProps = {
  resetHome,
  setGlobalTitle,
  fetchRequest,
};

const mapStateToProps = (state) => {
  return {
    isRequiereTitle: state.componentsReducer.isRequiereTitle
      ? state.componentsReducer.isRequiereTitle
      : appConstants.IS_REQUIRED_SET_TITLE,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
