import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgReportIcon(props) {
  const { height, width } = props;

  return (
    <svg
      height={height ? height : appConstants.ICON_DEFAULT_HEIGHT}
      width={width ? width : appConstants.ICON_DEFAULT_WIDTH}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      {...props}
    >
      <circle cx={256} cy={267} r={245} fill="#445ea0" />
      <path
        d="M100.718 421.644c-2.75 0-5-2.25-5-5V71.247H62.635c-2.75 0-5 2.25-5 5v334.546a246.426 246.426 0 0047.084 48.935h276.398c2.75 0 5-2.25 5-5v-33.083l-285.399-.001z"
        fill="#ffaf10"
      />
      <path
        d="M76.635 76.247c0-2.75 2.25-5 5-5h-19c-2.75 0-5 2.25-5 5v334.546a247.092 247.092 0 0019 23.085V76.247z"
        fill="#ef9614"
      />
      <path
        d="M133.718 385.646c-2.75 0-5-2.25-5-5V35.998H97.883c-2.75 0-5 2.25-5 5v378.48c0 2.751 2.25 5 5 5h318.484c2.75 0 5-2.249 5-5v-33.832H133.718z"
        fill="#ff9518"
      />
      <path
        d="M110.883 419.478V40.998c0-2.75 2.25-5 5-5h-18c-2.75 0-5 2.25-5 5v378.48c0 2.751 2.25 5 5 5h18c-2.75 0-5-2.249-5-5z"
        fill="#ef8318"
      />
      <path
        d="M454.365 383.48c0 2.75-2.25 5-5 5H130.883c-2.75 0-5-2.25-5-5V5c0-2.75 2.25-5 5-5h318.482c2.75 0 5 2.25 5 5v378.48z"
        fill="#d9eafc"
      />
      <path
        d="M145.727 383.48V5c0-2.75 2.25-5 5-5h-19.845c-2.75 0-5 2.25-5 5v378.48c0 2.75 2.25 5 5 5h19.845c-2.75 0-5-2.25-5-5z"
        fill="#c3ddf4"
      />
      <g fill="#db722c">
        <path d="M415.09 50.794h-69.561a7.499 7.499 0 01-7.5-7.5 7.5 7.5 0 017.5-7.5h69.561a7.5 7.5 0 017.5 7.5 7.5 7.5 0 01-7.5 7.5zM415.09 76.293h-69.561a7.5 7.5 0 01-7.5-7.5c0-4.143 3.357-7.5 7.5-7.5h69.561a7.5 7.5 0 010 15zM415.09 101.791h-69.561c-4.143 0-7.5-3.358-7.5-7.5s3.357-7.5 7.5-7.5h69.561a7.5 7.5 0 017.5 7.5 7.5 7.5 0 01-7.5 7.5zM415.09 127.29h-69.561a7.5 7.5 0 01-7.5-7.5c0-4.143 3.357-7.5 7.5-7.5h69.561a7.5 7.5 0 010 15z" />
      </g>
      <g fill="#52bbef">
        <path d="M285.397 305.281H168.238c-4.143 0-7.5-3.358-7.5-7.5s3.357-7.5 7.5-7.5h117.158a7.5 7.5 0 017.5 7.5 7.499 7.499 0 01-7.499 7.5zM285.397 330.779H168.238a7.499 7.499 0 01-7.5-7.5 7.5 7.5 0 017.5-7.5h117.158a7.5 7.5 0 017.5 7.5 7.498 7.498 0 01-7.499 7.5zM285.397 356.278H168.238a7.499 7.499 0 01-7.5-7.5 7.5 7.5 0 017.5-7.5h117.158a7.5 7.5 0 017.5 7.5 7.499 7.499 0 01-7.499 7.5z" />
      </g>
      <g fill="#fe3745">
        <path d="M415.09 171.788H308.358c-4.143 0-7.5-3.358-7.5-7.5s3.357-7.5 7.5-7.5H415.09a7.5 7.5 0 010 15zM415.09 197.287H308.358c-4.143 0-7.5-3.358-7.5-7.5s3.357-7.5 7.5-7.5H415.09a7.5 7.5 0 010 15zM415.09 222.785H308.358a7.5 7.5 0 010-15H415.09a7.5 7.5 0 010 15zM415.09 248.283H308.358c-4.143 0-7.5-3.358-7.5-7.5s3.357-7.5 7.5-7.5H415.09a7.5 7.5 0 010 15zM154.77 215.755h29.242c0-16.15 13.091-29.243 29.241-29.243v-29.24c-32.297 0-58.483 26.183-58.483 58.483z" />
      </g>
      <path
        d="M228.084 142.442v29.241c16.15 0 29.241 13.091 29.241 29.241h29.243c0-32.3-26.185-58.482-58.484-58.482z"
        fill="#91e0e8"
      />
      <g fill="#ffaf10">
        <path d="M242.494 215.755c0 16.148-13.09 29.241-29.24 29.241s-29.241-13.093-29.241-29.241H154.77c0 32.298 26.186 58.483 58.483 58.483 32.299 0 58.484-26.185 58.484-58.483h-29.243zM188.359 115.124V45.29c0-1.65-1.35-3-3-3h-18.182c-1.65 0-3 1.35-3 3v69.834h24.182z" />
      </g>
      <path d="M227.476 115.124V64.29c0-1.65-1.35-3-3-3h-18.181c-1.65 0-3 1.35-3 3v50.834h24.181z" fill="#fe3745" />
      <path d="M266.592 115.124V87.29c0-1.65-1.35-3-3-3h-18.181c-1.65 0-3 1.35-3 3v27.834h24.181z" fill="#37c47a" />
      <path d="M305.709 115.124V45.29c0-1.65-1.35-3-3-3h-18.182c-1.65 0-3 1.35-3 3v69.834h24.182z" fill="#91e0e8" />
      <path
        d="M379.592 319.708c0-1.654 1.346-3 3-3h13.561v-33.56c0-2.75-2.25-5-5-5h-45.121c-2.75 0-5 2.25-5 5v45.121c0 2.75 2.25 5 5 5h33.56v-13.561z"
        fill="#fe3745"
      />
      <path
        d="M415.09 347.206c0 2.75-2.25 5-5 5h-27.498c-2.75 0-5-2.25-5-5v-27.498c0-2.75 2.25-5 5-5h27.498c2.75 0 5 2.25 5 5v27.498z"
        fill="#37c47a"
      />
      <path
        d="M310.879 127.29H159.008a7.5 7.5 0 01-7.5-7.5c0-4.143 3.357-7.5 7.5-7.5h151.871c4.143 0 7.5 3.357 7.5 7.5a7.5 7.5 0 01-7.5 7.5z"
        fill="#52bbef"
      />
    </svg>
  );
}

export default SvgReportIcon;
