import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgFieldIcon(props) {
  const { height, width } = props;
  return (
    <svg  viewBox="0 -17 512 512" xmlns="http://www.w3.org/2000/svg" {...props}
          height={height ? height : appConstants.DEFAULT_HEIGHT}
      width={width ? width : appConstants.DEFAULT_WIDTH}
    >
      <path
        d="M460.8 179.2V42.667c0-18.852-15.28-34.133-34.132-34.133h-384c-18.852 0-34.133 15.281-34.133 34.133v315.73c0 18.852 15.281 34.137 34.133 34.137h170.664zm0 0"
        fill="#607d8b"
      />
      <path
        d="M394.95 145.066L435.2 179.2V42.668c.523-18.316-13.891-33.59-32.204-34.133H40.738C22.426 9.078 8.012 24.352 8.535 42.668v315.73c-.523 18.317 13.89 33.59 32.203 34.137h161.008zm0 0"
        fill="#cfd8dc"
      />
      <path
        d="M418.133 179.2v-76.802c0-4.71-3.82-8.53-8.531-8.53H59.734a8.202 8.202 0 00-8.535 7.765v240.469a8.204 8.204 0 008.535 7.765h153.598zm0 0"
        fill="#02a9f4"
      />
      <path
        d="M230.398 179.2h256c9.426 0 17.067 7.64 17.067 17.066v256c0 9.425-7.64 17.066-17.067 17.066h-256c-9.425 0-17.066-7.64-17.066-17.066v-256c0-9.426 7.64-17.067 17.066-17.067zm0 0"
        fill="#ff5722"
      />
      <path
        d="M230.398 179.2h230.403c9.426 0 17.066 7.64 17.066 17.066v256c0 9.425-7.64 17.066-17.066 17.066H230.398c-9.425 0-17.066-7.64-17.066-17.066v-256c0-9.426 7.64-17.067 17.066-17.067zm0 0"
        fill="#ff9801"
      />
      <path d="M59.734 59.734h17.067c4.71 0 8.531-3.82 8.531-8.535 0-4.71-3.82-8.531-8.531-8.531H59.734a8.533 8.533 0 100 17.066zm0 0M110.934 59.734H128c4.71 0 8.535-3.82 8.535-8.535 0-4.71-3.824-8.531-8.535-8.531h-17.066a8.533 8.533 0 100 17.066zm0 0M162.133 59.734h34.133a8.534 8.534 0 100-17.066h-34.133a8.532 8.532 0 00-8.531 8.531 8.533 8.533 0 008.53 8.535zm0 0M230.398 59.734h187.735a8.534 8.534 0 100-17.066H230.398a8.532 8.532 0 00-8.53 8.531 8.533 8.533 0 008.53 8.535zm0 0" />
      <path d="M486.398 170.668h-17.066v-128C469.305 19.113 450.219.028 426.668 0h-384C19.113.027.028 19.113 0 42.668v315.73c.027 23.555 19.113 42.641 42.668 42.668h162.133v51.2c0 14.14 11.46 25.601 25.597 25.601h256c14.141 0 25.602-11.46 25.602-25.601v-256c0-14.137-11.46-25.598-25.602-25.598zM42.668 384c-14.14 0-25.602-11.46-25.602-25.602V42.668c0-14.14 11.461-25.602 25.602-25.602h384c14.137 0 25.598 11.461 25.598 25.602v128h-25.598v-68.27c0-9.425-7.64-17.066-17.066-17.066H59.734c-9.207-.195-16.84 7.094-17.066 16.3v240.47c.227 9.207 7.86 16.492 17.066 16.296h145.067V384zm187.73-213.332a25.594 25.594 0 00-24.027 17.066h-69.836v-34.132h273.067v17.066zM59.734 341.266v-34.067h59.73v34.133zm349.868-238.868v34.137H136.535v-34.137zm-290.137 34.137h-59.73v-34.137h59.73zM59.735 256h59.73v34.133h-59.73zm76.8 0h68.266v34.133h-68.266zm68.266-17.066h-68.266V204.8h68.266zm-85.336 0h-59.73V204.8h59.73zm-59.73-51.2v-34.132h59.73v34.132zm76.8 119.465h68.266v34.133h-68.266zm358.399 145.067a8.534 8.534 0 01-8.536 8.535h-256a8.533 8.533 0 01-8.53-8.535v-256c0-4.711 3.82-8.532 8.53-8.532h256a8.533 8.533 0 018.536 8.532zm0 0" />
      <path d="M349.867 307.2h-32.426l6.825-68.266c0-9.426 7.64-17.067 17.066-17.067s17.066 7.64 17.066 17.067c0 4.71 3.82 8.53 8.536 8.53 4.71 0 8.53-3.82 8.53-8.53-.374-18.41-15.284-33.2-33.698-33.43-18.41-.23-33.684 14.184-34.524 32.574l-6.867 69.121h-27.309a8.533 8.533 0 00-8.53 8.535c0 4.711 3.82 8.532 8.53 8.532h25.602l-8.535 85.336c0 9.425-7.64 17.066-17.067 17.066-9.425 0-17.066-7.64-17.066-17.066a8.534 8.534 0 10-17.066 0c.379 18.406 15.289 33.195 33.699 33.425 18.41.23 33.687-14.18 34.523-32.574l8.578-86.187h34.133c4.711 0 8.531-3.82 8.531-8.532a8.533 8.533 0 00-8.53-8.535zm0 0M449.766 343.832a8.54 8.54 0 00-12.067 0l-32.367 32.367-32.367-32.367a8.534 8.534 0 00-12.067 12.066l32.368 32.368-32.368 32.367a8.536 8.536 0 003.77 14.395 8.527 8.527 0 008.297-2.329l32.367-32.367 32.367 32.367a8.538 8.538 0 0011.965-.101 8.538 8.538 0 00.102-11.965l-32.368-32.367 32.368-32.368a8.532 8.532 0 000-12.066zm0 0" />
    </svg>
  );
}

export default SvgFieldIcon;
