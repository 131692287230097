import { Box, Grid } from '@material-ui/core';
import 'c3/c3.css';
import { format } from 'd3-format';
import React from 'react';
import C3Chart from 'react-c3js';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { dashboardAction, downloadTrend, fetchRequest, listHistory } from '../../dashboard/action/dashboardActions';
import '../../dashboard/styles/dashboard.scss';
import { convertDate } from '../../service/util';

class TrendReport extends React.Component {
  constructor(props) {
    super(props);

    this.info = {
      updateIn: 5,
      frecuency: '',
      average: 0,
      current: undefined,
    };
    this.state = {
      graphic: {
        data: {
          x: 'x',
          columns: [['x'], ['']],
        },
        axis: {
          x: {
            label: 'Tiempo',
            type: 'timeseries',
            tick: {
              count: 10,
              format: '%M',
            },
          },
          y: {
            label: {
              text: this.getMedida(),
              position: 'outer-center',
            },
            type: 'cantidad',
            position: 'outer-middle',
            tick: {
              format: format('.2f'),
            },
          },
        },
        tooltip: {
          format: {
            title: function (x) {
              return `${convertDate(x)}`;
            },
          },
        },
        color: {
          pattern: ['#1d2877'],
        },
      },
      valuesHistory: [],
    };

    this.ref = {};

    this.timeOutRefres = undefined;
    this.reload = true;
  }
  getMedida = () => {
    if (this.props.field.unitMeasurement === '') {
      return '';
    }

    return this.props.field?.masterField?.unitMeasurement;
  };

  getFilter = () => {
    let from = new Date(Date.now() - this.info.frecuency * 60000);
    let fromFormatted = convertDate(from);
    let currentDate = convertDate(new Date(Date.now()));

    return {
      page: 0,
      size: 300,
      from: fromFormatted,
      to: currentDate,
      filters: { fieldId: this.props.field.id, deviceId: this.props.field.device.id },
    };
  };

  getFilterByField = () => {
    let from = new Date(Date.now() - this.info.frecuency * 60000);
    let fromFormatted = convertDate(from);
    let currentDate = convertDate(new Date(Date.now()));
    return {
      page: 0,
      size: 3000,
      from: fromFormatted,
      to: currentDate,
      filters: [this.props.field],
    };
  };

  handleDownload = (event) => {
    this.props.downloadTrend(this.getFilter());
  };

  putInfoInGraphic() {
    let values = this.props.valuesPerField[this.props.field?.name]?.content;
    this.reload = false;

    const { data: dataColumns } = this.state.graphic;
    dataColumns.columns[0].splice(1);
    dataColumns.columns[1].splice(1);

    if (values && values.length > 0) {
      this.info.current = values[0];

      values.forEach((element, index) => {
        dataColumns.columns[0].push(new Date(element.createdAt));
        dataColumns.columns[1].push(Number(element.value).toFixed(5));
      });
    }

    this.reload = true;
    let translate = {
      axis: { ...this.state.graphic.axis },
      data: dataColumns,

      regions: this.state.graphic.regions,
    };

    // if (this.state.timeOut) clearTimeout(this.state.timeOut);

    // let timeOut = setTimeout(() => {
    //
    //   this.props.listHistory(this.getFilterByField());
    // }, 120000);

    this.setState({ graphic: translate, valuesHistory: values });
  }

  handleFrecuency = (event) => {
    this.info.frecuency = event.target.value;

    let valueFrecuency = JSON.parse(`{ "${this.props.field.name}": ${event.target.value}}`);

    this.props.fetchRequest(dashboardAction.ADD_FRECUENCY_GLOBAL, valueFrecuency);

    this.props.listHistory(this.getFilterByField());
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { valuesPerField: values } = this.props;
    const valuesContent = values[this.props.field?.name];

    if (valuesContent) {
      const { content } = valuesContent;

      if (content) {
        let prevValues = prevState.valuesHistory;
        if (
          !prevValues ||
          (content.length == 0 && prevValues.length > 1) ||
          (content.length == 1 && prevValues.length == 1)
        ) {
          this.putInfoInGraphic();
        } else if (content.length != prevValues.length) {
          this.putInfoInGraphic();
        } else if (content.length == prevValues.length && content.length > 0) {
          let index = content.length - 1;

          if (content[0].id != prevValues[0].id || content[index].id != prevValues[index].id) {
            this.putInfoInGraphic();
          }
        }
      }
    }
  }

  render() {
    return (
      <Box height={1} width={1}>
        <Grid container>
          <Box height={9 / 10} width={1} padding={2}>
            {
              <C3Chart
                data={this.state.graphic.data}
                axis={this.state.graphic.axis}
                regions={this.state.graphic.regions}
                tooltip={this.state.graphic.tooltip}
                color={this.state.graphic.color}
              />
            }
          </Box>
        </Grid>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedDevice: state.deviceReducer.selectedDevice,
    selectedHeadquarter: state.headquarterReducer.selectedHeadquarter,
    selectedIot: state.iotReducer.selectedIot,
    intervals: state.dashboardReducer.intervals ? state.dashboardReducer.intervals : [],
    values: state.dashboardReducer.values ? state.dashboardReducer.values : [],
    valuesPerField: state.dashboardReducer.valuesPerField ? state.dashboardReducer.valuesPerField : {},
    isRequiredAddButtons: state.componentsReducer.buttons
      ? appConstants.IS_NOT_REQUIRED_ADD_BUTTON
      : appConstants.IS_REQUIRED_ADD_BUTTON,
  };
};

const mapDispatchToProps = {
  listHistory,
  downloadTrend,
  fetchRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrendReport);
