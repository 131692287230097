import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgBombilloIcon(props) {
  const { height, width, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height ? height : appConstants.ICON_DEFAULT_HEIGHT}
      width={width ? width : appConstants.ICON_DEFAULT_WIDTH}
      viewBox="0 0 375 375"
      {...props}
    >
      <defs>
        <clipPath id="BombilloIcon_svg__a">
          <path d="M82 81h225v293.977H82zm0 0" />
        </clipPath>
        <clipPath id="BombilloIcon_svg__b">
          <path d="M1.246 191H44v8H1.246zm0 0" />
        </clipPath>
      </defs>
      <path
        d="M140.543 308.914l.11 6.98c.445 30.594 25.585 55.094 56.156 54.649 30.57-.441 55.05-25.606 54.605-56.2l-.11-6.429c0-1.664 0-3.215.223-4.875l-111.207 1.55c.114 1.442.223 2.884.223 4.325zm0 0"
        fill="#7acdf1"
      />
      <path
        d="M266.258 273.11c22.152-20.067 35.996-49.106 35.555-81.364-.778-59.86-50.399-107.633-110.43-105.973-58.262 1.665-105.004 50.215-104.45 108.524.333 32.254 14.731 60.965 37.438 80.473 8.863 7.648 14.621 18.293 15.95 29.93l111.206-1.552c.996-11.527 6.2-22.28 14.73-30.039zm0 0"
        fill="#fff"
      />
      <g clipPath="url(#BombilloIcon_svg__a)">
        <path
          d="M196.809 374.977c-33.004.445-60.141-26.047-60.586-59.082l-.11-6.98c-.113-11.75-5.539-22.95-14.73-30.93a111.966 111.966 0 01-38.988-83.801C81.73 133.55 130.575 82.78 191.16 81.117c30.13-.773 58.817 10.2 80.524 31.149 21.82 20.953 34.003 49.105 34.449 79.257.441 32.258-13.07 63.075-36.996 84.688-8.75 7.871-13.625 19.398-13.512 31.48l.11 6.43c.554 33.254-25.919 60.414-58.926 60.856zm-3.875-284.77h-1.442c-55.824 1.555-100.68 48.219-100.129 104.09.332 29.707 13.403 57.75 35.887 77.148 11.078 9.532 17.613 23.168 17.723 37.465l.113 6.985c.332 28.156 23.59 50.66 51.613 50.324 28.024-.332 50.617-23.61 50.285-51.653l-.109-6.43c-.223-14.632 5.758-28.597 16.39-38.241 22.044-19.844 34.45-48.332 34.005-78.04-.332-27.82-11.63-53.761-31.676-72.937-19.606-18.953-45.414-29.152-72.66-28.711zm0 0"
          fill="#1b3c65"
        />
      </g>
      <path
        d="M273.457 307.25l-155.066 2.105a4.522 4.522 0 01-4.543-4.324c0-2.437 1.882-4.433 4.32-4.543l155.066-2.105a4.521 4.521 0 014.543 4.32c.11 2.442-1.882 4.547-4.32 4.547zm0 0M249.422 329.086l-105.223 1.441c-1.883 0-3.324-1.441-3.324-3.324 0-1.887 1.441-3.328 3.324-3.328l105.223-1.438c1.883 0 3.32 1.438 3.32 3.325 0 1.883-1.547 3.324-3.32 3.324zm0 0M240.895 351.922l-87.504 1.219c-1.883 0-3.32-1.442-3.32-3.325 0-1.886 1.437-3.328 3.32-3.328l87.504-1.218c1.882 0 3.32 1.44 3.32 3.328-.11 1.773-1.55 3.324-3.32 3.324zm0 0"
        fill="#1b3c65"
      />
      <path
        d="M172.441 308.691a4.519 4.519 0 01-4.539-4.324l-.664-52.43c0-1.222.442-2.328 1.215-3.105l39.875-41.012-37.324.551c-1.774 0-3.434-.996-4.102-2.66a4.64 4.64 0 01.887-4.875l47.738-49.106c1.664-1.773 4.543-1.773 6.313-.113 1.773 1.664 1.773 4.547.113 6.32l-40.316 41.458 37.324-.555c1.773 0 3.434.996 4.098 2.66a4.645 4.645 0 01-.883 4.879l-46.078 47.332.664 50.547c.11 2.328-1.883 4.32-4.32 4.433zm0 0M209.77 308.137a4.525 4.525 0 01-4.543-4.325l-.774-53.539c0-2.437 1.883-4.433 4.32-4.543a4.518 4.518 0 014.54 4.32l.777 53.54c.11 2.441-1.883 4.547-4.32 4.547zm0 0M383.773 193.52l-35.441.44c-1.883 0-3.324-1.44-3.324-3.323s1.441-3.325 3.324-3.325l35.441-.445c1.883 0 3.325 1.442 3.325 3.328 0 1.883-1.442 3.325-3.325 3.325zm0 0"
        fill="#1b3c65"
      />
      <g clipPath="url(#BombilloIcon_svg__b)">
        <path
          d="M40.414 198.285l-35.441.445c-1.883 0-3.325-1.44-3.325-3.328 0-1.882 1.442-3.324 3.325-3.324l35.441-.445c1.883 0 3.324 1.441 3.324 3.328 0 1.773-1.441 3.324-3.324 3.324zm0 0"
          fill="#1b3c65"
        />
      </g>
      <path
        d="M192.27 42.102c-1.883 0-3.325-1.442-3.325-3.329l-.441-35.468c0-1.887 1.437-3.328 3.32-3.328 1.883 0 3.324 1.441 3.324 3.328l.442 35.468c0 1.774-1.438 3.329-3.32 3.329zm0 0M62.344 331.969c-.883 0-1.66-.332-2.324-.887-1.329-1.332-1.329-3.437-.11-4.656l24.7-25.383c1.328-1.332 3.433-1.332 4.652-.113 1.328 1.332 1.328 3.437.11 4.656l-24.7 25.387c-.664.664-1.55.996-2.328.996zm0 0M301.813 85.773c-.887 0-1.665-.332-2.329-.886-1.328-1.328-1.328-3.434-.109-4.657l24.7-25.382c1.327-1.332 3.433-1.332 4.652-.11 1.328 1.328 1.328 3.434.109 4.653l-24.7 25.386c-.663.555-1.55.887-2.324.996zm0 0M84.055 88.766c-.887 0-1.66-.332-2.325-.887L56.254 63.05c-1.328-1.332-1.328-3.438-.11-4.656 1.329-1.329 3.434-1.329 4.649-.11L86.16 83.004c1.328 1.328 1.328 3.437.11 4.656-.555.664-1.329.996-2.215 1.106zm0 0M330.168 328.313c-.887 0-1.664-.336-2.328-.891l-25.363-24.719c-1.329-1.328-1.329-3.433-.114-4.652 1.332-1.332 3.434-1.332 4.653-.113l25.367 24.718c1.328 1.332 1.328 3.438.11 4.656-.665.665-1.442 1-2.325 1zm0 0"
        fill="#1b3c65"
      />
    </svg>
  );
}

export default SvgBombilloIcon;
