import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, Grid } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import esLocale from 'date-fns/locale/es';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FilterIcon } from '../../assets/icons';
import '../../assets/styles/App.scss';
import { commonMessage, icon } from '../../service/messageHandler';
import {getDateYearAndDayAndHouCero } from '../../service/util';
import { dashboardAction, fetchRequest } from '../action/dashboardActions';
import { handleAddserie } from '../services/filterDateObservable';

const localeMap = {
  es: esLocale,
};

const ComparativeDate = (props) => {
  const [locale, setLocale] = useState('es');
  const [localSeries, setLocalSerie] = useState(props.series);
  const [shouldReload, setShouldReload] = useState(false);

  useEffect(() => {
    globalFilter(props.globalSeries);
  }, [localSeries, props.globalSeries]);

  const handleClickOne = (serie, type, event, index) => {
    
    serie = { ...serie, [type]: getDateYearAndDayAndHouCero(event).replace(' ', 'T')};

    let serieToChange = localSeries.filter((element) => element.name == serie.name);

    localSeries.splice(localSeries.indexOf(serieToChange[0]), 1);

    if (index == 0 && serie.to != undefined) {
      if (localSeries.length > 0) {
        localSeries[0].from = serie.to;
        let dateSecond = getDiference(serie);
        localSeries[0].to = getDateYearAndDayAndHouCero(dateSecond).replace(' ', 'T');
      }

      

      setShouldReload(!shouldReload);
      setLocalSerie([serie, ...localSeries]);
    } else {
      if (index == 0) {
        setLocalSerie([serie, ...localSeries]);
      } else {
        setLocalSerie([...localSeries, serie]);
      }
    }
    if (index > 0) {
      let date = getTiDateFromFirstRange(serie.from);
      serie.to = getDateYearAndDayAndHouCero(date).replace(' ', 'T');

      setShouldReload(!shouldReload);
    }
  };

  const getTiDateFromFirstRange = (date) => {
    if (localSeries.length == 0 || !localSeries[0].from || !localSeries[0].to) {
      commonMessage('Debe seleccionar las fechas del primer rango', '', icon.info);

      return;
    }

    let difference = getDifferentBetweenDates(localSeries[0].from, localSeries[0].to);
    let returningDate = new Date(date);
    let valueSecondsNextDate = returningDate.getTime() + difference;
    return new Date(valueSecondsNextDate);
  };

  const getDiference = (date) => {
    let difference = getDifferentBetweenDates(date.from, date.to);
    let returningDate = new Date(date.to);
    let valueSecondsNextDate = returningDate.getTime() + difference;
    return new Date(valueSecondsNextDate);
  };

  const getDifferentBetweenDates = (from, to) => {
    let fromDate = new Date(from);
    let toDate = new Date(to);
    return toDate.getTime() - fromDate.getTime();
  };

  const handleFilter = (event) => {
    event.preventDefault();
  props.fetchRequest(dashboardAction.SET_SERIES, localSeries);
    handleAddserie({ series: localSeries, name: props.nameItem });
  };

  const globalFilter = (event) => {
    handleAddserie({ series: props.globalSeries, name: props.nameItem });
  };

  const getMinDate = (index, field) => {
    if (index == 0) {
      let date = field == 'from' || !localSeries[0].from ? new Date() : new Date(localSeries[0].from);

      let valueSeconds =
        field == 'from' || !localSeries[0].from ? date.getTime() - 365 * 24 * 60 * 60 * 1000 : date.getTime();

      let dateTransformed = new Date(valueSeconds);

      return `${dateTransformed.getMonth() + 1}/${dateTransformed.getDay()}/${dateTransformed.getFullYear()}`;
    } else {
      let date = localSeries[0].to ? new Date(localSeries[0].to) : new Date();

      return `${date.getMonth() + 1}/${date.getDay()}/${date.getFullYear()}`;
    }
  };
  return (
    <Box width={1} height={1}>
      <Grid container spacing={2}>
        {localSeries &&
          localSeries.map((serie, index) => {
            return (
              <Box key={serie.name} width={1} paddingTop={2}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl={props.xl ? props.xl : 12}
                    lg={props.lg ? props.lg : 12}
                    md={props.md ? props.md : 12}
                    sm={12}
                    xs={12}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                      <KeyboardDatePicker
                        value={serie.from}
                        disableFuture
                        locale="es"
                        onChange={(event) => handleClickOne(serie, 'from', event, index)}
                        label={serie.firstNameTitle}
                        disabled={index > 0 && (!localSeries[0].from || !localSeries[0].to)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid
                    item
                    xl={props.xl ? props.xl : 12}
                    lg={props.lg ? props.lg : 12}
                    md={props.md ? props.md : 12}
                    sm={12}
                    xs={12}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                      <KeyboardDatePicker
                        locale="es"
                        value={serie.to}
                        disableFuture={index != 0 ? false : true}
                        minDate={getMinDate(index, 'to')}
                        onChange={(event) => handleClickOne(serie, 'to', event, index)}
                        label={serie.secondNameTitle}
                        disabled={index > 0}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </Box>
            );
          })}

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="center-info">
          <Box className="center-info">
            <Button color="primary" className="bg-green-gradient mr-2 pl-5 pr-5 center-info" onClick={handleFilter}>
              <FilterIcon className="mr-2 center-info" />
              Filtrar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    isRequiredChangeComparativeDates: state.dashboardReducer.isRequiredChangeComparativeDates
      ? state.dashboardReducer.isRequiredChangeComparativeDates
      : false,
    globalSeries: state.dashboardReducer.globalSeries ? state.dashboardReducer.globalSeries : [],
  };
};
const mapDispatchToProps = {
  fetchRequest,
};
export default connect(mapStateToProps, mapDispatchToProps)(ComparativeDate);
