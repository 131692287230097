import { appConstants } from '../../appConstants';
import ApiAxios from '../../service/Api';
import reportApi from '../services/reportApi';

export const reportsActions = {
  FETCHING_REPORT_FAILURE: 'FETCHING_REPORT_FAILURE',
  FETCH_REQUEST_TYPE_REPORT: 'FETCH_REQUEST_TYPE_REPORT',
  FETCH_REQUEST_TYPE_REPORT_SUCCESS: 'FETCH_REQUEST_TYPE_REPORT_SUCCESS',
  SET_GLOBAL_SERIES_REPORT: 'SET_GLOBAL_SERIES_REPORT',
  IS_REQUIRED_CHANGE_COMPARATIVE_DATE_REPORTS: 'IS_REQUIRED_CHANGE_COMPARATIVE_DATE_REPORTS',
  SET_FIELD_REPORT: 'SET_FIELD_REPORT',
  FETCH_REQUEST_GENERATE_REPORT_REQUEST: 'FETCH_REQUEST_GENERATE_REPORT_REQUEST',
  FETCH_REQUEST_GENERATE_REPORT_SUCCESS: 'FETCH_REQUEST_GENERATE_REPORT_SUCCESS',
  SET_TYPE_REPORT: 'SET_TYPE_REPORT',
  FETCH_REPORT_GRAPH: 'FETCH_REPORT_GRAPH',
  FETCH_REPORT_GRAPH_SUCCESS: 'FETCH_REPORT_GRAPH_SUCCESS',
  FETCH_REQUEST_ERROR: 'FETCH_REQUEST_ERROR',
  CLEAN_REPORT_FIELD: 'CLEAN_REPORT_FIELD',
};

export const fetchRequest = (type, payload = {}) => {
  return {
    type: type,
    payload: payload,
  };
};

export const getTypereport = () => {
  return function (dispatch) {
    ApiAxios.crudGeneric.get(`${appConstants.CONTEXT_REPORT}report-types`, dispatch, fetchRequest, {
      request: reportsActions.FETCH_REQUEST_TYPE_REPORT,
      success: reportsActions.FETCH_REQUEST_TYPE_REPORT_SUCCESS,
      error: reportsActions.FETCHING_REPORT_FAILURE,
    });
  };
};

export const downloadIndividualReport = (filter) => {
  return function (dispatch) {
    reportApi.dowload(
      dispatch,
      {
        request: reportsActions.FETCH_REPORT_GRAPH,
        success: reportsActions.FETCH_REPORT_GRAPH_SUCCESS,
        error: reportsActions.FETCH_REQUEST_ERROR,
      },
      filter,
      'export/individual',
    );
  };
};
export const downloadComparativeReport = (filter) => {
  return function (dispatch) {
    reportApi.dowload(
      dispatch,
      {
        request: reportsActions.FETCH_REPORT_GRAPH,
        success: reportsActions.FETCH_REPORT_GRAPH_SUCCESS,
        error: reportsActions.FETCH_REQUEST_ERROR,
      },
      filter,
      'export/comparative',
    );
  };
};
