import * as React from 'react';
import { appConstants } from '../../appConstants';

function SvgHome(props) {
  const { height, width, color } = props;
  return (
    <svg
                height={height ? height : appConstants.ICON_DEFAULT_HEIGHT}
      width={width ? width : appConstants.ICON_DEFAULT_WIDTH}
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 2"
      {...props}
    >
      <path d="M8.536 118.531h110.928v7.672H8.536z" fill="#284cb2" />
      <path d="M109.464 118.531H18.536V49.452L64 19.428l45.464 30.024z" fill="#e8f3fc" />
      <path d="M64 7.444L8.536 44.072V62.2h10V49.452L64 19.428l45.464 30.024V62.2h10V44.072z" fill="#284cb2" />
      <path d="M99.147 30.655l-18.48-12.204V1.797h18.48z" fill="#06daf7" />
      <path d="M99.147 7.213h-18.48v3.5h18.48z" fill="#05b8e2" />
      <path d="M30.318 76.045h25.678v42.487H30.318z" fill="#ff6ca8" />
      <path
        d="M49.5 100.539a1.75 1.75 0 01-1.75-1.75v-2.476a1.75 1.75 0 013.5 0v2.476a1.75 1.75 0 01-1.75 1.75z"
        fill="#ef4a8a"
      />
      <path d="M66.25 76.045h35.25v21.507H66.25z" fill="#06daf7" />
      <g fill="#b7dbf6">
        <path d="M64 51.529a7.631 7.631 0 00-5.433 2.25 1.75 1.75 0 002.475 2.475 4.187 4.187 0 015.915 0 1.75 1.75 0 002.475-2.475A7.631 7.631 0 0064 51.529z" />
        <path d="M64 44.081a15.032 15.032 0 00-10.7 4.427 1.75 1.75 0 102.475 2.475 11.654 11.654 0 0116.458 0 1.75 1.75 0 102.467-2.475A15.036 15.036 0 0064 44.081z" />
        <path d="M79.975 43.235a22.618 22.618 0 00-31.949 0 1.75 1.75 0 102.474 2.476 19.115 19.115 0 0127 0 1.75 1.75 0 002.475-2.476z" />
        <circle cx={64} cy={60.044} r={2.094} />
      </g>
      <path d="M85.625 85.048v-9.003h-3.5v9.003H66.25v3.5h15.875v9.003h3.5v-9.003H101.5v-3.5z" fill="#05b8e2" />
    </svg>
  );
}

export default SvgHome;
