import { Box, Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { setGlobalTitle } from '../../components/actions/componentActions';
import AutoCompleteFilters from '../../components/filters/AutoCompleteFilters';
import { CregActions, fetchRequest } from '../action/CregActions';
import AlarmCregList from '../components/AlarmCregList';
import Consolidated from '../components/Consolidated';
import DateFilterCreg from '../components/DateFilterCreg';
import ModalInfoCreg from '../components/ModalInfoCreg';

const ReactivesView = (props) => {
  useEffect(() => {
    if (props.isRequiereTitle === appConstants.IS_REQUIRED_SET_TITLE) {
      props.setGlobalTitle(appConstants.ENERGY_PENALTY_TITLE);
    }

    return function () {
      props.fetchRequest(CregActions.CLEAN_ON_EXIT_REACTIVE);
    };
  }, []);

  return (
    <Box width={1} padding={1} className="background-image-bubble mt-10px">
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={6} xs={12} sm={12}>
          <Paper className="paper">
            <Box boxShadow={3} height={1} borderRadius={10} padding={2}>
              <Grid item xl={12} lg={12} md={12} xs={12} report sm={12} className="center-info bg-secundary border-10 ">
                <Grid item xl={2} lg={2} md={2} sm={2} xs={2} className="bg-secundary ">
                  <ModalInfoCreg />
                </Grid>
                <Grid item xl={10} lg={10} md={10} xs={10} sm={10} className="center-info bg-secundary border-10 ">
                  <Typography variant="h4" component="h3" className="ff-poppins fc-third ">
                    Filtro
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} className="mt-8px">
                <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                  <AutoCompleteFilters
                    iot={true}
                    headquarter={true}
                    device={true}
                    deviceType={appConstants.PRINCIPAL_DEVICE}
                    xl={12}
                    lg={12}
                    md={12}
                  />
                </Grid>
                <Grid item xl={12} md={12} lg={12} xs={12} sm={12} className="center-info">
                  <DateFilterCreg series={[{ name: 'serie a', title: 'año y Mes' }]} />
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item xl={6} lg={6} md={6} xs={12} sm={12}>
          <Consolidated />
        </Grid>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
          <AlarmCregList />
        </Grid>
      </Grid>
    </Box>
  );
};

const mapDispatchToProps = {
  fetchRequest,
  setGlobalTitle,
};

const mapStateToProps = (state) => {
  return {
    isRequiereTitle: state.componentsReducer.isRequiereTitle
      ? state.componentsReducer.isRequiere
      : appConstants.IS_REQUIRED_SET_TITLE,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReactivesView);
