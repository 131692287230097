import { appConstants } from '../../appConstants';
import { errorHandler } from '../../service/messageHandler';
import { alarmHistoryActions } from '../action/alarmHistoryActions';

const alarmHistoryReducer = (state = {}, action) => {
  switch (action.type) {
    case alarmHistoryActions.FETCHING_ALARM_HISTORY_LIST:
      return {
        ...state,
        loading: { open: true },
        isRequiredHistoryAlarms: appConstants.IS_NOT_REQUIRED_ALARMS,
        selectedHistoryAlarms: {},
      };
    case alarmHistoryActions.FETCHING_ALARM_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        loading: { open: false },
        selectedHistoryAlarms: {},
        historyAlarms: action.payload.content ? action.payload.content : [],
      };
    case alarmHistoryActions.FETCHING_ALARM_HISTORY_FAILURE:
      errorHandler(action.payload);
      return {
        ...state,
        laoding: { open: false },
        isRequiredHistoryAlarms: appConstants.IS_NOT_REQUIRED_ALARMS,
      };

    case alarmHistoryActions.CLEAN_ALARM_HISTORY_STORE:
      return {
        ...state,
        historyAlarms: [],
        isRequiredHistoryAlarms: appConstants.IS_REQUIRED_HISTORY_ALARMS,
        selectedHistoryAlarms: undefined,
      };

    default:
      return state;
  }
};

export default alarmHistoryReducer;
