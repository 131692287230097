export const validateEmail = (email) => {
  let error = '';

  let posicionArroba = email.lastIndexOf('@');
  let posicionPunto = email.lastIndexOf('.');

  error += !email || email?.trim() == '' ? 'Debe especificar el correo electrónico \n' : '';

  if (error === '') {
    if (
      !(
        posicionArroba < posicionPunto &&
        posicionArroba > 0 &&
        email.indexOf('@@') == -1 &&
        posicionPunto > 2 &&
        email.length - posicionPunto > 2
      )
    ) {
      error = 'Por favor, ingresa un correo válido.';
    }
  }
  return error;
};
