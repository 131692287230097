import * as React from 'react';
 import { appConstants } from '../../appConstants';
function SvgLicenciasIcon(props) {
 const { height, width, color } = props;
  return (
    <svg height={640} viewBox="0 0 480.016 480" width={640} xmlns="http://www.w3.org/2000/svg" {...props}
           height={height ? height : appConstants.ICON_DEFAULT_HEIGHT}
      width={width ? width : appConstants.ICON_DEFAULT_WIDTH}
    >
      <path d="M96.016 40.008v432h320v-432zm0 0" fill="#348fd9" />
      <path d="M400.016 96.008l-56-56h-248v416h304zm0 0" fill="#3086cd" />
      <path d="M64.016 440.008h320v-360l-72-72h-248zm0 0" fill="#60d1e0" />
      <path d="M384.016 80.008h-72v-72zm0 0" fill="#9fe4ed" />
      <path d="M384.016 176.008l-264 264h264zm0 0" fill="#5ac6d4" />
      <path
        d="M352.016 408.008h-160a8 8 0 01-8-8v-64a8 8 0 018-8h160a8 8 0 018 8v64a8 8 0 01-8 8zm-152-16h144v-48h-144zm0 0"
        fill="#348fd9"
      />
      <path d="M216.016 360.008h32v16h-32zm0 0M264.016 360.008h64v16h-64zm0 0" fill="#348fd9" />
      <path d="M248.016 264.008l47.996-47.996 112 112-47.996 47.996zm0 0" fill="#e0e0de" />
      <path
        d="M424.016 144.008c-26.508 0-48 21.492-48 48v4l-72 76 40 40 76-72h4c26.511 0 48-21.492 48-48s-21.489-48-48-48zm0 0"
        fill="#95573a"
      />
      <path d="M224.02 288.004l24-24 112 112-24 24zm0 0" fill="#ebebe9" />
      <path
        d="M443.68 148.246c-33.145 83.816-84.93 123.832-119.575 143.852l19.91 19.91 76-72h4c22.61-.028 42.137-15.832 46.876-37.942s-6.598-44.527-27.211-53.82zm0 0"
        fill="#8f4d2e"
      />
      <path
        d="M254.969 83.207a13.6 13.6 0 00-2.64-15.512 13.6 13.6 0 00-15.513-2.64 13.601 13.601 0 00-25.648 0c-5.203-2.47-11.398-1.395-15.465 2.683s-5.125 10.274-2.64 15.469a13.602 13.602 0 000 25.648 13.605 13.605 0 002.683 15.465 13.598 13.598 0 0015.469 2.64 13.601 13.601 0 0025.648 0c5.203 2.47 11.399 1.395 15.465-2.683s5.125-10.273 2.64-15.468a13.602 13.602 0 000-25.602zm0 0"
        fill="#ffb531"
      />
      <path
        d="M200.016 144.008a87.941 87.941 0 00-46.036-77.38c-28.39-15.382-62.925-13.972-89.964 3.677V217.71c27.039 17.648 61.574 19.059 89.964 3.676a87.941 87.941 0 0046.036-77.38zm0 0M328.016 80.008h56v16h-56zm0 0"
        fill="#5ac6d4"
      />
      <g fill="#3fb7c7">
        <path d="M152.016 232.008h80v16h-80zm0 0M104.016 264.008h96v16h-96zm0 0M104.016 200.008h168v16h-168zm0 0M104.016 296.008h48v16h-48zm0 0M104.016 328.008h64v16h-64zm0 0M104.016 360.008h64v16h-64zm0 0M104.016 392.008h64v16h-64zm0 0M104.016 232.008h32v16h-32zm0 0M168.016 296.008h32v16h-32zm0 0M168.016 160.008h32v16h-32zm0 0M216.016 160.008h64v16h-64zm0 0" />
      </g>
      <path
        d="M184.016 128.008c0 48.601-39.399 88-88 88s-88-39.399-88-88c0-48.602 39.398-88 88-88s88 39.398 88 88zm0 0"
        fill="#7eb457"
      />
      <path
        d="M64.016 176.008a8.001 8.001 0 01-5.657-2.344l-24-24 11.313-11.312 18.344 18.343 74.343-74.343 11.313 11.312-80 80a8.001 8.001 0 01-5.656 2.344zm0 0"
        fill="#ffb531"
      />
      <path
        d="M198.191 126.137a86.944 86.944 0 00-5.28-16.801 13.398 13.398 0 005.28 16.8zm0 0M192.512 108.527a88.356 88.356 0 00-8.192-14.617 13.312 13.312 0 008.192 14.617zm0 0"
        fill="#f98500"
      />
      <path d="M424.016 120.008v-80a8 8 0 00-8-8h-32v16h24v72zm0 0M56.016 240.008v200a8 8 0 008 8h320a8 8 0 008-8v-56h-16v48h-304v-192zm0 0M315.328.809c-.25-.114-.488-.176-.746-.266a7.952 7.952 0 00-2.07-.414c-.176-.031-.328-.121-.496-.121h-248a8 8 0 00-8 8v16h16v-8h232v64a8 8 0 008 8h64v40h16v-48c0-.168-.086-.32-.098-.496a7.794 7.794 0 00-.414-2.07c-.09-.258-.152-.497-.266-.747a7.947 7.947 0 00-1.597-2.343l-72-72a8.013 8.013 0 00-2.313-1.543zm49.375 71.199h-44.687V27.32zm0 0" />
      <path d="M184.016 336.008v64a8 8 0 008 8h104v-16h-96v-48h40v-16h-48a8 8 0 00-8 8zm0 0" />
      <path d="M216.016 360.008h48v16h-48zm0 0" />
      <path d="M424.016 136.008c-30.91.039-55.961 25.09-56 56v.8l-40.801 43.07-25.543-25.542a7.997 7.997 0 00-11.313 0l-72 72a8.004 8.004 0 000 11.312l112 112a8.004 8.004 0 0011.313 0l24-24 42.344-42.328v124.688h-312v16h320a8 8 0 008-8v-192h-16v36.687l-27.887-27.886 43.086-40.801h.8c30.93 0 56-25.07 56-56s-25.07-56-56-56zm-88 252.687L235.328 288.008l12.688-12.688 100.687 100.688zm60.687-60.687l-36.687 36.687-100.688-100.687 36.688-36.688 20.191 20.192-18 18.992a8 8 0 00.152 11.2l40 40a7.998 7.998 0 0011.153.151l19-18zm27.313-96h-4a8.015 8.015 0 00-5.504 2.191l-70.344 66.61-28.953-28.954 66.64-70.343a8 8 0 002.16-5.504v-4c0-22.09 17.91-40 40-40s40 17.91 40 40-17.91 40-40 40zm0 0M152.016 232.008h80v16h-80zm0 0M104.016 264.008h96v16h-96zm0 0M176.016 200.008h96v16h-96zm0 0M104.016 296.008h48v16h-48zm0 0M104.016 328.008h64v16h-64zm0 0" />
      <path d="M104.016 360.008h64v16h-64zm0 0M104.016 392.008h64v16h-64zm0 0M104.016 232.008h32v16h-32zm0 0M168.016 296.008h32v16h-32zm0 0M200.016 160.008h80v16h-80zm0 0" />
      <path d="M96.016 224.008c51.668-.059 94.023-41.004 95.832-92.64a21.148 21.148 0 0015.511 4.847 21.697 21.697 0 0033.313 0 21.698 21.698 0 0023.55-23.55 21.684 21.684 0 007.794-16.657 21.684 21.684 0 00-7.793-16.656A21.698 21.698 0 00240.672 55.8a21.697 21.697 0 00-33.313 0 21.698 21.698 0 00-23.55 23.55 20.77 20.77 0 00-3.2 3.297c-22.195-41.398-71.082-60.656-115.547-45.52C20.599 52.27-6.386 97.356 1.29 143.696c7.676 46.34 47.758 80.32 94.727 80.313zm99.726-133.281a8 8 0 004.711-4.473 8.015 8.015 0 00-.164-6.492 5.606 5.606 0 011.078-6.403 5.606 5.606 0 016.403-1.078 8.015 8.015 0 006.492.164 8 8 0 004.472-4.71 5.602 5.602 0 0110.563 0 8 8 0 004.473 4.71c2.09.864 4.449.805 6.496-.164a5.601 5.601 0 016.398 1.078 5.606 5.606 0 011.078 6.403 8.015 8.015 0 00-.164 6.492 8 8 0 004.711 4.473 5.602 5.602 0 010 10.562 8 8 0 00-4.71 4.473 8.024 8.024 0 00.163 6.496 5.382 5.382 0 01-1.101 6.398 5.447 5.447 0 01-6.403 1.106 7.984 7.984 0 00-6.492-.164 7.982 7.982 0 00-4.473 4.707 5.602 5.602 0 01-10.563 0 7.98 7.98 0 00-4.48-4.711 8.156 8.156 0 00-3.062-.61 7.834 7.834 0 00-3.426.801 5.608 5.608 0 01-6.398-1.082 5.601 5.601 0 01-1.078-6.398 8.015 8.015 0 00.164-6.493 7.995 7.995 0 00-4.711-4.476 5.598 5.598 0 010-10.559zm-99.726-42.72c44.183 0 80 35.817 80 80 0 44.184-35.817 80-80 80s-80-35.816-80-80c.047-44.163 35.836-79.952 80-80zm0 0" />
      <path d="M58.36 173.664a8.004 8.004 0 0011.312 0l80-80-11.313-11.312-74.343 74.343-18.344-18.343-11.313 11.312zm0 0" />
    </svg>
  );
}

export default SvgLicenciasIcon;
