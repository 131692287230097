import swal from 'sweetalert';
import { appConstants } from '../appConstants';

export const position = {
  top: 'top',
  topEnd: 'top-end',
  topStart: 'top-start',
  center: 'center',
  centerStart: 'center-start',
  centerEnd: 'center-end',
  bottom: 'bottom',
  bottomStart: 'bottom-start',
  bottomEnd: 'bottom-end',
};

export const icon = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
  question: 'question',
};

export const titles = {
  error: 'Alerta',
  success: 'Operación completada con éxito',
  information: 'Se informa que',
};

export const errorHandler = (error) => {
  let msn = appConstants.DEFAULT_ERROR;
  if (error) {
    if ((error.payload + '').indexOf('Network Error') != -1) {
      msn = 'Error de conexión ';
    } else if (error.payload && error.payload.response) {
      let data = error.payload.response?.data;
      switch (error.payload.response?.status) {
        case 400:
          msn = data?.code ? data?.code : 'No se encontro el recurso solicitado.';
          break;
        case 401:
          msn = 'No se encuentra autorizado para acceder al recurso solicitado.';

          break;
        case 404:
          msn = data?.code ? data?.code : 'No se encontro el recurso solicitado.';
          break;

        default:
          break;
      }
    }
  }

  swal({ title: titles.error, text: msn, icon: icon.error });
};

export const commonMessage = (message, title, iconParam = icon.info) => {
  swal({ text: message, title: title, icon: iconParam });
};
