import { appConstants } from '../../appConstants';
import DocumentationApi from '../services/DocumentationApi';

export const DocumentationActions = {
  SET_MANUAL_UPLOAD: 'SET_MANUAL_UPLOAD',
  FETCHING_DOCUMENTATION_SAVE_REQUEST: 'FETCHING_DOCUMENTATION_SAVE_REQUEST',
  FETCHING_DOCUMENTATION_SAVE_SUCCESS: 'FETCHING_DOCUMENTATION_SAVE_SUCCESS',
  FETCHING_DOCUMENTATION_FAILURE: 'FETCHING_DOCUMENTATION_FAILURE',
};

export const fetchAction = (type, payload = undefined) => {
  return {
    type,
    payload,
  };
};

export const saveManual = (file) => {
  return function (dispatch) {
    DocumentationApi.saveDocumnent(appConstants.CONTEX_DOCUMENTATION, file, dispatch, fetchAction, {
      request: DocumentationActions.FETCHING_DOCUMENTATION_SAVE_REQUEST,
      success: DocumentationActions.FETCHING_DOCUMENTATION_SAVE_SUCCESS,
      error: DocumentationActions.FETCHING_DOCUMENTATION_FAILURE,
    });
  };
};
