import { Box, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { fetchAction } from '../../components/actions/componentActions';
import { getCurrentUser } from '../actions/userActions';
import '../assets/styles/user.scss';

const InfoUser = (props) => {
  useEffect(() => {
    if (props.isRequiredCurrentUser == appConstants.IS_REQUIRED_CURRENT_USER) {
      props.getCurrentUser();
    }
  }, []);

  return (
    <Box height={1} width={1} boxShadow={3} className="bg-primary ma-0 container">
      <Grid container spacing={2} className="pa-0 w-100 ">
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="pa-0">
          <Box width={1} fontWeight="fontWeightBold" justifyContent="center" display="flex">
            <Typography className="ws-pre-wrap fw-bold ff-poppins fc-third">{props.company?.name}</Typography>
          </Box>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="pt-0 pb-3 pl-0 pr-0">
          <Box width={1} fontWeight="fontWeightBold " justifyContent="center" display="flex">
            <Typography className="ws-pre-wrap fw-bold ff-poppins fc-third">{props.user?.name}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    isRequiredCurrentUser: state.userReducer.isRequiredCurrentUser
      ? state.userReducer.isRequiredCurrentUser
      : appConstants.IS_REQUIRED_CURRENT_USER,
    user: state.userReducer.currentUser,
    company: state.companyReducer.currentCompany,
  };
};

const mapDispatchToProps = {
  getCurrentUser,
  fetchAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoUser);
