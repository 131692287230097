import { Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { componentActions, fetchAction, removeButton, setGlobalTitle } from '../../components/actions/componentActions';
import Loading from '../../components/Loading';
import CompanyForm from '../components/CompanyForm';
import CompanyList from '../components/CompanyList';

const CompanyView = (props) => {
  const toolbarButtons = [{ id: appConstants.BACK_BUTTON_ID, history: props.history }];

  useEffect(() => {
    props.setGlobalTitle(appConstants.COMPANY_MENU_TITLE);

    props.fetchAction(componentActions.ADD_TOOLBAR_BUTTON, toolbarButtons);
  }, []);

  return (
    <Box height={1} width={1} padding={3} className="background-image-bubble ">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box boxShadow={4} borderRadius={10}>
            <CompanyForm />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box boxShadow={4} borderRadius={10}>
            <CompanyList />
          </Box>
        </Grid>
      </Grid>
      <Loading />
    </Box>
  );
};
const mapDispatchToProps = {
  setGlobalTitle,
  fetchAction,
  removeButton,
};
export default connect(null, mapDispatchToProps)(CompanyView);
