import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CheckIcon } from '../../assets/icons';
import '../../assets/styles/App.scss';
import './MenuButtons.scss';

const MenuButtons = (props) => {
  const { items, perRow } = props;

  return (
    <Box
      width={1}
      height={1}
      padding={3}
      justifyContent="center"
      alignItems="center"
      className="background-image-bubble"
    >
      <Grid container spacing={3} className="d-flex center">
        {items.map((item, index) => {
          return (
            <Grid item xl={6} lg={6} md={6} xs={12} sm={12} style={{ position: 'relative' }} key={index}>
              <Link to={item.route} className="item-navbar item-menu-button-text">
                <Box boxShadow={3} borderRadius={17} justifyContent="center" width={1} height={1} className="box-item">
                  <Box
                    height={8 / 10}
                    width={1}
                    justifyContent="center"
                    display="flex"
                    position="relative"
                    padding="15px"
                  >
                    {item.icon}
                    <Box position="absolute" zIndex={10} left={5} top="50%" id={`icon${index}`} display="none">
                      <CheckIcon height="25px" width="25px" />
                    </Box>
                  </Box>
                  <Box height={2 / 10} width={1} justifyContent="center" display="flex">
                    <Typography variant="h5" className="item-menu-button-text">
                      {item.name}
                    </Typography>
                  </Box>
                </Box>
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

const mapDispatchToProps = {};

export default connect(null, null)(MenuButtons);
