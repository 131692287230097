import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  AoaIcon,
  ChargeabilityIcon,
  DashboardIcon,
  HeadquarterIcon,
  HomeIcon,
  InstallationIcon,
  PowerPlantIcon,
  ReportIcon,
  SupportIcon,
  TrendIcon,
  UserIcon,
  WarningIcon,
} from '../../assets/icons';
export const menuAdmin = (
  <div>
    <Link to="/" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <HomeIcon height={30} width={30} />
        </ListItemIcon>
        <ListItemText primary="Inicio" className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>

    <Link to="/users" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <UserIcon height={30} width={30} />
        </ListItemIcon>
        <ListItemText primary="Usuarios" className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>

    <Link to="/headquarter" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <HeadquarterIcon height={30} width={30} />
        </ListItemIcon>
        <ListItemText primary="Sedes" className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>

    <Link to="/installation/menu" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <InstallationIcon height={30} width={30} />
        </ListItemIcon>
        <ListItemText primary="Instalación " className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>

    <Link to="/trends" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <TrendIcon height={30} width={30} />
        </ListItemIcon>
        <ListItemText primary="Trends" className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>

    <Link to="/dashboard" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon height={30} width={30} />
        </ListItemIcon>
        <ListItemText primary="Dashboard" className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>

    <Link to="/reports" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <ReportIcon height={30} width={30} />
        </ListItemIcon>
        <ListItemText primary="Reportes" className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>

    <Link to="/energy-reactive/menu/" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <PowerPlantIcon height={30} width={30} />
        </ListItemIcon>
        <ListItemText primary="Análisis reactivos" className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>

    <Link to="/alarms/menu" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <WarningIcon height={30} width={30} />
        </ListItemIcon>
        <ListItemText primary="Alarmas" className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>

    <Link to="/aoa" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <AoaIcon height={42} width={42} />
        </ListItemIcon>
        <ListItemText primary="AOA" className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>

    <Link to="/chargeability" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <ChargeabilityIcon height={42} width={38} />
        </ListItemIcon>
        <ListItemText primary="Cargabilidad" className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>

    <Link to="/support" className="item-navbar ">
      <ListItem button>
        <ListItemIcon>
          <SupportIcon height={30} width={30} />
        </ListItemIcon>
        <ListItemText primary="Soporte" className="item-navbar-text ff-poppins" />
      </ListItem>
    </Link>
  </div>
);
