import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { fetchRequest, saveCompany, updateCompany } from '../../company/actions/companyActions';
import { activeCompany } from '../../license/actions/licenseActions';
import { commonMessage, icon } from '../../service/messageHandler';
import LogOutModalButton from '../../user/components/LogOutModalButton';
import { equalsLicense, haveLicense, isActive } from '../../util/function/ValidLicense';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '90vh',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ValidLicenseModal = (props) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [serial, setSerial] = React.useState({ number: '' });
  const { company, licenseFound } = props;

  useEffect(() => {
    if (company === undefined) {
      return;
    }

    if (haveLicense(company)) {
      if (!isActive(company?.license)) setOpen(true);
      if (isActive(company?.license)) setOpen(false);
    } else setOpen(true);

    if (licenseFound !== undefined) {
      setOpen(false);
    }
  }, [company, licenseFound]);

  const handleSave = () => {
    let msn = validate(serial.number);
    if (msn !== '') {
      commonMessage(msn, appConstants.VALIDATION_ERROR_TITLE, icon.info);
      return;
    }
    props.activeCompany(serial.number);
  };
  const validate = (number) => {
    let msn = '';
    msn += !number || number?.trim() === '' ? 'Debe Ingresar el número de licencía  \n' : '';
    return msn;
  };

  function TextMaskCustom(data) {
    const { inputRef, ...other } = data;

    return (
      <MaskedInput
        className="center-important"
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          '-',
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          '-',
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          '-',
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          '-',
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          /\w/,
          /\w/,
        ]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  }
  return (
    <div>
      <Modal open={open} aria-labelledby="license-modal-title" aria-describedby="license-modal-description">
        <div style={modalStyle} className={classes.paper}>
          <Box height={1}>
            <Card className="border-10">
              <CardContent>
                <form noValidate={false} autoComplete="off">
                  <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info bg-secundary border-10">
                      <Typography variant="h5" className="ff-poppins fw-bold  fc-third">
                        Activar Licencia
                      </Typography>
                    </Grid>

                    <Grid item md={12} xl={12} lg={12} sm={12} xs={12} className="center-info t-center-important">
                      <Box
                        height={1}
                        width={1}
                        boxShadow={5}
                        borderRadius={10}
                        className="center-info t-center-important"
                      >
                        <TextField
                          className="center-info t-center-important"
                          required
                          id="serial"
                          label="Número de Licencía"
                          fullWidth
                          variant="outlined"
                          value={serial.number}
                          onChange={(valueEvent) => setSerial({ ...serial, number: valueEvent.target.value })}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
              <CardActions className="center d-flex">
                <Grid item md={6} xl={6} lg={6} sm={6} xs={6} className="center-info">
                  <ButtonGroup variant="text" color="primary" aria-label="text primary button group" className="center">
                    <Button className="bg-green-gradient mr-2 pl-5 pr-5" onClick={handleSave}>
                      Activar
                    </Button>
                    <LogOutModalButton />
                  </ButtonGroup>
                </Grid>
              </CardActions>
            </Card>
          </Box>
        </div>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = {
  fetchRequest,
  saveCompany,
  updateCompany,
  isActive,
  haveLicense,
  activeCompany,
  equalsLicense,
};

const mapStateToProps = (state) => {
  return {
    company: state.companyReducer.currentCompany ? state.companyReducer.currentCompany : undefined,
    isRequiredCompanies: state.companyReducer.isRequiredCompanies,
    licenseFound: state.licenseReducer.licenseFound ? state.licenseReducer.licenseFound : undefined,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidLicenseModal);
