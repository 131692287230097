import { saveAs } from 'file-saver';
import { appConstants } from '../../appConstants';
import { errorHandler } from '../../service/messageHandler';
import { showToast } from '../../service/messageToast';
import { convertDate } from '../../service/util';
import { dashboardAction } from '../action/dashboardActions';

const dashboardReducer = (state = {}, action) => {
  switch (action.type) {
    case dashboardAction.FETCH_SERIE_COMPARATIVE_REQUEST:
    case dashboardAction.FETCH_SERIE_REQUEST:
    case dashboardAction.FETCH_REPORT:
    case dashboardAction.FETCH_LIST_HISTORY:
    case dashboardAction.FETCH_LIST_PER_VALUE:
      return {
        ...state,
        loading: { open: true, message: '' },
      };
    case dashboardAction.FETCH_INTERVALS: {
      return {
        ...state,
        loading: { open: true, message: '' },
        isRequiredIntervals: appConstants.IS_NOT_REQUIRED_DASHBOARD_INTERVALS,
      };
    }

    case dashboardAction.FETCH_INTERVALS_SUCCESS:
      return {
        ...state,
        intervals: action.payload,
        loading: { open: false, message: '' },
      };
    case dashboardAction.FETCH_LIST_HISTORY_SUCCESS:
      return {
        ...state,
        loading: { open: false, message: '' },
        valuesPerField: action.payload,
      };
    case dashboardAction.FETCH_REQUEST_ERROR:
      errorHandler(action);
      return {
        ...state,
        error: action.payload,
        loading: { open: false, message: '' },
      };
    case dashboardAction.FETCH_REPORT_SUCCESS:
      let date= convertDate(new Date()) ;
      let text = 'Reporte variable '+ date+'.xlsx'; 
      saveAs(action.payload, text);
      return {
        ...state,
        loading: { open: false },
      };
    case dashboardAction.FETCH_REPORT:
      return {
        ...state,
        loading: { open: true, message: '' },
      };
    case dashboardAction.FETCH_LIST_PER_VALUE_SUCCESS:
      return {
        ...state,
        valuesPerField: action.payload,
        loading: { open: false },
      };

    case dashboardAction.ADD_FRECUENCY_GLOBAL:
      if (!state.frecuency) state.frecuency = [];

      state.frecuency.push(action.payload);

      return {
        ...state,
        isRequieredChangeGlobalFrecuency: false,
        isFrecuencyReload: false,
      };

    case dashboardAction.CLEAN_DASHBOARD:
      return {
        ...state,
        isRequiredIntervals: appConstants.IS_REQUIRED_DASHBOARD_INTERVALS,
        valuesPerField: [],
        frecuency: [],
      };
    case dashboardAction.FETCH_SERIE_REQUEST_SUCCESS:
      let principalkeyFilter = Object.keys(action.payload.result);

      let result;
      if (principalkeyFilter && principalkeyFilter.length > 0) {
        state.valuesPerField = state.valuesPerField ? state.valuesPerField : {};
        result = state.valuesPerField[principalkeyFilter[0]];
        let totalResult = defineSeries(result, {
          name: action.payload.serie,
          content: action.payload.result[principalkeyFilter[0]],
        });

        state.valuesPerField[principalkeyFilter[0]] = totalResult;
      }

      return {
        ...state,
        loading: { open: false, message: '' },
      };

    case dashboardAction.CLEAN_VALUES_PER_FIELD_DASHBOARD:
      return {
        ...state,
        valuesPerField: undefined,
      };
    case dashboardAction.FETCHING_CO2_SUCCESS:
      return {
        ...state,
        co2: action.payload,
      };

    case dashboardAction.FETCHING_SYNC_TRENDS:
      return {
        ...state,
        isRequieredSyncTrend: action.payload,
        isRequiredFields: appConstants.IS_REQUIRED_FIELDS_LIST,
        isRequiredIntervals: appConstants.IS_REQUIRED_DASHBOARD_INTERVALS,
      };
    case dashboardAction.FETCH_SERIE_COMPARATIVE_REQUEST_SUCCESS:
      action.payload.forEach((elememnt) => {
        let principalkey = Object.keys(elememnt.result);

        let resultR;
        if (principalkey && principalkey.length > 0) {
          state.valuesPerFieldReport = state.valuesPerFieldReport ? state.valuesPerFieldReport : {};
          resultR = state.valuesPerFieldReport[principalkey[0]];

          let totalResult = defineSeries(resultR, {
            name: elememnt.serie,
            content: elememnt.result[principalkey[0]],
          });

          state.valuesPerFieldReport[principalkey[0]] = totalResult;
        }
      });

      return {
        ...state,
        loading: { open: false, message: '' },
      };
    case dashboardAction.CLEAN_VALUES_PER_FIELD_REPORT_DASHBOARD:
      return {
        ...state,
        valuesPerFieldReport: undefined,
      };

    case dashboardAction.FETCH_REQUEST_EMPTY:
      showToast(appConstants.DONT_INFO_TITLE);
      return {
        ...state,
        loading: { open: false, message: '' },
      };

    case dashboardAction.SET_FRECUENCY_RELOAD:
      return {
        ...state,
        isFrecuencyReload: true,
      };

    case dashboardAction.CLEAN_FRECUENCY_RELOAD:
      return {
        ...state,
        isFrecuencyReload: false,
        frecuency: undefined,
      };

    case dashboardAction.CLEAN_GLOBAL_FRECUENCY:
      return {
        ...state,
        isRequieredChangeGlobalFrecuency: false,
        frecuency: undefined,
      };
    case dashboardAction.SET_GLOBAL_FRECUENCY:
      return {
        ...state,
        globalFrecuency: action.payload,
        isRequieredChangeGlobalFrecuency: true,
      };
    case dashboardAction.SET_SELECTED_FILTER:
      return {
        ...state,
        selectedFilter: action.payload,
      };
    case dashboardAction.CLEAN_SELECTED_FILTER:
      return {
        ...state,
        selectedFilter: undefined,
      };
    case dashboardAction.SET_GLOBAL_SERIES:
      return {
        ...state,
        globalSeries: action.payload,
        isRequiredChangeComparativeDates: true,
      };
      case dashboardAction.SET_SERIES:
      return {
        ...state,
        localSeries: action.payload,
        isRequiredChangeComparativeDates: true,
      };
    case dashboardAction.IS_REQUIRED_CHANGE_COMPARATIVE_DATES:
      return {
        ...state,
        isRequiredChangeComparativeDates: false,
      };

    default:
      return state;
  }
};

const defineSeries = (field, serie) => {
  const series = field?.series;

  if (!series) {
    return { ...field, series: [serie] };
  } else {
    let exists = series.filter((element) => element.name == serie.name);
    if (exists.length > 0) {
      field.series = field.series.map((element) => (element.name == serie.name ? serie : element));
    } else {
      field.series.push(serie);
    }

    return field;
  }
};

export default dashboardReducer;
