import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { getGreatestRol } from '../../user/util/roleRate';
import { menuAdmin } from '../components/MenuAdmin';
import { menuSuperAdmin } from '../components/MenuSuperAdmin';
import { menuSupervisor } from '../components/MenuSupervisor';

export const listItems = (user) => {
  let roleUser = getGreatestRol(user.roles);
  switch (roleUser.name) {
    case appConstants.SUPERADMIN:
      return menuSuperAdmin;

    case appConstants.ADMIN:
      return menuAdmin;

    case appConstants.SUPERVISOR:
      return menuSupervisor;

    case appConstants.OPERATOR:
      break;
    case appConstants.CONTROLLER:
      break;
    case appConstants.USER:
      break;
    default:
      return '';
  }
};
