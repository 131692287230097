import { Box, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const Loading = (props) => {
  const { loading, message } = props;
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(loading);
  }, [loading]);

  const body = (
    <Box>
      <div style={modalStyle} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
            <Box width={1} justifyContent="center" display="flex">
              <CircularProgress disableShrink />
            </Box>
          </Grid>
          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
            <Box width={1} justifyContent="center" display="flex">
              <h4>{message}</h4>
            </Box>
          </Grid>
        </Grid>
      </div>
    </Box>
  );

  const handleClose = () => {};

  return (
    <>
      <Modal
        open={open ? open : false}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading:
      state.componentsReducer.loading?.open ||
      state.userReducer.loading?.open ||
      state.companyReducer.loading?.open ||
      state.headquarterReducer.loading?.open ||
      state.iotReducer.loading?.open ||
      state.fieldReducer.loading?.open ||
      state.dashboardReducer.loading?.open ||
      state.deviceReducer.loading?.open ||
      state.supportReducer.loading?.open ||
      state.valueReducer.loading?.open ||
      state.alarmReducer.loading?.open ||
      state.installationReducer.loading?.open ||
      state.reportReducers.loading?.open ||
      state.CregReducer.loading?.open,
    message: 'cargando ...',
  };
};

export default connect(mapStateToProps, null)(Loading);
