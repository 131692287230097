import { Box, Button, Grid, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import EditIcon from '@material-ui/icons/Edit';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { CancelIcon } from '../../assets/icons';
import AlarmWithSelectsForms from '../components/AlarmWithSelectsForms';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '100vh',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const AlarmFormModal = (props) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(appConstants.CLOSE_EVENT == props.alarmCreationModal);
  }, [props.alarmCreationModal]);

  return (
    <Box height={1}>
      {props.edit ? (
        <Button color="primary" className="btn-transparent ff-poppins fc-green" onClick={handleOpen}>
          <EditIcon />
        </Button>
      ) : (
        <Button type="button" onClick={handleOpen} className="bg-green-gradient h-100 w-100">
          <AddAlarmIcon />
          Alarma
        </Button>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="alarm-modal-title"
        aria-describedby="alarm-modal-description"
      >
        <Box className=" mt-20px center-info ">
          <Grid container spacing={1} className="size-modal">
            <Grid item xl={12} lg={12} md={12} xs={12} sm={12} container className="center-info bg-secundary ">
              <Grid item xl={11} lg={11} md={11} xs={10} sm={10} className="center-info bg-secundary ">
                <Typography className="ff-lpoppins fc-third fs-alarm">CREACIÓN DE ALARMAS</Typography>
              </Grid>
              <Grid item xl={1} lg={1} md={1} sm={2} xs={2} className="bg-secundary ">
                <Button onClick={(e) => handleClose()}>
                  <CancelIcon className="mr-1" height="25px" width="25px" />
                </Button>
              </Grid>
            </Grid>
            <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info">
              <AlarmWithSelectsForms />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    alarmCreationModal: state.alarmReducer.alarmCreationModal ? true : false,
  };
};

export default connect(mapStateToProps, null)(AlarmFormModal);
