import { Box, Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { appConstants } from './appConstants';
import logoglem from './assets/image/logoglem.png';
import './assets/styles/App.scss';
import { getCurrentByUser } from './company/actions/companyActions';
import { fetchAction } from './components/actions/componentActions';
import NotFound from './components/NotFound';
import ValidLicenseModal from './license/components/ValidLicenseModal';
import { getCurrentUser } from './user/actions/userActions';
import InfoUser from './user/components/InfoUser';
import LogOutButton from './user/components/LogOutButton';
import { getRoutes } from './user/util/routers';
import { listItems } from './util/function/getListItems';
import { getToolbarButtonById, getToolbarIconByTitle } from './util/UtilFunctions';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    fontWeight: 'bold',
    color: appConstants.COLOR_THIRD,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: appConstants.COLOR_THIRD,
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
}));

const App = (props) => {
  const { isUserLogged, globalTitle, toolbarButtons, company } = props;

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (props.isRequiredCurrentUser == appConstants.IS_REQUIRED_CURRENT_USER && localStorage.getItem('token')) {
      props.getCurrentUser();
    }

    if (isUserLogged && company === undefined) {
      props.getCurrentByUser();
    }
  }, [isUserLogged]);

  return (
    <BrowserRouter className="w-width-260px">
      <div className="root ">
        <CssBaseline />

        {isUserLogged && (
          <AppBar className={`${clsx(classes.appBar, open && classes.appBarShift)} app-bar`}>
            <Toolbar className="app-toolbar bg-secundary">
              <Tooltip title="Abrir Menu" aria-label="add">
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                >
                  <MenuIcon />
                </IconButton>
              </Tooltip>
              {getToolbarIconByTitle(globalTitle)}
              <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                <span className="ff-poppins">{globalTitle}</span>
              </Typography>

              {toolbarButtons &&
                toolbarButtons.map((button) => {
                  return <Box key={button.id}>{getToolbarButtonById(button)}</Box>;
                })}
            </Toolbar>
          </AppBar>
        )}
        {isUserLogged && (
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            className="background-view "
            open={open}
            style={{ background: appConstants.COLOR_THIRD }}
          >
            <Box>
              <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                <div className={classes.toolbarIcon}>
                  <Box height={1} width={8 / 10} justifyContent="center" display="flex">
                    {open && <img src={logoglem} height={'80px'} width={'140px'} className="pt-2 pb-2" />}
                  </Box>
                  <Box height={1} width={2 / 10} alignItems="center" display="flex">
                    <Tooltip title="Cerrar Menu" aria-label="add">
                      <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </div>
              </Grid>
            </Box>
            <Divider />
            {props.isNotRequiredListMenuAndRoutes == appConstants.IS_REQUIRED_MENU_ROUTE_LIST && props.user?.roles && (
              <List className="oy-scroll ox-hidden h-100">{listItems(props.user, props.fetchAction)}</List>
            )}
            <Box bottom={0} width={1} padding={0}>
              <Grid container spacing={0}>
                {open && (
                  <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                    <InfoUser />
                  </Grid>
                )}
                <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                  <LogOutButton />
                </Grid>
              </Grid>
            </Box>
          </Drawer>
        )}
        <div className={isUserLogged ? 'app-content' : 'app-content-no-logged'}>
          <Switch>
            {props.isNotRequiredListMenuAndRoutes == appConstants.IS_REQUIRED_MENU_ROUTE_LIST &&
              getRoutes(props.user, isUserLogged)}

            <Route component={NotFound} />
          </Switch>
          {props.user && props.user?.roles[0].role.name !== appConstants.SUPERADMIN && <ValidLicenseModal />}
        </div>
      </div>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  return {
    isRequiredCurrentUser: state.userReducer.isRequiredCurrentUser
      ? state.userReducer.isRequiredCurrentUser
      : appConstants.IS_REQUIRED_CURRENT_USER,
    user: state.userReducer.currentUser,

    isUserLogged: state.userReducer.logged ? state.userReducer.logged : false,

    globalTitle: state.componentsReducer.globalTitle ? state.componentsReducer.globalTitle : appConstants.GLOBAL_TITLE,

    buttons: state.componentsReducer.buttons ? state.componentsReducer.buttons : [],

    toolbarButtons: state.componentsReducer.toolbarButtons ? state.componentsReducer.toolbarButtons : [],

    isNotRequiredListMenuAndRoutes: state.componentsReducer.isNotRequiredListMenuAndRoutes
      ? state.componentsReducer.isNotRequiredListMenuAndRoutes
      : appConstants.IS_REQUIRED_MENU_ROUTE_LIST,

    isRequiredCompany: state.companyReducer.isRequiredCompany
      ? state.companyReducer.isRequiredCompany
      : appConstants.IS_REQUIRED_COMPANY,
    company: state.companyReducer.currentCompany ? state.companyReducer.currentCompany : undefined,
  };
};

const mapDispatchToProps = {
  fetchAction,
  getCurrentUser,
  getCurrentByUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
