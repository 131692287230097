import { appConstants } from '../../appConstants';
import { ConfigHomeActions } from '../../configHome/actions/ConfigHomeActions';
import { commonMessage, icon } from '../../service/messageHandler';

const ConfigHomeReducers = (state = {}, action) => {
  switch (action.type) {

    case ConfigHomeActions.SET_TYPE_HOME:
      return { ...state, selectedTypeHome: action.payload.item };

    case ConfigHomeActions.FETCH_TYPE_SUCCESS:
      return {
        ...state,
        loading: { open: false },
        typeHome: action.payload,
      };

    case ConfigHomeActions.FETCH_REQUEST_TYPE:
      return {
        ...state,
        loading: { open: true },
        isRequiredTypeHome: appConstants.IS_NOT_REQUIRED_TYPE_HOME,
      };

    case ConfigHomeActions.FETCHING_CONFIG_HOME_SAVE_SUCCESS:
      commonMessage(appConstants.SAVE_CONFIG_HOME_SUCCESS, appConstants.CONFIG_HOME_TITLE, icon.success);
      return {
        ...state,
        loading: { open: false },
        selectedConfigHome: {},
        isRequiredConfigHome: appConstants.IS_REQUIERED_CONFIG_HOME_LIST,
      };
    case ConfigHomeActions.FETCHING_CONFIG_HOME_LIST_SUCCESS:
      return {
        ...state,
        avaliabledConfigHome: action.payload.content ? action.payload.content : [],
        isRequiredConfigHome: appConstants.IS_NOT_REQUIERED_CONFIG_HOME_LIST,
        loading: { open: false },
      };
    case ConfigHomeActions.FETCHING_CONFIG_HOME_REQUEST_LIST:
      return {
        ...state,
        isRequiredConfigHome: appConstants.IS_NOT_REQUIERED_CONFIG_HOME_LIST,
        loading: { open: true },
      };
    case ConfigHomeActions.SET_CONFIG_HOME:
      return { ...state, selectedConfigHome: action.payload.item };

    case ConfigHomeActions.FETCHING_CONFIG_HOME_UPDATE_SUCCESS:
      commonMessage(appConstants.UPDATE_CONFIG_HOME_SUCCESS, appConstants.CONFIG_HOME_TITLE, icon.success);
      return {
        ...state,
        selectedConfigHome: {},
        loading: { open: false },
        isRequiredConfigHome: appConstants.IS_REQUIERED_CONFIG_HOME_LIST,
      };
      case ConfigHomeActions.FETCHING_NOTIFICATION_LIST_SUCCESS:
        return {
          ...state,
          avaliabledNotification: action.payload.content,
          loading: { open: false },
        };

        case ConfigHomeActions.FETCHING_NOTIFICATION_REQUEST_LIST:
          return {
            ...state,
            avaliabledNotification: [],
            loading: { open: true },
            isRequiredNotification: appConstants.IS_NOT_REQUIERED_NOTIFICATION_LIST,
          };

        case ConfigHomeActions.FETCHING_UPDATE_NOTIFICATION_SUCCESS:
          commonMessage(appConstants.UPDATE_NOTIFICATION_SUCCESS, appConstants.NOTIFICATION_TITLE, icon.success);
          return {
            ...state,
            selectedNotification: {},
            loading: { open: false },
            isRequiredNotification: appConstants.IS_REQUIERED_NOTIFICATION_LIST,
          };
        case ConfigHomeActions.SET_OPEN_MODAL:
            return { ...state, isOpen: action.payload.item };

    default:
      return {};
  }
};

export default ConfigHomeReducers;
