import { Box, Grid, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { LogoutIcon } from '../../assets/icons';
import '../../assets/styles/App.scss';
import { companyActions, fetchRequest as fetchCompany } from '../../company/actions/companyActions';
import { fetchRequest, userActions } from '../actions/userActions';
import '../assets/styles/user.scss';

function LogOutButton(props) {
  const logOut = (event) => {
    event.preventDefault();
    props.fetchRequest(userActions.LOG_OUT);
    props.fetchCompany(companyActions.LOG_OUT);
    history.push('');
  };

  const history = useHistory();

  return (
    <Box height={1} width={1} boxShadow={3} className="bg-primary ma-0 container" fontWeight="fontWeightBold ">
      <Grid container spacing={2}>
        <Grid item xlxs={12} lgxs={12} mdxs={12} smxs={12} xs={12}>
          <ListItem button onClick={logOut}>
            <ListItemIcon>
              <LogoutIcon height={30} width={30} />
            </ListItemIcon>
            <ListItemText primary="Cerrar sesión" className="log-out-text" />
          </ListItem>
        </Grid>
      </Grid>
    </Box>
  );
}

const mapDispatchToProps = {
  fetchRequest,
  fetchCompany,
};

export default connect(null, mapDispatchToProps)(LogOutButton);
