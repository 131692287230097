import { appConstants } from '../../appConstants';
import ApiAxios from '../../service/Api';

export const masterFieldActions = {
  SET_FIELD_MASTER: 'SET_FIELD_MASTER',
  listFieldMasters: 'list',
  setFieldMasters: 'setFieldMasters',
  FETCHING_FIELD_MASTER_REQUEST_SAVE: 'FETCHING_FIELD_MASTER_REQUEST',
  FETCHING_FIELD_MASTER_REQUEST_UPDATE: 'FETCHING_FIELD_MASTER_REQUEST_UPDATE',
  FETCHING_FIELD_MASTER_REQUEST_LIST: 'FETCHING_FIELD_MASTER_REQUEST_LIST',
  FETCHING_FIELD_MASTER_SAVE_SUCCESS: 'FETCHING_FIELD_MASTER_SAVE_SUCCESS',
  FETCHING_FIELD_MASTER_UPDATE_SUCCESS: 'FETCHING_FIELD_MASTER_UPDATE_SUCCESS',
  FETCHING_FIELD_MASTER_LIST_SUCCESS: 'FETCHING_FIELD_MASTER_LIST_SUCCESS',
  FETCHING_FIELD_MASTER_FAILURE: 'FETCHING_FIELD_MASTER_FAILURE',
  CLEAN_FIELD_MASTER_STORE: 'CLEAN_FIELD_MASTER',
  FETCH_REQUEST_UNITS: 'FETCH_REQUEST_UNITS',
  FETCH_REQUEST_UNITS_SUCCESS: 'FETCH_REQUEST_UNITS_SUCCESS',
  FETCH_REQUEST_GRAPHICS: 'FETCH_REQUEST_GRAPHICS',
  FETCH_REQUEST_GRAPHICS_SUCCESS: 'FETCH_REQUEST_GRAPHICS_SUCCESS',
  FETCH_REQUEST_SIGNALTYPES: 'FETCH_REQUEST_SIGNALTYPES',
  FETCH_REQUEST_SIGNALTYPES_SUCCESS: 'FETCH_REQUEST_SIGNALTYPES_SUCCESS',
  FETCH_REQUEST_TYPEVALUES: 'FETCH_REQUEST_TYPEVALUES',
  FETCH_REQUEST_TYPEVALUES_SUCCESS: 'FETCH_REQUEST_TYPEVALUES_SUCCESS',
  FETCH_REQUEST_TYPEVALUES_FIELD: 'FETCH_REQUEST_TYPEVALUES_FIELD',
  FETCH_REQUEST_TYPEVALUES_FIELD_SUCCESS: 'FETCH_REQUEST_TYPEVALUES_FIELD_SUCCESS',
  FETCHING_MASTER_FIELD_REQUEST: 'FETCHING_MASTER_FIELD_REQUEST',
  FETCHING_MASTER_FIELD_DELETE_SUCCESS: 'FETCHING_MASTER_FIELD_DELETE_SUCCESS',
};

export const setFieldMaster = (payload) => ({
  type: masterFieldActions.SET_FIELD_MASTER,
  payload,
});

export const setFieldMasters = (payload) => ({
  type: masterFieldActions.setFieldMasters,
  payload,
});

export const cleanFieldMasters = () => {
  return {
    type: masterFieldActions.CLEAN_FIELD_MASTER_STORE,
  };
};

export const fetchRequest = (type, payload) => {
  return {
    type,
    payload,
  };
};

export const saveFieldMaster = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_MASTER_FIELD, item, dispatch, fetchRequest, {
      request: masterFieldActions.FETCHING_FIELD_MASTER_REQUEST_SAVE,
      success: masterFieldActions.FETCHING_FIELD_MASTER_SAVE_SUCCESS,
      error: masterFieldActions.FETCHING_FIELD_MASTER_FAILURE,
    });
  };
};

export const updateFieldMaster = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.put(appConstants.CONTEXT_MASTER_FIELD, item, dispatch, fetchRequest, {
      request: masterFieldActions.FETCHING_FIELD_MASTER_REQUEST_UPDATE,
      success: masterFieldActions.FETCHING_FIELD_MASTER_UPDATE_SUCCESS,
      error: masterFieldActions.FETCHING_FIELD_MASTER_FAILURE,
    });
  };
};

export const listFieldsMaster = (dto) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_MASTER_FIELDS, dto, dispatch, fetchRequest, {
      request: masterFieldActions.FETCHING_FIELD_MASTER_REQUEST_LIST,
      success: masterFieldActions.FETCHING_FIELD_MASTER_LIST_SUCCESS,
      error: masterFieldActions.FETCHING_FIELD_MASTER_FAILURE,
    });
  };
};

export const getUnits = () => {
  return function (dispatch) {
    ApiAxios.crudGeneric.get(`${appConstants.CONTEXT_MASTER_FIELD}units`, dispatch, fetchRequest, {
      request: masterFieldActions.FETCH_REQUEST_UNITS,
      success: masterFieldActions.FETCH_REQUEST_UNITS_SUCCESS,
      error: masterFieldActions.FETCHING_FIELD_MASTER_FAILURE,
    });
  };
};
export const getTypeGraphics = () => {
  return function (dispatch) {
    ApiAxios.crudGeneric.get(`${appConstants.CONTEXT_MASTER_FIELD}graphic-types`, dispatch, fetchRequest, {
      request: masterFieldActions.FETCH_REQUEST_GRAPHICS,
      success: masterFieldActions.FETCH_REQUEST_GRAPHICS_SUCCESS,
      error: masterFieldActions.FETCHING_FIELD_MASTER_FAILURE,
    });
  };
};
export const getTypeSignals = () => {
  return function (dispatch) {
    ApiAxios.crudGeneric.get(`${appConstants.CONTEXT_MASTER_FIELD}signal-types`, dispatch, fetchRequest, {
      request: masterFieldActions.FETCH_REQUEST_SIGNALTYPES,
      success: masterFieldActions.FETCH_REQUEST_SIGNALTYPES_SUCCESS,
      error: masterFieldActions.FETCHING_FIELD_MASTER_FAILURE,
    });
  };
};
export const getTypeValue = () => {
  return function (dispatch) {
    ApiAxios.crudGeneric.get(`${appConstants.CONTEXT_MASTER_FIELD}value-types`, dispatch, fetchRequest, {
      request: masterFieldActions.FETCH_REQUEST_TYPEVALUES,
      success: masterFieldActions.FETCH_REQUEST_TYPEVALUES_SUCCESS,
      error: masterFieldActions.FETCHING_FIELD_MASTER_FAILURE,
    });
  };
};
export const getTypeValueField = () => {
  return function (dispatch) {
    ApiAxios.crudGeneric.get(`${appConstants.CONTEXT_MASTER_FIELD}value-field-types`, dispatch, fetchRequest, {
      request: masterFieldActions.FETCH_REQUEST_TYPEVALUES_FIELD,
      success: masterFieldActions.FETCH_REQUEST_TYPEVALUES_FIELD_SUCCESS,
      error: masterFieldActions.FETCHING_FIELD_MASTER_FAILURE,
    });
  };
};

export const deleteMasterField = (id) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.delete(`${appConstants.CONTEXT_MASTER_FIELD}${id}`, dispatch, fetchRequest, {
      request: masterFieldActions.FETCHING_MASTER_FIELD_REQUEST,
      success: masterFieldActions.FETCHING_MASTER_FIELD_DELETE_SUCCESS,
      error: masterFieldActions.FETCHING_FIELD_MASTER_FAILURE,
    });
  };
};
