import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import CloudDownload from '@material-ui/icons/CloudDownload';
import 'c3/c3.css';
import { format } from 'd3-format';
import React from 'react';
import C3Chart from 'react-c3js';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import Loading from '../../components/Loading';
import axiosInstance from '../../service/axiosInstance';
import { showToast } from '../../service/messageToast';
import { convertDate, getGroupByTrends } from '../../service/util';
import { dashboardAction, downloadTrend, fetchRequest } from '../action/dashboardActions';
import '../styles/dashboard.scss';

class Trend extends React.Component {
  constructor(props) {
    super(props);
    this.info = {
      updateIn: 5,
      frecuency: '15',
      average: 0,
      current: undefined,
    };
    this.state = {
      graphic: {
        data: {
          x: 'x',
          columns: [['x'], [props.field.name]],
        },
        legend: {
          show: false,
        },
        padding: {
          bottom: 75,
        },
        axis: {
          x: {
            label: {
              text: 'FECHA',
              position: 'inner-right',
            },

            type: 'timeseries',
            tick: {
              rotate: -50,
              format: '%m-%d %H:%M',
            },
          },
          y: {
            label: {
              text: this.getMedida(),
              position: 'outer-top',
            },
            type: 'cantidad',
            position: 'outer-middle',
            tick: {
              format: format('.2f'),
            },
          },
        },
        tooltip: {
          format: {
            title: function (x) {
              return `${convertDate(x)}`;
            },
          },
        },
        color: {
          pattern: ['#1d2877'],
        },
      },
      valuesHistory: this.props.valuesPerField && this.props.valuesPerField[this.props.field?.name],
    };

    this.ref = {};

    this.timeOutRefres = undefined;
    this.reload = true;
    this.props.fetchRequest(dashboardAction.CLEAN_FRECUENCY_RELOAD);
    this.props.fetchRequest(dashboardAction.CLEAN_GLOBAL_FRECUENCY);
    this.listValueHistoryInitial(this.getFilterByField());
  }

  componentDidUpdate(prevProps) {
    let frecuency = this.props.globalFrecuency ? this.props.globalFrecuency : '15';

    if (this.props.isRequieredSyncTrend == true && prevProps.isRequieredSyncTrend == false) {
      this.setFrecuency(frecuency);
      this.info.frecuency = frecuency;
    }

    if (this.props.isFrecuencyReload == true && prevProps.isFrecuencyReload == false) {
      this.setFrecuency(frecuency);
      this.info.frecuency = frecuency;
    }

    if (this.props?.isRequieredChangeGlobalFrecuency == true && prevProps?.isRequieredChangeGlobalFrecuency == false) {
      this.setFrecuency(frecuency);
      this.info.frecuency = frecuency;
    }
  }

  listValueHistory = (item) => {
    axiosInstance()
      .post(`${appConstants.CONTEXT_TREND}list`, item)
      .then((data) => {
        if (this.props.listFields[this.props.listFields.length - 1].id == this.props.field.id) {
          this.props.fetchRequest(dashboardAction.CLEAN_FRECUENCY_RELOAD);
          this.props.fetchRequest(dashboardAction.CLEAN_GLOBAL_FRECUENCY);
        }
        if (data.data[this.props.field?.name].content.length > 0) {
          this.putInfoInGraphic(data.data);
        } else {
          showToast(appConstants.DONT_INFO_TITLE);
        }
      })
      .catch((error) => {});
  };

  listValueHistoryInitial = (item) => {
    axiosInstance()
      .post(`${appConstants.CONTEXT_TREND}list`, item)
      .then((data) => {
        if (data.data[this.props.field?.name].content.length > 0) {
          this.putInfoInGraphic(data.data);
        } else {
          showToast(appConstants.DONT_INFO_TITLE);
        }
      })
      .catch((error) => {});
  };

  putInfoInGraphic(value) {
    this.reload = false;
    let values = value[this.props.field?.name]?.content;

    const { data: dataColumns } = this.state.graphic;
    dataColumns.columns[0].splice(1);
    dataColumns.columns[1].splice(1);

    if (values && values.length > 0) {
      this.info.current = values[values.length - 1];
      values.forEach((element) => {
        dataColumns.columns[0].push(new Date(element.createdAt));
        dataColumns.columns[1].push(Number(element.value));
      });
    }

    this.reload = true;
    let translate = {
      axis: { ...this.state.graphic.axis },
      data: dataColumns,

      regions: this.state.graphic.regions,
    };
    this.setState({ graphic: translate, valuesHistory: values });
  }

  getMedida = () => {
    if (this.props.field.unitMeasurement === '') {
      return '';
    }
    if (this.props.field?.masterField?.unitMeasurement === 'NA') {
      return '';
    }
    if (this.props.field?.masterField?.unitMeasurement === 'PORCENTAJE') {
      return '%';
    }
    if (this.props.field?.masterField?.unitMeasurement === 'C') {
      return '°C';
    }

    return this.props.field?.masterField?.unitMeasurement;
  };

  handleDownload = (event) => {
    this.props.downloadTrend(this.getFilter());
  };

  handleFrecuency = (event) => {
    this.info.frecuency = event.target.value;
    this.listValueHistory(this.getFilterByField());
  };

  setFrecuency = (event) => {
    this.info.frecuency = event;
    this.listValueHistory(this.getFilterByField());
  };

  getFilter = () => {
    let from = new Date(Date.now() - this.info.frecuency * 60000);
    let fromFormatted = convertDate(from);
    let currentDate = convertDate(new Date(Date.now()));
    return {
      page: 0,
      size: 300,
      from: fromFormatted,
      to: currentDate,
      filters: { fieldId: this.props.field.id, deviceId: this.props.field.device.id },
    };
  };

  getFilterByField = () => {
    let from = new Date(Date.now() - this.info.frecuency * 60000);
    let fromFormatted = convertDate(from);
    let currentDate = convertDate(new Date(Date.now()));
    let gb = getGroupByTrends(this.info.frecuency);
    return {
      page: 0,
      size: 3000,
      from: fromFormatted,
      to: currentDate,
      groupBy: gb,
      filters: [this.props.field],
    };
  };

  order() {
    let keys = Object.keys(this.props.intervals);
    let intervals = [];

    for (let i = 0; i < keys.length; i++) {
      intervals.push({ name: keys[i], value: this.props.intervals[keys[i]] });
    }

    intervals.sort(function (a, b) {
      if (a.value > b.value) {
        return 1;
      } else if (a.value < b.value) {
        return -1;
      }

      return 0;
    });

    let valores = intervals.map((element) => {
      return element.name;
    });

    return valores;
  }

  render() {
    return (
      <Box height={1} width={1}>
        <Grid container>
          <Box width={1} margin={2}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Box alignItems="center" display="flex" height={1} width={1}>
                  <Tooltip title="Descargar" aria-label="add">
                    <Button
                      variant="contained"
                      onClick={(e) => this.handleDownload(e)}
                      className="ff-poppins bg-green-gradient"
                    >
                      <CloudDownload className="mr-1 " />
                    </Button>
                  </Tooltip>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  alignItems="center"
                  className="font-bold fs-title-graphic t-center"
                  display="flex"
                  height={1}
                  width={1}
                >
                  <span>
                    Último valor{' '}
                    {this.info.current ? (
                      <Typography className="font-bold t-center">
                        {(this.info.current.value * 1).toFixed(3) + ' ' + this.getMedida()}
                      </Typography>
                    ) : (
                      'cargando...'
                    )}
                  </span>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <FormControl variant="filled" fullWidth style={{ border: '20px' }}>
                  <InputLabel id="typeSignal">Rango de tiempo</InputLabel>
                  <Select
                    labelId="frecuency"
                    id="frecuency"
                    variant="filled"
                    fullWidth={true}
                    value={this.info.frecuency}
                    onChange={(e) => this.handleFrecuency(e)}
                  >
                    <MenuItem value="" key="none">
                      <em>Ninguna</em>
                    </MenuItem>

                    {this.props.intervals &&
                      this.order().map((element) => {
                        return (
                          <MenuItem value={this.props.intervals[element]} key={this.props.intervals[element]}>
                            {element}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                <Box className="font-bold fs-title-graphic t-center" justifyContent="center" display="flex">
                  {this.props.field.device.name}
                </Box>
              </Grid>
              <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                <Tooltip title="El muestreo es realizado cada dos minutos" aria-label="add">
                  <Box className=" fs-title-graphic t-center" justifyContent="center" display="flex">
                    {this.props.field.name}
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>

          <Box width={1} padding={2}>
            {
              <C3Chart
                data={this.state.graphic.data}
                axis={this.state.graphic.axis}
                regions={this.state.graphic.regions}
                tooltip={this.state.graphic.tooltip}
                color={this.state.graphic.color}
                legend={this.state.graphic.legend}
                padding={this.state.graphic.padding}
              />
            }
          </Box>
        </Grid>
        <Loading />
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedDevice: state.deviceReducer.selectedDevice,
    selectedHeadquarter: state.headquarterReducer.selectedHeadquarter,
    selectedIot: state.iotReducer.selectedIot,
    listFields: state.fieldReducer.avaliabledFields ? state.fieldReducer.avaliabledFields : [],
    intervals: state.dashboardReducer.intervals ? state.dashboardReducer.intervals : [],
    values: state.dashboardReducer.values ? state.dashboardReducer.values : [],
    isRequiredAddButtons: state.componentsReducer.buttons
      ? appConstants.IS_NOT_REQUIRED_ADD_BUTTON
      : appConstants.IS_REQUIRED_ADD_BUTTON,
    isRequieredSyncTrend: state.dashboardReducer.isRequieredSyncTrend,
    isFrecuencyReload: state.dashboardReducer.isFrecuencyReload,
    isRequieredChangeGlobalFrecuency: state.dashboardReducer.isRequieredChangeGlobalFrecuency,
    globalFrecuency: state.dashboardReducer.globalFrecuency ? state.dashboardReducer.globalFrecuency : '',
  };
};

const mapDispatchToProps = {
  downloadTrend,
  fetchRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Trend);
