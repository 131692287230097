import { Button, List, Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Save from '@material-ui/icons/Save';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { componentActions, fetchAction, setGlobalTitle } from '../../components/actions/componentActions';
import Loading from '../../components/Loading';
import SideBarIotItem from '../../components/sidebar/SideBarIotItem';
import UploadButton from '../../components/upload/UploadButton';
import { cleanDevices, listDevices } from '../../device/actions/deviceActions';
import {
  editInstallationWithoutFile,
  fetchRequest as installationFetchRequest,
  installationActions,
  saveInstallation,
} from '../../installation/actions/installationActions';
import SideBarDeviceItem from '../../installation/components/sidebar/SideBarDeviceItem';
import { cleanIot, listIots, setIot } from '../../iot/actions/iotActions';
import { commonMessage, icon } from '../../service/messageHandler';
import { loadImage } from '../actions/installationActions';
import FormInstallation from '../components/forms/FormInstallation';
import RenderDistribution from './RenderDistribution';

class Distribution extends Component {
  constructor(props) {
    super(props);
    this.uploadImage = (e) => {
      this.setState({
        file: e.target.files[0],
      });
    };
    this.iots = this.props.iots;
    this.toolbarButtons = [{ id: appConstants.BACK_BUTTON_ID, history: props.history }];
    this.state = {
      formInstallation: {
        name: props.selectedInstallation ? props.selectedInstallation.name : '',
        description: props.selectedInstallation ? props.selectedInstallation.description : '',
      },
    };
  }

  getImage() {
    if (this.state.file) return <img height="100%" width="100%" src={URL.createObjectURL(this.state.file)} />;
    else return '';
  }

  handleBack(type, e) {
    e.preventDefault();
    switch (type) {
      case appConstants.BACK_DEVICE_TO_IOT:
        this.props.setIot({ item: undefined });
        this.props.cleanDevices();
        break;
      default:
        break;
    }
  }

  componentDidMount() {
    this.props.setGlobalTitle(appConstants.DISTRIBUTION_TITLE);
    this.props.fetchAction(componentActions.ADD_TOOLBAR_BUTTON, this.toolbarButtons);

    if (this.props.selectedInstallation?.id) {
      this.props.loadImage(this.props.selectedInstallation?.urlDiagram);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isRequiredIots == appConstants.IS_REQUIRED_IOT_LIST) {
      this.props.listIots({
        page: 0,
        size: 300,
        filters: { headquarterId: this.props.selectedHeadquarter?.id },
      });
    }
    if (this.props.isRequiredDevices == appConstants.IS_REQUIRED_DEVICE_LIST) {
      this.props.listDevices({
        page: 0,
        size: 300,
        filters: { iot: this.props.iot },
      });
    }

    if (!prevProps.selectedInstallation && this.props.selectedInstallation) {
      this.setState({
        formInstallation: {
          name: this.props.selectedInstallation.name,
          id: this.props.selectedInstallation.id,
          description: this.props.selectedInstallation.description,
        },
      });
    }

    if (this.props.redirect) this.props.history.push({ pathname: appConstants.INSTALLATION_VIEW });
  }

  componentWillUnmount() {
    this.props.cleanDevices();
    this.props.cleanIot();
    this.props.installationFetchRequest(installationActions.CLEAN_INSTALLATION);
  }

  handleSave(event) {
    event.preventDefault();

    let msn = this.validate();

    if (msn == '') {
      if (this.props.imageFile) {
        this.props.saveInstallation(
          this.props.imageFile,
          {
            ...this.props.selectedInstallation,
            name: this.state.formInstallation.name,
            description: this.state.formInstallation.description,
            headquarterId: this.props.selectedHeadquarter.id,
            id: this.props.selectedInstallation?.id ? this.props.selectedInstallation.id : undefined,
          },
          this.props.items,
        );
      } else {
        this.props.editInstallationWithoutFile(
          {
            ...this.props.selectedInstallation,
            name: this.state.formInstallation.name,
            description: this.state.formInstallation.description,
            headquarterId: this.props.selectedHeadquarter.id,
            id: this.props.selectedInstallation?.id ? this.props.selectedInstallation.id : undefined,
          },
          this.props.items,
        );
      }
    } else {
      commonMessage(msn, appConstants.VALIDATION_ERROR_TITLE, icon.error);
    }
  }

  validate() {
    let msn = '';

    msn += !this.props.selectedHeadquarter ? 'Debe seleccionar un sede \n' : '';

    msn += !this.props.iot ? 'Debe seleccionar un iot \n' : '';

    msn +=
      !this.state.formInstallation.name || this.state.formInstallation.name.length < 3
        ? 'Debe ingresar un nombre para la instalación'
        : '';

    msn += !this.props.imageFile && !this.props.imageFile64 ? 'Debe seleccionar una imagen' : '';

    if (this.props.type == appConstants.DEVICE_ENTITY) {
      this.props.items.forEach((element) => {
        if (!element.style) {
          msn += appConstants.MESSAGE_UN_STYLED_DEVICE;
          return;
        }
      });
    }

    return msn;
  }

  handleChangeForm = (field, event) => {
    this.setState({ ...this.state, formInstallation: { ...this.state.formInstallation, [field]: event.target.value } });
  };

  render() {
    return (
      <Box height={1} padding={1} className="background-image-bubble mt-10px ">
        <Grid container spacing={2} style={{ height: '100%', width: '100%' }}>
          <Grid item xl={9} lg={9} md={9} xs={12} sm={12} style={{ height: '90%', width: '100%' }}>
            <Box width={1} height={1} border={1} boxShadow={3}>
              <Grid container style={{ height: '100%', width: '100%' }}>
                <Grid item xs={12} style={{ height: '90%', width: '100%' }}>
                  <Box height={1} width={1} justifyContent="center">
                    <RenderDistribution />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xl={3} lg={3} md={3} xs={12} sm={12}>
            <Paper className="paper border-10 ">
              <Box height={1} width={1}>
                <Box height={this.props.selectedHeadquarter ? 1 / 2 : 0} border={1} overflow="auto">
                  <Box height={this.props.type == appConstants.DEVICE_ENTITY ? 8 / 9 : 1} overflow="auto">
                    <List style={{ width: '100%' }}>
                      {this.props.items.map((item, index) => {
                        return this.props.type == appConstants.IOT_ENTITY ? (
                          <SideBarIotItem divider={true} item={item} id={index} />
                        ) : (
                          <SideBarDeviceItem divider={true} item={item} id={index} />
                        );
                      })}
                    </List>
                  </Box>

                  {this.props.type == appConstants.DEVICE_ENTITY && (
                    <Box height={1 / 9} position="relative" className="center-info">
                      <Tooltip title="Atrás" aria-label="add">
                        <Button
                          className="bg-green-gradient w-50px-important"
                          fullWidth={true}
                          style={{ bottom: 0, position: 'absolute' }}
                          onClick={(e) => this.handleBack(appConstants.BACK_DEVICE_TO_IOT, e)}
                        >
                          <ArrowBackIcon />
                        </Button>
                      </Tooltip>
                    </Box>
                  )}
                </Box>

                <Box height={this.props.selectedHeadquarter ? 1 / 2 : 1} padding={2} border={1}>
                  <FormInstallation
                    installation={this.state.formInstallation}
                    handleChangeForm={this.handleChangeForm}
                  />
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
            <UploadButton accepted="image/*" onChangeFile={this.uploadImage} />
            <label htmlFor="select-file-button">
              <Button
                onClick={(e) => this.handleSave(e)}
                variant="contained"
                className="bg-green-gradient  pl-5 pr-5 mt-10px"
                component="span"
                id="select-file-button"
                style={{ marginLeft: 2 }}
              >
                <Save className="mr-2" />
                Guardar cambios
              </Button>
            </label>
          </Grid>
        </Grid>

        <Loading />
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  let newProps = !state.iotReducer.selectedIot
    ? {
        items: state.iotReducer.avaliabledIots ? state.iotReducer.avaliabledIots : [],
        type: appConstants.IOT_ENTITY,
      }
    : {
        items: state.deviceReducer.avaliabledDevices ? state.deviceReducer.avaliabledDevices : [],
        type: appConstants.DEVICE_ENTITY,
      };

  newProps = {
    ...newProps,
    image: state.componentsReducer.imageShowed ? state.componentsReducer.imageShowed : undefined,
    isRequiredIots:
      state.headquarterReducer.selectedHeadquarter &&
      state.iotReducer.isRequiredIots != appConstants.IS_NOT_REQUIRED_IOT_LIST
        ? appConstants.IS_REQUIRED_IOT_LIST
        : appConstants.IS_NOT_REQUIRED_IOT_LIST,
    iot: state.iotReducer.selectedIot ? state.iotReducer.selectedIot : undefined,
    isRequiredDevices:
      state.iotReducer.selectedIot && state.deviceReducer.isRequiredDevices != appConstants.IS_NOT_REQUIRED_DEVICE_LIST
        ? appConstants.IS_REQUIRED_DEVICE_LIST
        : appConstants.IS_NOT_REQUIRED_DEVICE_LIST,
    selectedHeadquarter: state.headquarterReducer.selectedHeadquarter
      ? state.headquarterReducer.selectedHeadquarter
      : undefined,
    imageFile: state.componentsReducer.file,
    imageFile64: state.installationReducer.imageFile64,
    selectedInstallation: state.installationReducer.selectedInstallation
      ? state.installationReducer.selectedInstallation
      : undefined,
    isRequiredImage: state.installationReducer.imageShowed
      ? appConstants.IS_NOT_REQUIRED_LOAD_IMAGE
      : appConstants.IS_REQUIRED_LOAD_IMAGE,
    redirect: state.installationReducer.redirect,
  };

  return newProps;
};

const mapDispatchToProps = {
  setIot,
  listIots,
  listDevices,
  cleanDevices,
  cleanIot,
  setGlobalTitle,
  fetchAction,
  saveInstallation,
  loadImage,
  installationFetchRequest,
  editInstallationWithoutFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Distribution);
