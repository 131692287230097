import { Box, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { cleanFields, listFields, setField } from '../actions/fieldActions';

class FieldAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.selectedField = undefined;
    this.ref = undefined;
  }

  render() {
    return (
      <Grid container spacing={1} className="grid-input-element">
        <Grid item xs={12}>
          <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
            <Autocomplete
              id="fieldSelect"
              ref={(ref) => (this.ref = ref)}
              options={this.props.fields}
              getOptionLabel={(option) => option.name}
              onChange={(e, option) => {
                this.handleSelectField(option);
              }}
              noOptionsText="Debe seleccionar un dispositivo"
              fullWidth
              renderInput={(params) => <TextField {...params} label="Selecciona una variable" variant="outlined" />}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }

  handleSelectField(option) {
    this.selectedField = option;

    this.props.setField({ item: option });
  }

  componentDidMount() {}

  list() {
    this.props.listFields({
      sort: 'name',
      page: 0,
      size: 300,
      filters: {
        headquarterId: this.props.selectedHeadquarter?.id,
        iot: this.props.selectedIot,
        device: this.props.selectedDevice,
      },
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevProps.selectedDevice && this.props.selectedDevice) ||
      (prevProps.selectedDevice?.id &&
        this.props.selectedDevice?.id &&
        prevProps.selectedDevice.id != this.props.selectedDevice.id)
    ) {
      this.list();
    }

    if (prevProps.selectedDevice && !this.props.selectedDevice) {
      const e = this.ref.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
      if (e) e.click();
      this.selectedField = undefined;
    }
  }

  componentWillUnmount() {
    this.props.cleanFields();
  }
}

const mapStateToProps = (state) => {
  return {
    fields: state.fieldReducer.avaliabledFields ? state.fieldReducer.avaliabledFields : [],
    isRequiredFields:
      (state.deviceReducer.selectedDevice && !state.fieldReducer.isRequiredFields) ||
      state.fieldReducer.isRequiredFields == appConstants.IS_REQUIRED_FIELDS_LIST
        ? appConstants.IS_REQUIRED_FIELDS_LIST
        : appConstants.IS_NOT_REQUIRED_FIELDS_LIST,
    selectedHeadquarter: state.headquarterReducer.selectedHeadquarter,
    selectedIot: state.iotReducer.selectedIot,
    selectedDevice: state.deviceReducer.selectedDevice,
  };
};

const mapDispatchToProps = {
  cleanFields,
  listFields,
  setField,
};

export default connect(mapStateToProps, mapDispatchToProps)(FieldAutocomplete);
