import { BehaviorSubject } from 'rxjs';

let filterDateObservable = new BehaviorSubject([]);

export const handleAddserie = (e) => {
  filterDateObservable.next(e);
};

export const handleAddArraySerie = (series) => {
  let values = filterDateObservable.getValue();

  series.forEach((element) => {
    let exists = values.filter((value) => value.name == element.name);

    if (exists.length == 0) values.push(element);
    else {
      values.splice(values.indexOf(exists[0]), 1);
      values.push(element);
    }
  });
  filterDateObservable.next(values);
};

export function getFilterDateObservable() {
  return filterDateObservable;
}
