import { appConstants } from '../../appConstants';
import ApiAxios from '../../service/Api';

export const ConfigHomeActions = {
  SET_CONFIG_HOME: 'SET_CONFIG_HOME',
  FETCH_REQUEST_TYPE:'FETCH_REQUEST_TYPE',
  FETCH_TYPE_SUCCESS:'FETCH_TYPE_SUCCESS',
  FETCHING_CONFIG_HOME_FAILURE:'FETCHING_CONFIG_HOME_FAILURE',
  FETCHING_CONFIG_HOME_SAVE_SUCCESS:'FETCHING_CONFIG_HOME_SAVE_SUCCESS',
  FETCHING_CONFIG_HOME_SAVE_REQUEST:'FETCHING_CONFIG_HOME_SAVE_REQUEST',
  FETCHING_CONFIG_HOME_REQUEST_LIST:'FETCHING_CONFIG_HOME_REQUEST_LIST',
  FETCHING_CONFIG_HOME_LIST_SUCCESS:'FETCHING_CONFIG_HOME_LIST_SUCCESS',
  FETCHING_CONFIG_HOME_UPDATE_SUCCESS:'FETCHING_CONFIG_HOME_UPDATE_SUCCESS',
  FETCHING_CONFIG_HOME_REQUEST:'FETCHING_CONFIG_HOME_REQUEST',
  FETCHING_NOTIFICATION_REQUEST_LIST:'FETCHING_NOTIFICATION_REQUEST_LIST',
  FETCHING_NOTIFICATION_LIST_SUCCESS:'FETCHING_NOTIFICATION_LIST_SUCCESS',
  FETCHING_UPDATE_NOTIFICATION_REQUEST:'FETCHING_UPDATE_NOTIFICATION_REQUEST',
  FETCHING_UPDATE_NOTIFICATION_SUCCESS:'FETCHING_UPDATE_NOTIFICATION_SUCCESS',
  SET_OPEN_MODAL:'SET_OPEN_MODAL',
  CLEAN_ON_EXIT_HOME:'CLEAN_ON_EXIT_HOME'
};


export const fetchRequest = (type, payload) => {
  return {
    type,
    payload,
  };
};

export const saveConfigHome = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_CONFIG_HOME, item, dispatch, fetchRequest, {
      request: ConfigHomeActions.FETCHING_CONFIG_HOME_SAVE_REQUEST,
      success: ConfigHomeActions.FETCHING_CONFIG_HOME_SAVE_SUCCESS,
      error: ConfigHomeActions.FETCHING_CONFIG_HOME_FAILURE,
    });
  };
};



export const getTypeHome = () => {
  return function (dispatch) {
    ApiAxios.crudGeneric.get(`${appConstants.CONTEXT_CONFIG_HOME}type`, dispatch, fetchRequest, {
      request: ConfigHomeActions.FETCH_REQUEST_TYPE,
      success: ConfigHomeActions.FETCH_TYPE_SUCCESS,
      error: ConfigHomeActions.FETCHING_CONFIG_HOME_FAILURE,
    });
  };
};

export const listConfigHome = (dto) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(`${appConstants.CONTEXT_CONFIG_HOME}list`, dto, dispatch, fetchRequest, {
      request: ConfigHomeActions.FETCHING_CONFIG_HOME_REQUEST_LIST,
      success: ConfigHomeActions.FETCHING_CONFIG_HOME_LIST_SUCCESS,
      error: ConfigHomeActions.FETCHING_CONFIG_HOME_FAILURE,
    });
  };
};

export const updateConfigHome = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.put(appConstants.CONTEXT_CONFIG_HOME, item, dispatch, fetchRequest, {
      request: ConfigHomeActions.FETCHING_CONFIG_HOME_REQUEST,
      success: ConfigHomeActions.FETCHING_CONFIG_HOME_UPDATE_SUCCESS,
      error: ConfigHomeActions.FETCHING_CONFIG_HOME_FAILURE,
    });
  };
};


export const getNotification = (dto) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(`${appConstants.CONTEXT_NOTIFICATION}`, dto, dispatch, fetchRequest, {
      request: ConfigHomeActions.FETCHING_NOTIFICATION_REQUEST_LIST,
      success: ConfigHomeActions.FETCHING_NOTIFICATION_LIST_SUCCESS,
      error: ConfigHomeActions.FETCHING_CONFIG_HOME_FAILURE,
    });
  };
};

export const updateNotification = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.put(appConstants.CONTEXT_NOTIFICATION, item, dispatch, fetchRequest, {
      request: ConfigHomeActions.FETCHING_UPDATE_NOTIFICATION_REQUEST,
      success: ConfigHomeActions.FETCHING_UPDATE_NOTIFICATION_SUCCESS,
      error: ConfigHomeActions.FETCHING_CONFIG_HOME_FAILURE,
    });
  };
};