import { Grid, Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import 'c3/c3.css';
import React from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { dashboardAction, downloadTrend, fetchRequest } from '../action/dashboardActions';
import '../styles/dashboard.scss';
import ComparativeGlobalDate from './ComparativeGlobalDate';

class SelectedGlobalComparativeDates extends React.Component {
  constructor(props) {
    super(props);

    this.info = {
      frecuency: '',
    };
  }
  handleFrecuency = (event) => {
    this.info.frecuency = event.target.value;
    this.props.fetchRequest(dashboardAction.SET_GLOBAL_FRECUENCY, event.target.value);
  };

  order() {
    let keys = Object.keys(this.props.intervals);
    let intervals = [];

    for (let i = 0; i < keys.length; i++) {
      intervals.push({ name: keys[i], value: this.props.intervals[keys[i]] });
    }

    intervals.sort(function (a, b) {
      if (a.value > b.value) {
        return 1;
      } else if (a.value < b.value) {
        return -1;
      }

      return 0;
    });

    let valores = intervals.map((element) => {
      return element.name;
    });

    return valores;
  }
  render() {
    return (
      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>Compara por fechas</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ComparativeGlobalDate
              series={[
                {
                  name: appConstants.BAR_INITIAL_RANGE_COMPARATIVE,
                  title: 'Comparativo 1',
                  firstNameTitle: 'Fecha inicial rango 1',
                  secondNameTitle: 'Fecha final rango 1',
                },
                {
                  name: appConstants.BAR_SECOND_RANGE_COMPARATIVE,
                  title: 'Comparativo 2',
                  firstNameTitle: 'Fecha inicial rango 2',
                  secondNameTitle: 'Fecha final rango 2',
                },
              ]}
              md={6}
              lg={6}
              xl={6}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    selectedDevice: state.deviceReducer.selectedDevice,
    selectedHeadquarter: state.headquarterReducer.selectedHeadquarter,
    selectedIot: state.iotReducer.selectedIot,
    intervals: state.dashboardReducer.intervals ? state.dashboardReducer.intervals : [],
  };
};

const mapDispatchToProps = {
  downloadTrend,
  fetchRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedGlobalComparativeDates);
