import { Box, Card } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import Loading from '../../components/Loading';
import { cleanDevices, listDevices } from '../../device/actions/deviceActions';
import { getFactorMForAllDevices } from '../action/CregActions';
import EditFactorM from '../components/EditFactorM';

const columns = [
  { id: 'device', label: 'Dispositivo' },
  { id: 'factorM', label: 'Factor M' },
  { id: 'consecutiveMonths', label: 'Meses Consecutivos' },
];

const FactorMList = (props) => {
  const { listDevice, listFactorM } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (props.isRequiredDevices == appConstants.IS_REQUIRED_DEVICE_LIST) {
      listItems();
    }
    if (
      listDevice.length > 0 &&
      props.isRequiredFactorMForAllDevice === appConstants.IS_REQUIRED_FACTORM_FOR_ALL_DEVICE
    ) {
      let listIds = [];
      listDevice.forEach((device) => {
        listIds.push(device.id);
      });
      props.getFactorMForAllDevices(listIds);
    }

    if (props.selectedDevice != undefined) {
      return function () {
        props.cleanDevices();
      };
    }
  }, [listDevice, props.updateFactorM]);

  const listItems = () => {
    let filterDto = {
      page: 0,
      size: 300,
      filters: { classification: appConstants.PRINCIPAL_DEVICE },
    };
    props.listDevices(filterDto);
  };

  return (
    <Box width={1} padding={1}>
      <Card className="border-10">
        <TableContainer className="table-master">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    className="bg-primary fc-white ff-poppins fw-bold fs-Large t-center-important"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell align="center" className="bg-primary fc-white ff-poppins fw-bold fs-Large" key={'action'}>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listFactorM.map((item) => {
                let device = item.device;
                let breach = item.cregBreach;
                let row = { device: device.name, factorM: breach.factorM, consecutiveMonths: breach.consecutiveMonths };
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} className="t-center-important">
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell key={'action'} className="center-info" align="center">
                      <EditFactorM factorM={item} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={listDevice.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Card>
      <Loading />
    </Box>
  );
};

const mapDispatchToProps = {
  listDevices,
  cleanDevices,
  getFactorMForAllDevices,
};

const mapStateToProps = (state) => {
  return {
    isRequiredFactorMForAllDevice: state.CregReducer.isRequiredFactorMForAllDevice
      ? state.CregReducer.isRequiredFactorMForAllDevice
      : appConstants.IS_REQUIRED_FACTORM_FOR_ALL_DEVICE,

    isRequiredDevices: state.deviceReducer.isRequiredDevices
      ? state.deviceReducer.isRequiredDevices
      : appConstants.IS_REQUIRED_DEVICE_LIST,

    updateFactorM: state.CregReducer.avaliableUpdateFactorM,
    listDevice: state.deviceReducer.avaliabledDevices ? state.deviceReducer.avaliabledDevices : [],
    listFactorM: state.CregReducer.avaliabledFactorMForAllDevice ? state.CregReducer.avaliabledFactorMForAllDevice : [],
    device: state.deviceReducer.selectedDevice,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FactorMList);
