import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import Save from '@material-ui/icons/Save';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { commonMessage, icon } from '../../service/messageHandler';
import { getTypeLicense, savelicenseCycle, updatelicenseCycle } from '../actions/licenseCycleActions';

const LicenseCycleForm = (props) => {
  const { licenseCycle } = props;
  const [typeLicense, setlicenseType] = React.useState('');

  const handleLicenseType = (event) => {
    setlicenseType(event.target.value);
    licenseCycleForm.typeLicense = event.target.value;
  };
  const [licenseCycleForm, setLicenseCycleForm] = useState({
    name: '',
    durationDay: '',
    typeLicense: '',
  });

  useEffect(() => {
    deserialize();
    if (props.isRequiredTypeLicense === appConstants.IS_REQUIRED_TYPE_LICENSE) props.getTypeLicense();
  }, [props.licenseCycle,licenseCycle]);

  const deserialize = () => {
    let valueForm = {};
    valueForm.id = licenseCycle.id ? licenseCycle.id : '';
    valueForm.name = licenseCycle.name ? licenseCycle.name : '';
    valueForm.durationDay = licenseCycle.durationDay ? licenseCycle.durationDay : '';
    setlicenseType(licenseCycle.typeLicense ? licenseCycle.typeLicense : '');
    valueForm.typeLicense = licenseCycle.typeLicense ? licenseCycle.typeLicense : '';
    setLicenseCycleForm({
      ...valueForm,
    });
  };

  const handleSave = () => {
    let msn = validate(licenseCycleForm);
    if (msn == '') {
      let licenseSended = {
        ...licenseCycleForm,
      };
      if (!licenseSended.id) props.savelicenseCycle(licenseSended);
      else props.updatelicenseCycle(licenseSended);
    } else {
      commonMessage(msn, appConstants.VALIDATION_ERROR_TITLE, icon.info);
    }
  };

  const validate = (param) => {
    let msn = '';

    msn += !param.name ? 'Debe especificar un nombre \n' : '';
    msn += !param.durationDay ? 'Debe especificar una duración \n' : '';
    msn += !param.typeLicense ? 'Debe selecionar un tipo de licencia \n' : '';

    return msn;
  };

  return (
    <Box height={1}>
      <Card className="border-10">
        <CardContent>
          <form noValidate={false} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item md={3} xl={3} lg={3} sm={3} xs={3}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    id="name"
                    label="Nombre"
                    fullWidth
                    variant="outlined"
                    value={licenseCycleForm.name}
                    onChange={(valueEvent) =>
                      setLicenseCycleForm({ ...licenseCycleForm, name: valueEvent.target.value })
                    }
                  />
                </Box>
              </Grid>

              <Grid item md={3} xl={3} lg={3} sm={3} xs={3}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    type="number"
                    id="name"
                    label="Duracion en días"
                    fullWidth
                    variant="outlined"
                    value={licenseCycleForm.durationDay}
                    onChange={(valueEvent) =>
                      setLicenseCycleForm({ ...licenseCycleForm, durationDay: valueEvent.target.value })
                    }
                  />
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="typeLicense">Tipo de licencia</InputLabel>
                    <Select
                      labelId="typeLicense"
                      id="typeLicense"
                      variant="outlined"
                      fullWidth={true}
                      value={typeLicense}
                      onChange={handleLicenseType}
                    >
                      <MenuItem value="" key="none">
                        <em>Ninguna</em>
                      </MenuItem>

                      {props.typeLicense.map((element) => {
                        return (
                          <MenuItem value={element} key={element}>
                            {element}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <CardActions className="center d-flex">
          <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
            <Button
              className="bg-green-gradient mr-2 pl-5 pr-5"
              color="primary"
              style={{ background: '#e3dafb', borderRadius: '30px', width: '100%', marginRight: '5px' }}
              onClick={handleSave}
            >
              <Save />
              {licenseCycle.id ? 'Actualizar' : 'Guardar'}
            </Button>
          </ButtonGroup>
        </CardActions>
      </Card>
    </Box>
  );
};

const mapDispatchToProps = {
  savelicenseCycle,
  getTypeLicense,
  updatelicenseCycle,
};

const mapStateToProps = (state) => {
  return {
    licenseCycle: state.licenseCycleReducer.selectedLicenseCycle ? state.licenseCycleReducer.selectedLicenseCycle : {},

    isRequiredTypeLicense: state.licenseCycleReducer.isRequiredTypeLicense
      ? state.licenseCycleReducer.isRequiredTypeLicense
      : appConstants.IS_REQUIRED_TYPE_LICENSE,

    typeLicense: state.licenseCycleReducer.typeLicense ? state.licenseCycleReducer.typeLicense : [],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LicenseCycleForm);
