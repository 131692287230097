import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { DeviceIcon, FieldIcon, IotColorIcon, SchemeIcon } from '../../assets/icons';
import { componentActions, fetchAction, setGlobalTitle } from '../../components/actions/componentActions';
import MenuButttons from '../../components/menu/MenuButtons';

const menu = [
  {
    name: 'IOTs',
    icon: <IotColorIcon height="130px" width="130px" className="icon-rotate-90" />,
    route: '/iot/create',
  },
  {
    name: 'Medidores',
    icon: <DeviceIcon height="130px" width="130px" />,
    route: '/device/create',
  },
  {
    name: 'Variables',
    icon: <FieldIcon height="130px" width="130px" />,
    route: '/field/create',
  },
  {
    name: 'Diagrama',
    icon: <SchemeIcon height="130px" width="130px" />,
    route: '/distribution/menu',
  },
];

const PrincipalMenu = (props) => {
  const buttonsToolbar = [{ id: appConstants.BACK_BUTTON_ID, history: props.history }];

  useEffect(() => {
    props.setGlobalTitle(appConstants.INSTALLATION_MENU);

    props.fetchAction(componentActions.ADD_TOOLBAR_BUTTON, buttonsToolbar);
  }, []);

  return (
    <Box
      width={1}
      height={1}
      padding={3}
      justifyContent="center"
      alignItems="center"
      className="background-image-bubble"
    >
      <MenuButttons items={menu} perRow={2} />
    </Box>
  );
};

const mapDispatchToProps = {
  setGlobalTitle,
  fetchAction,
};

export default connect(null, mapDispatchToProps)(PrincipalMenu);
