import Swal from 'sweetalert2';

export const position = {
  top: 'top',
  topEnd: 'top-end',
  topStart: 'top-start',
  center: 'center',
  centerStart: 'center-start',
  centerEnd: 'center-end',
  bottom: 'bottom',
  bottomStart: 'bottom-start',
  bottomEnd: 'bottom-end',
};

export const icon = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
  question: 'question',
};

export const titles = {
  error: 'Alerta',
  success: 'Operación completada con éxito',
  information: 'Se informa que',
};
export const showToast = (message, duration = 4500) => {
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: duration,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: icon.info,
    title: message,
  });
};
