import { Box, Button, Grid, Modal, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { CancelIcon, CheckIcon } from '../../assets/icons';
import '../../assets/styles/App.scss';
import { commonMessage, icon } from '../../service/messageHandler';
import { validateEmail } from '../../util/validations';
import { recoverPassword } from '../actions/userActions';
import '../assets/styles/user.scss';

const ModalRecoverPassword = (props) => {
  const [open, setOpen] = useState(false);
  const changeOpen = (event) => {
    setOpen(!open);
  };
  const [recoverForm, setRecoveriForm] = useState({
    email: '',
  });

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const handleClose = () => {
    setOpen(false);
  };

  const validate = (userParam) => {
    let msn = '';

    msn += validateEmail(userParam.email);

    return msn;
  };

  const handleSave = () => {
    let recoverSended = recoverForm;

    let msn = validate(recoverForm);
    if (msn == '') {
      props.recoverPassword(recoverSended.email);
    } else {
      commonMessage(msn, appConstants.VALIDATION_ERROR_TITLE, icon.info);
    }
    handleClose();
  };

  const body = (
    <div className="modal-recover-pass">
      <Grid container spacing={3}>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info bg-secundary">
          <Typography className="ff-poppins fw-bold  fc-third">Recuperación de contraseña</Typography>
        </Grid>
        <Grid item xlxs={12} lgxs={12} mdxs={12} smxs={12} xs={12}>
          <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
            <TextField
              required
              fullWidth
              id="email"
              label="Ingresa tu correo"
              type="text"
              variant="outlined"
              value={recoverForm.email}
              onChange={(valueEvent) => setRecoveriForm({ ...recoverForm, email: valueEvent.target.value })}
            />
          </Box>
        </Grid>

        <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="pt-2">
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className="center-info">
              <Button variant="contained" color="primary" className="bg-green-gradient" onClick={handleSave}>
                <CheckIcon className="mr-2 " height="25px" width="25px" />
                Enviar
              </Button>
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className="center-info">
              <Button variant="contained" color="primary" className="bg-green-gradient" onClick={handleClose}>
                <CancelIcon className="mr-2" height="25px" width="25px" />
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <>
      <Typography>
        <Button className="fw-bold ff-poppins fc-black" onClick={changeOpen}>
          ¿Olvidaste tu contraseña?
        </Button>
      </Typography>
      <Modal
        open={open}
        onClose={changeOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        {body}
      </Modal>
    </>
  );
};
const mapDispatchToProps = {
  recoverPassword,
};

export default connect(null, mapDispatchToProps)(ModalRecoverPassword);
