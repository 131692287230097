import axios from 'axios';

export const axiosInstance = (history = null) => {
  let headers = {};

  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }

  return axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_CONTEXT}${process.env.REACT_APP_API_VERSION}`,
    headers: headers,
  });
};

export default axiosInstance;
