import { Box, Card } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import '../../assets/styles/App.scss';
import { convertDate } from '../../service/util';
import { listlicenses } from '../actions/licenseHistoryActions';

const columns = [
  { id: 'company', label: 'Compañia' },
  { id: 'startDate', label: 'Fecha inicio', format: 'date' },
  {
    id: 'endDate',
    label: 'Fecha fin ',
    format: 'date',
  },
  {
    id: 'cantDevice',
    label: 'Dispositivos ',
  },
];
const ListHistoryLicenses = (props) => {
  const { list } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    props.listlicenses({
      page: 0,
      size: 300,
    });
  }, []);
  const format = (value) => {
    return convertDate(new Date(value));
  };
  return (
    <Box width={1}>
      <Card className="border-10">
        <TableContainer className="table-master">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    className="bg-primary fc-white ff-poppins fw-bold fs-Large t-center-important"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((item) => {
                let row = { ...item, company: item?.company?.name };
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} className="t-center-important">
                          {column.format ? format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Card>
    </Box>
  );
};

const mapDispatchToProps = {
  listlicenses,
};
const mapStateToProps = (state) => {
  return {
    list: state.licenseHistoryReducer.avaliabledLicenses ? state.licenseHistoryReducer.avaliabledLicenses : [''],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListHistoryLicenses);
