import { saveAs } from 'file-saver';
import { appConstants } from '../../appConstants';
import { commonMessage, errorHandler, icon } from '../../service/messageHandler';
import { reportsActions } from '../action/reportsActions';

const reportReducers = (state = {}, action) => {
  switch (action.type) {
    case reportsActions.FETCH_REQUEST_TYPE_REPORT:
      return {
        ...state,
        loading: { open: true },
        isRequiredTypeReport: appConstants.IS_NOT_REQUIERED_TYPE_REPORT,
      };

    case reportsActions.FETCH_REQUEST_TYPE_REPORT_SUCCESS:
      return {
        ...state,
        loading: { open: false },
        typeReport: action.payload,
      };
    case reportsActions.SET_GLOBAL_SERIES_REPORT:
      return {
        ...state,
        globalSeriesReport: action.payload,
        isRequiredChangeComparativeDatesReport: true,
      };
    case reportsActions.IS_REQUIRED_CHANGE_COMPARATIVE_DATES:
      return {
        ...state,
        isRequiredChangeComparativeDatesReport: false,
      };
    case reportsActions.SET_FIELD_REPORT:
      return {
        ...state,
        fieldReport: action.payload,
        isRequiredChangefieldReport: true,
      };
    case reportsActions.FETCH_REQUEST_GENERATE_REPORT_SUCCESS:
      commonMessage(appConstants.GENERATE_REPORT_MESSAGE, appConstants.SUCCESFUL_SAVED_TITLE, icon.success);
      return {
        ...state,
        loading: { open: false },
      };
    case reportsActions.SET_TYPE_REPORT:
      return {
        ...state,
        setTypeReport: action.payload.item,
        isRequiredChangeTypeReport: true,
      };
    case reportsActions.FETCH_REPORT_GRAPH:
      return {
        ...state,
        loading: { open: true, message: '' },
      };

    case reportsActions.FETCH_REPORT_GRAPH_SUCCESS:
      saveAs(action.payload, `${'reporteVariable'}-${+new Date()}.pdf`);
      return {
        ...state,
        loading: { open: false },
      };
    case reportsActions.CLEAN_REPORT_FIELD:
      return {
        ...state,
        fieldReport: [],
        globalSeriesReport: {},
      };
    case reportsActions.FETCH_REQUEST_ERROR:
      errorHandler(action);
      return {
        ...state,
        laoding: { open: false },
      };
    default:
      return state;
  }
};

export default reportReducers;
