import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, ButtonGroup, Card, CardActions, CardContent, Grid, TextField } from '@material-ui/core';
import Save from '@material-ui/icons/Save';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { commonMessage, icon } from '../../service/messageHandler';
import { fetchRequest as fetchRequestLicense , licenseActions,savelicense, updatelicense } from '../actions/licenseActions';
import { fetchRequest as fetchRequestCycleLicense, licenseCycleActions } from '../actions/licenseCycleActions';
import LicenseCycleAutocomplete from '../components/LicenseCycleAutocomplete';

const LicenseForm = (props) => {
  const { license, company } = props;
  useEffect(() => {
    deserialize();
    if(company.license != undefined && company.license.active !=false){
      setStateText(true)
      deserializeCompany();
    }
  }, [company]);

  const [startDate, setStartDate] = React.useState(new Date(Date.now()));
  const [endDate, setEndDate] = React.useState(new Date(Date.now()));
  const [stateText, setStateText] = React.useState(false);

  const [licenseForm, setLicenseForm] = useState({
    startDate: '',
    endDate: '',
    licenseCycleId: '',
    cantDevice: '',
    value: 0,
  });

    const deserializeCompany = () => {
    let valueForm = {};
    valueForm.id = company.license.id ? company.license.id : '';
    valueForm.value = company.license.value ? company.license.value : '';
    valueForm.cantDevice = company.license.cantDevice ? company.license.cantDevice : '';
    setStartDate(company.license.startDate);
    setEndDate(company.license.endDate);
    props.fetchRequestCycleLicense(licenseCycleActions.ADD_LICENSE_CYCLE,{id: company.license.licenseCycleId})
    setLicenseForm({
      ...valueForm,
    });
  };

  const deserialize = () => {
    let valueForm = {};
    valueForm.value = license.value ? license.value : '';
    valueForm.startDate = license.startDate ? license.startDate : '';
    valueForm.endDate = license.endDate ? license.endDate : '';
    valueForm.cantDevice = license.cantDevice ? license.cantDevice : '';
    valueForm.licenseCycleId = license.licenseCycleId ? license.licenseCycleId : '';

    setLicenseForm({
      ...valueForm,
    });
  };

  const handleSave = () => {
    
    let LicenseSended = {
      ...licenseForm,
      company: company,
      licenseCycleId: props.selectedLicenseCycle?.id,
    };
    let msn = validate(licenseForm);

    if (msn == '') {
      if (!LicenseSended.id) props.savelicense(LicenseSended);
      props.fetchRequestLicense(licenseActions.SET_OPEN_MODAL,{item:false})
    } else {
      commonMessage(msn, appConstants.VALIDATION_ERROR_TITLE, icon.info);
    }
  };
  const handleStartChange = (date) => {
    licenseForm.startDate = startDate;
    setStartDate(date);
    HandleEndChange(date);
  };
  const HandleEndChange = (date) => {
    if (props.selectedLicenseCycle) {
      
      date.setDate(date.getDate() + (props.selectedLicenseCycle.durationDay)*1);
      setEndDate(date);
      licenseForm.endDate = date;

    }
  };
  const validate = (licenseParam) => {
    let msn = '';

    msn += !props.selectedLicenseCycle?.id == {} ? 'Debe especificar un periodo  \n' : '';

    msn +=
      !licenseParam.cantDevice || licenseParam.cantDevice == ''
        ? 'Debe especificar la cantidad de dispositivos \n'
        : '';

    msn += !licenseParam.value || licenseParam.value == '' ? 'Debe especificar el costo de licencia \n' : '';

    return msn;
  };
  return (
    <Box height={1}>
      <Card className="border-10">
        <CardContent>
          <form noValidate={false} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item md={6} xl={6} lg={6} sm={6} xs={6}>
                <LicenseCycleAutocomplete/>
              </Grid>

              <Grid item md={6} xl={6} lg={6} sm={6} xs={6}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element center-info">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      readOnly={props.selectedLicenseCycle ? false : true}
                      id="startDate"
                      name="startDate"
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      label="Fecha Inicio"
                      onChange={handleStartChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      disabled={stateText}
                      autoOk={true}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
              </Grid>

              <Grid item md={6} xl={6} lg={6} sm={6} xs={6}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element center-info">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      id="endDate"
                      name="endDate"
                      readOnly
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      label="Fecha fin"
                      disabled={true}
                      value={endDate}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
              </Grid>
              <Grid item md={6} xl={6} lg={6} sm={6} xs={6}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    type="number"
                    id="cantDevice"
                    label="Catidad de Dispositivos"
                    fullWidth
                    variant="outlined"
                    value={licenseForm.cantDevice}
                    disabled={stateText}
                    onChange={(valueEvent) => setLicenseForm({ ...licenseForm, cantDevice: valueEvent.target.value })}
                  />
                </Box>
              </Grid>
              <Grid item md={6} xl={6} lg={6} sm={6} xs={6}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    type="number"
                    id="value"
                    label="Costos de licencia"
                    fullWidth
                    variant="outlined"
                    value={licenseForm.value}
                    disabled={stateText}
                    onChange={(valueEvent) => setLicenseForm({ ...licenseForm, value: valueEvent.target.value })}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <CardActions className="center d-flex">
          <Grid item md={6} xl={6} lg={6} sm={6} xs={6} className="center-info">
            <ButtonGroup variant="text" color="primary" aria-label="text primary button group" className="center">
              <Button className="bg-green-gradient mr-2 pl-5 pr-5" onClick={handleSave}>
                <Save />
                Guardar
              </Button>
            </ButtonGroup>
          </Grid>
        </CardActions>
      </Card>
    </Box>
  );
};

const mapDispatchToProps = { updatelicense, savelicense,fetchRequestCycleLicense,fetchRequestLicense };
const mapStateToProps = (state) => {
  return {
    selectedLicenseCycle: state.licenseCycleReducer.selectedLicenseCycle,
    license: state.licenseReducer.selectedLicense ? state.licenseReducer.selectedLicense : {},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LicenseForm);
