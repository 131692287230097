import { appConstants } from '../../appConstants';
import ApiAxios from '../../service/Api';

export const companyActions = {
  FETCH_REQUEST_CURRENT: 'FETCH_REQUEST_CURRENT',
  FETCH_REQUEST_CURRENT_SUCCESS: 'FETCH_REQUEST_CURRENT_SUCCESS',
  FETCH_REQUEST_ERROR: 'FETCH_REQUEST_COMPANY_ERROR',
  CLEAN_COMPANY_STORE: 'CLEAN_COMPANY_STORE',
  FETCHING_UPDATE_COMPANY_REQUEST: 'FETCHING_UPDATE_COMPANY_REQUEST',
  FETCHING_UPDATE_COMPANY_SUCCESS: 'FETCHING_UPDATE_COMPANY_SUCCESS',
  SET_COMPANY: 'SET_COMPANY',
  FETCHING_SAVE_COMPANY_REQUEST: 'FETCHING_SAVE_COMPANY_REQUEST',
  FETCHING_SAVE_COMPANY_SUCCESS: 'FETCHING_SAVE_COMPANY_SUCCESS',
  FETCHING_LIST_COMPANY_SUCCESS: 'FETCHING_LIST_COMPANY_SUCCESS',
  FETCHING_LIST_COMPANY_REQUEST: 'FETCHING_LIST_COMPANY_REQUEST',
  LOG_OUT: 'LOG_OUT',
  FETCHING_COMPANY_DELETE_SUCCESS: 'FETCHING_COMPANY_DELETE_SUCCESS',
  FETCHING_COMPANY_REQUEST: 'FETCHING_COMPANY_REQUEST',
};

export const fetchRequest = (type, payload = undefined) => {
  return {
    type,
    payload,
  };
};

export const getCurrentByUser = () => {
  return function (dispatch) {
    ApiAxios.crudGeneric.get(`${appConstants.CONTEXT_COMPANY}current`, dispatch, fetchRequest, {
      request: companyActions.FETCH_REQUEST_CURRENT,
      success: companyActions.FETCH_REQUEST_CURRENT_SUCCESS,
      error: companyActions.FETCH_REQUEST_ERROR,
    });
  };
};

export const saveCompany = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_COMPANY, item, dispatch, fetchRequest, {
      request: companyActions.FETCHING_SAVE_COMPANY_REQUEST,
      success: companyActions.FETCHING_SAVE_COMPANY_SUCCESS,
      error: companyActions.FETCH_REQUEST_ERROR,
    });
  };
};

export const updateCompany = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.put(appConstants.CONTEXT_COMPANY, item, dispatch, fetchRequest, {
      request: companyActions.FETCHING_UPDATE_COMPANY_REQUEST,
      success: companyActions.FETCHING_UPDATE_COMPANY_SUCCESS,
      error: companyActions.FETCH_REQUEST_ERROR,
    });
  };
};

export const listCompanies = (item) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_COMPANIES, item, dispatch, fetchRequest, {
      request: companyActions.FETCHING_LIST_COMPANY_REQUEST,
      success: companyActions.FETCHING_LIST_COMPANY_SUCCESS,
      error: companyActions.FETCH_REQUEST_ERROR,
    });
  };
};

export const cleanCompany = () => {
  return {
    type: companyActions.CLEAN_COMPANY_STORE,
  };
};

export const deleteCompany = (id) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.delete(`${appConstants.CONTEXT_COMPANY}${id}`, dispatch, fetchRequest, {
      request: companyActions.FETCHING_COMPANY_REQUEST,
      success: companyActions.FETCHING_COMPANY_DELETE_SUCCESS,
      error: companyActions.FETCH_REQUEST_ERROR,
    });
  };
};
