import { Box, Grid } from '@material-ui/core';
import React from 'react';
import logogl2 from '../../assets/image/logogl2.png';
import '../../assets/styles/App.scss';

const InfoLogin = () => {
  return (
    <Box height={1} width={1} alignItems="center" display="flex">
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box display="flex" justifyContent="center">
            <img src={logogl2} height="300px" width="6000px" />
          </Box>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <span className="ff-poppins fs-info f-color-principal center d-flex t-center">Bienvenidos</span>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <span className=" ff-poppins fs-Large center d-flex t-center">
            La Herramienta para una gestión energética más eficiente e inteligente
          </span>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InfoLogin;
