import FilterAlarmButton from '../alarms/components/AlarmFilterModal';
import { appConstants } from '../appConstants';
import {
  AoaIcon,
  ChargeabilityIcon,
  CompanyIcon,
  DashboardIcon,
  HeadquarterIcon,
  HomeIcon,
  InstallationIcon,
  LicenciasIcon,
  ManagementFolderIcon,
  ManualIcon,
  PowerPlantIcon,
  ReportIcon,
  SupportIcon,
  TrendIcon,
  UserIcon,
  WarningIcon,
} from '../assets/icons';
import '../assets/styles/App.scss';
import BackButton from '../components/BackButton';
import ModalFilterChargeability from '../dashboard/components/ModalFilterChargeability';
import ModalFilterDashboard from '../dashboard/components/ModalFilterDashboard';
import ModalFilterTrend from '../dashboard/components/ModalFilterTrend';
import SyncTrend from '../dashboard/components/SyncTrend';

export const getToolbarButtonById = (button) => {
  switch (button.id) {
    case appConstants.BACK_BUTTON_ID:
      return <BackButton id={appConstants.BACK_BUTTON_ID} key={appConstants.BACK_BUTTON_ID} history={button.history} />;

    case appConstants.FILTER_ALARM_BUTTON:
      return <FilterAlarmButton open={button.open ? button.open : false} />;

    case appConstants.FILTER_TRENDS_BUTTON:
      return <ModalFilterTrend />;

    case appConstants.FILTER_DASHBOARD_BUTTON:
      return <ModalFilterDashboard />;

    case appConstants.FILTER_CHARGEABILITY:
      return <ModalFilterChargeability />;

    case appConstants.SYNC_TRENS_BUTTON:
      return <SyncTrend />;
    default:
      return undefined;
  }
};

export const getToolbarIconByTitle = (title) => {
  switch (title) {
    case appConstants.USER_VIEW_TITLE:
      return <UserIcon className="mr-2" height={30} width={30} />;

    case appConstants.TREND_DASHBOARD_TITLE:
      return <TrendIcon className="mr-2" height={30} width={30} />;

    case appConstants.TREND_CHARGEABILITY_TITLE:
      return <ChargeabilityIcon className="mr-2" height={30} width={30} />;

    case appConstants.DASHBOARD_TITLE:
      return <DashboardIcon className="mr-2" height={30} width={30} />;

    case appConstants.INSTALLATION_MENU:
    case appConstants.IOT_VIEW_TITLE:
    case appConstants.FIELD_VIEW_TITLE:
    case appConstants.INSTALLATION_VIEW_TITLE:

    case appConstants.DEVICE_VIEW_TITLE:
      return <InstallationIcon className="mr-2" height={30} width={30} />;

    case appConstants.SUPPORT_TITLE:
      return <SupportIcon className="mr-2" height={30} width={30} />;

    case appConstants.ALARM_MENU_TITLE:

    case appConstants.ALARM_VIEW_TITLE:
      return <WarningIcon className="mr-2" height={30} width={30} />;

    case appConstants.AOA_VIEW_TITLE:
      return <AoaIcon className="mr-2" height={30} width={30} />;

    case appConstants.NAS_VIEW_TITLE:
      return <ManagementFolderIcon className="mr-2" height={30} width={30} />;

    case appConstants.COMPANY_MENU_TITLE:
      return <CompanyIcon className="mr-2" height={30} width={30} />;

    case appConstants.REPORT_VIEW_TITLE:
      return <ReportIcon className="mr-2" height={30} width={30} />;

    case appConstants.HEADQUARTER_VIEW_TITLE:
      return <HeadquarterIcon className="mr-2" height={30} width={30} />;

    case appConstants.MENU_ENERGY_REACTIVE:
      return <PowerPlantIcon className="mr-2" height={30} width={30} />;

    case appConstants.ENERGY_PENALTY_TITLE:
      return <PowerPlantIcon className="mr-2" height={30} width={30} />;

    case appConstants.CONFIG_HOME_TITLE:
      return <ManualIcon className="mr-2" height={30} width={30} />;

    case appConstants.LICENSE_VIEW_TITLE:
      return <LicenciasIcon className="mr-2" height={30} width={30} />;

    default:
      return <HomeIcon className="mr-2" height={30} width={30} />;
  }
};
