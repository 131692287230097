import { Box, Button, Card } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { ConfigHomeActions, fetchRequest, listConfigHome } from '../../configHome/actions/ConfigHomeActions';


const columns = [
  { id: 'name', label: 'Nombre' },
  { id: 'type', label: 'Tipo' },
  {id: 'info',label: 'Información',},
];

const ListConfigHome = (props) => {
  const { list } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if(props.isRequiredConfigHome===appConstants.IS_REQUIERED_CONFIG_HOME_LIST){
      listItems();
    }
  }, [props.selectedConfigHome]);

  const handleEdit = (itemSelected) => {
    props.fetchRequest(ConfigHomeActions.SET_CONFIG_HOME,{item: itemSelected})
  };
  
  const listItems = () => {
    let filterDto = {
      page: 0,
      size: 300,
    };
    props.listConfigHome(filterDto);
  };

  return (
    <Box width={1} height={1}>
      <Card className="border-10">
        <TableContainer className="table-master">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    className="bg-primary fc-white ff-poppins fw-bold fs-Large t-center-important"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}

                <TableCell align="center" className="bg-primary fc-white ff-poppins fw-bold fs-Large  " key={'action'}>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} className="t-center-important">
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell key={'action'} className="center-info" align="center">
                      <Button
                        color="primary"
                        className="btn-transparent ff-poppins fc-green min-width-1px"
                        onClick={(e) => handleEdit(row)}
                      >
                        <EditIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Card>
    </Box>
  );
};

const mapDispatchToProps = {
listConfigHome,
fetchRequest
};

const mapStateToProps = (state) => {
  return {
    isRequiredConfigHome: state.ConfigHomeReducers.isRequiredConfigHome ? state.ConfigHomeReducers.isRequiredConfigHome :appConstants.IS_REQUIERED_CONFIG_HOME_LIST,
    list: state.ConfigHomeReducers.avaliabledConfigHome ? state.ConfigHomeReducers.avaliabledConfigHome :[],
    selectedConfigHome: state.ConfigHomeReducers.selectedConfigHome ? state.ConfigHomeReducers.selectedConfigHome :{},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListConfigHome);
