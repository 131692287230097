import ApiAxios from '../../service/Api';
import axiosInstance from '../../service/axiosInstance';

const DocumentationApi = {
  post: ApiAxios.crudGeneric.post,

  saveDocumnent(contex, file, dispatch, action, actions) {
    dispatch(action(actions.request));
    let data = new FormData();

    data.append('documentation', file);

    let headersDefine = { 'Content-Type': 'multipart/form-data', Accept: 'application/json' };
    const options = { headers: headersDefine };

    dispatch(action(actions.request));
    axiosInstance()
      .post(contex, data, options)
      .then((response) => {
        dispatch(action(actions.success, response.data));
      })
      .catch((error) => {
        dispatch(action(actions.error, error));
      });
  },
};

export default DocumentationApi;
