export const convertDate = (date) => {
  return `${date.getFullYear()}-${getCompletedNumber(date.getMonth() + 1)}-${getCompletedNumber(
    date.getDate(),
  )} ${getCompletedNumber(date.getHours())}:${getCompletedNumber(date.getMinutes())}:${getCompletedNumber(
    date.getSeconds(),
  )}`;
};

export const convertDateMonth = (date) => {
  return `${date.getFullYear()}-${getCompletedNumber(date.getMonth() + 1)}`;
};

export const getPlusMonth = (date, month = 0) => {
  return `${getCompletedNumber(date.getMonth() + (month + 1))}`;
};


export const getYear = (date) => {
  return `${date.getFullYear()}`;
};
export const getDay = (date) => {
  return `${getCompletedNumber(date.getDate())}`;
};

export const getDateYearAndDay = (date) => {
  return `${date.getFullYear()}-${getCompletedNumber(date.getMonth() + 1)}-${getCompletedNumber(date.getDate(),)}`;
};

export const getDateYearAndDayAndHouCero = (date) => {
  return `${date.getFullYear()}-${getCompletedNumber(date.getMonth() + 1)}-${getCompletedNumber(date.getDate(),)}` + ' 00:00:00';
};

export const getDateDayAndHour = (date) => {
  return `${getCompletedNumber(date.getDate(),)}-${getCompletedNumber(date.getHours())}`;
};

export const getGroupBy = (frecuency) => {
  let groupBy = '';

  if (frecuency >= 359 && frecuency <= 4320) {
    //more than 3 our use hour interval
    groupBy = 'hour';
  }

  if (frecuency > 4320 && frecuency <= 86600) {
    groupBy = 'day';
  }

  if (frecuency > 86600 && frecuency <= 2078400) {
    groupBy = 'month';
  }
  return groupBy;
};

export const getSumDate = (from, to) => {
  let fromDate = new Date(from);
  let toDate = new Date(to);

  return toDate.getTime() - fromDate.getTime();
};

export const getDifferentBetweenDates = (from, to) => {
  let fromDate = new Date(from);
  let toDate = new Date(to);

  return toDate.getTime() - fromDate.getTime();
};

export const getDifferentBetweenDatesMount = (from, to) => {
  let fromDate = new Date(from);
  let toDate = new Date(to);

  return toDate.getMonth() - fromDate.getMonth();
};

export const getDifferentBetweenDay = (from, to) => {
  let fromDate = new Date(from);
  let toDate = new Date(to);
  return Math.floor((toDate - fromDate)/(1000*60*60*24));
};

function getCompletedNumber(number) {
  return (number + '').length == 1 ? `0${number}` : number;
}

export const getGroupByTrends = (frecuency) => {
  let groupBy = '';
  if (frecuency >= 360 && frecuency < 720) {
    //more than one our use fitty interval
    groupBy = 'fiftyInterval';
  }

  if (frecuency >= 720 && frecuency <= 10080) {
    //more than 3 our use hour interval
    groupBy = 'hour';
  }

  if (frecuency > 10080 && frecuency <= 86600) {
    groupBy = 'day';
  }

  if (frecuency > 86600 && frecuency <= 2078400) {
    groupBy = 'month';
  }
  return groupBy;
};

export const getDatePeriod = (datePeriod) => {
  if (datePeriod == 'day') {
    return 'Cada día';
  } else if (datePeriod == 'month') {
    return 'Cada mes';
  } else if (datePeriod == 'hour') {
    return 'Cada hora';
  } else {
    return 'Cada 2 minutos';
  }
};
