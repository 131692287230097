import { Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { fetchAction, setGlobalTitle } from '../../components/actions/componentActions';
import Loading from '../../components/Loading';
import AlarmFormModal from '../components/AlarmFormModal';
import AlarmList from '../components/AlarmList';

const AlarmView = (props) => {
  const buttonsToolbar = [
    { id: appConstants.BACK_BUTTON_ID, history: props.history },
    { id: appConstants.FILTER_ALARM_BUTTON, open: true },
  ];

  useEffect(() => {
    props.setGlobalTitle(appConstants.ALARM_VIEW_TITLE);
  }, []);

  return (
    <Box height={1} width={1} padding={1} className="background-image-bubble mt-10px">
      <Box height={9 / 10}>
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
            <Box boxShadow={4} borderRadius={10}>
              <AlarmList />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box height={1 / 10} justifyContent="center" display="flex" width={1}>
        <Box borderRadius={30} boxShadow={3}>
          <AlarmFormModal />
        </Box>
      </Box>

      <Loading />
    </Box>
  );
};

const mapDispatchToProps = {
  setGlobalTitle,
  fetchAction,
};

export default connect(null, mapDispatchToProps)(AlarmView);
