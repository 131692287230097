import { Button, Grid, Modal, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import { CancelIcon, CheckIcon, FilterIcon } from '../../assets/icons';
import { listFields } from '../../field/actions/fieldActions';
import { useModalStylesborder } from '../../util/styles';
import { dashboardAction, fetchRequest } from '../action/dashboardActions';
import FilterTrend from './FilterTrend';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const ModalFilterDashboard = (props) => {
  const [open, setOpen] = useState(false);
  const classes = useModalStylesborder();
  const [modalStyle] = React.useState(getModalStyle);

  const changeOpen = (event) => {
    setOpen(!open);
    event.preventDefault();
    let filterOfMaster = {
      typeGraphic: appConstants.BAR,
    };
    let filter = {
      sort: 'name',
      page: 0,
      size: 3000,
      filters: {
        headquarter: props.headquarter,
        iot: props.iot,
        device: props.device,
        masterField: filterOfMaster,
      },
    };
    props.fetchRequest(dashboardAction.SET_SELECTED_FILTER, filter.filters);
    props.listFields(filter);
  };

  useEffect(() => {});

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Grid container spacing={2} borderRadius={10}>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info bg-secundary">
          <Typography className="fc-third" variant="h4" component="h4">
            Filtros
          </Typography>
        </Grid>

        <FilterTrend />

        <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="pt-2">
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className="center-info">
              <Button variant="contained" color="primary" className="bg-green-gradient" onClick={changeOpen}>
                <CheckIcon className="mr-2 " height="25px" width="25px" />
                Aceptar
              </Button>
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className="center-info">
              <Button variant="contained" color="primary" className="bg-green-gradient" onClick={handleClose}>
                <CancelIcon className="mr-2" height="25px" width="25px" />
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <>
      <Tooltip title="Filtro" aria-label="add">
        <Button onClick={changeOpen}>
          <FilterIcon />
        </Button>
      </Tooltip>

      <Modal
        open={open}
        onClose={changeOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    iot: state.iotReducer.selectedIot,
    device: state.deviceReducer.selectedDevice,
    headquarter: state.headquarterReducer.selectedHeadquarter,
  };
};

const mapDispatchToProps = {
  listFields,
  fetchRequest,
};
export default connect(mapStateToProps, mapDispatchToProps)(ModalFilterDashboard);
