import { Box, Button, Grid, Typography } from '@material-ui/core';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import VideoPlayer from 'react-simple-video-player';
import { appConstants } from '../../appConstants';
import { downloadManual } from '../../components/actions/componentActions';
import Loading from '../../components/Loading';
import { fetchRequest as FetchRequestUser, userActions } from '../../user/actions/userActions';
import ModalChangePassword from '../../user/components/ModalChangePassword';
import { ConfigHomeActions, fetchRequest, getNotification, listConfigHome } from '../actions/ConfigHomeActions';
import ModalNotification from './ModalNotification';

const InfoHome = (props) => {
  const [valuesView] = React.useState({ url: '', title: '', body: '' });

  useEffect(() => {
    shownValue(props.listHome);
    if (props.isRequiredConfigHome === appConstants.IS_REQUIERED_CONFIG_HOME_LIST) {
      listItems();
    }
    if (
      props.company?.id != undefined &&
      props.isRequiredNotification === appConstants.IS_REQUIERED_NOTIFICATION_LIST
    ) {
      listNotificationItem();
    }
    if (props.listNotification.length > 0) {
      props.fetchRequest(ConfigHomeActions.SET_OPEN_MODAL, { item: true });
    }
    if (props.isLogOut === appConstants.IS_REQUIRED_LOG_OUT) {
      props.FetchRequestUser(userActions.LOG_OUT);
    }
  }, [props.listNotification, props.listHome, props.isRequiredNotification, props.isLogOut]);

  const listNotificationItem = () => {
    let filterDto = {
      page: 0,
      size: 300,
      filters: { companyId: props.company?.id },
    };
    props.getNotification(filterDto);
  };

  const handleDownload = () => {
    props.downloadManual();
  };

  const listItems = () => {
    let filterDto = {
      page: 0,
      size: 300,
    };
    props.listConfigHome(filterDto);
  };
  const shownValue = (value) => {
    value.forEach((element) => {
      if (element.type === 'VIDEO') {
        valuesView.url = element?.info;
      }
      if (element.type === 'CUERPO') {
        valuesView.body = element?.info;
      }
      if (element.type === 'TITULO') {
        valuesView.title = element?.info;
      }
    });
  };
  return (
    <Box
      height={1}
      width={1}
      padding={1}
      className="background-image-bubble "
      justifyContent="center"
      alignItems="center"
    >
      <Grid container spacing={2} className="mt-10px  h-98">
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12} className="center-info bg-white">
          <Typography className="ff-poppins  fc-text-co2-primary fs-home-info">
            GLEM, Programa utilizado por {props.company?.name}
          </Typography>
        </Grid>
        <Grid item xl={6} lg={6} md={6} xs={12} sm={12} className="center-info bg-co2-secundary h-80">
          <VideoPlayer url={valuesView.url} autosize={true} controls={true} playsinline={true} />
        </Grid>
        <Grid item xl={6} lg={6} md={6} xs={12} sm={12} className="center-info  bg-co2-primary">
          <Grid>
            <Typography className="ff-poppins fs-t-title-co2 fc-text-co2-secundary t-center mt-20px">
              {valuesView.title}
            </Typography>
            <Typography className="ff-poppins fc-white fs-t-text t-justify mt-20px">{valuesView.body}</Typography>
          </Grid>
        </Grid>
        <Grid item xl={10} lg={10} md={10} xs={12} sm={12} className="center-info bg-white">
          <Typography className="ff-poppins   fc-text-co2-primary t-center">
            "GLEM, LA HERRAMIENTA PARA UNA RED ELÉCTRICA MÁS INTELIGENTE” GLEM ES: GL ENERGY MANAGEMENT
          </Typography>
        </Grid>
        <Grid item xl={2} lg={2} md={2} xs={12} sm={12} className="center-info bg-white">
          <Button
            variant="contained"
            color="primary"
            className="bg-green-gradient h-50px"
            onClick={(e) => handleDownload()}
          >
            <ContactSupportIcon fontSize="default" />
            Manual
          </Button>
          <ModalNotification />
        </Grid>
        <Loading />
        <ModalChangePassword />
      </Grid>
    </Box>
  );
};

const mapDispatchToProps = {
  downloadManual,
  listConfigHome,
  fetchRequest,
  getNotification,
  FetchRequestUser,
};

const mapStateToProps = (state) => {
  return {
    company: state.companyReducer.currentCompany ? state.companyReducer.currentCompany : {},
    isRequiredConfigHome: state.ConfigHomeReducers.isRequiredConfigHome
      ? state.ConfigHomeReducers.isRequiredConfigHome
      : appConstants.IS_REQUIERED_CONFIG_HOME_LIST,
    listHome: state.ConfigHomeReducers.avaliabledConfigHome ? state.ConfigHomeReducers.avaliabledConfigHome : [],
    listNotification: state.ConfigHomeReducers.avaliabledNotification
      ? state.ConfigHomeReducers.avaliabledNotification
      : [],
    isOpen: state.ConfigHomeReducers.isOpen,
    isRequiredNotification: state.ConfigHomeReducers.isRequiredNotification
      ? state.ConfigHomeReducers.isRequiredNotification
      : appConstants.IS_REQUIERED_NOTIFICATION_LIST,
    isLogOut: state.userReducer.isLogOut ? state.userReducer.isLogOut : appConstants.IS_NOT_REQUIRED_LOG_OUT,
    isRequiereTitle: state.componentsReducer.isRequiereTitle
      ? state.componentsReducer.isRequiereTitle
      : appConstants.IS_REQUIRED_SET_TITLE,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoHome);
