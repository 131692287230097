import { appConstants } from '../../appConstants';
import ApiAxios from '../../service/Api';
import dashboardApi from '../services/dashboardApi';

export const dashboardAction = {
  FETCH_INTERVALS: 'FETCH_INTERVALS',
  FETCH_INTERVALS_SUCCESS: 'FETCH_INTERVALS_SUCCESS',
  FETCH_REPORT: 'FETCH_REPORT',
  FETCH_REPORT_SUCCESS: 'FETCH_REPORT_SUCCESS',
  FETCH_LIST_HISTORY: 'FETCH_LIST_HISTORY',
  FETCH_LIST_HISTORY_SUCCESS: 'FETCH_LIST_HISTORY_SUCCESS',
  FETCH_REQUEST_ERROR: 'FETCH_REQUEST_DASHBOARD_ERROR',
  FETCH_REQUEST_EMPTY: 'FETCH_REQUEST_DASHBOARD_EMPTY',
  FETCH_LIST_PER_VALUE: 'FETCH_LIST_PER_VALUE',
  FETCH_LIST_PER_VALUE_SUCCESS: 'FETCH_LIST_PER_VALUE_SUCCESS',
  ADD_FRECUENCY_GLOBAL: 'ADD_FRECUENCY_GLOBAL',
  CLEAN_DASHBOARD: 'CLEAN_DASHBOARD',
  FETCH_SERIE_REQUEST: 'FETCH_SERIE_REQUEST',
  FETCH_SERIE_REQUEST_SUCCESS: 'FETCH_SERIE_REQUEST_SUCCESS',
  CLEAN_VALUES_PER_FIELD_DASHBOARD: 'CLEAN_VALUES_PER_FIELD_DASHBOARD',
  FETCHING_CO2: 'FETCHING_CO2',
  FETCHING_CO2_SUCCESS: 'FETCHING_CO2_SUCCESS',
  FETCHING_SYNC_TRENDS: 'FETCHING_SYNC_TRENDS',
  FETCH_SERIE_COMPARATIVE_REQUEST: 'FETCH_SERIE_COMPARATIVE_REQUEST',
  FETCH_SERIE_COMPARATIVE_REQUEST_SUCCESS: 'FETCH_SERIE_COMPARATIVE_REQUEST_SUCCESS',
  CLEAN_VALUES_PER_FIELD_REPORT_DASHBOARD: 'CLEAN_VALUES_PER_FIELD_REPORT_DASHBOARD',
  FETCH_REQUEST_DONT_INFO: 'FETCH_REQUEST_DONT_INFO',
  CLEAN_FRECUENCY: 'CLEAN_FRECUENCY',
  SET_FRECUENCY_RELOAD: 'SET_FRECUENCY_RELOAD',
  CLEAN_FRECUENCY_RELOAD: 'CLEAN_FRECUENCY_RELOAD',
  SET_GLOBAL_FRECUENCY: 'SET_GLOBAL_FRECUENCY',
  CLEAN_GLOBAL_FRECUENCY: 'CLEAN_GLOBAL_FRECUENCY',
  SET_SELECTED_FILTER: 'SET_SELECTED_FILTER',
  SET_GLOBAL_SERIES: 'SET_GLOBAL_SERIES',
  CLEAN_SELECTED_FILTER: 'CLEAN_SELECTED_FILTER',
  SET_SERIES: 'SET_SERIES',
};

export const fetchRequest = (type, payload = {}) => {
  return {
    type: type,
    payload: payload,
  };
};

export const getIntervals = () => {
  return function (dispatch) {
    dashboardApi.getIntervals(dispatch, {
      request: dashboardAction.FETCH_INTERVALS,
      success: dashboardAction.FETCH_INTERVALS_SUCCESS,
      error: dashboardAction.FETCH_REQUEST_ERROR,
    });
  };
};

export const listHistory = (filter) => {
  return function (dispatch) {
    dashboardApi.post(
      dispatch,
      {
        request: dashboardAction.FETCH_LIST_HISTORY,
        success: dashboardAction.FETCH_LIST_HISTORY_SUCCESS,
        error: dashboardAction.FETCH_REQUEST_ERROR,
        empty: dashboardAction.FETCH_REQUEST_EMPTY,
      },
      filter,
      'list',
    );
  };
};

export const downloadTrend = (filter) => {
  return function (dispatch) {
    dashboardApi.dowload(
      dispatch,
      {
        request: dashboardAction.FETCH_REPORT,
        success: dashboardAction.FETCH_REPORT_SUCCESS,
        error: dashboardAction.FETCH_REQUEST_ERROR,
      },
      filter,
      'export',
    );
  };
};

export const listValuesPerField = (fields) => {
  return function (dispatch) {
    dashboardApi.post(
      dispatch,
      {
        request: dashboardAction.FETCH_LIST_PER_VALUE,
        success: dashboardAction.FETCH_LIST_PER_VALUE_SUCCESS,
        error: dashboardAction.FETCH_REQUEST_ERROR,
        empty: dashboardAction.FETCH_REQUEST_EMPTY,
      },
      fields,
      'list',
    );
  };
};

export const listFilterValuesPerField = (fields) => {
  return function (dispatch) {
    dashboardApi.post(
      dispatch,
      {
        request: dashboardAction.FETCH_LIST_PER_VALUE,
        success: dashboardAction.FETCH_LIST_PER_VALUE_SUCCESS,
        error: dashboardAction.FETCH_REQUEST_ERROR,
        empty: dashboardAction.FETCH_REQUEST_EMPTY,
      },
      fields,
      'list/filters',
    );
  };
};

export const listSeries = (data) => {
  return function (dispatch) {
    dashboardApi.listSeries(
      dispatch,
      {
        request: dashboardAction.FETCH_SERIE_REQUEST,
        success: dashboardAction.FETCH_SERIE_REQUEST_SUCCESS,
        error: dashboardAction.FETCH_REQUEST_ERROR,
        empty: dashboardAction.FETCH_REQUEST_EMPTY,
      },
      data.filter,
      data.name,
      fetchRequest,
    );
  };
};

export const listSeriesComparate = (data, field) => {
  return function (dispatch) {
    dashboardApi.listSeriesComparate(
      dispatch,
      {
        request: dashboardAction.FETCH_SERIE_COMPARATIVE_REQUEST,
        success: dashboardAction.FETCH_SERIE_COMPARATIVE_REQUEST_SUCCESS,
        error: dashboardAction.FETCH_REQUEST_ERROR,
      },
      data,
      field,
      fetchRequest,
    );
  };
};

export const getCo2 = (id) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.get(`${appConstants.CONTEXT_TREND}co2/${id}`, dispatch, fetchRequest, {
      request: dashboardAction.FETCHING_CO2,
      success: dashboardAction.FETCHING_CO2_SUCCESS,
      error: dashboardAction.FETCH_REQUEST_ERROR,
    });
  };
};
