import { Box, Button, Card } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../../assets/styles/App.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import Modal from '@material-ui/core/Modal';
import {getNotification,updateNotification,fetchRequest,ConfigHomeActions} from '../../configHome/actions/ConfigHomeActions'
import { CancelIcon } from '../../assets/icons';

const ModalNotification= (props) => {
  const [open, setOpen] = useState(false);


  const columns = [
    { id: 'info', label: 'Notificación' },
  ];
  
  const { list } = props;


  const changeOpen = (event) => {
    setOpen(event);
  };

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen,open,props.list]);

  const handleClose = (event) => {
    props.fetchRequest(ConfigHomeActions.SET_OPEN_MODAL,{item:false})
    setOpen(false);

  };

  const listNotification = () => {
    let filterDto = {
      page: 0,
      size: 300,
      filters: {companyId:props.company?.id},
    };
    props.getNotification(filterDto);
  };

  const handleEdit = (itemSelected) => {
        props.updateNotification(itemSelected);
  };


  const body = (    
  <Box className="mt-20px center-info" >
    <Card className="border-10">
      <TableContainer className="table-master">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  className="bg-primary fc-white ff-poppins fw-bold fs-Large t-center-important"
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}

              <TableCell align="center" className="bg-primary fc-white ff-poppins fw-bold fs-Large  " key={'action'}>
                <Button  onClick={handleClose}>
                  <CancelIcon className="mr-2" height="25px" width="25px" />
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align} className="t-center-important">
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                  <TableCell key={'action'} className="center-info" align="center">
                    <Button
                      color="primary"
                      className="btn-transparent ff-poppins fc-green min-width-1px"
                      onClick={(e) => handleEdit(row)}
                    >
                      <DeleteIcon color="secondary" />
                    </Button>

                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  </Box>
  );

  return (
    <>
      <Modal
        open={open}
        onClose={changeOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="o-scroll"
      >
        {body}
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isOpen: state.ConfigHomeReducers.isOpen ? state.ConfigHomeReducers.isOpen: false,
    list: state.ConfigHomeReducers.avaliabledNotification ? state.ConfigHomeReducers.avaliabledNotification : [],
  };
};

const mapDispatchToProps = {
   getNotification,
   updateNotification,
   fetchRequest,
  };

export default connect(mapStateToProps, mapDispatchToProps)(ModalNotification);
