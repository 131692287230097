import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import Brush from '@material-ui/icons/Brush';
import Save from '@material-ui/icons/Save';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { appConstants } from '../../appConstants';
import '../../assets/styles/App.scss';
import { fetchRequest as fetchHeadquarter, headquarterActions } from '../../headquarter/actions/headquarterActions';
import { fetchRequest as fetchRequestIot, iotActions, listIots } from '../../iot/actions/iotActions';
import { commonMessage, icon } from '../../service/messageHandler';
import {
  deviceActions,
  fetchRequest,
  getClasification,
  getTypeProtocol,
  saveDevice,
  setDevice,
  updateDevice,
} from '../actions/deviceActions';

const FormDevice = (props) => {
  const { device, isRequiredIots, selectedIot } = props;

  const [deviceForm, setDeviceForm] = useState({
    name: '',
    machineName: '',
    machineIdentification: '',
    classification: '',
    protocol: '',
    reference: '',
    transferType: '',
    description: '',
    ratedPower: '',
    transformerVoltage: '',
  });

  useEffect(() => {
    deserialize();
    if (props.isRequiredTypeProtocol === appConstants.IS_REQUIRED_MASTER_FIELDS_TYPEPROTOCOL) props.getTypeProtocol();
    if (props.isRequiredClassification === appConstants.IS_REQUIRED_CLASIFICATION) props.getClasification();
    if (isRequiredIots == appConstants.IS_REQUIRED_IOT_LIST) {
      props.listIots({
        page: 0,
        size: 3000,
      });
    }
  }, [props.deviceForm, device]);

  const deserialize = () => {
    let valueForm = {};
    valueForm.id = device.id ? device.id : '';
    valueForm.name = device.name ? device.name : '';
    valueForm.machineName = device.machineName ? device.machineName : '';
    valueForm.machineIdentification = device.machineIdentification ? device.machineIdentification : '';
    valueForm.classification = device.classification ? device.classification : '';
    valueForm.protocol = device.protocol ? device.protocol : '';
    valueForm.reference = device.reference ? device.reference : '';
    valueForm.transferType = device.transferType ? device.transferType : '';
    valueForm.description = device.description ? device.description : '';
    valueForm.ratedPower = device.ratedPower ? device.ratedPower : '';
    valueForm.transformerVoltage = device.transformerVoltage ? device.transformerVoltage : '';

    setProtocol(device.protocol ? device.protocol : '');
    setClassification(device.classification ? device.classification : '');

    setDeviceForm({
      ...valueForm,
    });
  };

  const handleClean = () => {
    props.fetchRequest(deviceActions.SET_DEVICE, { item: {} });
    setProtocol('');
    props.fetchHeadquarter(headquarterActions.SET_HEADQUARTER, { item: undefined });
    props.fetchRequestIot(iotActions.SET_IOT, { item: undefined });
  };

  const handleSave = () => {
    let deviceSended = {
      ...deviceForm,
      headquarterId: props.selectedHeadquarter?.id,
      iot: selectedIot,
    };
    let msn = validate(deviceForm);

    if (msn == '') {
      if (!deviceSended.id) props.saveDevice(deviceSended);
      else props.updateDevice(deviceSended);
    } else {
      commonMessage(msn, appConstants.VALIDATION_ERROR_TITLE, icon.info);
    }
    handleClean();
  };

  const validate = (deviceParam) => {
    let msn = '';

    msn += !props.selectedHeadquarter?.id ? 'Debe seleccionar la sede \n' : '';
    msn += !props.selectedIot?.id ? 'Debe seleccionar el IOT \n' : '';

    msn += !deviceParam.name || deviceParam.name?.trim() == '' ? 'Debe especificar el nombre \n' : '';

    msn +=
      !deviceParam.machineName || deviceParam.machineName?.trim() == ''
        ? 'Debe especificar el nombre de de la máquina  \n'
        : '';

    msn +=
      !deviceParam.machineIdentification || deviceParam.machineIdentification?.trim() == ''
        ? 'Debe especificar la identificación de la máquina \n'
        : '';

    msn +=
      !deviceParam.classification || deviceParam.classification?.trim() == ''
        ? 'Debe especificar la tipificación \n'
        : '';

    msn += !deviceParam.reference || deviceParam.reference?.trim() == '' ? 'Debe especificar la referencia \n' : '';

    msn +=
      !deviceParam.transferType || deviceParam.transferType?.trim() == ''
        ? 'Debe especificar el tipo de transferencia \n'
        : '';

    if (deviceParam.machineIdentification < 0) {
      msn += 'La cantidad de dispositivos debe ser positiva';
    }

    msn += !deviceParam.protocol ? 'Debe selecionar un protocolo \n' : '';

    msn += !deviceParam.ratedPower ? 'Debe especificar la carga asignada \n' : '';

    msn += !deviceParam.transformerVoltage ? 'Debe especificar la tensión del transformador \n' : '';

    return msn;
  };

  const [Protocol, setProtocol] = React.useState('');
  const [Classification, setClassification] = React.useState('');

  const handleProtocolo = (event) => {
    setProtocol(event.target.value);
    deviceForm.protocol = event.target.value;
  };
  const handleClassification = (event) => {
    setClassification(event.target.value);
    deviceForm.classification = event.target.value;
  };

  const validateListProtocol = (protocol) => {
    if (protocol === 'MODBUSTCPIP') {
      return 'MODBUS TCP/IP';
    }
    return protocol;
  };

  return (
    <Box>
      <Card className="border-10">
        <CardContent>
          <form noValidate={false} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xl={4} lg={4} md={6} xs={12} sm={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    id="name"
                    label="Nombre del dispositivo"
                    fullWidth
                    variant="outlined"
                    value={deviceForm.name}
                    onChange={(valueEvent) => setDeviceForm({ ...deviceForm, name: valueEvent.target.value })}
                  />
                </Box>
              </Grid>
              <Grid item xl={4} lg={4} md={6} xs={12} sm={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    id="machineName"
                    label="Carga Asignada"
                    fullWidth
                    variant="outlined"
                    value={deviceForm.machineName}
                    onChange={(valueEvent) => setDeviceForm({ ...deviceForm, machineName: valueEvent.target.value })}
                  />
                </Box>
              </Grid>

              <Grid item xl={4} lg={4} md={6} xs={12} sm={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    id="machineIdentification"
                    fullWidth
                    label="Identificación de la máquina"
                    type="number"
                    variant="outlined"
                    value={deviceForm.machineIdentification}
                    onChange={(valueEvent) =>
                      setDeviceForm({ ...deviceForm, machineIdentification: valueEvent.target.value })
                    }
                  />
                </Box>
              </Grid>
              <Grid item xl={4} lg={4} md={6} xs={12} sm={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    id="ratedPower"
                    fullWidth
                    label="Potencia nominal KVA"
                    type="number"
                    variant="outlined"
                    value={deviceForm.ratedPower}
                    onChange={(valueEvent) => setDeviceForm({ ...deviceForm, ratedPower: valueEvent.target.value })}
                  />
                </Box>
              </Grid>
              <Grid item xl={4} lg={4} md={6} xs={12} sm={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    id="transformerVoltage"
                    fullWidth
                    label="Tensión de transformador (V)"
                    type="number"
                    variant="outlined"
                    value={deviceForm.transformerVoltage}
                    onChange={(valueEvent) =>
                      setDeviceForm({ ...deviceForm, transformerVoltage: valueEvent.target.value })
                    }
                  />
                </Box>
              </Grid>

              <Grid item xl={4} lg={4} md={6} xs={12} sm={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="classification">Tipificación</InputLabel>
                    <Select
                      labelId="classification"
                      id="classification"
                      variant="outlined"
                      fullWidth={true}
                      value={Classification}
                      onChange={handleClassification}
                    >
                      <MenuItem value="" key="none">
                        <em>Ninguna</em>
                      </MenuItem>

                      {props.classification.map((element) => {
                        return (
                          <MenuItem value={element} key={element}>
                            {validateListProtocol(element)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xl={4} lg={4} md={4} xs={12} sm={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="typeProtocol">Protocol</InputLabel>
                    <Select
                      labelId="typeProtocol"
                      id="typeProtocol"
                      variant="outlined"
                      fullWidth={true}
                      value={Protocol}
                      onChange={handleProtocolo}
                    >
                      <MenuItem value="" key="none">
                        <em>Ninguna</em>
                      </MenuItem>

                      {props.typeProtocol.map((element) => {
                        return (
                          <MenuItem value={element} key={element}>
                            {validateListProtocol(element)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xl={4} lg={4} md={4} xs={12} sm={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    fullWidth
                    id="reference"
                    label="Referencia"
                    variant="outlined"
                    value={deviceForm.reference}
                    onChange={(valueEvent) => setDeviceForm({ ...deviceForm, reference: valueEvent.target.value })}
                  />
                </Box>
              </Grid>
              <Grid item xl={4} lg={4} md={4} xs={12} sm={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    required
                    fullWidth
                    id="transferType"
                    label="Tipo de transferencia"
                    variant="outlined"
                    value={deviceForm.transferType}
                    onChange={(valueEvent) => setDeviceForm({ ...deviceForm, transferType: valueEvent.target.value })}
                  />
                </Box>
              </Grid>

              <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                <Box height={1} width={1} boxShadow={5} borderRadius={10} className="input-element">
                  <TextField
                    fullWidth
                    id="description"
                    label="Descripción"
                    variant="outlined"
                    multiline
                    rows={2}
                    value={deviceForm.description}
                    onChange={(valueEvent) => setDeviceForm({ ...deviceForm, description: valueEvent.target.value })}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <CardActions className="center d-flex">
          <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
            <Button
              className="bg-green-gradient mr-2 pl-5 pr-5"
              color="primary"
              style={{ background: '#e3dafb', borderRadius: '30px', width: '100%', marginRight: '5px' }}
              onClick={handleSave}
            >
              <Save />
              {device.id ? 'Actualizar' : 'Guardar'}
            </Button>

            <Button
              className="bg-green-gradient mr-2 pl-5 pr-5"
              style={{ background: '#5404b4', color: 'white', borderRadius: '30px', width: '100%' }}
              disabled={device == {}}
              onClick={handleClean}
            >
              <Brush />
              Limpiar
            </Button>
          </ButtonGroup>
        </CardActions>
      </Card>
    </Box>
  );
};

const mapDispatchToProps = {
  saveDevice,
  setDevice,
  listIots,
  fetchRequest,
  updateDevice,
  getTypeProtocol,
  getClasification,
  fetchRequestIot,
  fetchHeadquarter,
};

const mapStateToProps = (state) => {
  return {
    selectedIot: state.iotReducer.selectedIot ? state.iotReducer.selectedIot : undefined,
    device: state.deviceReducer.selectedDevice ? state.deviceReducer.selectedDevice : {},
    isRequiredIots: state.iotReducer.isRequiredIots
      ? state.iotReducer.isRequiredIots
      : appConstants.IS_REQUIRED_IOT_LIST,
    selectedHeadquarter: state.headquarterReducer.selectedHeadquarter
      ? state.headquarterReducer.selectedHeadquarter
      : undefined,

    isRequiredTypeProtocol: state.deviceReducer.isRequiredTypeProtocol
      ? state.deviceReducer.isRequiredTypeProtocol
      : appConstants.IS_REQUIRED_MASTER_FIELDS_TYPEPROTOCOL,

    isRequiredClassification: state.deviceReducer.isRequiredClassification
      ? state.deviceReducer.isRequiredClassification
      : appConstants.IS_REQUIRED_CLASIFICATION,

    typeProtocol: state.deviceReducer.typeProtocol ? state.deviceReducer.typeProtocol : [],
    classification: state.deviceReducer.classification ? state.deviceReducer.classification : [],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormDevice);
