import { appConstants } from '../../appConstants';
import { commonMessage, errorHandler, icon } from '../../service/messageHandler';
import { alarmActions } from '../action/alarmActions';

const alarmReducer = (state = {}, action) => {
  switch (action.type) {
    case alarmActions.FETCHING_ALARM_LIST:
      return {
        ...state,
        loading: { open: true },
        isRequiredAlarms: appConstants.IS_NOT_REQUIRED_ALARMS,
      };
    case alarmActions.FETCHING_ALARM_LIST_SUCCESS:
      let result = [];
      action.payload.content.forEach((element) => {
        if (element.alarms != null) {
          element.alarms.forEach((item) => {
            let aux = {
              field: element,
              alarm: item,
            };

            result = result.concat(aux);
          });
        }
      });

      return {
        ...state,
        loading: { open: false },
        alarms: result,
      };
    case alarmActions.FETCHING_ALARM_FAILURE:
      errorHandler(action.payload);
      return {
        ...state,
        laoding: { open: false },
      };
    case alarmActions.ALARM_MODAL_CREATE: {
      return {
        ...state,
        alarmCreationModal: state.alarmCreationModal ? false : true,
      };
    }

    case alarmActions.FETCHING_ALARM_REQUEST:
    case alarmActions.FETCHING_ALARM_SAVE: {
      return {
        ...state,
        loading: { open: true },
      };
    }

    case alarmActions.FETCHING_ALARM_SAVE_SUCCESS: {
      commonMessage(appConstants.SAVE_ALARM_SUCCESS_MESSAGE, appConstants.SUCCESFUL_SAVED_TITLE, icon.success);
      return {
        ...state,
        loading: { open: false },
        selectedAlarms: {},
        alarmCreationModal: false,
        isRequiredAlarms: appConstants.IS_REQUIRED_ALARMS,
      };
    }

    case alarmActions.CLEAN_ALARM_STORE:
      return {
        ...state,
        avaliabledAlarms: [],
        isRequiredAlarms: appConstants.IS_REQUIRED_ALARMS,
        selectedAlarms: {},
      };

    case alarmActions.SET_ALARM:
      return {
        ...state,
        selectedfields: action.payload.item,
      };

    case alarmActions.FETCHING_ALARM_FAILURE:
      errorHandler(action);
      return { ...state, loading: { open: false } };

    case alarmActions.FETCHING_ALARM_DELETE_SUCCESS:
      commonMessage(appConstants.DELETE_ALARM_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);
      return {
        ...state,
        selectedAlarms: {},
        loading: { open: false },
        isRequiredAlarms: appConstants.IS_REQUIRED_ALARMS,
      };
      case alarmActions.FETCHING_ALARM_UPDATE_SUCCESS:
      commonMessage(appConstants.UPDATE_ALARM_SUCCESS_MESSAGE, appConstants.SUCCESFUL_UPDATE_TITLE, icon.success);
      return {
        ...state,
        selectedAlarms: {},
        loading: { open: false },
        isRequiredAlarms: appConstants.IS_REQUIRED_ALARMS,
      };
   
    default:
      return state;
  }
};

export default alarmReducer;
