import { appConstants } from '../../appConstants';
import ApiAxios from '../../service/Api';

export const alarmHistoryActions = {
  FETCHING_ALARM_HISTORY_REQUEST: 'FETCHING_ALARM_HISTORY_REQUEST',
  FETCHING_ALARM_HISTORY_LIST: 'FETCHING_ALARM_HISTORY_LIST',
  FETCHING_ALARM_HISTORY_SAVE: 'FETCHING_ALARM_HISTORY_SAVE',
  FETCHING_ALARM_HISTORY_SAVE_SUCCESS: 'FETCHINGFETCHING_ALARM_HISTORY_SAVE_ALARM_HISTORY_SAVE_SUCCESS',
  FETCHING_ALARM_HISTORY_UPDATE_SUCCESS: 'FETCHING_ALARM_HISTORY_UPDATE_SUCCESS',
  FETCHING_ALARM_HISTORY_LIST_SUCCESS: 'FETCHING_ALARM_HISTORY_LIST_SUCCESS',
  FETCHING_ALARM_HISTORY_FAILURE: 'FETCHING_ALARM_HISTORY_FAILURE',
  CLEAN_ALARM_HISTORY_STORE: 'CLEAN_ALARM',
  ALARM_MODAL_CREATE: 'ALARM_MODAL_CREATE',
  FETCHING_ALARM_HISTORY_DELETE_SUCCESS: 'FETCHING_ALARM_HISTORY_DELETE_SUCCESS',
  SET_ALARM_HISTORY: 'SET_ALARM_HISTORY',
};

export const fetchRequest = (type, payload = undefined) => {
  return {
    type,
    payload,
  };
};

export const listHistoryAlarms = (filter) => {
  return function (dispatch) {
    ApiAxios.crudGeneric.post(appConstants.CONTEXT_ALARMS, filter, dispatch, fetchRequest, {
      request: alarmHistoryActions.FETCHING_ALARM_HISTORY_LIST,
      success: alarmHistoryActions.FETCHING_ALARM_HISTORY_LIST_SUCCESS,
      error: alarmHistoryActions.FETCHING_ALARM_HISTORY_FAILURE,
    });
  };
};

export const cleanAlarm = () => {
  return {
    type: alarmHistoryActions.CLEAN_ALARM_HISTORY_STORE,
  };
};
